export const VEHICLE_TYPE = "VEHICLE_TYPE";
export const VEHICLE_TYPE_SUCCESS = "VEHICLE_TYPE_SUCCESS";
export const VEHICLE_TYPE_FAILED = "VEHICLE_TYPE_FAILED";
export const SAVE_VEHICLE_TYPE = "SAVE_VEHICLE_TYPE";
export const SAVE_VEHICLE_TYPE_SUCCESS = "SAVE_VEHICLE_TYPE_SUCCESS";
export const SAVE_VEHICLE_TYPE_FAILED = "SAVE_VEHICLE_TYPE_FAILED";
export const EDIT_VEHICLE_TYPE = "EDIT_VEHICLE_TYPE";
export const EDIT_VEHICLE_TYPE_SUCCESS = "EDIT_VEHICLE_TYPE_SUCCESS";
export const EDIT_VEHICLE_TYPE_FAILED = "EDIT_VEHICLE_TYPE_FAILED";
export const DELETE_VEHICLE_TYPE = "DELETE_VEHICLE_TYPE";
export const DELETE_VEHICLE_TYPE_SUCCESS =
  "DELETE_VEHICLE_TYPE_SUCCESS";
export const DELETE_VEHICLE_TYPE_FAILED = "DELETE_VEHICLE_TYPE_FAILED";
export const GET_VEHICLE_TYPE = "GET_VEHICLE_TYPE";
export const GET_VEHICLE_TYPE_SUCCESS = "GET_VEHICLE_TYPE_SUCCESS";
export const GET_VEHICLE_TYPE_FAILED = "GET_VEHICLE_TYPE_FAILED";
