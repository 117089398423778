import { find as _find } from "lodash";
import { createSelector } from "reselect";

// import { countries } from 'pages/Signup/constants';

export const cartCount = createSelector(
  (state) => state.Cart,
  ({ data }) => ({ cartItemCount: data?.length })
);

export const cartIds = createSelector(
  (state) => state.Cart,
  ({ data }) =>
    data
      ? { cartIdList: data?.map((v) => v?.rfqResponseId) }
      : { cartIdList: [] }
);

export const userAddressSelector = createSelector(
  (state) => state.Cart,
  ({ userAddress }) => ({ ...userAddress })
);
