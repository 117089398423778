// @flow
import {
  DELETE_VEHICLE_MAKE,
  DELETE_VEHICLE_MAKE_FAILED,
  DELETE_VEHICLE_MAKE_SUCCESS,
  VEHICLE_MAKE,
  VEHICLE_MAKE_FAILED,
  VEHICLE_MAKE_SUCCESS,
  GET_VEHICLE_MAKE,
  GET_VEHICLE_MAKE_FAILED,
  GET_VEHICLE_MAKE_SUCCESS,
} from "./constants";

const INIT_STATE = {
  data: [],
  vehicleMake: {},
  loading: false,
  error: null,
};

const VehicleMake = (state = INIT_STATE, action) => {
  switch (action.type) {
    case VEHICLE_MAKE:
      return { ...state, vehicleMake: {}, loading: true };
    case VEHICLE_MAKE_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
        error: null,
      };
    case VEHICLE_MAKE_FAILED:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case GET_VEHICLE_MAKE:
      return { ...state, loading: true };
    case GET_VEHICLE_MAKE_SUCCESS:
      return {
        ...state,
        vehicleMake: action.payload,
        loading: false,
        error: null,
      };
    case GET_VEHICLE_MAKE_FAILED:
      return { ...state, error: action.payload, loading: false };
    case DELETE_VEHICLE_MAKE:
      return { ...state, loading: true };
    case DELETE_VEHICLE_MAKE_SUCCESS: {
      return {
        ...state,
        data: {
          ...state?.data,
          total: state?.data?.total - 1,
          data: state?.data?.data?.filter((v) => v?.id !== action?.payload?.id),
        },
        loading: false,
        error: null,
      };
    }
    case DELETE_VEHICLE_MAKE_FAILED:
      return { ...state, error: action.payload, loading: false };
    default:
      return { ...state };
  }
};

export default VehicleMake;
