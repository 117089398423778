import moment from "moment";
import React, { useEffect, useState } from "react";
import { Type } from "react-bootstrap-table2-editor";
import { selectFilter, textFilter } from "react-bootstrap-table2-filter";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Alert, Button, Col, Row } from "reactstrap";
import CustomModal from "../../components/CustomModal/CustomModal";
import OrderStatusDropDown from "../../components/OrderStatusDropDown";
import PageTitle from "../../components/PageTitle";
import Status from "../../components/Status";
import Actions from "../../components/table/Actions";
import Table from "../../components/table/Table";
import useTableActions from "../../components/table/useActions";
import { RFQ_STATUS } from "../../constants";
import { ORDER_STATUS } from "../../constants/orderStatus";
import { useModal } from "../../hooks/useModal";
import { appNotification } from "../../redux/app/actions";
import { getOrders } from "../../redux/orders/actions";
import { getRewardPoints, getRfq } from "../../redux/rfq/actions";
import { deleteUser, getUsers } from "../../redux/users/actions";
import {
  getAllServiceProviderBusinessApi,
  getRfqAddOnModalByRfq,
} from "../../services/api";
import {
  addPointHistory,
  getPointHistory,
  updatePointHistory,
} from "../../redux/rewardPoints/actions";
import {
  pointsHistorySelector,
  updatePointHistoryStateSelector,
} from "../../redux/rewardPoints/selectors";
import { ButtonGroup, Form, FormControl, Spinner } from "react-bootstrap";
import AsyncReactSelect from "react-select/async";

const columns = ({
  hasFilter = false,
  handleMarkAsCompleted,
  updatePointHistoryData = { loading: false, id: null },
}) => [
  {
    dataField: "id",
    text: "ID",
    sort: true,
    filter: hasFilter && textFilter(),
    editable: false,
  },
  {
    dataField: "user.id",
    text: "User",
    sort: true,
    filter: hasFilter && textFilter(),
    formatter: (cell, { user }) => {
      return (
        <div className="d-flex me-2">
          <div className="d-flex flex-column">
            <span>
              {[user?.name, user?.phoneNumber, user?.userCode]
                .filter((v) => v)
                .join(" | ")}
            </span>
          </div>
        </div>
      );
    },
    editable: false,
  },
  {
    dataField: "user.service_provider_business.businessName",
    text: "Business name",
    sort: true,
    formatter: (cell, row) => (
      <span>
        {row?.user?.service_provider_business
          .map((v) => v?.businessName)
          .filter((v) => v)
          .join(" | ")}
      </span>
    ),
  },
  {
    dataField: "points",
    text: "Points",
    sort: true,
  },

  {
    dataField: "created_at",
    text: "Created on",
    sort: true,
    filter: hasFilter && textFilter(),
    formatter: (cell, row) =>
      row?.created_at
        ? moment(row?.created_at).format("DD-MM-YYYY hh:mm:A")
        : null,
    editable: false,
  },
  {
    dataField: "updated_at",
    text: "Updated on",
    sort: true,
    filter: hasFilter && textFilter(),
    formatter: (cell, row) =>
      row?.updated_at
        ? moment(row?.updated_at).format("DD-MM-YYYY hh:mm:A")
        : null,
    editable: false,
  },

  {
    dataField: "status",
    text: "Status",
    sort: true,
    // isDummyField: true,
    exportCSV: false,
    formatter: Actions,
    formatExtraData: {
      canDelete: false,
      canEdit: false,
      canView: false,
      renderAction: (row) => (
        <>
          {row?.status === "PROCESSING" ? (
            updatePointHistoryData.id === row.id &&
            updatePointHistoryData?.loading ? (
              <Spinner />
            ) : (
              <Button className="btn-sm" onClick={handleMarkAsCompleted(row)}>
                Mark as redeemed
              </Button>
            )
          ) : (
            <span className="badge badge-soft-warning py-1">{row?.status}</span>
          )}
        </>
      ),
    },
    editable: false,
  },
];

const RewardPoints = () => {
  const [pagination, setPagination] = useState({
    page: 1,
    searchText: "",
    sizePerPage: 10,
    sortField: "created_at",
    sortOrder: "desc",
  });
  const [addOnData, setAddOnData] = useState({
    user_id: null,
    comments: "",
    status: "ADDON",
    points: null,
  });
  const dispatch = useDispatch();
  const {
    open: openAddOnModal,
    onOpen: onOpenAddOnModal,
    onClose: onCloseAddOnModal,
  } = useModal();
  const { data, error, loading } = useSelector(pointsHistorySelector);
  const {
    data: updatePointData,
    error: updatePointError,
    loading: updatePointLoading,
  } = useSelector(updatePointHistoryStateSelector);

  const [hasFilter, setHasFilter] = useState(false);

  const toggleFilter = () => setHasFilter(!hasFilter);
  const handleTableChange = (e, v) => {
    console.log(e, v);
    const { page, searchText, sizePerPage, sortField, sortOrder } = v;
    setPagination({ page, searchText, sizePerPage, sortField, sortOrder });
    dispatch(
      getPointHistory({
        page,
        searchText,
        sizePerPage,
        sortField,
        sortOrder,
      })
    );
  };
  const handleMarkAsCompleted = (row) => (e) => {
    dispatch(
      updatePointHistory({
        id: row?.id,
        status: "REDEEMED",
      })
    );
  };
  const handleServiceProviderChange = (options, actionMeta) => {
    setAddOnData((s) => ({ ...s, user_id: options.userId }));
  };
  const handlePointsChange = (e) => {
    const points = e.target.value;
    setAddOnData((s) => ({
      ...s,
      points,
      comments: `${points} has been credited as rewards`,
    }));
  };
  const handleAddOnSubmit = (e) => {
    dispatch(addPointHistory(addOnData));
    onCloseAddOnModal();
  };
  const fetchServiceProviderOptions = async (search) => {
    const res = await getAllServiceProviderBusinessApi(search);
    return res?.data?.data;
  };
  useEffect(() => {
    dispatch(getPointHistory(pagination));
  }, []);

  return (
    <>
      <Row className="page-title">
        <Col md={9}>
          <PageTitle
            breadCrumbItems={[
              {
                label: "Reward points",
                path: "/reward-points",
              },
            ]}
            title={"Reward points"}
          />
        </Col>
        <Col md={3} sm={12}>
          <Button onClick={onOpenAddOnModal}>Add extra points</Button>
        </Col>
      </Row>
      {error && <Alert color="danger">Oops something went wrong</Alert>}
      <Table
        loading={loading}
        records={data?.data}
        disableExport={true}
        columns={columns({
          hasFilter,
          handleMarkAsCompleted,
          updatePointHistoryData: {
            id: updatePointData?.id,
            loading: updatePointLoading,
          },
        })}
        handleTableChange={handleTableChange}
        pagination={{ ...pagination, totalSize: data?.total }}
        canCellEdit={false}
      />
      <CustomModal
        noFooter
        open={openAddOnModal}
        handleOnClose={onCloseAddOnModal}
        closeModal={onCloseAddOnModal}
      >
        <Form>
          <AsyncReactSelect
            loadOptions={fetchServiceProviderOptions}
            getOptionLabel={(option) => option.businessName}
            getOptionValue={(option) => option.id}
            onChange={handleServiceProviderChange}
            placeholder="Select business"
          />
          <Form.Group>
            <Form.Label>Enter addon points</Form.Label>
            <FormControl
              placeholder="Enter points"
              type="number"
              onChange={handlePointsChange}
              value={addOnData.points}
            />
          </Form.Group>
          <Form.Group>
            <ButtonGroup>
              <Button onClick={handleAddOnSubmit}>Save</Button>
            </ButtonGroup>
          </Form.Group>
        </Form>
      </CustomModal>
    </>
  );
};

export default RewardPoints;
