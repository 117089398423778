export const CAR_TRIM = "CAR_TRIM";
export const CAR_TRIM_SUCCESS = "CAR_TRIM_SUCCESS";
export const CAR_TRIM_FAILED = "CAR_TRIM_FAILED";
export const SAVE_CAR_TRIM = "SAVE_CAR_TRIM";
export const SAVE_CAR_TRIM_SUCCESS = "SAVE_CAR_TRIM_SUCCESS";
export const SAVE_CAR_TRIM_FAILED = "SAVE_CAR_TRIM_FAILED";
export const EDIT_CAR_TRIM = "EDIT_CAR_TRIM";
export const EDIT_CAR_TRIM_SUCCESS = "EDIT_CAR_TRIM_SUCCESS";
export const EDIT_CAR_TRIM_FAILED = "EDIT_CAR_TRIM_FAILED";
export const DELETE_CAR_TRIM = "DELETE_CAR_TRIM";
export const DELETE_CAR_TRIM_SUCCESS =
  "DELETE_CAR_TRIM_SUCCESS";
export const DELETE_CAR_TRIM_FAILED = "DELETE_CAR_TRIM_FAILED";
export const GET_CAR_TRIM = "GET_CAR_TRIM";
export const GET_CAR_TRIM_SUCCESS = "GET_CAR_TRIM_SUCCESS";
export const GET_CAR_TRIM_FAILED = "GET_CAR_TRIM_FAILED";
