export const SERVICE_CATEGORY = "SERVICE_CATEGORY";
export const SERVICE_CATEGORY_SUCCESS = "SERVICE_CATEGORY_SUCCESS";
export const SERVICE_CATEGORY_FAILED = "SERVICE_CATEGORY_FAILED";
export const SAVE_SERVICE_CATEGORY = "SAVE_SERVICE_CATEGORY";
export const SAVE_SERVICE_CATEGORY_SUCCESS = "SAVE_SERVICE_CATEGORY_SUCCESS";
export const SAVE_SERVICE_CATEGORY_FAILED = "SAVE_SERVICE_CATEGORY_FAILED";
export const EDIT_SERVICE_CATEGORY = "EDIT_SERVICE_CATEGORY";
export const EDIT_SERVICE_CATEGORY_SUCCESS = "EDIT_SERVICE_CATEGORY_SUCCESS";
export const EDIT_SERVICE_CATEGORY_FAILED = "EDIT_SERVICE_CATEGORY_FAILED";
export const DELETE_SERVICE_CATEGORY = "DELETE_SERVICE_CATEGORY";
export const DELETE_SERVICE_CATEGORY_SUCCESS =
  "DELETE_SERVICE_CATEGORY_SUCCESS";
export const DELETE_SERVICE_CATEGORY_FAILED = "DELETE_SERVICE_CATEGORY_FAILED";
export const GET_SERVICE_CATEGORY = "GET_SERVICE_CATEGORY";
export const GET_SERVICE_CATEGORY_SUCCESS = "GET_SERVICE_CATEGORY_SUCCESS";
export const GET_SERVICE_CATEGORY_FAILED = "GET_SERVICE_CATEGORY_FAILED";
