import {
  DELETE_VEHICLE_MAKE,
  DELETE_VEHICLE_MAKE_FAILED,
  DELETE_VEHICLE_MAKE_SUCCESS,
  EDIT_VEHICLE_MAKE,
  EDIT_VEHICLE_MAKE_FAILED,
  EDIT_VEHICLE_MAKE_SUCCESS,
  SAVE_VEHICLE_MAKE,
  SAVE_VEHICLE_MAKE_FAILED,
  SAVE_VEHICLE_MAKE_SUCCESS,
  VEHICLE_MAKE,
  VEHICLE_MAKE_FAILED,
  VEHICLE_MAKE_SUCCESS,
  GET_VEHICLE_MAKE,
  GET_VEHICLE_MAKE_SUCCESS,
  GET_VEHICLE_MAKE_FAILED,
} from "./constants";

export const getVehicleMake = (id) => ({
  type: GET_VEHICLE_MAKE,
  payload: { id },
});

export const getVehicleMakeSuccess = (data) => ({
  type: GET_VEHICLE_MAKE_SUCCESS,
  payload: data,
});

export const getVehicleMakeFailed = (errorMessage) => ({
  type: GET_VEHICLE_MAKE_FAILED,
  payload: errorMessage,
});

export const getVehicleMakes = (payload) => ({
  type: VEHICLE_MAKE,
  payload,
});

export const getVehicleMakesSuccess = (data) => ({
  type: VEHICLE_MAKE_SUCCESS,
  payload: { data },
});

export const getVehicleMakesFailed = (errorMessage) => ({
  type: VEHICLE_MAKE_FAILED,
  payload: errorMessage,
});

export const saveVehicleMake = ({ data, history }) => ({
  type: SAVE_VEHICLE_MAKE,
  payload: { data, history },
});

export const saveVehicleMakeSuccess = (success) => ({
  type: SAVE_VEHICLE_MAKE_SUCCESS,
  payload: success,
});

export const saveVehicleMakeFailed = (errorMessage) => ({
  type: SAVE_VEHICLE_MAKE_FAILED,
  payload: errorMessage,
});

export const editVehicleMake = (data) => ({
  type: EDIT_VEHICLE_MAKE,
  payload: data,
});

export const editVehicleMakeSuccess = (success) => ({
  type: EDIT_VEHICLE_MAKE_SUCCESS,
  payload: success,
});

export const editVehicleMakeFailed = (errorMessage) => ({
  type: EDIT_VEHICLE_MAKE_FAILED,
  payload: errorMessage,
});

export const deleteVehicleMake = (data) => ({
  type: DELETE_VEHICLE_MAKE,
  payload: data,
});

export const deleteVehicleMakeSuccess = (success) => ({
  type: DELETE_VEHICLE_MAKE_SUCCESS,
  payload: success,
});

export const deleteVehicleMakeFailed = (errorMessage) => ({
  type: DELETE_VEHICLE_MAKE_FAILED,
  payload: errorMessage,
});
