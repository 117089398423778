// @flow
import { Cookies } from "react-cookie";

/**
 * Sets the session
 * @param {*} user
 */
const saveUserSession = (user) => {
  if (user) new Cookies().set("user", JSON.stringify(user), { path: "/" });
  else new Cookies().remove("user", { path: "/" });
};
const getUserSession = () => {
  const user = new Cookies().get("user");
  return user ? (typeof user == "object" ? user : JSON.parse(user)) : null;
};

const getUserRole = () => {
  const user = new Cookies().get("user");
  const currentUser = user
    ? typeof user == "object"
      ? user
      : JSON.parse(user)
    : null;
  if (currentUser) {
    const currentRole = currentUser?.roles?.map((v) => v?.role_slug);
    if (currentRole?.includes("supplier")) return "supplier";
    if (currentRole?.includes("service-provider")) return "service-provider";
    if (currentRole?.includes("user")) return "user";
    if (currentRole?.includes("insurance-provider"))
      return "insurance-provider";
    if (currentRole?.includes("admin")) return "admin";
    return currentRole?.[0];
  }
  return null;
};
const getCsrfToken = () => {
  const csrfToken = new Cookies().get("XSRF-TOKEN");
  return { csrfToken };
};
const clearUserSession = () => {
  new Cookies().remove("user", { path: "/" });
  new Cookies().remove("XSRF-TOKEN", { path: "/" });
};

/**
 * Checks if user is authenticated
 */
const isUserAuthenticated = () => {
  const user = getLoggedInUser();
  if (!user) {
    return false;
  }
  return !!user.token;
  // const decoded = (user.token);
  // const currentTime = Date.now() / 1000;
  // if (decoded.exp < currentTime) {
  //     console.warn('access token expired');
  //     return false;
  // } else {
  //     return true;
  // }
};

/**
 * Returns the logged in user
 */
const getLoggedInUser = () => {
  const cookies = new Cookies();
  const user = cookies.get("user");
  return user ? (typeof user == "object" ? user : JSON.parse(user)) : null;
};

const getLoggedInUserRoles = () => {
  const cookies = new Cookies();
  const user = cookies.get("user");
  const u = user ? (typeof user == "object" ? user : JSON.parse(user)) : null;
  if (u) {
    return u?.roles?.map((v) => v?.role_slug);
  }
  return [];
};

export {
  isUserAuthenticated,
  getLoggedInUser,
  getLoggedInUserRoles,
  getCsrfToken,
  getUserSession,
  clearUserSession,
  getUserRole,
  saveUserSession,
};
