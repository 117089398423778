import React, { useRef, useState } from "react";
import { Clipboard, Share } from "react-feather";
import { Input } from "reactstrap";

export const CopyAndShareText = ({ text, type = "text" }) => {
  const textRef = useRef(text);
  const [isCopied, setIsCopied] = useState(false);
  const handleCopyClick = async () => {
    try {
      console.log("textRef.current.value", textRef?.current?.value);
      await navigator.clipboard.writeText(textRef.current.value);
      console.log("Text copied to clipboard");
      setIsCopied(true);
    } catch (err) {
      console.error("Failed to copy text: ", err);
    }
  };

  const handleShareClick = async () => {
    try {
      await navigator.share({ text });
      console.log("Text shared successfully");
    } catch (err) {
      console.error("Failed to share text: ", err);
    }
  };

  return (
    <div className="d-flex">
      <Input type={type} readOnly innerRef={textRef} value={text} />
      <button
        onClick={handleCopyClick}
        disabled={isCopied}
        className="btn btn-outline-primary"
      >
        {!isCopied ? "Copy" : "Copied"}
      </button>
      <button onClick={handleShareClick} className="btn btn-outline-secondary">
        <Share />
      </button>
    </div>
  );
};
