import {
  all,
  call,
  fork,
  put,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";

import { authRequest } from "../../helpers/api";
import { appNotification } from "../app/actions";
import {
  deleteWhatsAppGroupFailed,
  deleteWhatsAppGroupSuccess,
  getWhatsAppGroupFailed,
  getWhatsAppGroupSuccess,
  saveWhatsAppGroupFailed,
  saveWhatsAppGroupSuccess,
  updateWhatsAppGroup,
  updateWhatsAppGroupFailed,
  updateWhatsAppGroupSuccess,
} from "./actions";
import {
  DELETE_WHATSAPP_GROUP,
  EDIT_WHATSAPP_GROUP,
  GET_WHATSAPP_GROUP,
  SAVE_WHATSAPP_GROUP,
} from "./constants";

function* getWhatsAppGroup({ payload }) {
  try {
    let url = `/whatsapp-group?`;
    if (payload)
      Object.keys(payload).map(
        (params) =>
          payload?.[params] && (url += `${params}=${payload[params]}&`)
      );
    const response = yield call(authRequest, url.slice(0, url.length - 1), {
      method: "get",
    });
    console.log("rs", response);
    yield put(getWhatsAppGroupSuccess(response?.data));
  } catch (error) {
    let message = "Failed to get whatApp group";
    yield put(getWhatsAppGroupFailed(message));
  }
}

function* saveWhatsAppGroup({ payload: { data, history } }) {
  try {
    const formData = new FormData();
    Object.keys(data).map((key) => formData.append(key, data[key]));
    let url = `/whatsapp-group`;
    if (data?.id) url += `/${data?.id}?_method=PUT`;

    const response = yield call(authRequest, url, {
      method: "post",
      data: formData,
      headers: {
        "content-type": "multipart/form-data",
      },
    });
    yield put(saveWhatsAppGroupSuccess(response?.data));
    yield put(appNotification({ type: "success", message: response?.message }));
    history.goBack();
  } catch (error) {
    let message = "Failed to save whatsApp group";
    yield put(saveWhatsAppGroupFailed(message));
  }
}
function* updateWhatsAppGroupSaga({ payload }) {
  try {
    const data = payload;
    const formData = new FormData();
    Object.keys(data).map((key) => formData.append(key, data[key]));
    let url = `/whatsapp-group`;
    if (data?.id) url += `/${data?.id}?_method=PUT`;

    const response = yield call(authRequest, url, {
      method: "post",
      data: formData,
      headers: {
        "content-type": "multipart/form-data",
      },
    });
    console.log("response", response);
    yield put(updateWhatsAppGroupSuccess(response?.data));
    yield put(appNotification({ type: "success", message: response?.message }));
  } catch (error) {
    let message = "Failed to save whatsApp group";
    yield put(updateWhatsAppGroupFailed(message));
  }
}

function* deleteWhatsAppGroup({ payload: { id } }) {
  try {
    const response = yield call(authRequest, `/whatsapp-group/${id}`, {
      method: "delete",
    });
    yield put(appNotification({ type: "success", message: response?.message }));
    yield put(deleteWhatsAppGroupSuccess({ id }));
  } catch (error) {
    console.log("error", error);
    let message = "Failed to get whatApp group";
    yield put(deleteWhatsAppGroupFailed(message));
  }
}

export function* watchGetWhatsAppGroup() {
  yield takeLatest(GET_WHATSAPP_GROUP, getWhatsAppGroup);
}
export function* watchSaveWhatsAppGroup() {
  yield takeLatest(SAVE_WHATSAPP_GROUP, saveWhatsAppGroup);
}
export function* watchEditWhatsAppGroup() {
  yield takeLatest(EDIT_WHATSAPP_GROUP, updateWhatsAppGroupSaga);
}
export function* watchDeleteWhatsAppGroup() {
  yield takeLatest(DELETE_WHATSAPP_GROUP, deleteWhatsAppGroup);
}

function* WhatsAppGroupSaga() {
  yield all([
    fork(watchGetWhatsAppGroup),
    fork(watchSaveWhatsAppGroup),
    fork(watchEditWhatsAppGroup),
    fork(watchGetWhatsAppGroup),
    fork(watchDeleteWhatsAppGroup),
  ]);
}

export default WhatsAppGroupSaga;
