import {
  all,
  call,
  fork,
  put,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";

import { authRequest } from "../../helpers/api";
import { appNotification } from "../app/actions";
import {
  getOrdersFailed,
  getOrdersSuccess,
  getOrderFailed,
  getOrderSuccess,
} from "./actions";
import {
  GET_ORDER,
  EDIT_ORDER,
  ORDERS,
  SAVE_ORDER,
  ASSIGN_DEVILERY_AGENT,
} from "./constants";

function* getOrders({ payload }) {
  try {
    let url = `/orders?`;
    if (payload)
      Object.keys(payload).map(
        (params) =>
          payload?.[params] && (url += `${params}=${payload[params]}&`)
      );
    const response = yield call(authRequest, url.slice(0, url.length - 1), {
      method: "get",
    });
    yield put(getOrdersSuccess(response?.data));
  } catch (error) {
    let message = "Failed to get orders";
    yield put(getOrdersFailed(message));
  }
}

function* saveOrder({ payload: { payload, onSuccess } }) {
  try {
    const formData = new FormData();
    Object.keys(payload).map((key) => formData.append(key, payload[key]));
    let url = `/orders`;
    if (payload?.id) url += `/${payload?.id}?_method=PUT`;

    const response = yield call(authRequest, url, {
      method: "post",
      data: payload,
      //   headers: {
      //     "content-type": "multipart/form-data",
      //   },
    });
    yield put(getOrdersSuccess(response?.data));
    yield put(appNotification({ type: "success", message: response?.message }));
    if (onSuccess) yield call(() => onSuccess());
  } catch (error) {
    console.log("error", error);
    let message = "Failed to save user data";
    if (error?.errors) yield put(getOrdersFailed(error?.errors));
    else yield put(getOrdersFailed(message));
  }
}
function* getOrder({ payload: { id } }) {
  try {
    const response = yield call(authRequest, `/orders/${id}`, {
      method: "get",
    });
    yield put(getOrderSuccess(response?.data));
  } catch (error) {
    let message = "Failed to get orders";
    yield put(getOrderFailed(message));
  }
}

function* assignDeliveryAgentSaga({ payload: { payload, onSuccess } }) {
  try {
    const formData = new FormData();
    Object.keys(payload).map((key) => formData.append(key, payload[key]));
    let url = `/orders`;
    if (payload?.id) url += `/${payload?.id}?_method=PUT`;

    const response = yield call(authRequest, url, {
      method: "post",
      data: payload,
      //   headers: {
      //     "content-type": "multipart/form-data",
      //   },
    });
    yield put(getOrdersSuccess(response?.data));
    yield put(appNotification({ type: "success", message: response?.message }));
    if (onSuccess) yield call(() => onSuccess());
  } catch (error) {
    console.log("error", error);
    let message = "Failed to save user data";
    if (error?.errors) yield put(getOrdersFailed(error?.errors));
    else yield put(getOrdersFailed(message));
  }
}
export function* watchGetOrders() {
  yield takeLatest(ORDERS, getOrders);
}
export function* watchGetOrder() {
  yield takeEvery(GET_ORDER, getOrder);
}
export function* watchSaveOrder() {
  yield takeEvery(SAVE_ORDER, saveOrder);
}

export function* watchAssignDeliveryAgent() {
  yield takeEvery(ASSIGN_DEVILERY_AGENT, assignDeliveryAgentSaga);
}
function* sparePartsCategorySaga() {
  yield all([
    fork(watchGetOrders),
    fork(watchSaveOrder),
    fork(watchGetOrder),
    fork(watchAssignDeliveryAgent),
  ]);
}

export default sparePartsCategorySaga;
