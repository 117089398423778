// import { useSelector } from "react-redux";
import { Redirect, Route } from "react-router-dom";
import { getLoggedInUser, isUserAuthenticated } from "../helpers/authUtils";

const PrivateRoute = ({ component: Component, roles, ...rest }) => {
  //   const roless = useSelector((state) => state.Roles.roles);
  return (
    <Route
      {...rest}
      render={(props) => {
        if (!isUserAuthenticated()) {
          // not logged in so redirect to login page with the return url
          return (
            <Redirect
              to={{
                pathname: "/account/login",
                state: { from: props.location },
              }}
            />
          );
        }

        const loggedInUser = getLoggedInUser();
        // check if route is restricted by role
        loggedInUser["role"] = loggedInUser?.roles?.[0]?.role_slug;
        if (roles && roles.indexOf(loggedInUser.role) === -1) {
          // role not authorised so redirect to home page
          return <Redirect to={{ pathname: "/" }} />;
        }

        // authorised so return component
        return <Component {...props} />;
      }}
    />
  );
};

export default PrivateRoute;
