import React from "react";
import { Redirect } from "react-router-dom";
import { Route } from "react-router-dom";
import * as FeatherIcon from "react-feather";

import {
  Home as HomeIcon,
  Calendar as CalendarIcon,
  Inbox as InboxIcon,
  Briefcase as BriefcaseIcon,
  Bookmark as BookmarkIcon,
  FileText as FileTextIcon,
  Package as PackageIcon,
  PieChart as PieChartIcon,
  Grid as GridIcon,
  List,
  UserX,
} from "react-feather";

import partnerAuthRoutes from "./PartnerRoutes";
import adminRoutes from "./adminRoutes";
import serviceProviderRoutes from "./serviceProviderRoutes";
import PrivateRoute from "../components/PrivateRoute";
import supplierRoutes from "./supplierRoutes";
import ActivateUser from "../pages/partner/auth/ActivateUser";
import UpdateSupplierBusiness from "../pages/supplierBusinessEntry/UpdateSupplierBusiness";
import AddSupplierBusiness from "../pages/supplierBusinessEntry/AddSupplierBusiness";
import QuoteList from "../pages/QuoteList/QuoteList";
import AddEditQuoteList from "../pages/QuoteList/AddEditQuoteList";
import AddEditRfq from "../pages/rfq/AddEditRfq";
import ListRfq from "../pages/rfq/ListRfq";
import ListGarages from "../pages/garages/ListGarages";
import RewardPoints from "../pages/rewardPoints/RewardPoints";
import ListOrders from "../pages/orders/ListOrders";
import ViewOrders from "../pages/orders/ViewOrders";
import DownloadPdf from "../pages/DownloadPdf/DownloadPdf";
import DownloadAllInvoice from "../pages/DownloadPdf/DownloadAllInvoice";

// auth
const Login = React.lazy(() => import("../pages/partner/auth/Login"));
const AdminLogin = React.lazy(() => import("../pages/auth/Login"));
const Logout = React.lazy(() => import("../pages/auth/Logout"));
const Register = React.lazy(() => import("../pages/partner/auth/Register"));
const ForgetPassword = React.lazy(() =>
  import("../pages/partner/auth/ForgetPassword")
);
const ResetPassword = React.lazy(() =>
  import("../pages/partner/auth/ResetPassword")
);
const Confirm = React.lazy(() => import("../pages/auth/Confirm"));
// dashboard
const Dashboard = React.lazy(() => import("../pages/dashboard"));
// apps
const CalendarApp = React.lazy(() => import("../pages/theme/apps/Calendar"));
const EmailInbox = React.lazy(() => import("../pages/theme/apps/Email/Inbox"));
const EmailDetail = React.lazy(() =>
  import("../pages/theme/apps/Email/Detail")
);
const EmailCompose = React.lazy(() =>
  import("../pages/theme/apps/Email/Compose")
);
const ProjectList = React.lazy(() =>
  import("../pages/theme/apps/Project/List")
);
const ProjectDetail = React.lazy(() =>
  import("../pages/theme/apps/Project/Detail/")
);
const TaskList = React.lazy(() => import("../pages/theme/apps/Tasks/List"));
const TaskBoard = React.lazy(() => import("../pages/theme/apps/Tasks/Board"));

// pages
const Starter = React.lazy(() => import("../pages/theme/other/Starter"));
const Profile = React.lazy(() => import("../pages/theme/other/Profile/"));
const Activity = React.lazy(() => import("../pages/theme/other/Activity"));
const Invoice = React.lazy(() => import("../pages/theme/other/Invoice"));
const Pricing = React.lazy(() => import("../pages/theme/other/Pricing"));
const Error404 = React.lazy(() => import("../pages/theme/other/Error404"));
const Error500 = React.lazy(() => import("../pages/theme/other/Error500"));

// ui
const BSComponents = React.lazy(() =>
  import("../pages/theme/uikit/BSComponents/")
);
// const FeatherIcon = React.lazy(() =>
//   import("../pages/theme/uikit/Icons/Feather")
// );
const UniconsIcons = React.lazy(() =>
  import("../pages/theme/uikit/Icons/Unicons")
);
const Widgets = React.lazy(() => import("../pages/theme/uikit/Widgets/"));

// charts
const Charts = React.lazy(() => import("../pages/theme/charts/"));

// forms
const BasicForms = React.lazy(() => import("../pages/theme/forms/Basic"));
const FormAdvanced = React.lazy(() => import("../pages/theme/forms/Advanced"));
const FormValidation = React.lazy(() =>
  import("../pages/theme/forms/Validation")
);
const FormWizard = React.lazy(() => import("../pages/theme/forms/Wizard"));
const FileUpload = React.lazy(() => import("../pages/theme/forms/FileUpload"));
const Editor = React.lazy(() => import("../pages/theme/forms/Editor"));

// tables
const BasicTables = React.lazy(() => import("../pages/theme/tables/Basic"));
const AdvancedTables = React.lazy(() =>
  import("../pages/theme/tables/Advanced")
);

// handle auth and authorization

// root routes
const rootRoute = {
  path: "/",
  exact: true,
  component: () => <Redirect to="/dashboard" />,
  route: PrivateRoute,
};

// dashboards
const dashboardRoutes = {
  path: "/dashboard",
  name: "Dashboard",
  icon: HomeIcon,
  header: "Navigation",
  badge: {
    variant: "success",
    text: "1",
  },
  component: Dashboard,
  roles: ["admin", "service-provider", "supplier", "user", "agent"],
  route: PrivateRoute,
};

// apps

const calendarAppRoutes = {
  path: "/apps/calendar",
  name: "Calendar",
  header: "Apps",
  icon: CalendarIcon,
  component: CalendarApp,
  route: PrivateRoute,
  roles: ["admin"],
};

const emailAppRoutes = {
  path: "/apps/email",
  name: "Email",
  icon: InboxIcon,
  children: [
    {
      path: "/apps/email/inbox",
      name: "Inbox",
      component: EmailInbox,
      route: PrivateRoute,
      roles: ["admin"],
    },
    {
      path: "/apps/email/details",
      name: "Details",
      component: EmailDetail,
      route: PrivateRoute,
      roles: ["admin"],
    },
    {
      path: "/apps/email/compose",
      name: "Compose",
      component: EmailCompose,
      route: PrivateRoute,
      roles: ["admin"],
    },
  ],
};

const projectAppRoutes = {
  path: "/apps/projects",
  name: "Projects",
  icon: BriefcaseIcon,
  children: [
    {
      path: "/apps/projects/list",
      name: "List",
      component: ProjectList,
      route: PrivateRoute,
      roles: ["admin"],
    },
    {
      path: "/apps/projects/detail",
      name: "Detail",
      component: ProjectDetail,
      route: PrivateRoute,
      roles: ["admin"],
    },
  ],
};

const taskAppRoutes = {
  path: "/apps/tasks",
  name: "Tasks",
  icon: BookmarkIcon,
  children: [
    {
      path: "/apps/tasks/list",
      name: "List",
      component: TaskList,
      route: PrivateRoute,
      roles: ["admin"],
    },
    {
      path: "/apps/tasks/board",
      name: "Board",
      component: TaskBoard,
      route: PrivateRoute,
      roles: ["admin"],
    },
  ],
};

const appRoutes = [
  calendarAppRoutes,
  emailAppRoutes,
  projectAppRoutes,
  taskAppRoutes,
];

// pages
const pagesRoutes = {
  path: "/pages",
  name: "Pages",
  header: "Custom",
  icon: FileTextIcon,
  children: [
    {
      path: "/pages/starter",
      name: "Starter",
      component: Starter,
      route: PrivateRoute,
      roles: ["admin"],
    },
    {
      path: "/pages/profile",
      name: "Profile",
      component: Profile,
      route: PrivateRoute,
      roles: ["admin"],
    },
    {
      path: "/pages/activity",
      name: "Activity",
      component: Activity,
      route: PrivateRoute,
      roles: ["admin"],
    },
    {
      path: "/pages/invoice",
      name: "Invoice",
      component: Invoice,
      route: PrivateRoute,
      roles: ["admin"],
    },
    {
      path: "/pages/pricing",
      name: "Pricing",
      component: Pricing,
      route: PrivateRoute,
      roles: ["admin"],
    },
    {
      path: "/pages/error-404",
      name: "Error 404",
      component: Error404,
      route: Route,
    },
    {
      path: "/pages/error-500",
      name: "Error 500",
      component: Error500,
      route: Route,
    },
  ],
};

// components
const componentsRoutes = {
  path: "/ui",
  name: "UI Elements",
  header: "Components",
  icon: PackageIcon,
  children: [
    {
      path: "/ui/bscomponents",
      name: "Bootstrap UI",
      component: BSComponents,
      route: PrivateRoute,
      roles: ["admin"],
    },
    {
      path: "/ui/icons",
      name: "Icons",
      children: [
        // {
        //   path: "/ui/icons/feather",
        //   name: "Feather Icons",
        //   component: FeatherIcon,
        //   route: PrivateRoute,
        //   roles: ["admin"],
        // },
        {
          path: "/ui/icons/unicons",
          name: "Unicons Icons",
          component: UniconsIcons,
          route: PrivateRoute,
          roles: ["admin"],
        },
      ],
    },
    {
      path: "/ui/widgets",
      name: "Widgets",
      component: Widgets,
      route: PrivateRoute,
      roles: ["admin"],
    },
  ],
};

// charts
const chartRoutes = {
  path: "/charts",
  name: "Charts",
  component: Charts,
  icon: PieChartIcon,
  roles: ["admin"],
  route: PrivateRoute,
};

// forms
const formsRoutes = {
  path: "/forms",
  name: "Forms",
  icon: FileTextIcon,
  children: [
    {
      path: "/forms/basic",
      name: "Basic Elements",
      component: BasicForms,
      route: PrivateRoute,
    },
    {
      path: "/forms/advanced",
      name: "Advanced",
      component: FormAdvanced,
      route: PrivateRoute,
    },
    {
      path: "/forms/validation",
      name: "Validation",
      component: FormValidation,
      route: PrivateRoute,
    },
    {
      path: "/forms/wizard",
      name: "Wizard",
      component: FormWizard,
      route: PrivateRoute,
    },
    {
      path: "/forms/editor",
      name: "Editor",
      component: Editor,
      route: PrivateRoute,
    },
    {
      path: "/forms/upload",
      name: "File Upload",
      component: FileUpload,
      route: PrivateRoute,
    },
  ],
};

const tableRoutes = {
  path: "/tables",
  name: "Tables",
  icon: GridIcon,
  children: [
    {
      path: "/tables/basic",
      name: "Basic",
      component: BasicTables,
      route: PrivateRoute,
    },
    {
      path: "/tables/advanced",
      name: "Advanced",
      component: AdvancedTables,
      route: PrivateRoute,
    },
  ],
};

// auth
const authRoutes = {
  path: "/account",
  name: "Auth",
  children: [
    {
      path: "/admin/login",
      name: "Admin Login",
      component: AdminLogin,
      route: Route,
    },
    {
      path: "/account/login",
      name: "Login",
      component: Login,
      route: Route,
    },
    {
      path: "/account/logout",
      name: "Logout",
      component: Logout,
      route: Route,
    },
    {
      path: "/account/register",
      name: "Register",
      component: Register,
      route: Route,
    },
    {
      path: "/account/confirm",
      name: "Confirm",
      component: Confirm,
      route: Route,
    },
    {
      path: "/account/forget-password",
      name: "Forget Password",
      component: ForgetPassword,
      route: Route,
    },
    {
      path: "/password/reset/:token/:email",
      name: "Reset Password",
      component: ResetPassword,
      route: Route,
    },
    {
      path: "/activate/:token/:email",
      name: "Activate user",
      component: ActivateUser,
      route: Route,
    },
  ],
};

// Admin routes

// service provider routes
const serviceProvider = [
  {
    path: "/business",
    name: "Garages/ Shop",
  },
];
// flatten the list of all nested routes
const flattenRoutes = (routes) => {
  let flatRoutes = [];

  routes = routes || [];
  routes.forEach((item) => {
    flatRoutes.push(item);

    if (typeof item.children !== "undefined") {
      flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)];
    }
  });
  return flatRoutes;
};
const quoteListRoute = [
  {
    path: "/quote-list",
    exact: true,
    name: "Quote list",
    icon: HomeIcon,
    component: QuoteList,
    route: PrivateRoute,
    roles: ["admin", "agent"],
  },
  {
    path: "/quote-list/add",
    component: AddEditQuoteList,
    route: PrivateRoute,
    roles: ["admin", "agent"],
  },
];
const agentRoutes = [
  {
    path: "/addSupplier",
    name: "Add supplier",
    icon: HomeIcon,
    component: AddSupplierBusiness,
    route: PrivateRoute,
    roles: ["agent"],
  },
  {
    path: "/updateSupplier",
    name: "Update supplier",
    icon: GridIcon,
    component: UpdateSupplierBusiness,
    route: PrivateRoute,
    roles: ["agent"],
  },
  {
    path: "/rfq",
    name: "Rfq",
    component: ListRfq,
    route: PrivateRoute,
    roles: ["agent"],
    exact: true,
    icon: List,
  },
  {
    path: "/service-provider/business",
    name: "Service provider business",
    component: ListGarages,
    icon: UserX,
    roles: ["agent"],
    route: PrivateRoute,
    exact: true,
  },
  // reward points route
  {
    path: "/reward-points",
    name: "User reward points",
    icon: FeatherIcon.Codepen,
    component: RewardPoints,
    route: PrivateRoute,
    roles: ["agent"],
    exact: true,
  },
  {
    path: "/orders",
    component: ListOrders,
    icon: FeatherIcon.Codepen,
    name: "Orders",
    roles: ["admin", "agent"],
    route: PrivateRoute,
    exact: true,
  },
  {
    path: "/orders/view/:id",
    component: ViewOrders,
    route: PrivateRoute,
    roles: ["admin", "agent"],
  },
  {
    path: "/download-pdf",
    component: DownloadPdf,
    route: PrivateRoute,
    roles: ["admin", "agent"],
  },
  {
    path: "/download-all-invoice",
    component: DownloadAllInvoice,
    route: PrivateRoute,
    roles: ["admin", "agent"],
  },
  ...quoteListRoute,
];
// All routes
const allRoutes = [
  rootRoute,
  dashboardRoutes,
  //   ...appRoutes,
  //   pagesRoutes,
  //   componentsRoutes,
  //   chartRoutes,
  //   formsRoutes,
  //   tableRoutes,
  authRoutes,
  partnerAuthRoutes,
  ...adminRoutes,
  ...serviceProviderRoutes,
  ...supplierRoutes,
  ...agentRoutes,
  ...quoteListRoute,
];

const authProtectedRoutes = [
  dashboardRoutes,
  //   ...appRoutes,
  //   pagesRoutes,
  //   componentsRoutes,
  //   chartRoutes,
  //   formsRoutes,
  //   tableRoutes,
];
const authProtectedRoutesWithRole = {
  admin: [
    ...authProtectedRoutes,
    ...serviceProviderRoutes,
    ...supplierRoutes,
    ...adminRoutes,
    ...quoteListRoute,
  ],
  "service-provider": [...authProtectedRoutes, ...serviceProviderRoutes],
  supplier: [...authProtectedRoutes, ...supplierRoutes],
  agent: [...agentRoutes],
};
const allFlattenRoutes = flattenRoutes(allRoutes);
export {
  allRoutes,
  authProtectedRoutes,
  authProtectedRoutesWithRole,
  allFlattenRoutes,
};
