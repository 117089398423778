import {
  LIST_RFQ,
  LIST_RFQ_FAILED,
  LIST_RFQ_SUCCESS,
  GET_RFQ,
  GET_RFQ_SUCCESS,
  GET_RFQ_FAILED,
  GET_RFQ_SUPPLIER,
  GET_RFQ_SUPPLIER_SUCCESS,
  GET_RFQ_SUPPLIER_FAILED,
  NOTIFY_RFQ_SUPPLIER,
  NOTIFY_RFQ_SUPPLIER_SUCCESS,
  NOTIFY_RFQ_SUPPLIER_FAILED,
  GET_RFQ_REQUEST_DETAIL,
  GET_RFQ_REQUEST_DETAIL_SUCCESS,
  GET_RFQ_REQUEST_DETAIL_FAILED,
  GET_RFQ_REQUEST_DETAIL_RESPONSES,
  GET_RFQ_REQUEST_DETAIL_RESPONSES_SUCCESS,
  GET_RFQ_REQUEST_DETAIL_RESPONSES_FAILED,
  ADD_RFQ_REQUEST,
  ADD_RFQ_REQUEST_SUCCESS,
  ADD_RFQ_REQUEST_FAILED,
} from "./constants";

export const getRfq = (id) => ({
  type: GET_RFQ,
  payload: { id },
});

export const getRfqSuccess = (data) => ({
  type: GET_RFQ_SUCCESS,
  payload: data,
});

export const getRfqFailed = (errorMessage) => ({
  type: GET_RFQ_FAILED,
  payload: errorMessage,
});

export const getListRfq = (payload) => ({
  type: LIST_RFQ,
  payload,
});

export const getListRfqSuccess = (data) => ({
  type: LIST_RFQ_SUCCESS,
  payload: { data },
});

export const getListRfqFailed = (errorMessage) => ({
  type: LIST_RFQ_FAILED,
  payload: errorMessage,
});

export const getRfqSupplier = (payload) => ({
  type: GET_RFQ_SUPPLIER,
  payload,
});

export const getRfqSupplierSuccess = (data) => ({
  type: GET_RFQ_SUPPLIER_SUCCESS,
  payload: data,
});

export const getRfqSupplierFailed = (errorMessage) => ({
  type: GET_RFQ_SUPPLIER_FAILED,
  payload: errorMessage,
});

export const notifyRfqSupplier = (payload) => ({
  type: NOTIFY_RFQ_SUPPLIER,
  payload,
});

export const notifyRfqSupplierSuccess = (data) => ({
  type: NOTIFY_RFQ_SUPPLIER_SUCCESS,
  payload: data,
});

export const notifyRfqSupplierFailed = (errorMessage) => ({
  type: NOTIFY_RFQ_SUPPLIER_FAILED,
  payload: errorMessage,
});

export const getRfqRequestDetail = ({ payload }) => ({
  type: GET_RFQ_REQUEST_DETAIL,
  payload,
});

export const getRfqRequestDetailSuccess = (data) => ({
  type: GET_RFQ_REQUEST_DETAIL_SUCCESS,
  payload: data,
});

export const getRfqRequestDetailFailed = (error) => ({
  type: GET_RFQ_REQUEST_DETAIL_FAILED,
  payload: error,
});

export const getRfqRequestDetailResponses = ({ payload }) => ({
  type: GET_RFQ_REQUEST_DETAIL_RESPONSES,
  payload,
});

export const getRfqRequestDetailResponsesSuccess = (data) => ({
  type: GET_RFQ_REQUEST_DETAIL_RESPONSES_SUCCESS,
  payload: data,
});

export const getRfqRequestDetailResponsesFailed = (error) => ({
  type: GET_RFQ_REQUEST_DETAIL_RESPONSES_FAILED,
  payload: error,
});

export const addRfqRequest = ({ payload, onSuccess }) => ({
  type: ADD_RFQ_REQUEST,
  payload: { payload, onSuccess },
});

export const addRfqRequestSuccess = (data) => ({
  type: ADD_RFQ_REQUEST_SUCCESS,
  payload: data,
});

export const addRfqRequestFailed = (error) => ({
  type: ADD_RFQ_REQUEST_FAILED,
  payload: error,
});
