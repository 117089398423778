import {
  forwardRef,
  ImgHTMLAttributes,
  memo,
  Ref,
  useCallback,
  useEffect,
  useState,
} from "react";
import { getAudioUrl, getImageUrl } from "../services/api";
const defaultImage = require("../assets/images/no-image.png");

const LoadImage = ({
  path,
  id,
  ref,
  isPublic = false,
  fileType = "image",
  ...rest
}) => {
  const [image, setImage] = useState({ [id]: defaultImage });
  const loadS3Image = useCallback(async () => {
    try {
      let file = null;
      if (fileType === "image")
        file = path
          ? isPublic
            ? path
            : await getImageUrl({ path })
          : defaultImage;
      if (fileType === "audio")
        file = path ? await getAudioUrl({ path }) : null;

      setImage({ [id]: file });
    } catch (e) {
      const defaultFile = fileType === "image" ? defaultImage : null;
      setImage({ [id]: defaultFile });
    }
  }, [path]);
  useEffect(() => {
    loadS3Image();
  }, [path]);
  if (fileType === "image")
    return (
      <img
        ref={ref}
        src={image?.[id]}
        onError={({ currentTarget }) => {
          currentTarget.onerror = null; // prevents looping
          currentTarget.src = defaultImage;
        }}
        {...rest}
      />
    );
  if (fileType === "audio")
    return image?.[id] ? (
      <audio
        style={{ width: "100%", maxWidth: 300, ...rest?.style }}
        ref={ref}
        src={image?.[id]}
        controls
        preload="auto"
        {...rest}
      />
    ) : (
      <span>No audio</span>
    );
  return null;
};

const LoadAsyncImage = memo(LoadImage);
export default forwardRef((props, ref) => (
  <LoadAsyncImage {...props} ref={ref} />
));
