import moment from "moment";
import React, { useEffect, useState } from "react";
import { Type } from "react-bootstrap-table2-editor";
import { selectFilter, textFilter } from "react-bootstrap-table2-filter";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Alert, Button, Col, Row } from "reactstrap";
import CustomModal from "../../components/CustomModal/CustomModal";
import OrderStatusDropDown from "../../components/OrderStatusDropDown";
import PageTitle from "../../components/PageTitle";
import Status from "../../components/Status";
import Actions from "../../components/table/Actions";
import Table from "../../components/table/Table";
import useTableActions from "../../components/table/useActions";
import { RFQ_STATUS } from "../../constants";
import { ORDER_STATUS } from "../../constants/orderStatus";
import { useModal } from "../../hooks/useModal";
import { appNotification } from "../../redux/app/actions";
import { getOrders } from "../../redux/orders/actions";
import { getListRfq, getRfq } from "../../redux/rfq/actions";
import { deleteUser, getUsers } from "../../redux/users/actions";
import { getRfqSupplierListByRfq } from "../../services/api";
import { ShoppingCart } from "react-feather";
const columns = ({
  hasFilter = false,
  open,
  toggleModal,
  modalOpen,
  handleDelete,
  baseUrl,
  handleClick,
}) => [
  {
    dataField: "rfqNo",
    text: "Rfq No",
    sort: true,
    filter: hasFilter && textFilter(),
    editable: false,
  },
  {
    dataField: "requested_by.name",
    text: "Requested by",
    sort: true,
    filter: hasFilter && textFilter(),
    formatter: (cell, { requested_by }) => {
      return (
        <div className="d-flex me-2">
          <div className="d-flex flex-column">
            <span>
              {[
                requested_by?.name,
                requested_by?.phoneNumber,
                requested_by?.userCode,
              ]
                .filter((v) => v)
                .join(" | ")}
            </span>
          </div>
        </div>
      );
    },
    editable: false,
  },
  {
    dataField: "business.businessName",
    text: "Business name",
    sort: true,
    formatter: (cell, { business }) => <span>{business?.businessName}</span>,
  },
  {
    dataField: "rfq_supplier_count",
    text: "Supplier responses",
    sort: true,
    formatter: (cell, row) => (
      <button
        className="btn btn-link text-secondary"
        onClick={handleClick(row?.id)}
      >
        {row?.rfq_supplier_count}
      </button>
    ),
  },
  {
    dataField: "vehicle_make.name",
    text: "Make",
    sort: true,
    formatter: (cell, { vehicle_make }) => <span>{vehicle_make?.name}</span>,
  },
  {
    dataField: "vehicle_model.name",
    text: "Model",
    sort: true,
    filter: hasFilter && textFilter(),
    formatter: (cell, { vehicle_model }) => <span>{vehicle_model?.name}</span>,
    editable: false,
  },
  {
    dataField: "vehicle_year.year",
    text: "Year",
    sort: true,
    filter: hasFilter && textFilter(),
    editable: false,
    formatter: (cell, { vehicle_year }) => (
      <span>{`${vehicle_year?.year}`}</span>
    ),
  },
  {
    dataField: "spec",
    text: "Spec",
    sort: true,
    filter: hasFilter && textFilter(),
    editable: false,
  },
  {
    dataField: "vinNumber",
    text: "Vin number",
    sort: true,
    filter: hasFilter && textFilter(),
    editable: false,
  },
  {
    dataField: "rfq_request",
    text: "Total spare parts",
    sort: true,
    filter: hasFilter && textFilter(),
    editable: false,
    formatter: (cell, { rfq_request }) => (
      <span>{`${rfq_request?.length}`}</span>
    ),
  },
  {
    dataField: "rftType",
    text: "RFQ type",
    sort: true,
    filter: hasFilter && textFilter(),
    editable: false,
    formatter: (cell, row) => (
      <span className="badge bg-secondary text-white">
        {row?.rfqType === 1 ? "Automatic" : "Manual"}
      </span>
    ),
  },
  {
    dataField: "created_at",
    text: "Created on",
    sort: true,
    filter: hasFilter && textFilter(),
    formatter: (cell, row) =>
      row?.created_at
        ? moment(row?.created_at).format("DD-MM-YYYY hh:mm:A")
        : null,
    editable: false,
  },
  {
    dataField: "updated_at",
    text: "Last updated on",
    sort: true,
    filter: hasFilter && textFilter(),
    formatter: (cell, row) =>
      row?.updated_at
        ? moment(row?.updated_at).format("DD-MM-YYYY hh:mm:A")
        : null,
    editable: false,
  },
  {
    dataField: "rfqStatus",
    text: "Status",
    sort: true,
    editable: true,
    formatter: (cell, row) => (
      <span className="badge badge-soft-warning py-1">
        {RFQ_STATUS[row?.rfqStatus]}
      </span>
    ),
  },

  {
    dataField: "actions",
    text: "Actions",
    sort: false,
    isDummyField: true,
    exportCSV: false,
    formatter: Actions,
    formatExtraData: {
      open,
      toggleModal,
      baseUrl,
      modalOpen,
      canDelete: false,
      canEdit: false,
      renderAction: (row) => (
        <>
          <Link
            className="btn btn-sm btn-primary mr-1"
            to={`/cart/${row?.requested_by?.id}`}
          >
            <ShoppingCart size={14} />
          </Link>
          {/* <Link
            className="btn btn-sm btn-primary mr-1"
            to={`${baseUrl}/detail/${row?.id}`}
          >
            Detail
          </Link> */}
          <Link
            className="btn btn-sm btn-primary m-1"
            to={`rfq-response/add/${row?.rfq_request?.[0]?.id}?r=${row?.id}`}
          >
            Qoutes
          </Link>
        </>
      ),
    },
    editable: false,
  },
];
const ListRfq = () => {
  const baseUrl = "/rfq";
  const { open, toggleModal, modalOpen, handleDelete } =
    useTableActions(deleteUser);
  const {
    open: openSupplierList,
    onOpen: onOpenSupplierList,
    onClose: onCloseSupplierList,
  } = useModal();
  const dispatch = useDispatch();
  const [pagination, setPagination] = useState({
    page: 1,
    searchText: "",
    sizePerPage: 10,
    sortField: "created_at",
    sortOrder: "desc",
  });
  const { data, error, loading } = useSelector((state) => state.Rfq);
  useEffect(() => {
    dispatch(getListRfq(pagination));
  }, []);
  const [hasFilter, setHasFilter] = useState(false);
  const [rfqSupplierList, setRfqSupplierList] = useState({
    data: [],
    loading: false,
  });
  const fetchRfqSupplierList = (id) => async (e) => {
    onOpenSupplierList();
    setRfqSupplierList((s) => ({ ...s, loading: true }));
    const d = await getRfqSupplierListByRfq(id);
    console.log(d?.status + " === " + "Success");
    if (d?.status === "Success")
      setRfqSupplierList((s) => ({ data: d?.data, loading: false }));
    else {
      dispatch(
        appNotification({
          type: "danger",
          message: "Oops something went wrong while fetching supplier list",
        })
      );
      setRfqSupplierList({ data: [], loading: false });
    }
  };
  const toggleFilter = () => setHasFilter(!hasFilter);
  const handleTableChange = (e, v) => {
    console.log(e, v);
    const { page, searchText, sizePerPage, sortField, sortOrder } = v;
    setPagination({ page, searchText, sizePerPage, sortField, sortOrder });
    dispatch(
      getListRfq({
        page,
        searchText,
        sizePerPage,
        sortField,
        sortOrder,
      })
    );
  };
  return (
    <>
      <Row className="page-title">
        <Col md={12}>
          <PageTitle
            breadCrumbItems={[
              {
                label: "Rfq list",
                path: "/users",
              },
            ]}
            title={"List of Rfq"}
          />
        </Col>
      </Row>
      {error && <Alert color="danger">Oops something went wrong</Alert>}
      <Table
        loading={loading}
        records={data?.data}
        addUrl={"/rfq/add"}
        columns={columns({
          hasFilter,
          open,
          baseUrl,
          toggleModal,
          modalOpen,
          handleDelete,
          handleClick: fetchRfqSupplierList,
        })}
        handleTableChange={handleTableChange}
        pagination={{ ...pagination, totalSize: data?.total }}
        canCellEdit={true}
      />
      <CustomModal
        noFooter
        open={openSupplierList}
        handleOnClose={onCloseSupplierList}
        closeModal={onCloseSupplierList}
      >
        <table
          className="table table-responsive"
          style={{ maxHeight: 400, overflow: "auto" }}
        >
          <thead>
            <tr>
              <th>Supplier</th>
              <th>Qoutes received</th>
              <th>Rating</th>
            </tr>
          </thead>
          <tbody>
            {rfqSupplierList?.data?.length ? (
              rfqSupplierList?.data?.map(
                ({ supplier_business, rfqResponseCount }) => (
                  <tr key={`rfq-supplier-list-${supplier_business?.id}`}>
                    <td>
                      <div className="d-flex flex-column">
                        <span>{supplier_business?.businessName}</span>
                        <span>
                          {`${supplier_business?.businessContactNumberCode} ${supplier_business?.businessContactNumber}`}{" "}
                        </span>
                        <span>{supplier_business?.city?.name}</span>
                      </div>
                    </td>
                    <td>{rfqResponseCount}</td>
                    <td>{supplier_business?.googleRating || 0}</td>
                  </tr>
                )
              )
            ) : (
              <tr>
                <td colSpan={2}>No data found</td>
              </tr>
            )}
          </tbody>
        </table>
      </CustomModal>
    </>
  );
};

export default ListRfq;
