export const SUPPLIER_BUSINESS = "SUPPLIER_BUSINESS";
export const SUPPLIER_BUSINESS_SUCCESS = "SUPPLIER_BUSINESS_SUCCESS";
export const SUPPLIER_BUSINESS_FAILED = "SUPPLIER_BUSINESS_FAILED";
export const SAVE_SUPPLIER_BUSINESS = "SAVE_SUPPLIER_BUSINESS";
export const SAVE_SUPPLIER_BUSINESS_SUCCESS = "SAVE_SUPPLIER_BUSINESS_SUCCESS";
export const SAVE_SUPPLIER_BUSINESS_FAILED = "SAVE_SUPPLIER_BUSINESS_FAILED";
export const EDIT_SUPPLIER_BUSINESS = "EDIT_SUPPLIER_BUSINESS";
export const EDIT_SUPPLIER_BUSINESS_SUCCESS = "EDIT_SUPPLIER_BUSINESS_SUCCESS";
export const EDIT_SUPPLIER_BUSINESS_FAILED = "EDIT_SUPPLIER_BUSINESS_FAILED";
export const DELETE_SUPPLIER_BUSINESS = "DELETE_SUPPLIER_BUSINESS";
export const DELETE_SUPPLIER_BUSINESS_SUCCESS =
  "DELETE_SUPPLIER_BUSINESS_SUCCESS";
export const DELETE_SUPPLIER_BUSINESS_FAILED =
  "DELETE_SUPPLIER_BUSINESS_FAILED";
export const GET_SUPPLIER_BUSINESS = "GET_SUPPLIER_BUSINESS";
export const GET_SUPPLIER_BUSINESS_SUCCESS = "GET_SUPPLIER_BUSINESS_SUCCESS";
export const GET_SUPPLIER_BUSINESS_FAILED = "GET_SUPPLIER_BUSINESS_FAILED";
export const UPDATE_SUPPLIER_VERIFICATION_STATUS =
  "UPDATE_SUPPLIER_VERIFICATION_STATUS";
export const UPDATE_SUPPLIER_VERIFICATION_STATUS_SUCCESS =
  "UPDATE_SUPPLIER_VERIFICATION_STATUS_SUCCESS";
export const UPDATE_SUPPLIER_VERIFICATION_STATUS_FAILED =
  "UPDATE_SUPPLIER_VERIFICATION_STATUS_FAILED";
export const GET_ALL_SUPPLIER_BUSINESS = "GET_ALL_SUPPLIER_BUSINESS";
export const GET_ALL_SUPPLIER_BUSINESS_SUCCESS =
  "GET_ALL_SUPPLIER_BUSINESS_SUCCESS";
export const GET_ALL_SUPPLIER_BUSINESS_FAILED =
  "GET_ALL_SUPPLIER_BUSINESS_FAILED";
