// @flow
import {
  GET_ROLES,
  GET_ROLES_SUCCESS,
  GET_ROLES_FAILED,
  SAVE_ROLES,
  SAVE_ROLES_SUCCESS,
  SAVE_ROLES_FAILED,
  DELETE_ROLES,
  DELETE_ROLES_SUCCESS,
  DELETE_ROLES_FAILED,
  GET_ROLE,
  GET_ROLE_SUCCESS,
  GET_ROLE_FAILED,
} from "./constants";

const INIT_STATE = {
  roles: [],
  data: [],
  loading: false,
  error: null,
  role: {
    data: null,
    error: null,
    loading: false,
  },
};

const Roles = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ROLES:
      return { ...state, loading: true };
    case GET_ROLES_SUCCESS:
      return { ...state, ...action.payload, loading: false, error: null };
    case GET_ROLES_FAILED:
      return { ...state, error: action.payload, loading: false };
    case GET_ROLE:
      return { ...state, role: { ...state.role, loading: true } };
    case GET_ROLE_SUCCESS:
      return {
        ...state,
        role: { ...state.role, ...action.payload, loading: false, error: null },
      };
    case GET_ROLE_FAILED:
      return {
        ...state,
        role: { ...state.role, error: action.payload, loading: false },
      };

    case SAVE_ROLES:
      return { ...state, loading: true };
    case SAVE_ROLES_SUCCESS:
      return {
        ...state,
        data: [...state.data, action.payload],
        loading: false,
        error: null,
      };
    case SAVE_ROLES_FAILED:
      return { ...state, error: action.payload, loading: false };
    case DELETE_ROLES:
      return { ...state, loading: true };
    case DELETE_ROLES_SUCCESS:
      return {
        ...state,
        data: state.data.filter((v) => v?.id !== action?.payload?.id),
        loading: false,
        error: null,
      };
    case DELETE_ROLES_FAILED:
      return { ...state, error: action.payload, loading: false };

    default:
      return { ...state };
  }
};

export default Roles;
