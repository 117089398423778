// @flow
import {
  GET_SUPPLIER_BUSINESS_UPDATE,
  GET_SUPPLIER_BUSINESS_UPDATE_FAILED,
  GET_SUPPLIER_BUSINESS_UPDATE_SUCCESS,
  SAVE_SUPPLIER_BUSINESS_UPDATE_SUCCESS,
  SAVE_SUPPLIER_BUSINESS_UPDATE,
  SAVE_SUPPLIER_BUSINESS_UPDATE_FAILED,
  CLEAR_SUPPLIER_BUSINESS_UPDATE,
} from "./constants";

const INIT_STATE = {
  supplierBusinessData: {},
  loading: false,
  error: null,
  saveSuppplierBusinessUpdate: {
    loading: false,
    data: null,
    error: null,
  },
};

const SupplierBusinessUpdate = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SAVE_SUPPLIER_BUSINESS_UPDATE:
      return {
        ...state,
        saveSuppplierBusinessUpdate: {
          ...state.saveSuppplierBusinessUpdate,
          loading: true,
        },
      };
    case SAVE_SUPPLIER_BUSINESS_UPDATE_SUCCESS:
      return {
        ...state,
        supplierBusinessData: {},
        saveSuppplierBusinessUpdate: {
          ...state.saveSuppplierBusinessUpdate,
          data: action.payload,
          loading: false,
        },
      };
    case SAVE_SUPPLIER_BUSINESS_UPDATE_FAILED:
      return {
        ...state,
        saveSuppplierBusinessUpdate: {
          ...state.saveSuppplierBusinessUpdate,
          error: action.payload,
          loading: false,
        },
      };

    case GET_SUPPLIER_BUSINESS_UPDATE:
      return { ...state, loading: true };
    case GET_SUPPLIER_BUSINESS_UPDATE_SUCCESS:
      return {
        ...state,
        supplierBusinessData: action.payload,
        loading: false,
        error: null,
      };
    case GET_SUPPLIER_BUSINESS_UPDATE_FAILED:
      return { ...state, error: action.payload, loading: false };
    case CLEAR_SUPPLIER_BUSINESS_UPDATE:
      return INIT_STATE;
    default:
      return { ...state };
  }
};

export default SupplierBusinessUpdate;
