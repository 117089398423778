// export * from './fake-backend';
export const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });


  export const toBase64Array = async (files, onlyBase64 = false) => {
    const allPromises = files.map(
      (file) =>
        new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          if (onlyBase64) reader.onload = () => resolve(reader.result);
          else
            reader.onload = () =>
              resolve({ name: file.name, size: file.size, type: file.type, base64: reader.result });
  
          reader.onerror = error => reject(error);
        }),
    );
    return Promise.all(allPromises).then(result => result);
  };