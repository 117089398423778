import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Alert, Col, Row } from "reactstrap";
import PageTitle from "../../../components/PageTitle";
import Status from "../../../components/Status";
import Actions from "../../../components/table/Actions";
import Table from "../../../components/table/Table";
import useTableActions from "../../../components/table/useActions";
import {
  deleteCarGeneration,
  getCarGenerations,
} from "../../../redux/carGeneration/actions";

const ListVehicleGeneration = () => {
  const baseUrl = "/vehicle/car-generation";
  const { open, toggleModal, modalOpen, handleDelete } =
    useTableActions(deleteCarGeneration);
  const columns = [
    {
      dataField: "id_car_generation",
      text: "ID",
      sort: true,
    },
    // {
    //   dataField: "car_model.name",
    //   text: "Car model",
    //   sort: true,
    // },
    {
      dataField: "car_model.name",
      text: "Car model",
      sort: true,
    },
    {
      dataField: "name",
      text: "Generation Name",
      sort: true,
    },
    {
      dataField: "created_at",
      text: "Created at",
      sort: true,
      formatter: (cell, row) => (
        <span>
          {row.created_at
            ? moment(row.created_at).format("DD-MM-YYYY H:m A")
            : null}
        </span>
      ),
    },
    {
      dataField: "updated_at",
      text: "Updated at",
      sort: true,
      formatter: (cell, row) => (
        <span>
          {row.updated_at
            ? moment(row.updated_at).format("DD-MM-YYYY H:m A")
            : null}
        </span>
      ),
    },
    // {
    //   dataField: "status",
    //   text: "Status",
    //   sort: true,
    //   formatter: (cel, row) => <Status status={row?.status} />,
    // },

    // {
    //   dataField: "actions",
    //   text: "Actions",
    //   sort: false,
    //   isDummyField: true,
    //   exportCSV: false,
    //   formatter: Actions,
    //   formatExtraData: {
    //     open,
    //     toggleModal,
    //     handleDelete,
    //     canView: false,
    //     baseUrl,
    //     modalOpen,
    //   },
    // },
  ];
  const dispatch = useDispatch();
  const [pagination, setPagination] = useState({
    page: 1,
    searchText: "",
    sizePerPage: 10,
    sortField: "id_car_generation",
    sortOrder: "asc",
  });
  const { data, error, loading } = useSelector((state) => state.CarGeneration);
  useEffect(() => {
    dispatch(getCarGenerations(pagination));
  }, []);
  const handleTableChange = (e, v) => {
    console.log(e, v);
    const { page, searchText, sizePerPage, sortField, sortOrder } = v;
    setPagination({ page, searchText, sizePerPage, sortField, sortOrder });
    dispatch(
      getCarGenerations({
        page,
        searchText,
        sizePerPage,
        sortField,
        sortOrder,
      })
    );
  };
  return (
    <>
      <Row className="page-title">
        <Col md={12}>
          <PageTitle
            breadCrumbItems={[
              {
                label: "Car generations",
                path: "/vehicles/generation",
              },
            ]}
            title={"List of Car generations"}
          />
        </Col>
      </Row>
      {error && <Alert color="danger">Oops something went wrong</Alert>}
      <Table
        loading={loading}
        records={data?.data}
        columns={columns}
        handleTableChange={handleTableChange}
        addUrl={`${baseUrl}/add`}
        pagination={{ ...pagination, totalSize: data?.total }}
      />
    </>
  );
};

export default ListVehicleGeneration;
