import { useDispatch } from "react-redux";
import ReactSelect from "react-select";
import { ORDER_STATUS } from "../constants/orderStatus";
import { saveOrder } from "../redux/orders/actions";

const OrderStatusDropDown = ({ value, orderId, onUpdate, ...rest }) => {
  const dispatch = useDispatch();
  const options = Object.keys(ORDER_STATUS).map((status) => ({
    label: ORDER_STATUS?.[status],
    value: status,
  }));
  const getValue = (val) => {
    return parseInt(val, 10);
  };
  const handleChange = (v) => {
    dispatch(
      saveOrder({ payload: { id: orderId, orderStatus: parseInt(v?.value) } })
    );

    onUpdate(getValue(v?.value));
  };
  return (
    <ReactSelect
      options={options}
      defualtValue={ORDER_STATUS?.[value]}
      onChange={handleChange}
    />
  );
};

export default OrderStatusDropDown;
