import React, { Component, useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Redirect, Link, withRouter } from "react-router-dom";

import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Button,
  Alert,
  Label,
  InputGroup,
  InputGroupAddon,
} from "reactstrap";
import {
  AvForm,
  AvGroup,
  AvInput,
  AvFeedback,
} from "availity-reactstrap-validation";
import { Lock } from "react-feather";

import { isUserAuthenticated } from "../../../helpers/authUtils";
import Loader from "../../../components/Loader";
import logo from "../../../assets/images/logo.png";
import { activateUser } from "../../../redux/actions";

const ActivateUser = ({ match, history }) => {
  let _isMounted = false;

  const { activateUserStatus, error, loading } = useSelector(
    (state) => state.Auth
  );
  const [isLoading, setIsLoading] = useState(false);
  const { token, email } = match?.params;
  const dispatch = useDispatch();
  useEffect(() => {
    _isMounted = true;
    document.body.classList.add("authentication-bg");
    return () => {
      _isMounted = false;
      document.body.classList.remove("authentication-bg");
    };
  }, []);

  /**
   * Handles the submit
   */
  const handleValidSubmit = (event, values) => {
    setIsLoading(true);
    dispatch(
      activateUser({ token, email: decodeURIComponent(email), ...values })
    );
  };

  /**
   * Redirect to root
   */
  const renderRedirectToRoot = () => {
    const isAuthTokenValid = isUserAuthenticated();
    if (isAuthTokenValid) {
      return <Redirect to="/" />;
    }
  };
  /**
   * Redirect to root
   */
  const redirectToLogin = () => {
    history.push("/account/login");
  };
  const isAuthTokenValid = isUserAuthenticated();
  return (
    <React.Fragment>
      {renderRedirectToRoot()}

      {(_isMounted || !isAuthTokenValid) && (
        <div className="account-pages my-5">
          <Container>
            <Row className="justify-content-center">
              <Col xl={6} md={8}>
                <Card className="">
                  <CardBody className="p-0">
                    <Row>
                      <Col md={12} className="p-5 position-relative">
                        {/* preloader */}
                        {loading && <Loader />}

                        <div className="mx-auto mb-5">
                          <a href="/">
                            <img src={logo} alt="" height="24" />
                          </a>
                        </div>

                        <h6 className="h5 mb-0 mt-4">Set Password</h6>
                        {activateUserStatus ? (
                          <>
                            <p className="text-muted mt-1 mb-4">
                              {activateUserStatus}
                            </p>
                            <Button
                              type="button"
                              onClick={redirectToLogin}
                              color="primary"
                              className="btn-block"
                            >
                              Continue to Login
                            </Button>
                          </>
                        ) : (
                          <>
                            <p className="text-muted mt-1 mb-4">
                              Enter your new password.
                            </p>

                            {error && typeof error === "string" && (
                              <Alert
                                color="danger"
                                isOpen={error ? true : false}
                              >
                                <div>{error}</div>
                              </Alert>
                            )}
                            {error?.token && (
                              <Alert color="danger" isOpen={true}>
                                <div>This link has been expired </div>
                              </Alert>
                            )}
                            <AvForm
                              onValidSubmit={handleValidSubmit}
                              className="authentication-form"
                            >
                              <AvGroup className="mb-3">
                                <Label for="password">Password</Label>
                                <InputGroup>
                                  <InputGroupAddon addonType="prepend">
                                    <span className="input-group-text">
                                      <Lock className="icon-dual" />
                                    </span>
                                  </InputGroupAddon>
                                  <AvInput
                                    type="password"
                                    name="password"
                                    id="password"
                                    placeholder="Enter your password"
                                    required
                                  />
                                </InputGroup>
                                <span
                                  className="text-danger"
                                  style={{ fontSize: 11 }}
                                >
                                  {error?.password?.[0]}
                                </span>
                                {/* <AvFeedback>This field is invalid</AvFeedback> */}
                              </AvGroup>
                              <AvGroup className="mb-3">
                                <Label for="password_confirmation">
                                  Confirm Password
                                </Label>
                                <InputGroup>
                                  <InputGroupAddon addonType="prepend">
                                    <span className="input-group-text">
                                      <Lock className="icon-dual" />
                                    </span>
                                  </InputGroupAddon>
                                  <AvInput
                                    type="password"
                                    name="password_confirmation"
                                    id="password_confirmation"
                                    placeholder="Confirm password"
                                    required
                                  />
                                </InputGroup>
                                <AvFeedback>This field is invalid</AvFeedback>
                              </AvGroup>

                              <FormGroup className="form-group mb-0 text-center">
                                <Button color="primary" className="btn-block">
                                  Submit
                                </Button>
                              </FormGroup>
                            </AvForm>
                          </>
                        )}
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Row className="mt-1">
              <Col className="col-12 text-center">
                <p className="texttext-muted">
                  Back to{" "}
                  <Link
                    to="/account/login"
                    className="text-primary font-weight-bold ml-1"
                  >
                    Login
                  </Link>
                </p>
              </Col>
            </Row>
          </Container>
        </div>
      )}
    </React.Fragment>
  );
};

export default withRouter(ActivateUser);
