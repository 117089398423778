import {
  all,
  call,
  fork,
  put,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";

import { authRequest } from "../../helpers/api";
import { appNotification } from "../app/actions";
import {
  deleteSparePartsCategoryFailed,
  deleteSparePartsCategorySuccess,
  getSparePartsCategoriesFailed,
  getSparePartsCategoriesSuccess,
  getSparePartsCategoryFailed,
  getSparePartsCategorySuccess,
  getSparePartsParentCategoryFailed,
  getSparePartsParentCategorySuccess,
} from "./actions";
import {
  DELETE_SPARE_PARTS_CATEGORY,
  GET_SPARE_PARTS_CATEGORY,
  GET_SPARE_PARTS_PARENT_CATEGORY,
  SAVE_SPARE_PARTS_CATEGORY,
  SPARE_PARTS_CATEGORY,
} from "./constants";

function* getSparePartsCategories({ payload }) {
  try {
    let url = `/spare-parts-categories?`;
    if (payload)
      Object.keys(payload).map(
        (params) =>
          payload?.[params] && (url += `${params}=${payload[params]}&`)
      );
    const response = yield call(authRequest, url.slice(0, url.length - 1), {
      method: "get",
    });
    yield put(getSparePartsCategoriesSuccess(response?.data));
  } catch (error) {
    let message = "Failed to get spare-parts category";
    yield put(getSparePartsCategoriesFailed(message));
  }
}

function* saveSparePartsCategory({ payload: { data, history } }) {
  try {
    const formData = new FormData();
    Object.keys(data).map((key) => formData.append(key, data[key]));
    let url = `/spare-parts-categories`;
    if (data?.id) url += `/${data?.id}?_method=PUT`;

    const response = yield call(authRequest, url, {
      method: "post",
      data: formData,
      headers: {
        "content-type": "multipart/form-data",
      },
    });
    yield put(getSparePartsCategoriesSuccess(response?.data));
    yield put(appNotification({ type: "success", message: response?.message }));
    history.goBack();
  } catch (error) {
    let message = "Failed to get spare-parts category";
    yield put(getSparePartsCategoriesFailed(message));
  }
}
function* getSparePartsCategory({ payload: { id } }) {
  try {
    const response = yield call(authRequest, `/spare-parts-categories/${id}`, {
      method: "get",
    });
    yield put(getSparePartsCategorySuccess(response?.data));
  } catch (error) {
    let message = "Failed to get spare-parts category";
    yield put(getSparePartsCategoryFailed(message));
  }
}
function* deleteSparePartsCategory({ payload: { id } }) {
  try {
    const response = yield call(authRequest, `/spare-parts-categories/${id}`, {
      method: "delete",
    });
    yield put(appNotification({ type: "success", message: response?.message }));
    yield put(deleteSparePartsCategorySuccess({ id }));
  } catch (error) {
    console.log("error", error);
    let message = "Failed to get spare-parts category";
    yield put(deleteSparePartsCategoryFailed(message));
  }
}

function* getSparePartsParentCategories({ payload }) {
  try {
    let url = `/spare-parts-categories/search?`;
    if (payload)
      Object.keys(payload).map(
        (params) =>
          payload?.[params] && (url += `${params}=${payload[params]}&`)
      );
    const response = yield call(authRequest, url.slice(0, url.length - 1), {
      method: "get",
    });
    yield put(getSparePartsParentCategorySuccess(response?.data));
  } catch (error) {
    let message = "Failed to get spare-parts category";
    yield put(getSparePartsParentCategoryFailed(message));
  }
}

export function* watchGetSparePartsCategories() {
  yield takeLatest(SPARE_PARTS_CATEGORY, getSparePartsCategories);
}
export function* watchGetSparePartsCategory() {
  yield takeEvery(GET_SPARE_PARTS_CATEGORY, getSparePartsCategory);
}
export function* watchSaveSparePartsCategory() {
  yield takeEvery(SAVE_SPARE_PARTS_CATEGORY, saveSparePartsCategory);
}
export function* watchDeleteSparePartsCategory() {
  yield takeEvery(DELETE_SPARE_PARTS_CATEGORY, deleteSparePartsCategory);
}
export function* watchGetSparePartsParentCategory() {
  yield takeEvery(
    GET_SPARE_PARTS_PARENT_CATEGORY,
    getSparePartsParentCategories
  );
}

function* sparePartsCategorySaga() {
  yield all([
    fork(watchGetSparePartsCategories),
    fork(watchSaveSparePartsCategory),
    fork(watchGetSparePartsCategory),
    fork(watchDeleteSparePartsCategory),
    fork(watchGetSparePartsParentCategory),
  ]);
}

export default sparePartsCategorySaga;
