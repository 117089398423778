import { authRequest } from "../../helpers/api";
import {
  all,
  call,
  fork,
  put,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";
import {
  EDIT_QUOTE_LIST,
  FETCH_QUOTE_LIST,
  SAVE_QUOTE_LIST,
} from "./constants";
import {
  editQuoteListFailure,
  editQuoteListSuccess,
  fetchQuoteListSuccess,
  saveQuoteListFailure,
  saveQuoteListSuccess,
} from "./actions";
import { appNotification } from "../app/actions";
import jsonToFormData from "json-form-data";
function* fetchQuoteListSaga({ payload }) {
  try {
    let url = `/quote-list/search?`;
    const { filters, ...restPayload } = payload;
    if (restPayload)
      Object.keys(restPayload).map(
        (params) =>
          restPayload?.[params] && (url += `${params}=${restPayload[params]}&`)
      );
    const response = yield call(authRequest, url.slice(0, url.length - 1), {
      method: "post",
      data: { filters },
    });
    yield put(fetchQuoteListSuccess(response?.data));
  } catch (e) {
    yield put(
      appNotification({ type: "danger", message: "Oops something went wrong" })
    );
  }
}

function* saveQuoteListSaga({ payload, onSuccess, onError }) {
  try {
    const formData = jsonToFormData(payload);
    let url = `/quote-list`;
    if (payload?.id) url += `/${payload?.id}?_method=PUT`;

    const response = yield call(authRequest, url, {
      method: "post",
      data: formData,
      headers: {
        "content-type": "multipart/form-data",
      },
    });
    if (response?.status === "Success") {
      yield put(saveQuoteListSuccess(response?.data));
      if (onSuccess) onSuccess(response?.data);
      yield put(
        appNotification({ type: "success", message: response?.message })
      );
    } else {
      yield put(
        appNotification({
          type: "danger",
          message: "Oops something went wrong",
        })
      );
      if (onError) onError(response?.message);
      yield put(saveQuoteListFailure(response?.message));
    }
  } catch (e) {
    console.log("eer", e);
    yield put(
      appNotification({ type: "danger", message: "Oops something went wrong" })
    );
    if (onError) onError(e);
    yield put(saveQuoteListFailure(e));
  }
}

function* editQuoteListSaga({ payload, onSuccess, onError }) {
  try {
    const formData = jsonToFormData(payload);
    let url = `/quote-list`;
    if (payload?.id) url += `/${payload?.id}?_method=PUT`;

    const response = yield call(authRequest, url, {
      method: "post",
      data: formData,
      headers: {
        "content-type": "multipart/form-data",
      },
    });
    if (response?.status === "Success") {
      yield put(editQuoteListSuccess(response?.data));
      if (onSuccess) onSuccess(response?.data);
      yield put(
        appNotification({ type: "success", message: response?.message })
      );
    } else {
      yield put(
        appNotification({
          type: "danger",
          message: "Oops something went wrong",
        })
      );
      if (onError) onError(response?.message);
      yield put(editQuoteListFailure(response?.message));
    }
  } catch (e) {
    yield put(
      appNotification({ type: "danger", message: "Oops something went wrong" })
    );
    if (onError) onError(e);
    yield put(editQuoteListFailure(e));
  }
}

export function* watchFetchQuoteList() {
  yield takeLatest(FETCH_QUOTE_LIST, fetchQuoteListSaga);
}
export function* watchSaveQuoteList() {
  yield takeEvery(SAVE_QUOTE_LIST, saveQuoteListSaga);
}
export function* watchEditQuoteList() {
  yield takeEvery(EDIT_QUOTE_LIST, editQuoteListSaga);
}

function* QuoteListSaga() {
  yield all([
    fork(watchFetchQuoteList),
    fork(watchSaveQuoteList),
    fork(watchEditQuoteList),
  ]);
}
export default QuoteListSaga;
