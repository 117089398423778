import {
  DELETE_ROLES,
  DELETE_ROLES_FAILED,
  DELETE_ROLES_SUCCESS,
  GET_ROLE,
  GET_ROLES,
  GET_ROLES_FAILED,
  GET_ROLES_SUCCESS,
  GET_ROLE_FAILED,
  GET_ROLE_SUCCESS,
  SAVE_ROLES,
  SAVE_ROLES_FAILED,
  SAVE_ROLES_SUCCESS,
} from "./constants";

export const getRoles = () => ({
  type: GET_ROLES,
  payload: {},
});

export const getRolesSuccess = (success) => ({
  type: GET_ROLES_SUCCESS,
  payload: success,
});

export const getRolesFailed = (errorMessage) => ({
  type: GET_ROLES_FAILED,
  payload: errorMessage,
});

export const getRole = (id) => ({
  type: GET_ROLE,
  payload: { id },
});

export const getRoleSuccess = (success) => ({
  type: GET_ROLE_SUCCESS,
  payload: success,
});

export const getRoleFailed = (errorMessage) => ({
  type: GET_ROLE_FAILED,
  payload: errorMessage,
});

export const saveRoles = (payload) => ({
  type: SAVE_ROLES,
  payload,
});

export const saveRolesSuccess = (success) => ({
  type: SAVE_ROLES_SUCCESS,
  payload: success,
});

export const saveRolesFailed = (errorMessage) => ({
  type: SAVE_ROLES_FAILED,
  payload: errorMessage,
});

export const deleteRoles = ({id}) => ({
  type: DELETE_ROLES,
  payload: { id },
});

export const deleteRolesSuccess = (success) => ({
  type: DELETE_ROLES_SUCCESS,
  payload: success,
});

export const deleteRolesFailed = (errorMessage) => ({
  type: DELETE_ROLES_FAILED,
  payload: errorMessage,
});
