import React, {
  ChangeEvent,
  ChangeEventHandler,
  forwardRef,
  InputHTMLAttributes,
  Ref,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  FileUploadContainer,
  FormField,
  DragDropText,
  UploadFileBtn,
  FilePreviewContainer,
  ImagePreview,
  PreviewContainer,
  PreviewList,
  FileMetaData,
  RemoveFileIcon,
  InputLabel,
  FileName,
} from "./FileUpload.styled";
import imageCompression from "browser-image-compression";
import { toBase64Array } from "../../helpers";
import { Plus, XCircle } from "react-feather";
const KILO_BYTES_PER_BYTE = 1000;
const DEFAULT_MAX_FILE_SIZE_IN_KB = 1000000;

const convertNestedObjectToArray = (nestedObj) =>
  Object.keys(nestedObj).map((key) => nestedObj[key]);

const convertBytesToKB = (bytes) => Math.round(bytes / KILO_BYTES_PER_BYTE);

const FileUpload = ({
  inputRef,
  label,
  updateFilesCb,
  maxFileSizeInKB = DEFAULT_MAX_FILE_SIZE_IN_KB,
  onChange,
  reset,
  labelProps,
  ...otherProps
}) => {
  const fileInputField = useRef(null);
  const [files, setFiles] = useState({ file: [], preview: [] });

  const handleUploadBtnClick = () => {
    fileInputField.current.click();
  };

  const addNewFiles = (newFiles) => {
    for (let file of newFiles) {
      if (convertBytesToKB(file.size) < maxFileSizeInKB) {
        if (!otherProps.multiple) {
          return { [file.name]: file };
        }
        files[file.name] = file;
      }
    }
    return { ...files };
  };

  const callUpdateFilesCb = (newFiles, event) => {
    const filesAsArray = convertNestedObjectToArray(newFiles);
    updateFilesCb(filesAsArray, event);
  };

  const handleImageChange = async (origFiles) => {
    const imageFiles = Array.from(origFiles);

    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };
    try {
      return await Promise.all(
        imageFiles.map(
          (imageFile) =>
            new Promise((resolve, reject) => {
              return Promise.all([
                imageCompression(imageFile, options),
                toBase64Array([imageFile], true),
              ]).then((res) => resolve(res));
            })
        )
      ).then((res) => {
        let fileObj = { ...files };
        res.forEach((v, i) => {
          fileObj.file.push(v[0]);
          fileObj.preview.push(v[1]);
        });
        return fileObj;
      });
    } catch (error) {
      console.log(error);
    }
  };
  const handleNewFileUpload = (handler) => async (e) => {
    if (handler) handler(e);
    const { files: newFiles } = e.target;
    if (newFiles.length) {
      const updatedFiles = await handleImageChange(newFiles);
      console.log("fiels", updatedFiles);

      // let updatedFiles = addNewFiles(newFiles);
      setFiles(updatedFiles);
      if (updateFilesCb) callUpdateFilesCb(updatedFiles.file, e);
    }
  };

  const removeFile = (index) => {
    if (files?.file?.[index]) {
      files?.file?.splice(index, 1);
      files?.preview?.splice(index, 1);
      let f = { ...files };
      setFiles(f);
      callUpdateFilesCb(files.file, {
        type: "change",
        target: { name: otherProps?.name, files: files?.file },
      });
    }
  };
  useEffect(() => {
    if (reset) {
      fileInputField.current.value = null;
      setFiles({ file: [], preview: [] });
    }
  }, [reset]);
  return (
    <div className="d-flex flex-wrap" style={{ position: "relative" }}>
      {/* <FilePreviewContainer> */}
      <PreviewList>
        {!!files?.file?.length &&
          files.file?.map((file, index) => {
            let preview = files?.preview?.[index];
            let isImageFile = file.type.split("/")[0] === "image";
            return (
              <PreviewContainer key={`${file?.name}`}>
                {isImageFile && (
                  <ImagePreview src={preview} alt={`file preview ${index}`} />
                )}
                <FileMetaData isImageFile={isImageFile}>
                  <FileName>{file.name}</FileName>
                  <aside>
                    <span>{convertBytesToKB(file.size)} kb</span>
                  </aside>
                </FileMetaData>

                <RemoveFileIcon onClick={() => removeFile(index)}>
                  <XCircle size={12} />
                </RemoveFileIcon>
              </PreviewContainer>
            );
          })}
        <FileUploadContainer
          isHidden={!otherProps?.multiple && files.file?.length !== 0}
        >
          <UploadFileBtn type="button" onClick={handleUploadBtnClick}>
            <Plus />
          </UploadFileBtn>
          <FormField
            type="file"
            ref={fileInputField}
            accept="image/*"
            onChange={
              onChange
                ? handleNewFileUpload(onChange)
                : handleNewFileUpload(null)
            }
            title=""
            value=""
            {...otherProps}
          />
        </FileUploadContainer>
      </PreviewList>
      {/* </FilePreviewContainer> */}
    </div>
  );
};

export default forwardRef((props, ref) => (
  <FileUpload inputRef={ref} {...props} />
));
