import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  Col,
  CustomInput,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import PageTitle from "../../../components/PageTitle";
import {
  AvForm,
  AvGroup,
  AvInput,
  AvFeedback,
  AvField,
} from "availity-reactstrap-validation";
import {
  getSeviceCategory,
  saveSeviceCategory,
} from "../../../redux/serviceCategory/actions";
import { toBase64 } from "../../../helpers";

const AddEditServiceCategory = () => {
  const params = useParams();
  const serviceCategory = useSelector(
    (state) => state.ServiceCategory?.serviceCategory
  );
  const [status, setStatus] = useState(0);
  const [image, setImage] = useState(null);
  const isEdit = !!params?.id;
  const history = useHistory();
  const [preview, setPreview] = useState(null);
  const dispatch = useDispatch();
  const toggleSwitch = (e) => setStatus(!status);
  const handleSubmit = (e, values) => {
    e.preventDefault();
    let data = { ...values };
    console.log("data", data);
    delete data.image;
    if (image) data = { ...data, image };
    if (params?.id) data["id"] = params?.id;
    dispatch(
      saveSeviceCategory({ data, history })
    );
  };
  const handlefileChange = async (e) => {
    const dataUrl = await toBase64(e.target.files[0]);
    setImage(e.target.files[0]);
    setPreview(dataUrl);
  };
  useEffect(() => {
    //edit actions
    if (params?.id) {
      dispatch(getSeviceCategory(params?.id));
    }
  }, []);
  useEffect(() => {
    if (serviceCategory) setStatus(serviceCategory?.status === 1);
  }, [serviceCategory]);
  return (
    <>
      <Row className="page-title">
        <Col md={12}>
          <PageTitle
            breadCrumbItems={[
              { label: "Service categories", path: "/service-categories" },
              { label: "add", path: "/service-categories/add", active: true },
            ]}
            title={`${isEdit ? "Edit" : "add new"} service category`}
          />
        </Col>
      </Row>
      <Card>
        <CardBody>
          <AvForm onValidSubmit={handleSubmit}>
            <Row>
              <Col xs={12} md={4} lg={4}>
                <Label
                  for="image"
                  className="d-flex flex-column"
                  style={{ cursor: "pointer" }}
                >
                  <span className="mb-4">Choose image</span>
                  <img
                    src={
                      preview ||
                      (serviceCategory?.image
                        ? serviceCategory?.image
                        : require("../../../assets/images/no-image.png"))
                    }
                    style={{ width: "50%" }}
                  />
                  <AvInput
                    type="file"
                    name="image"
                    id="image"
                    className="d-none"
                    onChange={handlefileChange}
                  />
                </Label>
              </Col>
              <Col xs={12} md={8} lg={8}>
                <FormGroup>
                  <Label for="text">Category name</Label>
                  <AvInput
                    type="text"
                    name="categoryName"
                    id="text"
                    placeholder="Enter category name"
                    value={serviceCategory?.categoryName || ""}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="status">Status</Label>
                  <div>
                    <AvField
                      tag={CustomInput}
                      type="switch"
                      id="status"
                      name="status"
                      label="Active"
                      checked={status}
                      value={status}
                      onChange={toggleSwitch}
                    />
                  </div>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col className="d-flex justify-content-end">
                <FormGroup>
                  <Button
                    type="button"
                    className="btn btn-light mr-1"
                    onClick={(e) => history.goBack()}
                  >
                    Cancel
                  </Button>
                  <Button color="primary">Save</Button>
                </FormGroup>
              </Col>
            </Row>
          </AvForm>
        </CardBody>
      </Card>
    </>
  );
};

export default AddEditServiceCategory;
