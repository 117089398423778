export const CAR_MODEL = "CAR_MODEL";
export const CAR_MODEL_SUCCESS = "CAR_MODEL_SUCCESS";
export const CAR_MODEL_FAILED = "CAR_MODEL_FAILED";
export const SAVE_CAR_MODEL = "SAVE_CAR_MODEL";
export const SAVE_CAR_MODEL_SUCCESS = "SAVE_CAR_MODEL_SUCCESS";
export const SAVE_CAR_MODEL_FAILED = "SAVE_CAR_MODEL_FAILED";
export const EDIT_CAR_MODEL = "EDIT_CAR_MODEL";
export const EDIT_CAR_MODEL_SUCCESS = "EDIT_CAR_MODEL_SUCCESS";
export const EDIT_CAR_MODEL_FAILED = "EDIT_CAR_MODEL_FAILED";
export const DELETE_CAR_MODEL = "DELETE_CAR_MODEL";
export const DELETE_CAR_MODEL_SUCCESS =
  "DELETE_CAR_MODEL_SUCCESS";
export const DELETE_CAR_MODEL_FAILED = "DELETE_CAR_MODEL_FAILED";
export const GET_CAR_MODEL = "GET_CAR_MODEL";
export const GET_CAR_MODEL_SUCCESS = "GET_CAR_MODEL_SUCCESS";
export const GET_CAR_MODEL_FAILED = "GET_CAR_MODEL_FAILED";
