import React, { Component, useEffect, useState } from "react";

import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const RichTextEditor = ({
  hideToolbar,
  initialContent,
  onEditorContentChange,
}) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  useEffect(() => {
    if (initialContent) {
      const { contentBlocks, entityMap } = htmlToDraft(initialContent);
      const contentState = ContentState.createFromBlockArray(
        contentBlocks,
        entityMap
      );
      setEditorState(EditorState.createWithContent(contentState));
    }
  }, [initialContent]);

  /**
   * On editor body change
   */
  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
    const body = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    onEditorContentChange && onEditorContentChange(body);
  };

  return (
    <React.Fragment>
      <Editor
        editorState={editorState}
        wrapperClassName="rich-editor-wrapper"
        editorClassName="rich-editor"
        onEditorStateChange={onEditorStateChange}
        toolbarClassName={hideToolbar ? "hide-toolbar" : ""}
      />
    </React.Fragment>
  );
};

export default RichTextEditor;
