import passwordComplexity from "joi-password-complexity";

import Joi from "../services/joi";

class FormValidationRules {
  get username() {
    return Joi.string()
      .regex(
        new RegExp(
          /^[A-Za-z](?=[A-Za-z0-9_.]{3,31}$)[a-zA-Z0-9_]*\.?[a-zA-Z0-9_]*$/
        )
      )
      .required()
      .messages({
        "string.pattern.base":
          "Use 4 to 32 ch aracters and start with a letter. You may use letters, numbers, underscores, and one dot (.)",
      })
      .label("Username");
  }
  set username(value) {
    FormValidationRules.throwSetError();
  }

  get email() {
    return Joi.string()
      .email({ tlds: { allow: false } })
      .label("Email");
  }
  set email(value) {
    FormValidationRules.throwSetError();
  }

  get name() {
    return Joi.string()
      .min(3)
      .max(30)
      .pattern(/^[a-zA-Z0-9_. ]*$/)
      .required()
      .messages({
        "string.pattern.base":
          "Should contains letters, underscore [_] and dot [.] only",
        "string.base": "This field is required",
      });
  }
  set name(value) {
    FormValidationRules.throwSetError();
  }

  get password() {
    return passwordComplexity({
      min: 8,
      max: 25,
      lowerCase: 1,
      upperCase: 1,
      numeric: 1,
      symbol: 1,
      requirementCount: 4,
    }).label("Password");
  }
  set password(value) {
    FormValidationRules.throwSetError();
  }

  get termsOfService() {
    return Joi.boolean().valid(true).required().messages({
      "any.only": "Please confirm that you have read the Terms and Conditions",
    });
  }
  set termsOfService(value) {
    FormValidationRules.throwSetError();
  }

  get country() {
    return Joi.string()
      .pattern(/^[a-zA-Z]{2,}$/)
      .allow("")
      .message("Invalid entry");
  }
  set country(value) {
    FormValidationRules.throwSetError();
  }

  get companyName() {
    return Joi.string()
      .pattern(/^[a-zA-Z0-9 ]*$/)
      .min(2)
      .max(100)
      .allow("")
      .label("Company Name")
      .messages({
        "string.pattern.base": "Should contains letters and numbers only",
      });
  }
  set companyName(value) {
    FormValidationRules.throwSetError();
  }

  get optionalField() {
    return Joi.allow("", 0);
  }
  set optionalField(value) {
    FormValidationRules.throwSetError();
  }
  get confirmationCode() {
    return Joi.string()
      .length(6)
      .pattern(/^[0-9]+$/)
      .required()
      .messages({
        "string.pattern.base": "Invalid code",
        "string.length": "Invalid code",
      })
      .label("Confirmation code");
  }
  set confirmationCode(value) {
    FormValidationRules.throwSetError();
  }
  set phoneNumber(value) {
    FormValidationRules.throwSetError();
  }
  get phoneNumber() {
    return Joi.number().min(1000000).max(9999999999).messages({
      "number.min": "Please enter a valid phone number",
      "number.max": "Please enter a valid phone number",
    });
  }
  get postalCode() {
    return Joi.number().max(6).min(6);
  }
  set postalCode(value) {
    FormValidationRules.throwSetError();
  }
  get url() {
    return Joi.string();
  }
  set url(value) {
    FormValidationRules.throwSetError();
  }
  
  get phoneCode() {
    return Joi.number().min(1).max(999).required().messages({
      "any.empty": '"" is a required field',
      "number.base": "Should contains numbers only",
    });
  }

  set phoneCode(value) {
    FormValidationRules.throwSetError();
  }
  throwSetError() {
    throw new Error("It's not possible to set this field");
  }
}

const formValidationRules = new FormValidationRules();
export default formValidationRules;
