import Echo from "laravel-echo";

window.Pusher = require("pusher-js");

export function createSocketConnection(token) {
  try {
    if (!window.Echo) {
      window.Echo = new Echo({
        broadcaster: "pusher",
        key: process.env.REACT_APP_PUSHER_API_KEY,
        cluster: process.env.REACT_APP_PUSHER_CLUSTER,
        encrypted: true,
        forceTLS: false,
        // wsHost: window.location.hostname,
        // // wssHost: window.location.hostname,
        // wsPort: 6001,
        // // wssPort: 6001,
        authEndpoint: `${process.env.REACT_APP_BACKEND_URL}/broadcasting/auth`,
        auth: {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
        // enabledTransports: ['ws'],
      });
    }
  } catch (error) {
    console.log("socket error", error);
  }
}
