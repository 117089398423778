// @flow
import {
  LIST_RFQ,
  LIST_RFQ_FAILED,
  LIST_RFQ_SUCCESS,
  GET_RFQ,
  GET_RFQ_FAILED,
  GET_RFQ_SUCCESS,
  GET_RFQ_SUPPLIER,
  GET_RFQ_SUPPLIER_SUCCESS,
  GET_RFQ_SUPPLIER_FAILED,
  NOTIFY_RFQ_SUPPLIER,
  NOTIFY_RFQ_SUPPLIER_SUCCESS,
  NOTIFY_RFQ_SUPPLIER_FAILED,
  GET_RFQ_REQUEST_DETAIL_RESPONSES,
  GET_RFQ_REQUEST_DETAIL_RESPONSES_SUCCESS,
  GET_RFQ_REQUEST_DETAIL_RESPONSES_FAILED,
  GET_RFQ_REQUEST_DETAIL,
  GET_RFQ_REQUEST_DETAIL_SUCCESS,
  GET_RFQ_REQUEST_DETAIL_FAILED,
  ADD_RFQ_REQUEST,
  ADD_RFQ_REQUEST_SUCCESS,
  ADD_RFQ_REQUEST_FAILED,
} from "./constants";

const INIT_STATE = {
  data: [],
  rfq: {},
  loading: false,
  error: null,
  rfqSupplier: {
    data: [],
    loading: false,
    error: null,
  },
  notifyRfqSupplier: {
    loading: false,
    error: null,
  },
  addRfq: {
    loading: false,
    error: null,
  },
  rfqDetail: {
    data: null,
    error: null,
    loading: null,
  },
  rfqDetailResponses: {
    data: null,
    error: null,
    loading: null,
  },
};

const Rfq = (state = INIT_STATE, action) => {
  switch (action.type) {
    case LIST_RFQ:
      return { ...state, rfq: {}, loading: true };
    case LIST_RFQ_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
        error: null,
      };
    case LIST_RFQ_FAILED:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case GET_RFQ:
      return { ...state, loading: true };
    case GET_RFQ_SUCCESS:
      return {
        ...state,
        rfq: action.payload,
        loading: false,
        error: null,
      };
    case GET_RFQ_FAILED:
      return { ...state, error: action.payload, loading: false };

    case GET_RFQ_SUPPLIER:
      return { ...state, rfqSupplier: { ...state.rfqSupplier, loading: true } };
    case GET_RFQ_SUPPLIER_SUCCESS:
      return {
        ...state,
        rfqSupplier: {
          data: action.payload,
          loading: false,
          error: null,
        },
      };
    case GET_RFQ_SUPPLIER_FAILED:
      return {
        ...state,
        rfqSupplier: {
          ...state.rfqSupplier,
          error: action.payload,
          loading: false,
        },
      };
    case NOTIFY_RFQ_SUPPLIER:
      return {
        ...state,
        notifyRfqSupplier: {
          ...state.notifyRfqSupplier,
          loading: true,
        },
      };
    case NOTIFY_RFQ_SUPPLIER_SUCCESS:
      return {
        ...state,
        notifyRfqSupplier: {
          error: null,
          loading: false,
        },
      };
    case NOTIFY_RFQ_SUPPLIER_FAILED:
      return {
        ...state,
        notifyRfqSupplier: {
          error: action.payload,
          loading: false,
        },
      };
    case GET_RFQ_REQUEST_DETAIL_RESPONSES:
      return {
        ...state,
        rfqDetailResponses: {
          ...state.rfqDetailResponses,
          loading: true,
        },
      };
    case GET_RFQ_REQUEST_DETAIL_RESPONSES_SUCCESS:
      return {
        ...state,
        rfqDetailResponses: {
          ...state.rfqDetailResponses,
          error: null,
          data: action.payload,
          loading: false,
        },
      };
    case GET_RFQ_REQUEST_DETAIL_RESPONSES_FAILED:
      return {
        ...state,
        rfqDetailResponses: {
          ...state.rfqDetailResponses,
          error: action.payload,
          data: null,
          loading: false,
        },
      };

    case GET_RFQ_REQUEST_DETAIL:
      return {
        ...state,
        rfqDetail: {
          ...state.rfqDetail,
          loading: true,
        },
      };
    case GET_RFQ_REQUEST_DETAIL_SUCCESS:
      return {
        ...state,
        rfqDetail: {
          ...state.rfqDetail,
          error: null,
          data: action.payload,
          loading: false,
        },
      };
    case GET_RFQ_REQUEST_DETAIL_FAILED:
      return {
        ...state,
        rfqDetail: {
          ...state.rfqDetail,
          error: action.payload,
          data: null,
          loading: false,
        },
      };

    case ADD_RFQ_REQUEST:
      return {
        ...state,
        addRfq: {
          ...state.addRfq,
          loading: true,
        },
      };
    case ADD_RFQ_REQUEST_SUCCESS:
      return {
        ...state,
        addRfq: {
          ...state.addRfq,
          data: action.payload,
          loading: false,
        },
      };
    case ADD_RFQ_REQUEST_FAILED:
      return {
        ...state,
        addRfq: {
          ...state.addRfq,
          error: action.payload,
          loading: false,
        },
      };
    default:
      return { ...state };
  }
};

export default Rfq;
