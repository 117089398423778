export const GET_ROLES = "GET_ROLES";
export const GET_ROLES_SUCCESS = "GET_ROLES_SUCCESS";
export const GET_ROLES_FAILED = "GET_ROLES_FAILED";

export const GET_ROLE = "GET_ROLE";
export const GET_ROLE_SUCCESS = "GET_ROLE_SUCCESS";
export const GET_ROLE_FAILED = "GET_ROLES_FAILED";

export const SAVE_ROLES = "SAVE_ROLES";
export const SAVE_ROLES_SUCCESS = "SAVE_ROLES_SUCCESS";
export const SAVE_ROLES_FAILED = "SAVE_ROLES_FAILED";

export const DELETE_ROLES = "DELETE_ROLES";
export const DELETE_ROLES_SUCCESS = "DELETE_ROLES_SUCCESS";
export const DELETE_ROLES_FAILED = "DELETE_ROLES_FAILED";
