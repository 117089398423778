import moment from "moment";
import React, { useEffect, useState } from "react";
import { selectFilter, textFilter } from "react-bootstrap-table2-filter";
import { useDispatch, useSelector } from "react-redux";
import ReactSelect from "react-select";
import { Alert, Button, Col, Row } from "reactstrap";
import PageTitle from "../../../components/PageTitle";
import Status from "../../../components/Status";
import StatusDropdown from "../../../components/StatusDropdown/StatusDropdown";
import Actions from "../../../components/table/Actions";
import Table from "../../../components/table/Table";
import useTableActions from "../../../components/table/useActions";
import { DEFAULT_STATUS } from "../../../constants";
import {
  deleteUser,
  getUsers,
  updateUserStatus,
  updateUserWhatsAppGroup,
} from "../../../redux/users/actions";
import { getWhatsAppGroupOptionApi } from "../../../services/api";
const ListUsers = () => {
  const baseUrl = "/users";
  const { open, toggleModal, modalOpen, handleDelete } =
    useTableActions(deleteUser);
  const [whatsAppGroupOption, setWhatsAppGroupOption] = useState([]);
  const fetchWhatsAppGroup = async () => {
    try {
      const response = await getWhatsAppGroupOptionApi();
      if (response?.data) setWhatsAppGroupOption(response?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const columns = ({ hasFilter = false }) => [
    {
      dataField: "id",
      text: "ID",
      sort: true,
    },
    {
      dataField: "name",
      text: "Display Name",
      sort: true,
      filter: hasFilter && textFilter(),
    },
    {
      dataField: "roles.role_name",
      text: "Role",
      sort: true,
      filter: hasFilter && textFilter(),
      formatter: (cell, row) =>
        row?.roles ? row?.roles?.map((v) => v?.role_name)?.join(", ") : "",
    },
    {
      dataField: "email",
      text: "Email",
      sort: true,
      filter: hasFilter && textFilter(),
    },
    {
      dataField: "phoneNumber",
      text: "Phone number",
      sort: true,
      filter: hasFilter && textFilter(),
    },
    {
      dataField: "isProfileCompleted",
      text: "Onboarded",
      sort: true,
    },
    {
      dataField: "created_at",
      text: "Created at",
      sort: true,
      filter: hasFilter && textFilter(),
      formatter: (cell, row) =>
        row?.created_at
          ? moment(row?.created_at).format("DD-MM-YYYY hh:mm:A")
          : null,
    },
    {
      dataField: "updated_at",
      text: "Updated at",
      sort: true,
      filter: hasFilter && textFilter(),
      formatter: (cell, row) =>
        row?.updated_at
          ? moment(row?.updated_at).format("DD-MM-YYYY hh:mm:A")
          : null,
    },
    {
      dataField: "whats_app_group",
      text: "WhatsApp group",
      sort: true,
      editable: true,
      formatter: (cel, row) => {
        console.log(row);
        return (
          <StatusDropdown
            defaultValue={row?.whats_app_group}
            options={whatsAppGroupOption}
            getOptionLabel={(option) => option?.groupName}
            getOptionValue={(option) => option?.id}
            dispatchOnChange={(val) =>
              updateUserWhatsAppGroup({
                whatsAppGroupId: val?.id,
                id: row?.id,
              })
            }
          />
        );
      },

      filter: hasFilter && selectFilter({ options: whatsAppGroupOption }),
    },

    {
      dataField: "status",
      text: "Status",
      sort: true,
      editable: true,
      formatter: (cel, row) => {
        console.log(row?.status);
        return (
          <StatusDropdown
            defaultValue={{
              value: row?.status,
              label: row?.status ? "Active" : "Inactive",
            }}
            options={[
              { label: "Active", value: true },
              { label: "Inactive", value: false },
            ]}
            dispatchOnChange={(val) =>
              updateUserStatus({ status: val?.value, id: row?.id })
            }
          />
        );
      },

      filter: hasFilter && selectFilter({ options: DEFAULT_STATUS }),
    },

    {
      dataField: "actions",
      text: "Actions",
      sort: false,
      isDummyField: true,
      exportCSV: false,
      formatter: Actions,
      formatExtraData: {
        open,
        toggleModal,
        handleDelete,
        baseUrl,
        modalOpen,
        canDelete: false,
      },
    },
  ];
  const dispatch = useDispatch();
  const [pagination, setPagination] = useState({
    page: 1,
    searchText: "",
    sizePerPage: 10,
    sortField: "id",
    sortOrder: "asc",
  });
  const { data, error, loading } = useSelector((state) => state.Users);
  useEffect(() => {
    dispatch(getUsers(pagination));
    fetchWhatsAppGroup();
  }, []);
  const [hasFilter, setHasFilter] = useState(false);
  const toggleFilter = () => setHasFilter(!hasFilter);
  const handleTableChange = (e, v) => {
    console.log(e, v);
    const { page, searchText, sizePerPage, sortField, sortOrder } = v;
    setPagination({ page, searchText, sizePerPage, sortField, sortOrder });
    dispatch(
      getUsers({
        page,
        searchText,
        sizePerPage,
        sortField,
        sortOrder,
      })
    );
  };
  return (
    <>
      <Row className="page-title">
        <Col md={12}>
          <PageTitle
            breadCrumbItems={[
              {
                label: "List users",
                path: "/users",
              },
            ]}
            title={"List of Users"}
          />
        </Col>
      </Row>
      {error && <Alert color="danger">Oops something went wrong</Alert>}
      <Table
        loading={loading}
        records={data?.data}
        columns={columns({ hasFilter })}
        handleTableChange={handleTableChange}
        addUrl={`${baseUrl}/add`}
        pagination={{ ...pagination, totalSize: data?.total }}
      />
    </>
  );
};

export default ListUsers;
