import { useEffect } from "react";
import { useSelector } from "react-redux";

import { getUserSession } from "../helpers/authUtils";

import { userSelector } from "../redux/users/selectors";
import { createSocketConnection } from "../services/socketService";

function listen(callBack, channel) {
  if (window.Echo) {
    console.log("notifcation listen", channel);
    window.Echo.private(channel)
      .listen(".message.new", (payload) => {
        console.log("notifcation payload", payload);

        callBack(payload);
      })
      .error((e) => console.log("echo error", e));

    return function cleanUp() {
      window.Echo.leaveChannel(`private-${channel}`);
    };
  }
}

export const usePusherSocket = ({ callBack, type = "NOTIFICATION" }, deps) => {
  const user = getUserSession();
  useEffect(() => {
    if (user) {
      createSocketConnection(user?.token);
      switch (type) {
        case "NOTIFICATION":
          return listen(callBack, `App.Models.User.${user?.id}`);
        case "NOTIFY_ALL":
          return listen(callBack, "notify-all");
        default:
          return listen(callBack, type);
      }
    }
  }, [user?.token, ...deps]);
};
