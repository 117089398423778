import {
  ORDERS,
  ORDERS_FAILED,
  ORDERS_SUCCESS,
  GET_ORDER,
  GET_ORDER_SUCCESS,
  GET_ORDER_FAILED,
  EDIT_ORDER,
  EDIT_ORDER_SUCCESS,
  EDIT_ORDER_FAILED,
  SAVE_ORDER,
  SAVE_ORDER_FAILED,
  SAVE_ORDER_SUCCESS,
  ASSIGN_DEVILERY_AGENT,
  ASSIGN_DEVILERY_AGENT_SUCCESS,
  ASSIGN_DEVILERY_AGENT_FAILED,
} from "./constants";

export const getOrder = (id) => ({
  type: GET_ORDER,
  payload: { id },
});

export const getOrderSuccess = (data) => ({
  type: GET_ORDER_SUCCESS,
  payload: data,
});

export const getOrderFailed = (errorMessage) => ({
  type: GET_ORDER_FAILED,
  payload: errorMessage,
});

export const getOrders = (payload) => ({
  type: ORDERS,
  payload,
});

export const getOrdersSuccess = (data) => ({
  type: ORDERS_SUCCESS,
  payload: { data },
});

export const getOrdersFailed = (errorMessage) => ({
  type: ORDERS_FAILED,
  payload: errorMessage,
});

export const editOrder = (data) => ({
  type: EDIT_ORDER,
  payload: data,
});

export const editOrderSuccess = (success) => ({
  type: EDIT_ORDER_SUCCESS,
  payload: success,
});

export const editOrderFailed = (errorMessage) => ({
  type: EDIT_ORDER_FAILED,
  payload: errorMessage,
});

export const saveOrder = (payload) => ({
  type: SAVE_ORDER,
  payload,
});

export const saveOrderSuccess = (success) => ({
  type: SAVE_ORDER_SUCCESS,
  payload: success,
});

export const saveOrderFailed = (error) => ({
  type: SAVE_ORDER_FAILED,
  payload: error,
});

export const assignDeliveryAgent = (payload) => ({
  type: ASSIGN_DEVILERY_AGENT,
  payload,
});

export const assignDeliveryAgentSuccess = (success) => ({
  type: ASSIGN_DEVILERY_AGENT_SUCCESS,
  payload: success,
});

export const assignDeliveryAgentFailed = (error) => ({
  type: ASSIGN_DEVILERY_AGENT_FAILED,
  payload: error,
});
