import moment from "moment";
import React, { useEffect, useState } from "react";
import { Type } from "react-bootstrap-table2-editor";
import { selectFilter, textFilter } from "react-bootstrap-table2-filter";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Alert, Col, Row } from "reactstrap";
import OrderStatusDropDown from "../../components/OrderStatusDropDown";
import PageTitle from "../../components/PageTitle";
import Status from "../../components/Status";
import Actions from "../../components/table/Actions";
import Table from "../../components/table/Table";
import useTableActions from "../../components/table/useActions";
import { ORDER_STATUS } from "../../constants/orderStatus";
import { getOrders } from "../../redux/orders/actions";
import { deleteUser, getUsers } from "../../redux/users/actions";
import { getCookie } from "../../utils/helpers";
import { useModal } from "../../hooks/useModal";
import CustomModal from "../../components/CustomModal/CustomModal";
import {
  downloadOrderListApi,
  getOrdersByOrderListIdApi,
  getUsersByRoleApi,
} from "../../services/api";
import {
  FormCheck,
  FormControl,
  FormGroup,
  FormLabel,
  Card,
  Button,
} from "react-bootstrap";
import ReactSelect from "react-select";
import FormCheckLabel from "react-bootstrap/esm/FormCheckLabel";

const ListOrders = () => {
  const baseUrl = "/orders";
  const { open, toggleModal, modalOpen, handleDelete } =
    useTableActions(deleteUser);
  const {
    open: openDeliveryManModal,
    onClose: onCloseDeliveryManModal,
    onOpen: onOpenDeliveryManModal,
  } = useModal();
  const columns = ({
    hasFilter = false,
    isMobile = false,
    handleOpenAssignedDeliveryMan,
  }) => [
    {
      dataField: "id",
      text: "ID",
      sort: true,
      filter: hasFilter && textFilter(),
      editable: false,
    },
    {
      dataField: "order.invoiceNo",
      text: "Invoice No",
      sort: true,
      filter: hasFilter && textFilter(),
      editable: false,
    },
    {
      dataField: "orderNo",
      text: "Order No",
      sort: true,
      filter: hasFilter && textFilter(),
      editable: false,
    },
    // this is used for asigning delivery agent but it is incomplete
    // {
    //   dataField: "assigned_delivery_man.name",
    //   text: "Delivery agent",
    //   sort: true,
    //   filter: hasFilter && textFilter(),
    //   editable: false,
    //   formatter: (cell, row) => {
    //     if (!row.assigned_delivery_man)
    //       return (
    //         <Button onClick={handleOpenAssignedDeliveryMan(row)}>
    //           Assign now
    //         </Button>
    //       );
    //   },
    // },
    {
      dataField: "orderItemNo",
      text: "Order Item no",
      sort: true,
      filter: hasFilter && textFilter(),
      editable: false,
    },
    {
      dataField: "sparePartDetail",
      text: "Item",
      sort: true,
      filter: hasFilter && textFilter(),
      formatter: (cell, row) => {
        const item = JSON.parse(row?.sparePartDetail);
        console.log("item", item);
        return (
          <div className="d-flex me-2">
            <div className="d-flex flex-column">
              <span>
                {[
                  item?.itemName,
                  item?.manufacturer,
                  item?.sparePartNameOrNumber,
                ]
                  .filter((v) => v)
                  .join(" | ")}
              </span>
            </div>
          </div>
        );
      },
      editable: false,
    },
    {
      dataField: "pricePerItem",
      text: "Payment details",
      sort: true,
      formatter: (cell, row) => (
        <div className="d-flex flex-column">
          <span>
            <b className="mr-2">Price:</b>
            {row?.pricePerItem}
          </span>
          <span>
            <b className="mr-2">Quantity:</b>
            {row?.quantity}
          </span>
          <span>
            <b className="mr-2">Shipping:</b>
            {row?.order?.deliveryFee}
          </span>
          <span>
            <b className="mr-2">Total:</b>
            {row?.pricePerItem * row?.quantity + parseFloat(row?.deliveryFee)}
          </span>
        </div>
      ),
    },
    {
      dataField: "supplier",
      text: "supplier Info",
      sort: true,
      filter: hasFilter && textFilter(),
      formatter: (cell, row) => (
        <div style={{ minWidth: 100 }}>
          <span className="d-block">{row?.supplier?.userCode}</span>
          <p className="m-0">{`${row?.supplier?.name}`}</p>
          <span>
            <small>
              ({row?.supplier?.phoneCode} {row?.supplier?.phoneNumber})
            </small>
          </span>
        </div>
      ),
      editable: false,
    },
    {
      dataField: "supplier_business.businessName",
      text: "Supplier Business name",
      sort: true,
      filter: hasFilter && textFilter(),
      editable: false,
    },
    {
      dataField: "supplier_business",
      text: "Supplier address",
      sort: true,
      filter: hasFilter && textFilter(),
      formatter: (cell, row) => {
        const str = [
          row?.supplier_business?.businessAddress1,
          row?.supplier_business?.businessAddress2,
          row?.supplier_business?.city?.name,
          row?.supplier_business?.province?.provinceName,
          row?.supplier_business?.country?.countryName,
        ]
          .filter((v) => v)
          .join(", ");
        return <p style={{ width: 200 }}>{str}</p>;
      },
      editable: false,
    },
    {
      dataField: "purchaser",
      text: "Buyer Info",
      sort: true,
      filter: hasFilter && textFilter(),
      formatter: (cell, row) => (
        <div style={{ minWidth: 100 }}>
          <span className="d-block">{row?.purchaser?.userCode}</span>
          <p className="m-0">{`${row?.purchaser?.name}`}</p>
          <span>
            <small>
              ({row?.purchaser?.phoneCode} {row?.purchaser?.phoneNumber})
            </small>
          </span>
        </div>
      ),
      editable: false,
    },
    {
      dataField: "service_provider_business.businessName",
      text: "Buyer Business name",
      sort: true,
      filter: hasFilter && textFilter(),
      editable: false,
    },
    {
      dataField: "purchaser.roles.[0].role_name",
      text: "Buyer type",
      sort: true,
      filter: hasFilter && textFilter(),
      formatter: (cell, row) => (
        <span> {row?.purchaser?.roles?.[0]?.role_name}</span>
      ),
      editable: false,
    },

    {
      dataField: "shippingAddress",
      text: "Buyer address",
      sort: true,
      formatter: (cell, row) => {
        const address = JSON.parse(row?.shippingAddress);
        const str = [
          address?.contactPersonName,
          address?.addressLine1,
          address?.addressLine2,
          address?.city?.name,
          address?.province?.provinceName,
          address?.country?.countryName,
          address?.postalCode,
        ]
          .filter((v) => v)
          .join(", ");
        return <p style={{ width: 200 }}>{str}</p>;
      },
      editable: false,
    },
    {
      dataField: "assignedDeliveryMan?.name",
      text: "Delivery man",
      sort: true,
      editable: false,
    },
    {
      dataField: "created_at",
      text: "Ordered on",
      sort: true,
      filter: hasFilter && textFilter(),
      formatter: (cell, row) =>
        row?.created_at
          ? moment(row?.created_at).format("DD-MM-YYYY hh:mm:A")
          : null,
      editable: false,
    },
    {
      dataField: "updated_at",
      text: "Last updated on",
      sort: true,
      filter: hasFilter && textFilter(),
      formatter: (cell, row) =>
        row?.updated_at
          ? moment(row?.updated_at).format("DD-MM-YYYY hh:mm:A")
          : null,
      editable: false,
    },
    {
      dataField: "orderStatus",
      text: "Status",
      sort: true,
      editable: true,
      formatter: (cell, row) => (
        <span className="badge badge-soft-warning py-1">
          {ORDER_STATUS[row?.orderStatus]}
        </span>
      ),
      editorRenderer: (
        editorProps,
        value,
        row,
        column,
        rowIndex,
        columnIndex
      ) => (
        <div style={{ width: 150 }}>
          <OrderStatusDropDown
            {...editorProps}
            value={value}
            orderId={row?.id}
          />
        </div>
      ),

      // formatter: (cel, row) => <Status status={row?.orderStatus} />,
      // filter:
      //   hasFilter && selectFilter({ options: { 1: "Active", 0: "Inactive" } }),
    },

    {
      dataField: "actions",
      text: "Actions",
      sort: false,
      isDummyField: true,
      exportCSV: false,
      formatter: Actions,

      formatExtraData: {
        open,
        toggleModal,
        baseUrl,
        modalOpen,
        canDelete: false,
        canEdit: false,
        canView: false,
        renderAction: (row) => (
          <>
            <Link
              target={isMobile ? "_self" : "_blank"}
              rel="noreferrer"
              className="btn btn-sm btn-primary mr-1"
              to={`/download-pdf?type=invoice&identifier=${row?.orderListId}`}
            >
              <i className="uil uil-file"></i>
            </Link>
            <Link
              target={"_blank"}
              rel="noreferrer"
              className="btn btn-sm btn-info mr-1"
              to={`/orders/view/${row?.orderListId}`}
            >
              <i className="uil uil-eye"></i>
            </Link>
          </>
        ),
      },
      editable: false,
    },
  ];
  const dispatch = useDispatch();
  const [pagination, setPagination] = useState({
    page: 1,
    searchText: "",
    sizePerPage: 10,
    sortField: "id",
    sortOrder: "desc",
  });
  const { data, error, loading } = useSelector((state) => state.Orders);
  useEffect(() => {
    dispatch(getOrders(pagination));
  }, []);
  const isMobile = getCookie("mobile");
  const [hasFilter, setHasFilter] = useState(false);
  const toggleFilter = () => setHasFilter(!hasFilter);
  const [deliveryManOptions, setDeliveryManOptions] = useState([]);
  const [orderItemList, setOrderItemList] = useState([]);
  const [selectedOrderItem, setSelectedOrderItem] = useState([]);
  const handleSelectAllOrderItem = () => {
    setSelectedOrderItem(orderItemList?.map((item) => item?.id));
  };
  const handleSelectOrderItem = (id) => (e) => {
    if (e.target.checked) {
      setSelectedOrderItem((s) => [...s, id]);
    } else setSelectedOrderItem((s) => s.filter((v) => v !== id));
  };
  const handleOpenAssignedDeliveryMan = (row) => async (e) => {
    console.log("row", row);
    onOpenDeliveryManModal();
    const {
      data: deliveryManData,
      status,
      error,
    } = await getUsersByRoleApi("delivery_agent");
    if (status === "Success") {
      setDeliveryManOptions(deliveryManData);
    }
    const {
      data: orderItemData,
      status: orderItemStatus,
      error: orderItemError,
    } = await getOrdersByOrderListIdApi(row?.orderListId);
    if (orderItemStatus === "Success") {
      setOrderItemList(orderItemData?.order_item);
    }
  };

  const handleDeliveryManSubmit = () => {
    // dispatch()
  };
  const [downloadOrderListLoading, setDownloadOrderListLoading] =
    useState(false);
  const handleOrderExport = async () => {
    try {
      setDownloadOrderListLoading(true);
      const response = await downloadOrderListApi();

      // Create a Blob from the response data
      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      // Create a link element and trigger the download
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "orders.xlsx";
      link.click();
    } catch (error) {
      console.error("Error downloading Excel file", error);
    }
    setDownloadOrderListLoading(false);
  };

  const handleTableChange = (e, v) => {
    console.log(e, v);
    const { page, searchText, sizePerPage, sortField, sortOrder } = v;
    setPagination({ page, searchText, sizePerPage, sortField, sortOrder });
    dispatch(
      getOrders({
        page,
        searchText,
        sizePerPage,
        sortField,
        sortOrder,
      })
    );
  };

  return (
    <>
      <Row className="page-title">
        <Col md={12}>
          <PageTitle
            breadCrumbItems={[
              {
                label: "List users",
                path: "/users",
              },
            ]}
            title={"List of Users"}
          />
        </Col>
      </Row>
      {error && <Alert color="danger">Oops something went wrong</Alert>}
      <div className="d-flex justify-content-end">
        <Link to="/download-all-invoice" className="btn btn-primary mb-2">
          Download invoices
        </Link>
        <Button
          disabled={downloadOrderListLoading}
          variant="contained"
          className="btn btn-secondary mb-2 ml-2"
          onClick={handleOrderExport}
        >
          {downloadOrderListLoading ? "Downloading..." : `Download order`}
        </Button>
      </div>
      <Table
        loading={loading}
        records={data?.data}
        columns={columns({
          hasFilter,
          isMobile,
          handleOpenAssignedDeliveryMan,
        })}
        handleTableChange={handleTableChange}
        pagination={{ ...pagination, totalSize: data?.total }}
        canCellEdit={true}
      />

      <CustomModal
        open={openDeliveryManModal}
        closeModal={onCloseDeliveryManModal}
        noFooter
      >
        <form onSubmit={handleDeliveryManSubmit}>
          <FormGroup>
            <FormLabel>Delivery man</FormLabel>
            <ReactSelect
              isLoading={!deliveryManOptions?.length}
              options={deliveryManOptions}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              placeholder="Select Delivery man"
            />
          </FormGroup>
          <div className="d-flex align-items-center justify-content-between">
            <span>Item name</span>
            <Button
              onClick={handleSelectAllOrderItem}
              className="my-3"
              variant="outline-secondary"
              size="sm"
            >
              {selectedOrderItem?.length === orderItemList?.length
                ? "Selected"
                : "Select all"}
            </Button>
          </div>

          <div
            className="d-flex flex-column"
            style={{ maxHeight: "56vh", overflow: "auto" }}
          >
            {orderItemList?.map((row) => {
              const item = JSON.parse(row?.sparePartDetail);

              return (
                <FormCheckLabel
                  key={`orderItemList-${row.id}`}
                  htmlFor={`orderItemList-${row.id}`}
                >
                  <Card style={{ border: "1px solid black" }}>
                    <Card.Body>
                      <div className="d-flex my-1 align-items-center">
                        <input
                          style={{
                            verticalAlign: "middle",
                            margin: 10,
                            height: 20,
                            width: 20,
                          }}
                          type="checkbox"
                          id={`orderItemList-${row.id}`}
                          onClick={handleSelectOrderItem(row?.id)}
                          checked={selectedOrderItem?.includes(row?.id)}
                        />
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <span>
                            {[
                              item?.itemName,
                              item?.manufacturer,
                              item?.sparePartNameOrNumber,
                            ]
                              .filter((v) => v)
                              .join(" | ")}
                          </span>
                          <span>{`${row?.rfq?.vehicle_make?.name} ${row?.rfq?.vehicle_model?.name} ${row?.rfq?.vehicle_year?.year}`}</span>
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                </FormCheckLabel>
              );
            })}
          </div>

          <div className="d-flex ">
            <Button
              variant="secondary"
              className="mr-2"
              onClick={onCloseDeliveryManModal}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              variant="primary"
              onClick={handleDeliveryManSubmit}
            >
              Save
            </Button>
          </div>
        </form>
      </CustomModal>
    </>
  );
};

export default ListOrders;
