// @flow
import { assignDeliveryAgent } from "./actions";
import {
  ORDERS,
  ORDERS_FAILED,
  ORDERS_SUCCESS,
  GET_ORDER,
  GET_ORDER_FAILED,
  GET_ORDER_SUCCESS,
  EDIT_ORDER,
  EDIT_ORDER_SUCCESS,
  EDIT_ORDER_FAILED,
  SAVE_ORDER,
  SAVE_ORDER_SUCCESS,
  SAVE_ORDER_FAILED,
  ASSIGN_DEVILERY_AGENT,
  ASSIGN_DEVILERY_AGENT_SUCCESS,
  ASSIGN_DEVILERY_AGENT_FAILED,
} from "./constants";

const INIT_STATE = {
  data: [],
  order: {},
  loading: false,
  error: null,
  assignDeliveryAgent: {
    data: [],
    error: null,
    loading: false,
  },
};

const Orders = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ORDERS:
      return { ...state, order: {}, loading: true };
    case ORDERS_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
        error: null,
      };
    case ORDERS_FAILED:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case GET_ORDER:
      return { ...state, loading: true };
    case GET_ORDER_SUCCESS:
      return {
        ...state,
        order: action.payload,
        loading: false,
        error: null,
      };
    case GET_ORDER_FAILED:
      return { ...state, error: action.payload, loading: false };
    case EDIT_ORDER:
      return { ...state, loading: true };
    case EDIT_ORDER_SUCCESS: {
      return {
        ...state,
        data: {
          ...state?.data,
          data: state?.data?.data?.filter((v) => v?.id !== action?.payload?.id),
        },
        loading: false,
        error: null,
      };
    }
    case EDIT_ORDER_FAILED:
      return { ...state, error: action.payload, loading: false };

    case SAVE_ORDER:
      return {
        ...state,
        loading: true,
      };
    case SAVE_ORDER_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case SAVE_ORDER_FAILED:
      return {
        ...state,
        loading: false,
      };

    case ASSIGN_DEVILERY_AGENT:
      return {
        ...state,
        assignDeliveryAgent: {
          ...state.assignDeliveryAgent,
          loading: true,
        },
      };
    case ASSIGN_DEVILERY_AGENT_SUCCESS:
      return {
        ...state,
        assignDeliveryAgent: {
          ...state.assignDeliveryAgent,
          data: action.payload,
          loading: false,
        },
      };
    case ASSIGN_DEVILERY_AGENT_FAILED:
      return {
        ...state,
        assignDeliveryAgent: {
          ...state.assignDeliveryAgent,
          error: action.payload,
          loading: false,
        },
      };
    default:
      return { ...state };
  }
};

export default Orders;
