import {
  DELETE_WHATSAPP_GROUP,
  DELETE_WHATSAPP_GROUP_FAILED,
  DELETE_WHATSAPP_GROUP_SUCCESS,
  EDIT_WHATSAPP_GROUP,
  EDIT_WHATSAPP_GROUP_FAILED,
  EDIT_WHATSAPP_GROUP_SUCCESS,
  SAVE_WHATSAPP_GROUP,
  SAVE_WHATSAPP_GROUP_FAILED,
  SAVE_WHATSAPP_GROUP_SUCCESS,
  GET_WHATSAPP_GROUP,
  GET_WHATSAPP_GROUP_SUCCESS,
  GET_WHATSAPP_GROUP_FAILED,
} from "./constants";

export const getWhatsAppGroup = (payload) => ({
  type: GET_WHATSAPP_GROUP,
  payload,
});

export const getWhatsAppGroupSuccess = (data) => ({
  type: GET_WHATSAPP_GROUP_SUCCESS,
  payload: data,
});

export const getWhatsAppGroupFailed = (errorMessage) => ({
  type: GET_WHATSAPP_GROUP_FAILED,
  payload: errorMessage,
});

export const saveWhatsAppGroup = ({ data, history }) => ({
  type: SAVE_WHATSAPP_GROUP,
  payload: { data, history },
});

export const saveWhatsAppGroupSuccess = (success) => ({
  type: SAVE_WHATSAPP_GROUP_SUCCESS,
  payload: success,
});

export const saveWhatsAppGroupFailed = (errorMessage) => ({
  type: SAVE_WHATSAPP_GROUP_FAILED,
  payload: errorMessage,
});

export const updateWhatsAppGroup = (data) => ({
  type: EDIT_WHATSAPP_GROUP,
  payload: data,
});

export const updateWhatsAppGroupSuccess = (success) => ({
  type: EDIT_WHATSAPP_GROUP_SUCCESS,
  payload: success,
});

export const updateWhatsAppGroupFailed = (errorMessage) => ({
  type: EDIT_WHATSAPP_GROUP_FAILED,
  payload: errorMessage,
});

export const deleteWhatsAppGroup = (data) => ({
  type: DELETE_WHATSAPP_GROUP,
  payload: data,
});

export const deleteWhatsAppGroupSuccess = (success) => ({
  type: DELETE_WHATSAPP_GROUP_SUCCESS,
  payload: success,
});

export const deleteWhatsAppGroupFailed = (errorMessage) => ({
  type: DELETE_WHATSAPP_GROUP_FAILED,
  payload: errorMessage,
});
