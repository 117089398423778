import debounce from "lodash.debounce";
import jsonToFormData from "json-form-data";
import { authRequest, fetchApi } from "../helpers/api";
import axios from "axios";
import { API_URL } from "../constants";

export const getCountryOption = async (input) => {
  return await fetchApi(`/countries?search=${input}`);
};

export const getSingleCountry = async (payload) => {
  const data = await fetchApi(`/countries/${payload.id}`);
  return data;
};

export const getCityOption = async (input) => {
  return await fetchApi(`/city?search=${input}`);
};
export const getCity = async (id) => {
  return await fetchApi(`/city/${id}`);
};
export const getCitiesWithPaginateApi = async (payload) => {
  let qs = `?search=${payload?.search}`;
  if (payload?.countryId) qs += `&country=${payload?.countryId}`;
  if (payload?.provinceId) qs += `&province=${payload?.provinceId}`;
  const { data } = await fetchApi(`/cities-with-paginate${qs}`);
  console.log("data", data);
  return data;
};
export const getProvince = async (payload) => {
  let qs = `?search=${payload?.search}`;
  if (payload?.countryId) qs += `&country=${payload?.countryId}`;
  if (payload?.provinceId) qs += `&province=${payload?.provinceId}`;

  const data = await authRequest(`/province${qs}`);
  return data;
};

export const getVehicleMakeOption = async (input) => {
  return await fetchApi(
    `/vehicleMake?search=${input}&sortField=car_make_category&sortOrder=asc`
  );
};
export const getCarModel = async ({ search = "", vehicleMake = "" }) => {
  const data = await fetchApi(
    `/car-model?vehicleMake=${vehicleMake}&search=${search}`
  );
  return data;
};

// deprecated method may be removed
export const getsparePartsSubCategories = async (input) => {
  return await fetchApi(
    `/spare-parts-categories/getAllSparePartsSubCategories?search=${input}`
  );
};

export const getSpartPartsSubCategory = async (payload) => {
  let str = [];
  if (payload?.categoryId) str.push(`categoryId=${payload.categoryId}`);
  if (payload?.search) str.push(`search=${payload.search}`);
  if (payload?.level) str.push(`level=${payload.level}`);
  const queryString = str.join("&");
  return await fetchApi(`/spare-parts-categories/search?${queryString}`);
};
export const getSpartPartsCategory = async (payload) => {
  const { data } = await fetchApi(
    `/spare-parts-categories/search?search=${payload?.search}&level=${payload.level}`
  );
  return data;
};
// export const getProvinceOption = async ({ countryId }) => {
//   const s = await fetchApi(`/province/${countryId}`);
//   return s;
// };
export const getProvinceOption = async ({
  countryId,
  provinceId,
  search = "",
}) => {
  let qs = `?search=${search}`;
  if (countryId) qs += `&country=${countryId}`;
  if (provinceId) qs += `&province=${provinceId}`;

  return await fetchApi(`/province${qs}`);
};

export const getServiceCategoryOption = async (input) => {
  return await fetchApi(`/services-list?search=${input}`);
};
export const getServiceProviderList = async (input) => {
  const result = await fetchApi(`/usersList/service-provider?search=${input}`);
  return result?.data;
};

export const getSupplierList = async (input) => {
  const result = await fetchApi(`/usersList/supplier?search=${input}`);
  return result?.data;
};

export const getSupplierDealershipTypeOption = async (input) => {
  return await fetchApi(`/dealershiptype-list?search=${input}`);
};

export const getCountryCode = async () => {
  return await fetchApi(`/country-code`);
};

export const getImageUrl = async ({ path }) => {
  const data = await fetchApi(`/image?path=${path}`);
  return data;
};

export const getAudioUrl = async ({ path }) => {
  const { data } = await fetchApi(`/audio?path=${path}`, {
    headers: {
      "content-type": "audio/mpeg",
    },
  });
  return data;
};

export const getAllSupplierBusinessApi = async (search = "") => {
  return await fetchApi(
    `/supplier-business/supplierBusinessList?searchText=${search}`
  );
};

export const getAllServiceProviderBusinessApi = async (search = "") => {
  return await fetchApi(
    `/service-provider-business/serviceProviderList?searchText=${search}`
  );
};

export const getPartsCategoryOptionBySupplierType = async (category) => {
  const data = await fetchApi(
    `/spare-parts-categories/getPartsCategoryOptionBySupplierType`,
    { params: { category } }
  );
  return data;
};

export const getRfqSupplierListByRfq = async (id) => {
  return await fetchApi(`/rfq/getRfqSupplier/${id}`);
};

export const getRfqNos = async () => {
  const data = await fetchApi(`/rfqNos`);
  return data;
};

export const getRfqDetailApi = async ({ id }) => {
  return await fetchApi(`/rfq/${id}`);
};
export const getRfqDetailResponsesApi = async ({ rfqRequestId }) => {
  return await fetchApi(`/rfq/responses/${rfqRequestId}`);
};

export const getImageUrls = async ({ paths }) => {
  const { data } = await authRequest(`/images`, {
    method: "post",
    data: { paths },
  });
  return data;
};

export const getWhatsAppGroupOptionApi = async () => {
  return await fetchApi(`/getWhatsAppGroup`);
};

const _fetchSupplierBusiness = (query, callback) => {
  getAllSupplierBusinessApi(query).then((resp) => callback(resp?.data?.data));
};
export const fetchSupplierBusiness = debounce(_fetchSupplierBusiness, 300);

const _fetchServiceProviderBusiness = (query, callback) => {
  getAllServiceProviderBusinessApi(query).then((resp) =>
    callback(resp?.data?.data)
  );
};

export const fetchServiceProviderBusiness = debounce(
  _fetchServiceProviderBusiness,
  300
);
export const getChatRoomApi = async () => {
  const data = await authRequest(`/chat`);
  return data;
};

export const getChatMessagesApi = async ({ chat_room_id }) => {
  const data = await authRequest(`/chat/${chat_room_id}`);
  return data;
};

export const addChatMessageApi = async (payload) => {
  console.log("p", payload);
  const formData = jsonToFormData(payload);
  let url = "chat/addNewMessage";
  const data = await authRequest(`/${url}`, {
    data: formData,
    method: "POST",
    headers: {
      "content-type": "multipart/form-data",
      Accept: "application/json",
    },
  });
  return data;
};

export const addChatRoomApi = async (payload) => {
  console.log("p", payload);
  const formData = jsonToFormData(payload);
  let url = "chat";
  const data = await authRequest(`/${url}`, {
    data: formData,
    method: "POST",
  });
  return data;
};

export const addUserToChatRoomApi = async (payload) => {
  const data = await authRequest(`/chat/addUserToChatRoom`, {
    data: payload,
    method: "POST",
  });
  return data;
};

export const removeUserFromChatRoomApi = async (payload) => {
  const data = await authRequest(`/chat/removeUserFromChatRoom`, {
    data: payload,
    method: "POST",
  });
  return data;
};

export const getUserListForChatApi = async (payload) => {
  const data = await authRequest(`/chat/getUserListForChat`, {
    data: payload,
    method: "POST",
  });
  return data;
};

export const getMakeAndModel = async (search) => {
  const { data } = await authRequest(`/make-model/${search}`);
  return data;
};

export const getCarYearList = async () => {
  const data = await authRequest(`/car-year`);
  return data;
};

export const addRfqRequestApi = async (payload) => {
  const formData = jsonToFormData(payload);
  let url = "rfq/add";
  const data = await authRequest(`/${url}`, {
    data: formData,
    method: "POST",
    headers: {
      "content-type": "multipart/form-data",
      Accept: "application/json",
    },
  });
  return data;
};

export const getRfqRequestNos = async (id) => {
  const data = await authRequest(`/rfqRequestNos?rfqId=${id}`);
  return data;
};

export const getRfqResponseApi = async (payload) => {
  const {
    page = 0,
    sortField = "created_at",
    sortOrder = "desc",
    sizePerPage = 10,
    search,
    status,
    ...restPayload
  } = payload;
  let qs = `search=${search}&page=${page}&sortField=${sortField}&sortOrder=${sortOrder}&sizePerPage=${sizePerPage}`;
  if (status) qs += `&status=${status}`;
  const data = await authRequest(`/rfq-response?${qs}`);
  return data;
};

export const deleteRfqResponseApi = async (payload) => {
  const data = await authRequest(`/rfq-response/${payload?.id}`, {
    method: "DELETE",
  });
  return data;
};

export const getRfqResponseDetailApi = async ({ id }) => {
  const data = await authRequest(`/rfq-response/${id}?r=a`);
  return data;
};

export const getRfqResponseQuotationDetailApi = async ({ id }) => {
  const data = await authRequest(`/rfq-response/quotation/${id}?r=a`);
  return data;
};

export const saveRfqResponseApi = async (payload) => {
  const formData = jsonToFormData(payload);
  let url = "rfq-response";
  if (payload?.id) url += `/${payload?.id}?_method=PUT`;

  const { data } = await authRequest(`/${url}`, {
    method: "POST",
    data: formData,
    headers: {
      "content-type": "multipart/form-data",
      Accept: "application/json",
    },
  });
  return data;
};

export const getAllCartApi = async ({ id }) => {
  const data = await authRequest(`/cart?userId=${id}`);
  return data;
};

export const getCartApi = async ({ id }) => {
  const data = await authRequest(`/cart/${id}`);
  return data;
};

export const saveCartApi = async (payload) => {
  const data = await authRequest(`/cart`, {
    method: "POST",
    data: payload,
  });
  return data;
};

export const deleteCartApi = async (payload) => {
  const data = await authRequest(`/cart/${payload?.id}`, {
    method: "DELETE",
  });
  return data;
};

export const getUserAddressApi = async (id) => {
  const data = await authRequest(`/user-address?userId=${id}`);
  return data;
};

export const saveUserAddressApi = async (payload) => {
  let url = "user-address";
  if (payload?.id) url += `/${payload?.id}?_method=PUT`;

  const data = await authRequest(`/${url}`, { data: payload, method: "POST" });
  return data;
};

export const getPointHistoryApi = async (payload) => {
  const {
    page = 0,
    sortField = "created_at",
    sortOrder = "desc",
    sizePerPage = 10,
    search,
    status,
    ...restPayload
  } = payload;
  let qs = `page=${page}&sortField=${sortField}&sortOrder=${sortOrder}&sizePerPage=${sizePerPage}`;
  if (search) qs += `&search=${search}`;
  const data = await authRequest(`/user-points/search?${qs}`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      accept: "application/json",
    },
  });
  return data;
};

export const getTotalPointsApi = async () => {
  const data = await authRequest(`/user-points/getTotal`);
  return data;
};

export const addPointHistoryApi = async (payload) => {
  const { data } = await authRequest(`/user-points`, {
    headers: {
      "content-type": "application/json",
      Accept: "application/json",
    },
    method: "POST",
    data: payload,
  });
  return data;
};

export const updatePointHistoryApi = async (payload) => {
  const { data } = await authRequest(`/user-points/${payload.id}?_method=PUT`, {
    headers: {
      "content-type": "application/json",
      Accept: "application/json",
    },
    method: "POST",
    data: payload,
  });
  return data;
};

export const getOrdersByOrderListIdApi = async (orderListId) => {
  const data = await authRequest(
    `/orders/getOrdersByOrderListId/${orderListId}`
  );
  return data;
};

export const getUsersByRoleApi = async (role) => {
  const data = await authRequest(`/users/getUsersByRole?role=${role}`);
  return data;
};

export const assignDeliveryManApi = async (payload) => {
  const { data } = await authRequest(`/orders/assignDeliveryMan`, {
    headers: {
      "content-type": "application/json",
      Accept: "application/json",
    },
    method: "POST",
    data: payload,
  });
  return data;
};

export const saveRolesApi = async (payload) => {
  let uri = `/roles`;
  console.log("payload", payload);
  if (payload?.id) uri += `/${payload?.id}?_method=PUT`;
  const { data } = await axios.post(`${API_URL}${uri}`, payload, {
    headers: {
      "content-type": "application/json",
      Accept: "application/json",
    },
  });
  return data;
};

export const deleteRolesApi = async ({ id }) => {
  let uri = `/roles/${id}`;
  const { data } = await authRequest(uri, {
    headers: {
      "content-type": "application/json",
      Accept: "application/json",
    },
    method: "DELETE",
    data: id,
  });
  return data;
};

export const downloadAllInvoiceApi = async ({
  page = 1,
  perPage = 20,
  startDate,
  endDate,
  dateRangeType,
}) => {
  const data = await authRequest(
    `/orders/downloadInvoice?page=${page}&perPage=${perPage}&startDate=${startDate}&endDate=${endDate}&dateRangeType=${dateRangeType}`
  );
  return data;
};

export const downloadOrderListApi = async () => {
  const data = await axios.get(`${API_URL}/exportOrderList`, {
    responseType: "blob",
  });
  return data;
};
