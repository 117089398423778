import { joiResolver } from "@hookform/resolvers/joi";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  Col,
  CustomInput,
  FormGroup,
  Label,
  Row,
} from "reactstrap";
import FileUploader from "../../components/FileUploader";
import FieldErrorText from "../../components/FormBuilder/FieldErrorText";
import { formField } from "../../components/FormBuilder/formField";
import GoogleMapInput from "../../components/GoogleMapInput";
import PreLoaderWidget from "../../components/Loader";
import PageTitle from "../../components/PageTitle";
import {
  getServiceProviderBusiness,
  saveServiceProviderBusiness,
} from "../../redux/serviceProviderBusiness/actions";
import { getProvinceOption } from "../../services/api";
import { formatArrayField } from "../../utils/helpers";
import FileUpload from "../../components/FileUpload/FileUpload";
import { fields, schema } from "./formFields";
import { toBase64 } from "../../helpers";
import { appNotification } from "../../redux/app/actions";
import sanitizeHtml from "sanitize-html";

const workingDaysOptions = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

const AddEditGarages = () => {
  const baseUrl = `/service-provider/business`;
  const isEdit = false;
  const { error, data, serviceProviderBusiness, loading } = useSelector(
    (state) => state.ServiceProviderBusiness
  );
  const params = useParams();

  const [defaultData, setDefaultData] = useState({
    userId: null,
    businessName: "",
    businessEmail: "",
    businessContactPerson: "",
    businessContactNumber: "",
    businessCountryId: "",
    businessProvinceId: "",
    businessCity: "",
    businessAddress1: "",
    businessAddress2: "",
    specialization: "",
    vehicleMake: null,
    description: "",
    licenceNumber: "",
    businessWebsite: "",
    logo: null,
    latitude: "",
    longitude: "",
    status: false,
    licenceDocument: null,
    // licenceNumberDocName: null,
    photos: null,
    workingDays: null,
    workingStartHours: null,
    workingEndHours: null,
    gMapLink: null,
    gMapPlusCode: null,
  });
  const {
    register,
    handleSubmit,
    control,
    setFocus,
    setError,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    reValidateMode: "onChange",
    mode: "onChange",
    defaultValues: defaultData,
    resolver: joiResolver(schema(fields)),
  });
  const history = useHistory();
  const handleCancel = useCallback((e) => history.goBack(), []);
  const dispatch = useDispatch();

  const onSubmit = (data) => {
    const specialization = formatArrayField(
      data?.specialization,
      "specialization",
      "id"
    );
    const vehicleMake = formatArrayField(
      data?.vehicleMake,
      "vehicleMake",
      "id_car_make"
    );
    let workingDays = [];
    let isWorkingDaysValid = true;
    console.log("data", data);
    if (data?.workingDays) {
      // workingDaysAndHours.workingDays = formatArrayField(
      //   data?.workingDays,
      //   "workingDays",
      //   null,
      //   (value, i) => (value ? workingDaysOptions[i] : null)
      // );
      // workingDaysAndHours.workingStartHours = formatArrayField(
      //   data?.workingStartHours,
      //   "workingStartHours",
      //   null,
      //   (value) =>
      //     typeof value === "object"
      //       ? moment(new Date(value)).format("HH:mm:ss")
      //       : value
      // );
      // workingDaysAndHours.workingEndHours = formatArrayField(
      //   data?.workingEndHours,
      //   "workingEndHours",
      //   null,
      //   (value) =>
      //     typeof value === "object"
      //       ? moment(new Date(value)).format("HH:mm:ss")
      //       : value
      // );
      workingDays = data?.workingDays.map((v, i) => {
        if (v) {
          let startTime, endTime;
          if (data?.workingStartHours[i])
            startTime =
              typeof data?.workingStartHours[i] === "object"
                ? moment(new Date(data?.workingStartHours[i])).format(
                    "HH:mm:ss"
                  )
                : data?.workingStartHours[i];
          if (data?.workingEndHours[i])
            endTime =
              typeof data?.workingEndHours[i] === "object"
                ? moment(new Date(data?.workingEndHours[i])).format("HH:mm:ss")
                : data?.workingEndHours[i];
          if (!startTime) {
            setError(`workingStartHours[${i}]`, {
              message: "Start time is required",
            });
            isWorkingDaysValid = false;
          }
          if (!endTime) {
            setError(`workingEndHours[${i}]`, {
              message: "End time is required",
            });

            isWorkingDaysValid = false;
          }
          return {
            day: typeof v === "boolean" && v ? workingDaysOptions[i] : v,
            startTime,
            endTime,
          };
        }
      });
    }

    const photos = formatArrayField(data?.photos, "photos");
    delete data.specialization;
    delete data.vehicleMake;
    delete data.photos;
    delete data.logo;
    delete data.licenceDocument;
    const formData = {
      ...data,
      userId: data?.userId?.id,
      businessProvinceId: data?.businessProvinceId?.id,
      businessCountryId: data?.businessCountryId?.id,
      description: encodeURIComponent(sanitizeHtml(data?.description)),
      ...specialization,
      ...vehicleMake,
      ...photos,
      workingDays: JSON.stringify(workingDays.filter((v) => v)),
    };
    if (fieldWithExtraAttribute?.logo?.binary?.[0])
      formData["logo"] = fieldWithExtraAttribute?.logo?.binary?.[0];
    if (fieldWithExtraAttribute?.licenceDocument?.binary?.[0])
      formData["licenceDocument"] =
        fieldWithExtraAttribute?.licenceDocument?.binary?.[0];
    console.log("data", formData);
    if (isWorkingDaysValid)
      dispatch(saveServiceProviderBusiness({ data: formData, history }));
    else
      dispatch(
        appNotification({
          type: "danger",
          message: "Start or end time is required",
        })
      );
  };

  const onCountryChange = (handler) => (country) => {
    setProvinceOption(country?.id);
    handler(country);
  };

  const [fieldWithExtraAttribute, setfieldWithExtraAttribute] = useState({
    ...fields,
    businessCountryId: {
      ...fields.businessCountryId,
      onChange: onCountryChange,
      value: {},
    },
  });
  const setProvinceOption = async (countryId) => {
    const provinceOption = await getProvinceOption({
      countryId,
    });
    setfieldWithExtraAttribute((state) => ({
      ...state,
      businessProvinceId: {
        ...state.businessProvinceId,
        options: provinceOption,
      },
    }));
  };
  const handleImageUpload = (handler) => async (file) => {
    if (file?.target?.name && file?.target?.files[0]) {
      let preview = [];
      [...file.target.files].forEach(async (v) =>
        preview.push(await toBase64(v))
      );
      setfieldWithExtraAttribute((state) => ({
        ...state,
        [file.target.name]: {
          ...state[file.target.name],
          binary: [...file.target.files],
          preview,
        },
      }));
    }
    handler(file);
  };
  useEffect(() => {
    if (error) {
      Object.keys(error).map((field) =>
        setError(field, { message: error?.[field]?.[0] })
      );
    }
  }, [error]);
  useEffect(() => {
    //edit actions
    if (params?.id) {
      dispatch(getServiceProviderBusiness(params?.id));
    }
  }, []);
  useEffect(() => {
    if (serviceProviderBusiness && !loading) {
      const newServiceProviderBusiness = {
        ...serviceProviderBusiness,
        businessCountryId: serviceProviderBusiness?.country,
        businessProvinceId: serviceProviderBusiness?.province,
        userId: serviceProviderBusiness?.user,
        "specialization[]": serviceProviderBusiness?.services,
        "vehicleMake[]": serviceProviderBusiness?.vehicle_makes,
        description: decodeURIComponent(serviceProviderBusiness?.description),
      };

      delete newServiceProviderBusiness?.country;
      delete newServiceProviderBusiness?.province;
      delete newServiceProviderBusiness?.user;
      delete newServiceProviderBusiness?.services;
      delete newServiceProviderBusiness?.vehicle_makes;
      delete newServiceProviderBusiness?.created_at;
      delete newServiceProviderBusiness?.updated_at;
      delete newServiceProviderBusiness?.deleted_at;
      delete newServiceProviderBusiness?.registerNumber;

      if (newServiceProviderBusiness?.working_days) {
        newServiceProviderBusiness?.working_days.forEach((v) => {
          const i = workingDaysOptions.indexOf(v?.working_day);
          setValue(`workingDays[${i}]`, v?.working_day);
          setValue(`workingStartHours[${i}]`, v?.working_start_time);
          setValue(`workingEndHours[${i}]`, v?.working_end_time);
        });
      }
      delete newServiceProviderBusiness?.working_days;
      Object.keys(newServiceProviderBusiness).map((key) => {
        setDefaultData((state) => {
          if (key === "licence_document") {
            return {
              ...state,
              licenceDocument: newServiceProviderBusiness["licence_document"],
            };
          }
          if (key === "photos") {
            return {
              ...state,
              "photos[]": newServiceProviderBusiness?.photos,
            };
          }
          return {
            ...state,
            [key]: newServiceProviderBusiness?.[key],
          };
        });

        if (key === "status") setValue(key, newServiceProviderBusiness?.[key]);
        if (key !== "licence_document" && key !== "logo" && key !== "photos")
          setValue(key, newServiceProviderBusiness?.[key]);
      });
    }
  }, [serviceProviderBusiness]);
  useEffect(() => {
    if (errors) {
      Object.keys(errors)?.forEach((k) =>
        appNotification({ type: "danger", message: errors[k]?.message })
      );
    }
  }, [errors]);

  return (
    <>
      <Row className="page-title">
        <Col md={12}>
          <PageTitle
            breadCrumbItems={[
              { label: "Service provider business", path: baseUrl },
              {
                label: "add",
                path: `${baseUrl}/add`,
                active: true,
              },
            ]}
            title={`${isEdit ? "Edit" : "Add new"} Service provider business`}
          />
        </Col>
      </Row>
      <Card>
        <CardBody>
          {loading && <PreLoaderWidget />}
          <form onSubmit={handleSubmit(onSubmit)}>
            <Row>
              {Object.keys(fieldWithExtraAttribute).map((key) => {
                const fieldVal = fieldWithExtraAttribute[key];
                const { columnProps, validationRules, ...fieldProps } =
                  fieldVal;
                if (fieldVal?.type === "section") {
                  return (
                    <React.Fragment key={`${fieldVal?.id}-${key}`}>
                      <Col xs={12}>
                        <h5
                          style={{
                            borderBottom: "1px solid",
                            paddingBottom: 10,
                          }}
                        >
                          {fieldVal?.title}
                        </h5>
                      </Col>
                    </React.Fragment>
                  );
                }
                if (fieldVal?.id === "workingDays")
                  return (
                    <Col xs={12} key={`${fieldVal?.id}-${key}`}>
                      <div>
                        {workingDaysOptions.map((v, i) => (
                          <div
                            className="d-flex"
                            key={`workingDays-${i}-${key}`}
                          >
                            <Col xs={6} md={4} lg={4}>
                              <FormGroup>
                                {i === 0 && <Label>{fieldVal?.label}</Label>}
                                <Controller
                                  control={control}
                                  name={`workingDays[${i}]`}
                                  render={({
                                    field: { ref, onChange, value, ...rest },
                                    fieldState: { error },
                                  }) => {
                                    return (
                                      <CustomInput
                                        {...rest}
                                        id={`${fieldVal.id}-${i}`}
                                        type="checkbox"
                                        checked={value || false}
                                        innerRef={ref}
                                        label={v}
                                        onChange={onChange}
                                        value={v || ""}
                                      />
                                    );
                                  }}
                                />
                              </FormGroup>
                            </Col>
                            <Col xs={4} md={3} lg={2}>
                              <FormGroup>
                                {i === 0 && <Label>Start time</Label>}
                                <Controller
                                  name={`workingStartHours[${i}]`}
                                  control={control}
                                  render={({ field, fieldState: { error } }) =>
                                    formField({
                                      field: {
                                        type: "timePicker",
                                        id: `${fieldVal.id}-${i}`,
                                        ...field,
                                      },
                                      error,
                                    })
                                  }
                                />
                              </FormGroup>
                            </Col>
                            <Col xs={6} md={3} lg={2}>
                              <FormGroup>
                                {i === 0 && <Label>End time</Label>}
                                <Controller
                                  name={`workingEndHours[${i}]`}
                                  control={control}
                                  render={({ field, fieldState: { error } }) =>
                                    formField({
                                      field: {
                                        type: "timePicker",
                                        id: `${fieldVal.id}-${i}`,
                                        ...field,
                                      },
                                      error,
                                    })
                                  }
                                />
                              </FormGroup>
                            </Col>
                          </div>
                        ))}
                      </div>
                    </Col>
                  );
                if (fieldVal?.type === "file" || fieldVal?.type === "image") {
                  const { ref, onChange } = register(fieldVal?.name);
                  return (
                    <Col {...columnProps} key={`${fieldVal?.id}-${key}`}>
                      <FormGroup>
                        <Label>{fieldProps?.label}</Label>
                        <FileUpload
                          fieldProps={fieldProps}
                          onChange={handleImageUpload(onChange)}
                          ref={ref}
                          type={fieldVal?.type}
                          preview={defaultData?.[fieldVal?.name] || null}
                        />
                        {errors?.[fieldProps?.name] && (
                          <FieldErrorText error={errors?.[fieldProps?.name]} />
                        )}
                      </FormGroup>
                    </Col>
                  );
                }
                if (fieldVal?.type === "gmaps") {
                  const { ref, onChange } = register(fieldVal?.name);
                  return (
                    <Col {...columnProps} key={`${fieldVal?.id}-${key}`}>
                      <FormGroup>
                        <Label>{fieldProps?.label}</Label>
                        <GoogleMapInput
                          inputProps={{
                            ...fieldProps,
                            id: fieldProps.id,
                            type: "text",
                            name: fieldProps.name,
                            onChange,
                          }}
                          ref={ref}
                          onPlaceSelected={(s) => console.log("s", s)}
                        />
                      </FormGroup>
                    </Col>
                  );
                }
                return (
                  <Col {...columnProps} key={`${fieldVal?.id}-${key}`}>
                    <FormGroup>
                      <Controller
                        control={control}
                        name={fieldProps?.name}
                        render={({
                          field: { onChange, onBlur, value, ref, ...rest },
                          fieldState: { error },
                        }) => {
                          let formFieldState = {
                            field: {
                              ...fieldProps,
                              ...rest,
                              innerRef: ref,
                              onChange: fieldProps?.onChange
                                ? fieldProps?.onChange(onChange)
                                : onChange,
                              onBlur: fieldProps?.onBlur
                                ? (e) => onBlur(fieldProps?.onBlur)
                                : onBlur,
                              value: value || "",
                            },
                            error,
                          };
                          if (fieldProps.type === "switch")
                            formFieldState = {
                              ...formFieldState,
                              field: {
                                ...formFieldState.field,
                                checked: value,
                              },
                            };
                          if (fieldProps.type === "richText")
                            formFieldState = {
                              ...formFieldState,
                              field: {
                                ...formFieldState.field,
                                initialContent: defaultData?.description,
                                onEditorContentChange: onChange,
                              },
                            };
                          return formField(formFieldState);
                        }}
                      ></Controller>
                    </FormGroup>
                  </Col>
                );
              })}
            </Row>
            <Row>
              <Col className="d-flex justify-content-end">
                <FormGroup>
                  <Button
                    type="button"
                    className="btn btn-light mr-1"
                    onClick={handleCancel}
                  >
                    Cancel
                  </Button>
                  <Button color="primary">Save</Button>
                </FormGroup>
              </Col>
            </Row>
          </form>

          {/* <FormBuilder
            schema={schema}
            fields={fields}
            onSubmit={handleSubmit}
          /> */}
        </CardBody>
      </Card>
    </>
  );
};

export default AddEditGarages;
