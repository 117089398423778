import { APP_NOTIFICATION, FETCH_COUNTRY_CODE, FETCH_COUNTRY_CODE_FAILURE, FETCH_COUNTRY_CODE_SUCCESS } from "./actions";
const initState = {
  type: "info",
  message: "",
  countryCode: {
    data: null,
    error: null,
    loading: false,
  },
};
const App = (state = initState, action) => {
  switch (action.type) {
    case APP_NOTIFICATION:
      return { ...state, ...action.payload };
    case FETCH_COUNTRY_CODE:
      return {
        ...state,
        country: {
          ...state.country,
          loading: true,
        },
      };
    case FETCH_COUNTRY_CODE_SUCCESS:
      return {
        ...state,
        countryCode: {
          ...state.countryCode,
          loading: false,
          data: action.payload,
          error: null,
        },
      };
    case FETCH_COUNTRY_CODE_FAILURE:
      return {
        ...state,
        countryCode: {
          ...state.countryCode,
          data: null,
          error: action.payload,
          loading: false,
        },
      };
    default:
      return state;
  }
};

export default App;
