import {
  ADD_POINT_HISTORY,
  ADD_POINT_HISTORY_FAILED,
  ADD_POINT_HISTORY_SUCCESS,
  GET_POINT_HISTORY,
  GET_POINT_HISTORY_FAILED,
  GET_POINT_HISTORY_SUCCESS,
  UPDATE_POINT_HISTORY,
  UPDATE_POINT_HISTORY_FAILED,
  UPDATE_POINT_HISTORY_SUCCESS,
} from "./constants";

export const getPointHistory = (payload) => ({
  type: GET_POINT_HISTORY,
  payload,
});

export const getPointHistorySuccess = (payload) => ({
  type: GET_POINT_HISTORY_SUCCESS,
  payload,
});

export const getPointHistoryFailed = (payload) => ({
  type: GET_POINT_HISTORY_FAILED,
  payload,
});

export const addPointHistory = (payload) => ({
  type: ADD_POINT_HISTORY,
  payload,
});

export const addPointHistorySuccess = (payload) => ({
  type: ADD_POINT_HISTORY_SUCCESS,
  payload,
});

export const addPointHistoryFailed = (payload) => ({
  type: ADD_POINT_HISTORY_FAILED,
  payload,
});

export const updatePointHistory = (payload) => ({
  type: UPDATE_POINT_HISTORY,
  payload,
});

export const updatePointHistorySuccess = (payload) => ({
  type: UPDATE_POINT_HISTORY_SUCCESS,
  payload,
});

export const updatePointHistoryFailed = (payload) => ({
  type: UPDATE_POINT_HISTORY_FAILED,
  payload,
});
