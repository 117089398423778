import { forwardRef } from "react";

export const EditableCell = forwardRef(
  (
    { onSave, onUpdate, type = "text", value, customComponent, ...props },
    ref
  ) => {
    const getValue = (val) => {
      return val;
    };
    const handleBlur = (handler) => (e) => {
      handler(e);
      if (onSave) onSave(e.target.value);
      if (onUpdate) onUpdate(getValue(e.target.value));
    };
    if (customComponent) {
      const Component = customComponent;
      return (
        <Component {...props} ref={ref} onBlur={handleBlur(props.onBlur)} />
      );
    }
    return (
      <input
        {...props}
        ref={ref}
        type={type}
        className="form-control"
        onBlur={handleBlur(props.onBlur)}
        // onChange={handleBlur}
      />
    );
  }
);
