// @flow
import {
  DELETE_USER,
  DELETE_USER_FAILED,
  DELETE_USER_SUCCESS,
  USER,
  USER_FAILED,
  USER_SUCCESS,
  GET_USER,
  GET_USER_FAILED,
  GET_USER_SUCCESS,
  UPDATE_USER_STATUS,
  UPDATE_USER_STATUS_SUCCESS,
  UPDATE_USER_STATUS_FAILED,
  UPDATE_USER_WHATSAPP_GROUP,
  UPDATE_USER_WHATSAPP_GROUP_SUCCESS,
  UPDATE_USER_WHATSAPP_GROUP_FAILED,
} from "./constants";

const INIT_STATE = {
  data: [],
  user: {},
  loading: false,
  error: null,
};

const Users = (state = INIT_STATE, action) => {
  switch (action.type) {
    case USER:
      return { ...state, user: {}, loading: true };
    case USER_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
        error: null,
      };
    case USER_FAILED:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case GET_USER:
      return { ...state, loading: true };
    case GET_USER_SUCCESS:
      return {
        ...state,
        user: action.payload,
        loading: false,
        error: null,
      };
    case GET_USER_FAILED:
      return { ...state, error: action.payload, loading: false };
    case DELETE_USER:
      return { ...state, loading: true };
    case DELETE_USER_SUCCESS: {
      return {
        ...state,
        data: {
          ...state?.data,
          total: state?.data?.total - 1,
          data: state?.data?.data?.filter((v) => v?.id !== action?.payload?.id),
        },
        loading: false,
        error: null,
      };
    }
    case DELETE_USER_FAILED:
      return { ...state, error: action.payload, loading: false };

    case UPDATE_USER_STATUS:
      return { ...state, loading: true };
    case UPDATE_USER_STATUS_SUCCESS: {
      return {
        ...state,
        data: {
          ...state?.data,
          data: state?.data?.data?.map((v) =>
            v?.id === action?.payload?.id
              ? {
                  ...v,
                  status: action.payload.status,
                }
              : { ...v }
          ),
        },
        loading: false,
        error: null,
      };
    }
    case UPDATE_USER_STATUS_FAILED:
      return { ...state, error: action.payload, loading: false };

    case UPDATE_USER_WHATSAPP_GROUP:
      return { ...state, loading: true };
    case UPDATE_USER_WHATSAPP_GROUP_SUCCESS: {
      return {
        ...state,
        data: {
          ...state?.data,
          data: state?.data?.data?.map((v) =>
            v?.id === action?.payload?.id
              ? {
                  ...v,
                  ...action.payload,
                }
              : { ...v }
          ),
        },
        loading: false,
        error: null,
      };
    }
    case UPDATE_USER_WHATSAPP_GROUP_FAILED:
      return { ...state, error: action.payload, loading: false };

    default:
      return { ...state };
  }
};

export default Users;
