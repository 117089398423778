import React from "react";
import { Link } from "react-router-dom";
import { Button, Modal, ModalBody, ModalFooter } from "reactstrap";

const Actions = (cell, row, rowIndex, formatExtraData) => {
  const {
    open,
    toggleModal,
    handleDelete,
    modalOpen,
    baseUrl,
    canEdit = true,
    canDelete = true,
    canView = true,
  } = formatExtraData;
  return (
    <>
      {formatExtraData?.renderAction && formatExtraData?.renderAction(row)}
      {canView && (
        <Link
          className="btn btn-sm btn-info mr-1"
          to={`${baseUrl}/view/${row?.id}`}
        >
          <i className="uil uil-eye"></i>
        </Link>
      )}
      {canEdit && (
        <Link
          className="btn btn-sm btn-warning mr-1"
          to={`${baseUrl}/edit/${row?.id}`}
        >
          <i className="uil uil-edit"></i>
        </Link>
      )}
      {canDelete && (
        <Button
          color="danger"
          className="btn btn-sm btn-danger mr-1"
          onClick={modalOpen(row?.id)}
        >
          <i className="uil uil-trash"></i>
        </Button>
      )}
      <Modal
        id={`delete-service-category-${row?.id}`}
        centered
        isOpen={open}
        toggle={toggleModal}
        size="sm"
        backdrop={false}
        contentClassName="modal-content-no-backdrop"
      >
        <ModalBody>
          <p>Are you sure to delete this?</p>
        </ModalBody>
        <ModalFooter>
          <Button color="light" onClick={toggleModal}>
            Cancel
          </Button>
          <Button color="danger" type="button" onClick={handleDelete}>
            Delete
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default Actions;
