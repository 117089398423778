import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Alert, Col, Row } from "reactstrap";
import PageTitle from "../../../components/PageTitle";
import Status from "../../../components/Status";
import Actions from "../../../components/table/Actions";
import Table from "../../../components/table/Table";
import useTableActions from "../../../components/table/useActions";
import {
  deleteSparePartsCategory,
  getSparePartsCategories,
} from "../../../redux/sparePartsCategory/actions";

const ListSparePartsCategory = () => {
  const baseUrl = "/spare-parts-category";
  const { open, toggleModal, modalOpen, handleDelete } =
    useTableActions(deleteSparePartsCategory);
  const columns = [
    {
      dataField: "id",
      text: "ID",
      sort: true,
    },
    {
      dataField: "categoryName",
      text: "Category Name",
      sort: true,
    },
    {
        dataField: "parent.categoryName",
        text: "Parent Category Name",
        sort: true,
      },
    {
      dataField: "status",
      text: "Status",
      sort: true,
      formatter: (cel, row) => <Status status={row?.status} />,
    },

    {
      dataField: "actions",
      text: "Actions",
      sort: false,
      isDummyField: true,
      exportCSV: false,
      formatter: Actions,
      formatExtraData: {
        open,
        toggleModal,
        handleDelete,
        canView: false,
        baseUrl,
        modalOpen,
      },
    },
  ];
  const dispatch = useDispatch();
  const [pagination, setPagination] = useState({
    page: 1,
    searchText: "",
    sizePerPage: 10,
    sortField: "id",
    sortOrder: "asc",
  });
  const { data, error, loading } = useSelector(
    (state) => state.SparePartsCategory
  );
  useEffect(() => {
    dispatch(getSparePartsCategories(pagination));
  }, []);
  const handleTableChange = (e, v) => {
    console.log(e, v);
    const { page, searchText, sizePerPage, sortField, sortOrder } = v;
    setPagination({ page, searchText, sizePerPage, sortField, sortOrder });
    dispatch(
      getSparePartsCategories({
        page,
        searchText,
        sizePerPage,
        sortField,
        sortOrder,
      })
    );
  };
  return (
    <>
      <Row className="page-title">
        <Col md={12}>
          <PageTitle
            breadCrumbItems={[
              { label: "Spare Parts categories", path: "/spare-parts-categories" },
            ]}
            title={"List of spare parts category"}
          />
        </Col>
      </Row>
      {error && <Alert color="danger">Oops something went wrong</Alert>}
      <Table
        loading={loading}
        records={data?.data}
        columns={columns}
        handleTableChange={handleTableChange}
        addUrl={`${baseUrl}/add`}
        pagination={{ ...pagination, totalSize: data?.total }}
      />
    </>
  );
};

export default ListSparePartsCategory;
