import {
  all,
  call,
  fork,
  put,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";

import { authRequest } from "../../helpers/api";
import { appNotification } from "../app/actions";
import {
  deleteSeviceCategoryFailed,
  deleteSeviceCategorySuccess,
  getSeviceCategoriesFailed,
  getSeviceCategoriesSuccess,
  getSeviceCategoryFailed,
  getSeviceCategorySuccess,
} from "./actions";
import {
  DELETE_SERVICE_CATEGORY,
  GET_SERVICE_CATEGORY,
  SAVE_SERVICE_CATEGORY,
  SERVICE_CATEGORY,
} from "./constants";

function* getSeviceCategories({ payload }) {
  try {
    let url = `/service-categories?`;
    if (payload)
      Object.keys(payload).map(
        (params) =>
          payload?.[params] && (url += `${params}=${payload[params]}&`)
      );
    const response = yield call(authRequest, url.slice(0, url.length - 1), {
      method: "get",
    });
    console.log("response", response);
    yield put(getSeviceCategoriesSuccess(response?.data));
  } catch (error) {
    let message = "Failed to get service category";
    yield put(getSeviceCategoriesFailed(message));
  }
}

function* saveSeviceCategory({ payload: { data, history } }) {
  try {
    console.log("data", data);
    const formData = new FormData();
    Object.keys(data).map((key) => formData.append(key, data[key]));
    let url = `/service-categories`;
    if (data?.id) url += `/${data?.id}?_method=PUT`;

    const response = yield call(authRequest, url, {
      method: "post",
      data: formData,
      headers: {
        "content-type": "multipart/form-data",
      },
    });
    yield put(getSeviceCategoriesSuccess(response?.data));
    yield put(appNotification({ type: "success", message: response?.message }));
    history.goBack();
  } catch (error) {
    let message = "Failed to get service category";
    yield put(getSeviceCategoriesFailed(message));
  }
}
function* getSeviceCategory({ payload: { id } }) {
  try {
    const response = yield call(authRequest, `/service-categories/${id}`, {
      method: "get",
    });
    yield put(getSeviceCategorySuccess(response?.data));
  } catch (error) {
    let message = "Failed to get service category";
    yield put(getSeviceCategoryFailed(message));
  }
}
function* deleteSeviceCategory({ payload: { id } }) {
  try {
    const response = yield call(authRequest, `/service-categories/${id}`, {
      method: "delete",
    });
    yield put(appNotification({ type: "success", message: response?.message }));
    yield put(deleteSeviceCategorySuccess({ id }));
  } catch (error) {
      console.log('error',error)
    let message = "Failed to get service category";
    yield put(deleteSeviceCategoryFailed(message));
  }
}
export function* watchGetServiceCategories() {
  yield takeLatest(SERVICE_CATEGORY, getSeviceCategories);
}
export function* watchGetServiceCategory() {
  yield takeEvery(GET_SERVICE_CATEGORY, getSeviceCategory);
}
export function* watchSaveServiceCategory() {
  yield takeEvery(SAVE_SERVICE_CATEGORY, saveSeviceCategory);
}
export function* watchDeleteServiceCategory() {
  yield takeEvery(DELETE_SERVICE_CATEGORY, deleteSeviceCategory);
}
function* serviceCategorySaga() {
  yield all([
    fork(watchGetServiceCategories),
    fork(watchSaveServiceCategory),
    fork(watchGetServiceCategory),
    fork(watchDeleteServiceCategory),
  ]);
}

export default serviceCategorySaga;
