import { API_URL, BASE_URL } from "../constants";
import axios from "../lib/axios";
import { getLoggedInUser } from "./authUtils";

/**
 * Fetch data from given url
 * @param {*} url
 * @param {*} options
 */
export const csrfToken = async () => await axios.get(`${BASE_URL}/sanctum/csrf-cookie`);

const fetchJSON = async (url, options = {}) => {
  return await axios
    .request(`${API_URL}${url}`, options)
    .then((response) => {
      if (!response.status === 200) {
        throw response;
      }
      return response?.data;
    })
    .catch((error) => {
      console.log("error", error.response);
      if (error.response.status != 422) throw error;

      return error.response.data;
    });

  //   await axios(url, options)
  //     .then((response) => {

  //         if (!response.status === 200) {
  //         throw response;
  //       }
  //       console.log(response?.data)
  //       return response?.data;
  //     })
  //     .catch((error) => {
  //         console.log(error)

  //         throw error;
  //     });
};
const authRequest = async (url, options = {}) => {
  const user = getLoggedInUser();
  const headers = {
    "content-type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${user?.token}`,
  };

  return await axios
    .request(`${API_URL}${url}`, {
      ...options,
      headers: {
        ...headers,
        ...options?.headers,
      },
    })
    .then((response) => {
      if (!response.status === 200) {
        throw response;
      }
      console.log(response?.data);
      return response?.data;
    })
    .catch((error) => {
      console.log("error", error.response);
      throw error?.response?.data;

      //   return error.response.data;
    });
};
const fetchApi = async (url, options) => {
  const user = getLoggedInUser();
  const headers = {
    "content-type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${user?.token}`,
  };

  return await axios
    .request(`${API_URL}${url}`, {
      ...options,
      headers: {
        ...headers,
        ...options?.headers,
      },
    })
    .then((r) => {
      return r?.data;
    })
    .catch((e) => {
      console.log("e", e);
      throw e;
    });
};
export { fetchJSON, authRequest, fetchApi };
