import {
    DELETE_CAR_SERIE,
    DELETE_CAR_SERIE_FAILED,
    DELETE_CAR_SERIE_SUCCESS,
    EDIT_CAR_SERIE,
    EDIT_CAR_SERIE_FAILED,
    EDIT_CAR_SERIE_SUCCESS,
    SAVE_CAR_SERIE,
    SAVE_CAR_SERIE_FAILED,
    SAVE_CAR_SERIE_SUCCESS,
    CAR_SERIE,
    CAR_SERIE_FAILED,
    CAR_SERIE_SUCCESS,
    GET_CAR_SERIE,
    GET_CAR_SERIE_SUCCESS,
    GET_CAR_SERIE_FAILED,
  } from "./constants";

  export const getCarSerie = (id) => ({
    type: GET_CAR_SERIE,
    payload: { id },
  });

  export const getCarSerieSuccess = (data) => ({
    type: GET_CAR_SERIE_SUCCESS,
    payload: data,
  });

  export const getCarSerieFailed = (errorMessage) => ({
    type: GET_CAR_SERIE_FAILED,
    payload: errorMessage,
  });

  export const getCarSeries = (payload) => ({
    type: CAR_SERIE,
    payload,
  });

  export const getCarSeriesSuccess = (data) => ({
    type: CAR_SERIE_SUCCESS,
    payload: { data },
  });

  export const getCarSeriesFailed = (errorMessage) => ({
    type: CAR_SERIE_FAILED,
    payload: errorMessage,
  });

  export const saveCarSerie = ({ data, history }) => ({
    type: SAVE_CAR_SERIE,
    payload: { data, history },
  });

  export const saveCarSerieSuccess = (success) => ({
    type: SAVE_CAR_SERIE_SUCCESS,
    payload: success,
  });

  export const saveCarSerieFailed = (errorMessage) => ({
    type: SAVE_CAR_SERIE_FAILED,
    payload: errorMessage,
  });

  export const editCarSerie = (data) => ({
    type: EDIT_CAR_SERIE,
    payload: data,
  });

  export const editCarSerieSuccess = (success) => ({
    type: EDIT_CAR_SERIE_SUCCESS,
    payload: success,
  });

  export const editCarSerieFailed = (errorMessage) => ({
    type: EDIT_CAR_SERIE_FAILED,
    payload: errorMessage,
  });

  export const deleteCarSerie = (data) => ({
    type: DELETE_CAR_SERIE,
    payload: data,
  });

  export const deleteCarSerieSuccess = (success) => ({
    type: DELETE_CAR_SERIE_SUCCESS,
    payload: success,
  });

  export const deleteCarSerieFailed = (errorMessage) => ({
    type: DELETE_CAR_SERIE_FAILED,
    payload: errorMessage,
  });
