import {
  DELETE_VEHICLE_TYPE,
  DELETE_VEHICLE_TYPE_FAILED,
  DELETE_VEHICLE_TYPE_SUCCESS,
  EDIT_VEHICLE_TYPE,
  EDIT_VEHICLE_TYPE_FAILED,
  EDIT_VEHICLE_TYPE_SUCCESS,
  SAVE_VEHICLE_TYPE,
  SAVE_VEHICLE_TYPE_FAILED,
  SAVE_VEHICLE_TYPE_SUCCESS,
  VEHICLE_TYPE,
  VEHICLE_TYPE_FAILED,
  VEHICLE_TYPE_SUCCESS,
  GET_VEHICLE_TYPE,
  GET_VEHICLE_TYPE_SUCCESS,
  GET_VEHICLE_TYPE_FAILED,
} from "./constants";

export const getVehicleType = (id) => ({
  type: GET_VEHICLE_TYPE,
  payload: { id },
});

export const getVehicleTypeSuccess = (data) => ({
  type: GET_VEHICLE_TYPE_SUCCESS,
  payload: data,
});

export const getVehicleTypeFailed = (errorMessage) => ({
  type: GET_VEHICLE_TYPE_FAILED,
  payload: errorMessage,
});

export const getVehicleTypes = (payload) => ({
  type: VEHICLE_TYPE,
  payload,
});

export const getVehicleTypesSuccess = (data) => ({
  type: VEHICLE_TYPE_SUCCESS,
  payload: { data },
});

export const getVehicleTypesFailed = (errorMessage) => ({
  type: VEHICLE_TYPE_FAILED,
  payload: errorMessage,
});

export const saveVehicleType = ({ data, history }) => ({
  type: SAVE_VEHICLE_TYPE,
  payload: { data, history },
});

export const saveVehicleTypeSuccess = (success) => ({
  type: SAVE_VEHICLE_TYPE_SUCCESS,
  payload: success,
});

export const saveVehicleTypeFailed = (errorMessage) => ({
  type: SAVE_VEHICLE_TYPE_FAILED,
  payload: errorMessage,
});

export const editVehicleType = (data) => ({
  type: EDIT_VEHICLE_TYPE,
  payload: data,
});

export const editVehicleTypeSuccess = (success) => ({
  type: EDIT_VEHICLE_TYPE_SUCCESS,
  payload: success,
});

export const editVehicleTypeFailed = (errorMessage) => ({
  type: EDIT_VEHICLE_TYPE_FAILED,
  payload: errorMessage,
});

export const deleteVehicleType = (data) => ({
  type: DELETE_VEHICLE_TYPE,
  payload: data,
});

export const deleteVehicleTypeSuccess = (success) => ({
  type: DELETE_VEHICLE_TYPE_SUCCESS,
  payload: success,
});

export const deleteVehicleTypeFailed = (errorMessage) => ({
  type: DELETE_VEHICLE_TYPE_FAILED,
  payload: errorMessage,
});
