import React from "react";
import ReactGoogleAutocomplete, {
  usePlacesWidget,
} from "react-google-autocomplete";
import { Input } from "reactstrap";

const GoogleMapInput = ({
  onPlaceSelected,
  options,
  inputComponent,
  inputProps,
  ref,
  ...props
}) => {
  const InputComponent = inputComponent || Input;
  const { ref: inputRef, autocompleteRef } = usePlacesWidget({
    apiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
    onPlaceSelected,
    options,
    ...props,
  });
  return <InputComponent innerRef={inputRef} ref={ref} {...inputProps} />;
};

export default GoogleMapInput;
