import {
  GET_CHAT_MESSAGES,
  GET_CHAT_MESSAGES_SUCCESS,
  GET_CHAT_MESSAGES_FAILED,
  GET_CHAT_ROOM,
  GET_CHAT_ROOM_SUCCESS,
  GET_CHAT_ROOM_FAILED,
  ADD_CHAT_MESSAGE,
  ADD_CHAT_MESSAGE_SUCCESS,
  ADD_CHAT_MESSAGE_FAILED,
  ADD_CHAT_ROOM,
  ADD_CHAT_ROOM_SUCCESS,
  ADD_CHAT_ROOM_FAILED,
} from "./constants";

const initialState = {
  chatRoom: { data: [], loading: false, error: null },
  chatMessages: { data: [], loading: false, error: null, msgLoading: false },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ADD_CHAT_ROOM:
      return {
        ...state,
        chatRoom: {
          ...state.chatRoom,
          loading: true,
        },
      };
    case ADD_CHAT_ROOM_SUCCESS:
      return {
        ...state,
        chatRoom: {
          ...state.chatRoom,
          loading: false,
          data: [action.payload, ...state?.chatRoom?.data],
          error: null,
        },
      };
    case ADD_CHAT_ROOM_FAILED:
      return {
        ...state,
        chatRoom: {
          ...state.chatRoom,
          error: action.payload,
          loading: false,
        },
      };

    case GET_CHAT_ROOM:
      return {
        ...state,
        chatRoom: {
          ...state.chatRoom,
          loading: true,
        },
      };
    case GET_CHAT_ROOM_SUCCESS:
      return {
        ...state,
        chatRoom: {
          ...state.chatRoom,
          loading: false,
          data: action.payload,
          error: null,
        },
      };
    case GET_CHAT_ROOM_FAILED:
      return {
        ...state,
        chatRoom: {
          ...state.chatRoom,
          error: action.payload,
          loading: false,
        },
      };

    case GET_CHAT_MESSAGES:
      return {
        ...state,
        chatMessages: {
          ...state.chatMessages,
          loading: true,
        },
      };
    case GET_CHAT_MESSAGES_SUCCESS:
      return {
        ...state,
        chatMessages: {
          ...state.chatMessages,
          loading: false,
          data: action.payload,
          error: null,
        },
      };
    case GET_CHAT_MESSAGES_FAILED:
      return {
        ...state,
        chatMessages: {
          ...state.chatMessages,
          error: action.payload,
          loading: false,
        },
      };

    case ADD_CHAT_MESSAGE:
      return {
        ...state,
        chatMessages: {
          ...state.chatMessages,
          msgLoading: true,
        },
      };
    case ADD_CHAT_MESSAGE_SUCCESS:
      return {
        ...state,
        chatMessages: {
          ...state.chatMessages,
          msgLoading: false,
          data: [...state.chatMessages.data, action.payload],
          error: null,
        },
      };
    case ADD_CHAT_MESSAGE_FAILED:
      return {
        ...state,
        chatMessages: {
          ...state.chatMessages,
          error: action.payload,
          loading: false,
        },
      };

    default:
      return state;
  }
};
