import {
    all,
    call,
    fork,
    put,
    takeEvery,
    takeLatest,
  } from "redux-saga/effects";

  import { authRequest } from "../../helpers/api";
  import { appNotification } from "../app/actions";
  import {
    deleteCarModelFailed,
    deleteCarModelSuccess,
    getCarModelsFailed,
    getCarModelsSuccess,
    getCarModelFailed,
    getCarModelSuccess,
  } from "./actions";
  import {
    DELETE_CAR_MODEL,
    GET_CAR_MODEL,
    SAVE_CAR_MODEL,
    CAR_MODEL,
  } from "./constants";

  function* getCarModels({ payload }) {
    try {
      let url = `/vehicle/car-model?`;
      if (payload)
        Object.keys(payload).map(
          (params) =>
            payload?.[params] && (url += `${params}=${payload[params]}&`)
        );
      const response = yield call(authRequest, url.slice(0, url.length - 1), {
        method: "get",
      });
      console.log("response", response);
      yield put(getCarModelsSuccess(response?.data));
    } catch (error) {
      let message = "Failed to get car model";
      yield put(getCarModelsFailed(message));
    }
  }

  function* saveCarModel({ payload: { data, history } }) {
    try {
      console.log("data", data);
      const formData = new FormData();
      Object.keys(data).map((key) => formData.append(key, data[key]));
      let url = `/vehicle/car-model`;
      if (data?.id) url += `/${data?.id}?_method=PUT`;

      const response = yield call(authRequest, url, {
        method: "post",
        data: formData,
        headers: {
          "content-type": "multipart/form-data",
        },
      });
      yield put(getCarModelsSuccess(response?.data));
      yield put(appNotification({ type: "success", message: response?.message }));
      history.goBack();
    } catch (error) {
      let message = "Failed to get car model";
      yield put(getCarModelsFailed(message));
    }
  }
  function* getCarModel({ payload: { id } }) {
    try {
      const response = yield call(authRequest, `/vehicle/car-model/${id}`, {
        method: "get",
      });
      yield put(getCarModelSuccess(response?.data));
    } catch (error) {
      let message = "Failed to get car model";
      yield put(getCarModelFailed(message));
    }
  }
  function* deleteCarModel({ payload: { id } }) {
    try {
      const response = yield call(authRequest, `/vehicle/car-model/${id}`, {
        method: "delete",
      });
      yield put(appNotification({ type: "success", message: response?.message }));
      yield put(deleteCarModelSuccess({ id }));
    } catch (error) {
        console.log('error',error)
      let message = "Failed to get car model";
      yield put(deleteCarModelFailed(message));
    }
  }
  export function* watchGetCarModels() {
    yield takeLatest(CAR_MODEL, getCarModels);
  }
  export function* watchGetCarModel() {
    yield takeEvery(GET_CAR_MODEL, getCarModel);
  }
  export function* watchSaveCarModel() {
    yield takeEvery(SAVE_CAR_MODEL, saveCarModel);
  }
  export function* watchDeleteCarModel() {
    yield takeEvery(DELETE_CAR_MODEL, deleteCarModel);
  }
  function* carModelSaga() {
    yield all([
      fork(watchGetCarModels),
      fork(watchGetCarModel),
      fork(watchSaveCarModel),
      fork(watchDeleteCarModel),
    ]);
  }

  export default carModelSaga;
