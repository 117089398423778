import React from "react";
import PropTypes from "prop-types";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import overlayFactory from "react-bootstrap-table2-overlay";
import cellEditFactory from "react-bootstrap-table2-editor";

import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import { Button, Card, CardBody, Col, Input, Row } from "reactstrap";
import { Filter, Loader } from "react-feather";
import { Link } from "react-router-dom";
import { Plus as PlusIcon, FileText as ExportIcon } from "react-feather";
import filterFactory from "react-bootstrap-table2-filter";

const sizePerPageRenderer = ({
  options,
  currSizePerPage,
  onSizePerPageChange,
}) => (
  <React.Fragment>
    <label className="d-inline mr-1">Show</label>
    <Input
      type="select"
      name="select"
      id="no-entries"
      className="custom-select custom-select-sm d-inline col-1"
      defaultValue={currSizePerPage}
      onChange={(e) => onSizePerPageChange(e.target.value)}
    >
      {options.map((option, idx) => {
        return <option key={idx}>{option.text}</option>;
      })}
    </Input>
    <label className="d-inline ml-1">entries</label>
  </React.Fragment>
);
const Table = ({
  loading,
  records,
  columns,
  defaultSorted,
  header,
  subHeader,
  handleTableChange,
  addUrl,
  pagination,
  toggleFilter,
  canCellEdit = false,
  enableSelection = false,
  handleRowSelect,
  handleSelectAll,
  disableExport = false,
  disableSearch = false,
  cellEditOption = { mode: "click" },
}) => {
  const { SearchBar } = Search;
  const { ExportCSVButton } = CSVExport;
  const onRowSelect = (row, isSelected, e) => {
    handleRowSelect(isSelected, row, e);
  };

  const onSelectAll = (isSelected, rows) => {
    handleSelectAll(isSelected, rows);
  };

  const selectRowProp = {
    mode: "checkbox",
    clickToSelect: true,
    onSelect: onRowSelect,
    onSelectAll: onSelectAll,
  };
  return (
    <Card>
      <CardBody>
        {header && <h4 className="header-title mt-0 mb-1">{header}</h4>}
        {subHeader && <p className="sub-header">{subHeader}</p>}
        {
          <ToolkitProvider
            bootstrap4
            keyField="id"
            data={records || []}
            columns={columns}
            search
            exportCSV={{ onlyExportFiltered: true, exportAll: false }}
          >
            {(props) => (
              <React.Fragment>
                <Row>
                  <Col xs={8}>
                    {!disableSearch && (
                      <div className="d-flex">
                        <SearchBar {...props.searchProps} />
                      </div>
                    )}
                  </Col>
                  <Col xs={4}>
                    <div className="d-flex justify-content-end">
                      {toggleFilter && (
                        <Button
                          onClick={toggleFilter}
                          color="warning"
                          className="mr-1 mb-2"
                        >
                          <Filter />
                        </Button>
                      )}

                      {addUrl && (
                        <Link className="btn btn-success mr-1 mb-2" to={addUrl}>
                          <PlusIcon />
                        </Link>
                      )}
                      {!disableExport && (
                        <ExportCSVButton
                          {...props.csvProps}
                          className="btn btn-primary mb-2"
                        >
                          <ExportIcon />
                        </ExportCSVButton>
                      )}
                    </div>
                  </Col>
                </Row>

                <BootstrapTable
                  {...props.baseProps}
                  selectRow={enableSelection ? selectRowProp : undefined}
                  filter={filterFactory()}
                  filterPosition="top"
                  remote
                  loading={loading}
                  overlay={overlayFactory({
                    spinner: true,
                    styles: {
                      overlay: (base) => ({
                        ...base,
                        background: "rgba(0, 0, 0, 0.1)",
                      }),
                      content: (base) => ({ ...base }),
                    },
                  })}
                  noDataIndication={
                    <span className="text-muted">No records found.</span>
                  }
                  //   keyField="id"
                  onTableChange={handleTableChange}
                  bordered={false}
                  defaultSorted={defaultSorted}
                  pagination={paginationFactory({
                    ...pagination,
                    sizePerPageRenderer: sizePerPageRenderer,
                    sizePerPageList: [
                      { text: "5", value: 5 },
                      { text: "10", value: 10 },
                      { text: "25", value: 25 },
                      { text: "All", value: records?.length || 0 },
                    ],
                  })}
                  wrapperClasses="table-responsive table-bordered"
                  cellEdit={canCellEdit ? cellEditFactory(cellEditOption) : {}}
                />
              </React.Fragment>
            )}
          </ToolkitProvider>
        }
      </CardBody>
    </Card>
  );
};

Table.propTypes = {
  loading: PropTypes.bool,
  records: PropTypes.array,
  columns: PropTypes.array,
  defaultSorted: PropTypes.object,
  header: PropTypes.string,
  subHeader: PropTypes.string,
  handleTableChange: PropTypes.func,
  addUrl: PropTypes.string,
  disableSearch: PropTypes.bool,
  cellEditOption: PropTypes.object,
};
export default Table;
