import { Settings } from "react-feather";
import PrivateRoute from "../components/PrivateRoute";
import AddEditGarages from "../pages/garages/AddEditGarages";
import ListGarages from "../pages/garages/ListGarages";
import ViewGarages from "../pages/garages/ViewGarages";

const garages = [
  {
    path: "/service-provider/business",
    name: "Service provider business",
    component: ListGarages,
    icon: Settings,
    roles: ["admin", "service-provider", "agent"],
    route: PrivateRoute,
    exact: true,
  },
  {
    path: "/service-provider/business/add",
    component: AddEditGarages,
    roles: ["admin", "service-provider"],
    route: PrivateRoute,
  },
  {
    path: "/service-provider/business/edit/:id",
    component: AddEditGarages,
    roles: ["admin", "service-provider"],
    route: PrivateRoute,
  },
  {
    path: "/service-provider/business/view/:id",
    component: ViewGarages,
    roles: ["admin", "service-provider"],
    route: PrivateRoute,
  },
];

const serviceProviderRoutes = [...garages];
export default serviceProviderRoutes;
