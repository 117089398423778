import {
  SAVE_SUPPLIER_BUSINESS_UPDATE,
  SAVE_SUPPLIER_BUSINESS_UPDATE_FAILED,
  SAVE_SUPPLIER_BUSINESS_UPDATE_SUCCESS,
  GET_SUPPLIER_BUSINESS_UPDATE,
  GET_SUPPLIER_BUSINESS_UPDATE_SUCCESS,
  GET_SUPPLIER_BUSINESS_UPDATE_FAILED,
  CLEAR_SUPPLIER_BUSINESS_UPDATE,
} from "./constants";

export const getSupplierBusinessUpdate = (id) => ({
  type: GET_SUPPLIER_BUSINESS_UPDATE,
  payload: { id },
});

export const getSupplierBusinessUpdateSuccess = (data) => ({
  type: GET_SUPPLIER_BUSINESS_UPDATE_SUCCESS,
  payload: data,
});

export const getSupplierBusinessUpdateFailed = (errorMessage) => ({
  type: GET_SUPPLIER_BUSINESS_UPDATE_FAILED,
  payload: errorMessage,
});

export const saveSupplierBusinessUpdate = ({ data, onSuccess, onFailed }) => ({
  type: SAVE_SUPPLIER_BUSINESS_UPDATE,
  payload: { data, onSuccess, onFailed },
});

export const saveSupplierBusinessUpdateSuccess = (success) => ({
  type: SAVE_SUPPLIER_BUSINESS_UPDATE_SUCCESS,
  payload: success,
});

export const saveSupplierBusinessUpdateFailed = (errorMessage) => ({
  type: SAVE_SUPPLIER_BUSINESS_UPDATE_FAILED,
  payload: errorMessage,
});

export const clearSupplierBusinessUpdate = () => ({
  type: CLEAR_SUPPLIER_BUSINESS_UPDATE,
  payload: {},
});
