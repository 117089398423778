import jsonToFormData from "json-form-data";

import {
  all,
  call,
  fork,
  put,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";

import { authRequest } from "../../helpers/api";
import { appNotification } from "../app/actions";
import {
  getSupplierBusinessUpdateFailed,
  getSupplierBusinessUpdateSuccess,
  saveSupplierBusinessUpdateFailed,
  saveSupplierBusinessUpdateSuccess,
} from "./actions";
import {
  SAVE_SUPPLIER_BUSINESS_UPDATE,
  GET_SUPPLIER_BUSINESS_UPDATE,
} from "./constants";
const base_url = `/supplier-business`;

function* saveSupplierBusinessUpdateSaga({
  payload: { data, onSuccess, onFailed },
}) {
  try {
    const formData = jsonToFormData(data);
    let url = `${base_url}/saveSupplier`;
    if (data?.id) url += `/${data?.id}?_method=PUT`;

    const response = yield call(authRequest, url, {
      method: "post",
      data: formData,
      headers: {
        "Content-type": "multipart/form-data",
        Accept: "application/json",
      },
    });
    yield put(saveSupplierBusinessUpdateSuccess("data saved"));
    if (onSuccess)
      yield call(() => {
        onSuccess(response);
      });
    yield put(appNotification({ type: "success", message: response?.message }));
  } catch (error) {
    let message = "Failed to save data";
    if (onFailed)
      yield call(() => {
        onFailed(error);
      });
    if (error?.errors)
      yield put(saveSupplierBusinessUpdateFailed(error?.errors));
    else yield put(saveSupplierBusinessUpdateFailed(message));
  }
}
function* getSupplierBusinessUpdateSaga({ payload: { id } }) {
  try {
    const response = yield call(authRequest, `${base_url}/show/${id}`, {
      method: "get",
    });
    console.log("response", response);
    yield put(getSupplierBusinessUpdateSuccess(response?.data));
  } catch (error) {
    let message = "Failed to get supplier business";
    yield put(getSupplierBusinessUpdateFailed(message));
  }
}

export function* watchGetSupplierBusiness() {
  yield takeEvery(GET_SUPPLIER_BUSINESS_UPDATE, getSupplierBusinessUpdateSaga);
}
export function* watchSaveSupplierBusiness() {
  yield takeEvery(
    SAVE_SUPPLIER_BUSINESS_UPDATE,
    saveSupplierBusinessUpdateSaga
  );
}
function* supplierBusinessUpdateSaga() {
  yield all([fork(watchSaveSupplierBusiness), fork(watchGetSupplierBusiness)]);
}

export default supplierBusinessUpdateSaga;
