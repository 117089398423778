import Joi from "joi";
import formValidationRules from "../../utils/formValidationRules";
import {
  getCitiesWithPaginateApi,
  getCityOption,
  getCountryOption,
  getsparePartsSubCategories,
  getSpartPartsCategory,
  getSupplierDealershipTypeOption,
  getSupplierList,
  getVehicleMakeOption,
} from "../../services/api";
export const verificationStatusOptions = {
  pending: { label: "Pending", value: "pending" },
  verified: { label: "Verified", value: "verified" },
  unverified: { label: "Not Verified", value: "unverified" },
};
export const fields = {
  BusinessProfileHeader: {
    type: "section",
    title: "Business profile",
    id: "BusinessProfileHeader",
  },
  userId: {
    type: "asyncSelect",
    name: "userId",
    validationRules: Joi.object()
      .required()
      .messages({ "object.base": `"Supplier" is required` }),
    getOptionLabel: (option) => (
      <span>{`${option.name} (${option.email || option.phoneNumber})`}</span>
    ),
    getOptionValue: (option) => option.id,
    id: "userId",
    label: "Supplier",
    loadOptions: getSupplierList,
    columnProps: {
      sm: 12,
      md: 6,
      lg: 6,
    },
  },
  businessName: {
    type: "text",
    name: "businessName",
    id: "businessName",
    label: "Business name",
    validationRules: Joi.string().required(),
    columnProps: {
      sm: 12,
      md: 6,
      lg: 6,
    },
  },
  businessEmail: {
    type: "email",
    name: "businessEmail",
    id: "businessEmail",
    label: "Business email",
    validationRules: formValidationRules.email.messages({
      required: "This is required",
    }),
    columnProps: {
      sm: 12,
      md: 6,
      lg: 6,
    },
  },
  businessContactPerson: {
    type: "text",
    name: "businessContactPerson",
    id: "businessContactPerson",
    label: "Business Contact Person",
    validationRules: formValidationRules.name,
    columnProps: {
      sm: 12,
      md: 6,
      lg: 6,
    },
  },
  businessContactNumber: {
    type: "phoneNumber",
    name: "businessContactNumber",
    id: "businessContactNumber",
    label: "business Contact Number (Optional)",
    phoneCodeProps: {
      name: "businessContactNumberCode",
    },
    validationRules: formValidationRules.phoneNumber.allow("", 0),
    columnProps: {
      sm: 12,
      md: 6,
      lg: 6,
    },
  },
  businessWebsite: {
    type: "url",
    name: "businessWebsite",
    id: "businessWebsite",
    label: "Business website",
    validationRules: formValidationRules.optionalField,
    columnProps: {
      sm: 12,
      md: 6,
      lg: 6,
    },
  },
  BusinessAddressHeader: {
    type: "section",
    title: "Business address",
    id: "BusinessAddressHeader",
  },
  businessAddress1: {
    type: "text",
    name: "businessAddress1",
    id: "businessAddress1",
    label: "Address line 1",
    validationRules: Joi.string().required(),
    columnProps: {
      sm: 12,
      md: 6,
      lg: 6,
    },
  },
  businessAddress2: {
    type: "text",
    name: "businessAddress2",
    id: "businessAddress2",
    label: "Address line 2 (Optional)",
    validationRules: formValidationRules.optionalField,
    columnProps: {
      sm: 12,
      md: 6,
      lg: 6,
    },
  },
  businessCity: {
    type: "asyncSelect",
    name: "businessCity",
    id: "businessCity",
    label: "City",
    getOptionLabel: (option) => option.name,
    getOptionValue: (option) => option.id,
    validationRules: Joi.object().required(),
    loadOptions: (search) => getCitiesWithPaginateApi({ search }),
    columnProps: {
      sm: 12,
      md: 6,
      lg: 6,
    },
  },

  businessProvinceId: {
    type: "select",
    name: "businessProvinceId",
    id: "businessProvinceId",
    label: "Province/ Emirates",
    validationRules: Joi.object().required(),
    getOptionLabel: (option) => option?.provinceName,
    getOptionValue: (option) => option?.id,
    options: [],
    columnProps: {
      sm: 12,
      md: 6,
      lg: 6,
    },
  },
  businessCountryId: {
    type: "select",
    name: "businessCountryId",
    getOptionLabel: (option) => option.countryName,
    getOptionValue: (option) => option.id,
    id: "businessCountryId",
    label: "Country",
    validationRules: Joi.object().required(),
    options: [],
    columnProps: {
      sm: 12,
      md: 6,
      lg: 6,
    },
  },
  businessPostalCode: {
    type: "text",
    maxLength: 6,
    name: "businessPostalCode",
    id: "businessPostalCode",
    label: "Zip code",
    validationRules: Joi.number(),
    columnProps: {
      sm: 12,
      md: 6,
      lg: 6,
    },
  },
  gMapLink: {
    type: "url",
    name: "gMapLink",
    id: "gMapLink",
    label: "Google map link",
    validationRules: formValidationRules.optionalField,
    columnProps: {
      sm: 12,
      md: 6,
      lg: 6,
    },
  },
  gMapPlusCode: {
    type: "text",
    name: "gMapPlusCode",
    id: "gMapPlusCode",
    validationRules: formValidationRules.optionalField,
    label: "Google map Plus code",
    columnProps: {
      sm: 12,
      md: 6,
      lg: 6,
    },
  },
  // googleMap: {
  //   type: "gmaps",
  //   maxLength: 6,
  //   name: "googleMap",
  //   id: "googlMap",
  //   validationRules:formValidationRules.optionalField,
  //   label: "Google map location",
  //   columnProps: {
  //     sm: 12,
  //     md: 6,
  //     lg: 6,
  //   },
  // },
  BusinessWorkingHours: {
    type: "section",
    title: "Business working hours",
    id: "BusinessWorkingHours",
  },
  workingDays: {
    type: "checkbox",
    name: "workingDays",
    id: "workingDays",
    label: "Working days",
    validationRules: Joi.array(),
    columnProps: {
      sm: 12,
      md: 6,
      lg: 6,
    },
  },
  BusinessDetailsHeader: {
    type: "section",
    title: "Business details",
    id: "BusinessDetailsHeader",
  },
  dealershipTypeId: {
    type: "asyncSelect",
    name: "dealershipTypeId",
    validationRules: Joi.required(),
    getOptionLabel: (option) => option.type,
    getOptionValue: (option) => option.id,
    id: "dealershipTypeId",
    label: "Type of dealership",

    loadOptions: getSupplierDealershipTypeOption,
    columnProps: {
      sm: 12,
      md: 6,
      lg: 6,
    },
  },
  vehicleMake: {
    type: "multiSelectCheckbox",
    name: "vehicleMake",
    dropdownFieldLabel: "Vehicle make",
    dropdownFieldName: "vehicleMake",
    checkboxFieldLabel: "Vehicle model",
    checkboxFieldName: "vehicleModel",
    checkboxFieldLabelKey: "name",
    checkboxFieldIdKey: "id_car_model",
    getOptionLabel: (option) => option.name,
    getOptionValue: (option) => option.id_car_make,
    labelField: "dropdownFieldLabel",
    loadOptions: getVehicleMakeOption,
    validationRules: Joi.array().required(),
    columnProps: {
      sm: 12,
      md: 6,
      lg: 6,
    },
  },
  vehicleModel: {
    type: "hidden",
    name: "vehicleModel",
    validationRules: Joi.required(),
    label: "Vehicle model",
  },
  supplierType: {
    type: "asyncSelect",
    name: "supplierType",
    isMulti: true,
    validationRules: Joi.array(),
    getOptionLabel: (option) => option.categoryName,
    getOptionValue: (option) => option.id,
    id: "supplierType",
    label: "Supplier type",
    loadOptions: () => getSpartPartsCategory({ level: 1, search: "" }),
    columnProps: {
      sm: 12,
      md: 6,
      lg: 6,
    },
  },
  category: {
    type: "multiSelectCheckbox",
    name: "category",
    dropdownFieldLabel: "Spare parts category",
    dropdownFieldName: "category",
    checkboxFieldLabel: "Sub category",
    checkboxFieldName: "subCategory",
    checkboxFieldLabelKey: "categoryName",
    checkboxFieldIdKey: "id",
    getOptionLabel: (option) => option.categoryName,
    getOptionValue: (option) => option.id,
    labelField: "dropdownFieldLabel",
    validationRules: Joi.array().required(),
    columnProps: {
      sm: 12,
      md: 6,
      lg: 6,
    },
  },
  subCategory: {
    type: "hidden",
    name: "subCategory",
    validationRules: Joi.required(),
    label: "Sub category",
  },
  verification_status: {
    type: "select",
    name: "verification_status",
    id: "verification_status",
    label: "Verification status",
    getOptionLabel: (option) => option.label,
    getOptionValue: (option) => option.value,
    validationRules: Joi.object().required(),
    options: Object.values(verificationStatusOptions),
    columnProps: {
      sm: 12,
      md: 6,
      lg: 6,
    },
  },
  description: {
    type: "richText",
    name: "description",
    validationRules: formValidationRules.optionalField,
    id: "description",
    label: "Description (Optional)",
    columnProps: {
      sm: 12,
      md: 12,
      lg: 12,
    },
  },

  licenceNumber: {
    type: "text",
    name: "licenceNumber",
    id: "licenceNumber",
    label: "Business licence number",
    validationRules: Joi.string().required(),
    columnProps: {
      sm: 12,
      md: 6,
      lg: 6,
    },
  },
  licenceDocument: {
    type: "file",
    name: "licenceDocument",
    id: "licenceDocument",
    label: "Upload your licence",
    validationRules: Joi.any().required(),
    accept: "application/msword,text/plain, application/pdf, image/*",
    columnProps: {
      sm: 12,
      md: 6,
      lg: 6,
    },
  },
  logo: {
    type: "image",
    name: "logo",
    id: "logo",
    label: "Logo (Optional)",
    accept: "image/*",
    validationRules: formValidationRules.optionalField,
    columnProps: {
      sm: 12,
      md: 6,
      lg: 6,
    },
  },
  photos: {
    type: "image",
    multiple: true,
    name: "photos",
    id: "photos",
    label: "Photos",
    validationRules: Joi.any().optional(),
    accept: "image/*",
    columnProps: {
      sm: 12,
      md: 6,
      lg: 6,
    },
  },
  status: {
    type: "switch",
    name: "status",
    id: "status",
    label: "Status",
    validationRules: Joi.any(),
    activeLabel: "Active",
    columnProps: {
      sm: 12,
      md: 6,
      lg: 6,
    },
  },
};
export const schema = (fields) => {
  const validationObject = {};
  Object.keys(fields).forEach(
    (v) =>
      fields[v]?.validationRules &&
      (validationObject[v] = fields[v].validationRules.label(
        fields[v]?.label
          ? fields[v]?.label
          : fields[v]?.labelField
          ? fields[v]?.[fields[v]?.labelField]
          : "field"
      ))
  );
  return Joi.object({
    ...validationObject,
    id: Joi.allow(""),
    workingStartHours: Joi.array(),
    workingEndHours: Joi.array(),
    latitude: formValidationRules.optionalField,
    longitude: formValidationRules.optionalField,
    licenceNumberDocName: formValidationRules.optionalField,
    businessContactNumberCode: Joi.number(),
    provider: Joi.optional(),
  }).options({
    abortEarly: false,
  });

  //   Joi.object({
  //     userId: Joi.object()
  //       .required()
  //       .label("Service provider")
  //       .messages({ "object.base": `"Service provider" is required` }),
  //     businessName: formValidationRules.name.label("Business name"),
  //     businessEmail: formValidationRules.email.label("Business email"),
  //     businessContactPerson: formValidationRules.name.label(
  //       "Business contact person"
  //     ),
  //     businessContactNumber: formValidationRules.phoneNumber.label(
  //       "Business contact number"
  //     ),
  //     businessCountryId: Joi.object().required().label("Country"),
  //     businessProvinceId: Joi.object().required().label("Province"),
  //     businessCity: Joi.string().required(),
  //     businessPostalCode: Joi.number(),
  //     businessAddress1: Joi.string().required(),
  //     businessAddress2: formValidationRules.optionalField,
  //     specialization: Joi.array().required(),
  //     vehicleMake: Joi.array().required(),
  //     description: formValidationRules.optionalField,
  //     licenceNumber: Joi.string().required(),
  //     businessWebsite: Joi.string().optional(),
  //     logo: formValidationRules.optionalField,
  //     // latitude: formValidationRules.optionalField,
  //     // longitude: formValidationRules.optionalField,
  //     status: Joi.boolean(),
  //     licenceDocument: Joi.any(),
  //     photos: Joi.any().optional(),
  //     workingDays: Joi.array(),
  //     // workingStartHours: Joi.array(),
  //     // workingEndHours: Joi.array(),
  //     gMapLink: formValidationRules.optionalField,
  //     gMapPlusCode: formValidationRules.optionalField,
  //   }).options({
  //     abortEarly: false,
  //   });
};
