import {
  DELETE_SPARE_PARTS_CATEGORY,
  DELETE_SPARE_PARTS_CATEGORY_FAILED,
  DELETE_SPARE_PARTS_CATEGORY_SUCCESS,
  EDIT_SPARE_PARTS_CATEGORY,
  EDIT_SPARE_PARTS_CATEGORY_FAILED,
  EDIT_SPARE_PARTS_CATEGORY_SUCCESS,
  SAVE_SPARE_PARTS_CATEGORY,
  SAVE_SPARE_PARTS_CATEGORY_FAILED,
  SAVE_SPARE_PARTS_CATEGORY_SUCCESS,
  SPARE_PARTS_CATEGORY,
  SPARE_PARTS_CATEGORY_FAILED,
  SPARE_PARTS_CATEGORY_SUCCESS,
  GET_SPARE_PARTS_CATEGORY,
  GET_SPARE_PARTS_CATEGORY_SUCCESS,
  GET_SPARE_PARTS_CATEGORY_FAILED,
  GET_SPARE_PARTS_PARENT_CATEGORY,
  GET_SPARE_PARTS_PARENT_CATEGORY_SUCCESS,
  GET_SPARE_PARTS_PARENT_CATEGORY_FAILED,
} from "./constants";

export const getSparePartsCategory = (id) => ({
  type: GET_SPARE_PARTS_CATEGORY,
  payload: { id },
});

export const getSparePartsCategorySuccess = (data) => ({
  type: GET_SPARE_PARTS_CATEGORY_SUCCESS,
  payload: data,
});

export const getSparePartsCategoryFailed = (errorMessage) => ({
  type: GET_SPARE_PARTS_CATEGORY_FAILED,
  payload: errorMessage,
});

export const getSparePartsCategories = (payload) => ({
  type: SPARE_PARTS_CATEGORY,
  payload,
});

export const getSparePartsCategoriesSuccess = (data) => ({
  type: SPARE_PARTS_CATEGORY_SUCCESS,
  payload: { data },
});

export const getSparePartsCategoriesFailed = (errorMessage) => ({
  type: SPARE_PARTS_CATEGORY_FAILED,
  payload: errorMessage,
});

export const saveSparePartsCategory = ({ data, history }) => ({
  type: SAVE_SPARE_PARTS_CATEGORY,
  payload: { data, history },
});

export const saveSparePartsCategorySuccess = (success) => ({
  type: SAVE_SPARE_PARTS_CATEGORY_SUCCESS,
  payload: success,
});

export const saveSparePartsCategoryFailed = (errorMessage) => ({
  type: SAVE_SPARE_PARTS_CATEGORY_FAILED,
  payload: errorMessage,
});

export const editSparePartsCategory = (data) => ({
  type: EDIT_SPARE_PARTS_CATEGORY,
  payload: data,
});

export const editSparePartsCategorySuccess = (success) => ({
  type: EDIT_SPARE_PARTS_CATEGORY_SUCCESS,
  payload: success,
});

export const editSparePartsCategoryFailed = (errorMessage) => ({
  type: EDIT_SPARE_PARTS_CATEGORY_FAILED,
  payload: errorMessage,
});

export const deleteSparePartsCategory = (data) => ({
  type: DELETE_SPARE_PARTS_CATEGORY,
  payload: data,
});

export const deleteSparePartsCategorySuccess = (success) => ({
  type: DELETE_SPARE_PARTS_CATEGORY_SUCCESS,
  payload: success,
});

export const deleteSparePartsCategoryFailed = (errorMessage) => ({
  type: DELETE_SPARE_PARTS_CATEGORY_FAILED,
  payload: errorMessage,
});

export const getSparePartsParentCategory = ({ search }) => ({
  type: GET_SPARE_PARTS_PARENT_CATEGORY,
  payload: { search },
});

export const getSparePartsParentCategorySuccess = (data) => ({
  type: GET_SPARE_PARTS_PARENT_CATEGORY_SUCCESS,
  payload: data,
});

export const getSparePartsParentCategoryFailed = (errorMessage) => ({
  type: GET_SPARE_PARTS_PARENT_CATEGORY_FAILED,
  payload: errorMessage,
});
