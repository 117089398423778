export const ORDERS = "ORDERS";
export const ORDERS_SUCCESS = "ORDERS_SUCCESS";
export const ORDERS_FAILED = "ORDERS_FAILED";

export const GET_ORDER = "GET_ORDER";
export const GET_ORDER_SUCCESS = "GET_ORDER_SUCCESS";
export const GET_ORDER_FAILED = "GET_ORDER_FAILED";


export const EDIT_ORDER = "EDIT_ORDER";
export const EDIT_ORDER_SUCCESS = "EDIT_ORDER_SUCCESS";
export const EDIT_ORDER_FAILED = "EDIT_ORDER_FAILED";

export const SAVE_ORDER = 'SAVE_ORDER';
export const SAVE_ORDER_SUCCESS = 'SAVE_ORDER_SUCCESS';
export const SAVE_ORDER_FAILED = 'SAVE_ORDER_FAILED';

export const ASSIGN_DEVILERY_AGENT = 'ASSIGN_DEVILERY_AGENT';
export const ASSIGN_DEVILERY_AGENT_SUCCESS = 'ASSIGN_DEVILERY_AGENT_SUCCESS';
export const ASSIGN_DEVILERY_AGENT_FAILED = 'ASSIGN_DEVILERY_AGENT_FAILED';
