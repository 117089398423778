/* AUTH */
export const SERVICE_PROVIDER_LOGIN_USER = "SERVICE_PROVIDER_LOGIN_USER";
export const SERVICE_PROVIDER_LOGIN_USER_SUCCESS = "SERVICE_PROVIDER_LOGIN_USER_SUCCESS";
export const SERVICE_PROVIDER_LOGIN_USER_FAILED = "SERVICE_PROVIDER_LOGIN_USER_FAILED";
export const SERVICE_PROVIDER_REGISTER_USER = "SERVICE_PROVIDER_REGISTER_USER";
export const SERVICE_PROVIDER_REGISTER_USER_SUCCESS = "SERVICE_PROVIDER_REGISTER_USER_SUCCESS";
export const SERVICE_PROVIDER_REGISTER_USER_FAILED = "SERVICE_PROVIDER_REGISTER_USER_FAILED";
export const SERVICE_PROVIDER_LOGOUT_USER = "SERVICE_PROVIDER_LOGOUT_USER";
export const SERVICE_PROVIDER_FORGET_PASSWORD = "SERVICE_PROVIDER_FORGET_PASSWORD";
export const SERVICE_PROVIDER_FORGET_PASSWORD_SUCCESS = "SERVICE_PROVIDER_FORGET_PASSWORD_SUCCESS";
export const SERVICE_PROVIDER_FORGET_PASSWORD_FAILED = "SERVICE_PROVIDER_FORGET_PASSWORD_FAILED";
export const SERVICE_PROVIDER_VERIFY_OTP = "SERVICE_PROVIDER_VERIFY_OTP";
export const SERVICE_PROVIDER_VERIFY_OTP_SUCCESS = "SERVICE_PROVIDER_VERIFY_OTP_SUCCESS";
export const SERVICE_PROVIDER_VERIFY_OTP_FAILED = "SERVICE_PROVIDER_VERIFY_OTP_FAILED";
