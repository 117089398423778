import React, { useEffect, useState } from "react";
import moment from "moment/moment";
import { Alert, Col, Row } from "react-bootstrap";
import { selectFilter, textFilter } from "react-bootstrap-table2-filter";
import { useDispatch, useSelector } from "react-redux";
import PageTitle from "../../components/PageTitle";
import Table from "../../components/table/Table";
import {
  editQuoteList,
  fetchQuoteList,
  saveQuoteList,
} from "../../redux/quoteList/actions";
import { quoteListSelector } from "../../redux/quoteList/selectors";
import { CustomInput } from "reactstrap";
import ReactSelect from "react-select";
import PhoneNumber from "../../components/PhoneNumber/PhoneNumber";
import { EditableCell } from "../../components/EditableCell/EditableCell";
import { Field } from "../../components/FormBuilder/formField";
import { fetchSupplierBusiness } from "../../services/api";
import { appNotification } from "../../redux/app/actions";

const columns = ({ hasFilter = false }) => [
  {
    dataField: "id",
    text: "Id",
    sort: true,
    editable: false,
    headerStyle: {
      minWidth: 10,
    },
  },
  {
    dataField: "service_provider_business_name",
    text: "Garage",
    sort: true,
    filter: hasFilter && textFilter(),
    editable: false,
  },
  {
    dataField: "item_name",
    text: "Item name",
    sort: true,
    filter: hasFilter && textFilter(),
    editable: true,
  },

  {
    dataField: "supplier_business_name",
    text: "Supplier",
    sort: true,
    filter: hasFilter && textFilter(),
    editable: true,
    headerStyle: {
      minWidth: 200,
    },
    editorRenderer: (
      editorProps,
      value,
      row,
      column,
      rowIndex,
      columnIndex
    ) => {
      console.log("ee", editorProps);
      const handleChange = (val) => {
        console.log("change", val);
        editorProps.onUpdate(val);
      };
      return (
        <Field
          field={{
            type: "asyncCreatableSelect",
            name: "supplierBusiness",
            getOptionLabel: (option) => option.businessName || option?.label,
            getOptionValue: (option) => option.id || "",
            loadOptions: fetchSupplierBusiness,
            cacheOptions: true,
            defaultOptions: true,
            onChange: handleChange,
          }}
        />
      );
    },
  },
  {
    dataField: "supplier_contact_number",
    text: "Supplier contact number",
    sort: true,
    filter: hasFilter && textFilter(),
    editable: true,
  },
  {
    dataField: "qoute_price",
    text: "Quote price",
    sort: true,
    filter: hasFilter && textFilter(),
    editable: true,
  },
  {
    dataField: "item_cost_price",
    text: "Item cost price",
    sort: true,
    filter: hasFilter && textFilter(),
    editable: true,
  },
  {
    dataField: "warranty",
    text: "Warranty",
    sort: true,
    filter: hasFilter && textFilter(),
    editable: true,
  },
  {
    dataField: "qoute_submited",
    text: "Quote submitted",
    sort: true,
    filter:
      hasFilter &&
      selectFilter({
        options: [
          { label: "Yes", value: 1 },
          { label: "No", value: 0 },
        ],
      }),
    editable: true,
    formatter: (val) => (val === 1 ? "Yes" : "No"),
    editorRenderer: (
      editorProps,
      value,
      row,
      column,
      rowIndex,
      columnIndex
    ) => {
      const { ref, onUpdate, ...rest } = editorProps;
      return (
        <ReactSelect
          {...rest}
          id={`edit-qoute-submitted-${row.id}`}
          options={[
            { label: "Yes", value: 1 },
            { label: "No", value: 0 },
          ]}
          onChange={(option) => onUpdate(option?.value)}
        />
      );
    },
  },
  {
    dataField: "order_received",
    text: "Order received",
    sort: true,
    filter:
      hasFilter &&
      selectFilter({
        options: [
          { label: "Yes", value: 1 },
          { label: "No", value: 0 },
        ],
      }),
    editable: true,
    formatter: (val) => (val === 1 ? "Yes" : "No"),
    editorRenderer: (
      editorProps,
      value,
      row,
      column,
      rowIndex,
      columnIndex
    ) => {
      console.log("ee", editorProps);
      const { ref, onUpdate, ...rest } = editorProps;
      return (
        <ReactSelect
          {...rest}
          id={`edit-order_received-${row.id}`}
          options={[
            { label: "Yes", value: 1 },
            { label: "No", value: 0 },
          ]}
          onChange={(option) => onUpdate(option?.value)}
        />
      );
    },
  },

  {
    dataField: "created_by.name",
    text: "Created By",
    sort: true,
    filter: hasFilter && textFilter(),
    editable: false,
  },
  {
    dataField: "updated_by.name",
    text: "Modified by",
    sort: true,
    filter: hasFilter && textFilter(),
    editable: false,
  },

  {
    dataField: "created_at",
    text: "Created Date",
    sort: true,
    formatter: (cell, row) =>
      row?.created_at
        ? moment(row?.created_at).format("DD-MM-YYYY hh:mm:A")
        : null,
    editable: false,
  },
  {
    dataField: "updated_at",
    text: "Last modified",
    sort: true,
    formatter: (cell, row) =>
      row?.created_at
        ? moment(row?.created_at).format("DD-MM-YYYY hh:mm:A")
        : null,
    editable: false,
  },
];

const QuoteList = () => {
  const baseUrl = "/quote-list";
  const { data, loading, error } = useSelector(quoteListSelector);
  const [hasFilter, setHasFilter] = useState(false);
  const dispatch = useDispatch();
  const [pagination, setPagination] = useState({
    page: 1,
    searchText: "",
    sizePerPage: 10,
    sortField: "id",
    sortOrder: "desc",
  });

  const handleTableChange = (e, v) => {
    console.log("ff", v);
    const { page, searchText, sizePerPage, sortField, sortOrder, filters } = v;
    if (v?.cellEdit) {
      let payload = {
        [v?.cellEdit?.dataField]: v?.cellEdit?.newValue,
        id: v?.cellEdit?.rowId,
      };
      if (v.cellEdit.dataField === "supplier_business_name") {
        console.log("v?.cellEdit?.newValue", v?.cellEdit?.newValue);
        payload["supplier_business_name"] =
          v?.cellEdit?.newValue?.businessName || v?.cellEdit?.newValue?.label;
        payload["supplier_business_id"] = v?.cellEdit?.newValue?.id;
      }
      dispatch(
        editQuoteList({
          payload,
          onSuccess: (response) => {
            console.log("response", response);
            dispatch(
              appNotification({
                type: "success",
                message: "Saved successfully",
              })
            );
          },
          onError: (err) => {
            console.log("err", err);
          },
        })
      );
    } else {
      setPagination({ page, searchText, sizePerPage, sortField, sortOrder });
      dispatch(
        fetchQuoteList({
          page,
          searchText,
          sizePerPage,
          sortField,
          sortOrder,
          filters,
        })
      );
    }
  };
  const toggleFilter = (e) => {
    setHasFilter(!hasFilter);
  };
  const onAfterSaveCell = (value, name) => {
    console.log(name, value);
  };
  useEffect(() => {
    dispatch(fetchQuoteList(pagination));
  }, []);

  return (
    <>
      <Row className="page-title">
        <Col md={12}>
          <PageTitle
            breadCrumbItems={[
              {
                label: "Quote list",
                path: "/quote-list",
              },
            ]}
            title={"List of Quote"}
          />
        </Col>
      </Row>

      {error && <Alert color="danger">Oops something went wrong</Alert>}
      <Table
        loading={loading}
        toggleFilter={toggleFilter}
        records={data?.data}
        columns={columns({ hasFilter })}
        handleTableChange={handleTableChange}
        addUrl={`${baseUrl}/add`}
        pagination={{ ...pagination, totalSize: data?.total }}
        canCellEdit={true}
        disableSearch={true}
        cellEditOption={{
          mode: "click",
          afterSaveCell: onAfterSaveCell,
        }}
      />
    </>
  );
};

export default QuoteList;
