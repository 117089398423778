// @flow
import { all } from "redux-saga/effects";
import authSaga from "./auth/saga";
import layoutSaga from "./layout/saga";
import appMenuSaga from "./appMenu/saga";
import rolesSaga from "./roles/saga";
import partnerAuthSaga from "./serviceProviderAuth/saga";
import serviceCategorySaga from "./serviceCategory/saga";
import sparePartsCategorySaga from "./sparePartsCategory/saga";
import vehicleMakeSaga from "./vehicleMake/saga";
import vehicleTypeSaga from "./vehicleType/saga";
import users from "./users/saga";
import serviceProviderBusiness from "./serviceProviderBusiness/saga";
import supplierBusiness from "./supplierBusiness/saga";
import carModelSaga from "./carModel/saga";
import carTrimSaga from "./carTrim/saga";
import carGenerationSaga from "./carGeneration/saga";
import carSerieSaga from "./carSeries/saga";
import appSaga from "./app/saga";
import orderSaga from "./orders/saga";
import rfqSaga from "./rfq/saga";
import supplierBusinessUpdateSaga from "./supplierBusinessUpdate/saga";
import contactQuerySaga from "./contactQuery/saga";
import whatsAppGroupSaga from "./whatsappGroup/saga";
import QuoteListSaga from "./quoteList/saga";
import chatSaga from "./chat/saga";
import rfqResponseSaga from "./rfqResponse/saga";
import cartSaga from "./cart/saga";
import pointHistorySaga from "./rewardPoints/saga";

export default function* rootSaga(getState) {
  yield all([
    appSaga(),
    authSaga(),
    layoutSaga(),
    appMenuSaga(),
    rolesSaga(),
    partnerAuthSaga(),
    serviceCategorySaga(),
    sparePartsCategorySaga(),
    vehicleMakeSaga(),
    vehicleTypeSaga(),
    users(),
    serviceProviderBusiness(),
    supplierBusiness(),
    carModelSaga(),
    carTrimSaga(),
    carGenerationSaga(),
    carSerieSaga(),
    orderSaga(),
    rfqSaga(),
    supplierBusinessUpdateSaga(),
    contactQuerySaga(),
    whatsAppGroupSaga(),
    QuoteListSaga(),
    chatSaga(),
    rfqResponseSaga(),
    cartSaga(),
    pointHistorySaga(),
  ]);
}
