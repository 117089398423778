export const SAVE_RFQ_RESPONSE = "SAVE_RFQ_RESPONSE";
export const SAVE_RFQ_RESPONSE_SUCCESS = "SAVE_RFQ_RESPONSE_SUCCESS";
export const SAVE_RFQ_RESPONSE_FAILED = "SAVE_RFQ_RESPONSE_FAILED";

export const GET_RFQ_RESPONSE = "GET_RFQ_RESPONSE";
export const GET_RFQ_RESPONSE_SUCCESS = "GET_RFQ_RESPONSE_SUCCESS";
export const GET_RFQ_RESPONSE_FAILED = "GET_RFQ_RESPONSE_FAILED";

export const GET_RFQ_RESPONSE_DETAIL = "GET_RFQ_RESPONSE_DETAIL";
export const GET_RFQ_RESPONSE_DETAIL_SUCCESS =
  "GET_RFQ_RESPONSE_DETAIL_SUCCESS";
export const GET_RFQ_RESPONSE_DETAIL_FAILED = "GET_RFQ_RESPONSE_DETAIL_FAILED";

export const GET_RFQ_RESPONSE_QUOTATION_DETAIL =
  "GET_RFQ_RESPONSE_QUOTATION_DETAIL";
export const GET_RFQ_RESPONSE_QUOTATION_DETAIL_SUCCESS =
  "GET_RFQ_RESPONSE_QUOTATION_DETAIL_SUCCESS";
export const GET_RFQ_RESPONSE_QUOTATION_DETAIL_FAILED =
  "GET_RFQ_RESPONSE_QUOTATION_DETAIL_FAILED";

export const DELETE_RFQ_RESPONSE = "DELETE_RFQ_RESPONSE";
export const DELETE_RFQ_RESPONSE_SUCCESS = "DELETE_RFQ_RESPONSE_SUCCESS";
export const DELETE_RFQ_RESPONSE_FAILED = "DELETE_RFQ_RESPONSE_FAILED";

export const saveRfqResponse = ({ payload, onSuccess }) => ({
  type: SAVE_RFQ_RESPONSE,
  payload: { payload, onSuccess },
});

export const saveRfqResponseSuccess = (data) => ({
  type: SAVE_RFQ_RESPONSE_SUCCESS,
  payload: data,
});

export const saveRfqResponseFailed = (error) => ({
  type: SAVE_RFQ_RESPONSE_FAILED,
  payload: error,
});

export const getRfqResponse = ({ payload }) => ({
  type: GET_RFQ_RESPONSE,
  payload,
});

export const getRfqResponseSuccess = (data) => ({
  type: GET_RFQ_RESPONSE_SUCCESS,
  payload: data,
});

export const getRfqResponseFailed = (error) => ({
  type: GET_RFQ_RESPONSE_FAILED,
  payload: error,
});

export const getRfqResponseDetail = ({ payload }) => ({
  type: GET_RFQ_RESPONSE_DETAIL,
  payload,
});

export const getRfqResponseDetailSuccess = (data) => ({
  type: GET_RFQ_RESPONSE_DETAIL_SUCCESS,
  payload: data,
});

export const getRfqResponseDetailFailed = (error) => ({
  type: GET_RFQ_RESPONSE_DETAIL_FAILED,
  payload: error,
});

export const getRfqResponseDetailQuotation = ({ payload }) => ({
  type: GET_RFQ_RESPONSE_QUOTATION_DETAIL,
  payload,
});

export const getRfqResponseDetailQuotationSuccess = (data) => ({
  type: GET_RFQ_RESPONSE_QUOTATION_DETAIL_SUCCESS,
  payload: data,
});

export const getRfqResponseDetailQuotationFailed = (error) => ({
  type: GET_RFQ_RESPONSE_QUOTATION_DETAIL_FAILED,
  payload: error,
});

export const deleteRfqResponse = (payload) => ({
  type: DELETE_RFQ_RESPONSE,
  payload,
});

export const deleteRfqResponseSuccess = (payload) => ({
  type: DELETE_RFQ_RESPONSE_SUCCESS,
  payload,
});

export const deleteRfqResponseFailed = (payload) => ({
  type: DELETE_RFQ_RESPONSE_FAILED,
  payload,
});
