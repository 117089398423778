export const CAR_GENERATION = "CAR_GENERATION";
export const CAR_GENERATION_SUCCESS = "CAR_GENERATION_SUCCESS";
export const CAR_GENERATION_FAILED = "CAR_GENERATION_FAILED";
export const SAVE_CAR_GENERATION = "SAVE_CAR_GENERATION";
export const SAVE_CAR_GENERATION_SUCCESS = "SAVE_CAR_GENERATION_SUCCESS";
export const SAVE_CAR_GENERATION_FAILED = "SAVE_CAR_GENERATION_FAILED";
export const EDIT_CAR_GENERATION = "EDIT_CAR_GENERATION";
export const EDIT_CAR_GENERATION_SUCCESS = "EDIT_CAR_GENERATION_SUCCESS";
export const EDIT_CAR_GENERATION_FAILED = "EDIT_CAR_GENERATION_FAILED";
export const DELETE_CAR_GENERATION = "DELETE_CAR_GENERATION";
export const DELETE_CAR_GENERATION_SUCCESS =
  "DELETE_CAR_GENERATION_SUCCESS";
export const DELETE_CAR_GENERATION_FAILED = "DELETE_CAR_GENERATION_FAILED";
export const GET_CAR_GENERATION = "GET_CAR_GENERATION";
export const GET_CAR_GENERATION_SUCCESS = "GET_CAR_GENERATION_SUCCESS";
export const GET_CAR_GENERATION_FAILED = "GET_CAR_GENERATION_FAILED";
