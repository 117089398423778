import "./viewOrder.scss";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import Select from "react-select";
import Loader from "../../components/Loader";

import {
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  CustomInput,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Spinner,
} from "reactstrap";
import PageTitle from "../../components/PageTitle";
import {
  AvForm,
  AvGroup,
  AvInput,
  AvFeedback,
  AvField,
} from "availity-reactstrap-validation";
import { getUser, saveUser } from "../../redux/users/actions";
import Status from "../../components/Status";
import { getOrder } from "../../redux/orders/actions";
import { ORDER_STATUS } from "../../constants/orderStatus";
import moment from "moment";
import useMediaQuery from "beautiful-react-hooks/useMediaQuery";
import LoadAsyncImage from "../../components/LoadAsyncImage";
import { tryParseJSONObject } from "../../utils/helpers";
import Routes from "../../routes/Routes";

const ViewOrders = () => {
  const params = useParams();
  const { order, loading, error } = useSelector((state) => state.Orders);
  const sm = useMediaQuery({ maxWidth: 992 });
  const [orderData, setOrderData] = useState(null);
  const id = params?.id;
  const history = useHistory();
  const dispatch = useDispatch();
  const [subTotal, setSubTotal] = useState(0);

  const defaultValue = {};
  useEffect(() => {
    dispatch(getOrder(id));
  }, []);
  useEffect(() => {
    if (order?.order_item) {
      let s = 0;
      const items = order?.order_item?.map((v) => {
        const item = v?.sparePartDetail ? JSON.parse(v?.sparePartDetail) : {};
        s += v?.pricePerItem * v?.quantity;
        return {
          ...v,
          ...item,
        };
      });
      setSubTotal(s);
      const shippingAddress = tryParseJSONObject(order?.shippingAddress) || {
        addressLine1: order?.shippingAddress,
      };
      setOrderData({ ...order, order_item: items, shippingAddress });
    }
  }, [order]);
  return (
    <>
      <Row className="page-title">
        <Col md={12}>
          <PageTitle
            breadCrumbItems={[
              {
                label: "Orders",
                path: "/orders",
              },
              {
                label: `${"View"}`,
                path: `/orders/view/${id}`,
                active: true,
              },
            ]}
            title={`View order`}
          />
        </Col>
      </Row>
      <Card>
        <CardBody>
          {loading ? (
            <Spinner animation="border" />
          ) : (
            <div className="my-order-detail-root">
              <div className={` ${sm ? "mobile_view" : "desktop_view"} mb-5`}>
                <div className="panel panel-default">
                  <div className="rfqlistTittle">
                    <h2 className="mb-0">Order detail</h2>
                  </div>
                  <div className="">
                    <b>
                      <p className="p-0 m-0">
                        Ordered on{" "}
                        {moment(orderData?.created_at).format("DD-MMM-YYYY")}
                      </p>
                    </b>
                    <b>
                      <p className="p-0 m-0">Order# {orderData?.orderNo}</p>
                    </b>
                  </div>
                  <div className="mobile-order-list mb-3 pt-4">
                    <div className="row">
                      <div className="col-xl-5 col-md-5">
                        <span>
                          <b>Shipping Address</b>
                        </span>
                        <br />
                        {orderData?.shippingAddress?.addressTag && (
                          <>
                            <span className="badge bg-primary text-white">
                              <b>{orderData?.shippingAddress?.addressTag}</b>
                            </span>
                            <br />
                          </>
                        )}
                        {orderData?.shippingAddress?.contactPersonName && (
                          <>
                            <span>
                              <i className="fa fa-user me-2"></i>
                              {orderData?.shippingAddress?.contactPersonName}
                            </span>
                            <br />
                          </>
                        )}
                        <span className="d-flex">
                          <i className="fa fa-map-marker me-2"></i>{" "}
                          <span>
                            {[
                              orderData?.shippingAddress?.addressLine1,
                              orderData?.shippingAddress?.addressLine2,
                              orderData?.shippingAddress?.city?.name,
                              orderData?.shippingAddress?.province
                                ?.provinceName,
                              orderData?.shippingAddress?.country?.countryName,
                              orderData?.shippingAddress?.postalCode,
                            ]
                              .filter((v) => v)
                              .join(", ")}
                          </span>
                        </span>
                        {orderData?.shippingAddress?.contactNumber && (
                          <>
                            <span>
                              <i className="fa fa-phone me-2"></i>{" "}
                              {orderData?.shippingAddress?.contactNumberCode}-
                              {orderData?.shippingAddress?.contactNumber}
                            </span>
                            <br />
                          </>
                        )}
                      </div>
                      <div className="col-xl-3 col-md-3">
                        <b>
                          Payment Method
                          <br />
                          <span>{orderData?.paymentMode}</span>
                        </b>
                        <br />
                        <br />
                      </div>
                      <div className="col-xl-4 col-md-4">
                        <span>
                          <b>Order Summary</b>
                        </span>

                        <br />

                        <div className="d-table">
                          <div className="d-table-row">
                            <div className="d-table-cell px-1">
                              <b>
                                <span>Subtotal:</span>
                              </b>
                            </div>
                            <div className="d-table-cell px-1">
                              <span>AED {subTotal}</span>
                            </div>
                          </div>
                          <div className="d-table-row">
                            <div className="d-table-cell px-1">
                              <b>
                                <span>Shipping:</span>
                              </b>
                            </div>
                            <div className="d-table-cell px-1">
                              <span>AED {orderData?.deliveryFee}</span>
                            </div>
                          </div>
                          <div className="d-table-row">
                            <div className="d-table-cell px-1">
                              <b>Total:</b>
                            </div>
                            <div className="d-table-cell px-1">
                              <b>
                                AED{" "}
                                {subTotal + parseFloat(orderData?.deliveryFee)}
                              </b>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mobile-order-list mb-5">
                    <div className="d-flex justify-content-between flex-wrap">
                      <div className="">
                        <p className="order-id">
                          Expected Delivery{" "}
                          {orderData?.expectedDeliveryDate
                            ? moment(orderData?.expectedDeliveryDate).format(
                                "DD-MMM-YYYY"
                              )
                            : ": NA"}
                        </p>
                      </div>
                      <div>
                        <Link
                          target={"_blank"}
                          rel="noreferrer"
                          className="btn btn-primary btn-sm mb-2"
                          to={`/download-pdf?type=invoice&identifier=${orderData?.id}`}
                        >
                          DOWNLOAD INVOICE
                        </Link>
                      </div>
                    </div>
                    {orderData?.order_item?.map((v) => (
                      <div className="d-flex" key={`order-item-${v?.id}`}>
                        <div className="p-2">
                          <LoadAsyncImage
                            isPublic={true}
                            path={v?.photo}
                            id={v?.id}
                            className="order-item-image"
                          />
                        </div>
                        <div
                          className="p-2 p-2 d-flex justify-content-between flex-wrap"
                          style={{ flex: 1 }}
                        >
                          <div className="d-flex flex-column">
                            <span className="text-primary-2 order-item-no">
                              {v?.orderItemNo}
                            </span>
                            <span className="order-item-title">
                              {v?.itemName}
                            </span>
                            <span className="order-item-subtitle">
                              AED {v?.pricePerItem}
                            </span>
                          </div>
                          <div className="d-flex flex-column">
                            <span className="order-item-description">
                              <b>Qty:</b>
                              {v?.quantity}
                            </span>
                            <span className="order-item-description">
                              <b>Total:</b>
                              {v?.pricePerItem * v?.quantity}
                            </span>
                            <span className="order-item-description">
                              <b>Warranty:</b>
                              {v?.warranty || 0} Days
                            </span>
                            <span className="order-item-description">
                              <b>Status:</b>
                              {ORDER_STATUS[v?.orderStatus]}
                            </span>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          )}
          {/* <Row>
            <Col md={12} className="position-relative">
              {loading && <Loader />}
              {error && typeof error === "string" && (
                <Row>
                  <Col>
                    {
                      <Alert color="danger" isOpen={error ? true : false}>
                        <div>{error}</div>
                      </Alert>
                    }
                  </Col>
                </Row>
              )}
              <Row>
                <Col xs={12} md={6} lg={6}>
                  <FormGroup>
                    <h6 for="role">Role</h6>
                    <p>{defaultValue.role?.role_name}</p>
                  </FormGroup>
                </Col>
                <Col xs={12} md={6} lg={6}>
                  <FormGroup>
                    <h6 for="text">Full name</h6>
                    <p>{defaultValue?.name}</p>
                  </FormGroup>
                </Col>
                <Col xs={12} md={6} lg={6}>
                  <FormGroup>
                    <h6 for="text">Email</h6>
                    <p>{defaultValue?.email}</p>
                  </FormGroup>
                </Col>
                <Col xs={12} md={6} lg={6}>
                  <FormGroup>
                    <h6 for="text">Phone number</h6>
                    <p>{defaultValue?.phoneNumber}</p>
                  </FormGroup>
                </Col>
                <Col xs={12} md={6} lg={6}>
                  <FormGroup>
                    <h6 for="status">Status</h6>
                    <p>
                      <Status status={defaultValue?.status} />
                    </p>
                  </FormGroup>
                </Col>
              </Row>
            </Col>
          </Row> */}
        </CardBody>
      </Card>
    </>
  );
};

export default ViewOrders;
