import IconButton from "../IconButton/IconButton";
import { Button, Modal } from "react-bootstrap";
import { MinusSquare } from "react-feather";
const CustomModal = ({
  title,
  open,
  openModal,
  setOpen,
  closeModal,
  children,
  handleOnClose,
  cancelButtonText,
  submitButtonText,
  renderFooterButtons,
  noFooter = false,
  onSubmit,
  disableSubmit = false,
  onCancel,
  noCloseIcon = false,
  ...props
}) => {
  const handleSubmit = () => {
    closeModal();
    onSubmit();
  };
  return (
    <Modal
      show={open}
      contentClassName={props?.fullscreen ? "modal-content-fullscreen" : ""}
      onExit={handleOnClose || null}
      {...props}
    >
      <Modal.Header>
        <div className="d-flex">
          {title && <span>{title}</span>}
          {!noCloseIcon && (
            <IconButton onClick={closeModal}>
              <MinusSquare color="black" />
            </IconButton>
          )}
        </div>
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
      {!noFooter &&
        (renderFooterButtons ? (
          <Modal.Footer>{renderFooterButtons}</Modal.Footer>
        ) : (
          <Modal.Footer>
            <Button onClick={closeModal}>{cancelButtonText || "Cancel"}</Button>
            <Button onClick={handleSubmit} disabled={disableSubmit}>
              {submitButtonText || "Submit"}
            </Button>
          </Modal.Footer>
        ))}
    </Modal>
  );
};

export default CustomModal;
