import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Alert, Button, Col, Row } from "reactstrap";
import PageTitle from "./PageTitle";
import Actions from "./table/Actions";
import Table from "./table/Table";
import useColumn from "./table/useColumn";

const ListView = ({
  columnData,
  deleteAction,
  getAction,
  baseUrl,
  actionAccess,
  breadCrumbLabel,
  breadCrumbTitle,
  reducerName,
  canCellEdit,
  renderAction,
}) => {
  const { columns, toggleFilter } = useColumn({
    columnData,
    baseUrl,
    Actions,
    deleteAction,
    actionAccess,
    renderAction,
  });
  const dispatch = useDispatch();
  const [pagination, setPagination] = useState({
    page: 1,
    searchText: "",
    sizePerPage: 10,
    sortField: "id",
    sortOrder: "asc",
    filters: [],
  });
  const { data, error, loading } = useSelector((state) => state?.[reducerName]);
  useEffect(() => {
    dispatch(getAction(pagination));
  }, []);
  const handleTableChange = (e, v) => {
    console.log(e, v);
    const { page, searchText, sizePerPage, sortField, sortOrder, filters } = v;
    setPagination({
      page,
      searchText,
      sizePerPage,
      sortField,
      sortOrder,
      filters,
    });
    dispatch(
      getAction({
        page,
        searchText,
        sizePerPage,
        sortField,
        sortOrder,
        filters,
      })
    );
  };

  return (
    <>
      <Row className="page-title">
        <Col md={12}>
          <PageTitle
            breadCrumbItems={[
              {
                label: breadCrumbLabel,
                path: baseUrl,
              },
            ]}
            title={breadCrumbTitle}
          />
        </Col>
      </Row>
      {error && <Alert color="danger">Oops something went wrong</Alert>}
      <Table
        loading={loading}
        records={data?.data}
        columns={columns}
        handleTableChange={handleTableChange}
        addUrl={`${baseUrl}/add`}
        pagination={{ ...pagination, totalSize: data?.total }}
        // toggleFilter={toggleFilter}
        canCellEdit={canCellEdit}
      />
    </>
  );
};

export default ListView;
