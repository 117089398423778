import { createSelector } from "reselect";

export const supplierDetailSelector = createSelector(
  (state) => state.supplierBusiness.supplier,
  (data) => ({ ...data })
);

export const addRfqSelector = createSelector(
  (state) => state.Rfq.addRfq,
  (data) => ({ ...data })
);
