import useMediaQuery from "beautiful-react-hooks/useMediaQuery";

const useBreakpoint = () => {
  const xs = useMediaQuery("( min-width: 0px and max-width: 575px)");
  const sm = useMediaQuery("(min-width: 576px )");
  const md = useMediaQuery("(min-width: 768px)");
  const lg = useMediaQuery("(min-width: 992px)");
  const xl = useMediaQuery("(min-width: 1200px)");
  const xxl = useMediaQuery("(min-width: 1400px)");
  return { xs, sm, md, lg, xl, xxl };
};

export default useBreakpoint;
