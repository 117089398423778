import { forwardRef } from "react";
import { StyledButton } from "./IconButtonStyled.js";

const IconButton = (props) => {
  const { children, ref, ...rest } = props;
  return (
    <StyledButton ref={ref} variant="flat" {...rest}>
      {children}
    </StyledButton>
  );
};

export default forwardRef((props, ref) => <IconButton ref={ref} {...props} />);
