import { PDFDownloadLink, PDFViewer, usePDF } from "@react-pdf/renderer";
import { getCookie } from "../../utils/helpers";
import { Button } from "react-bootstrap";
import { notifyReact } from "../../services/mobileService";
import axios from "axios";
import { useEffect, useRef } from "react";

const GeneratePdf = ({
  children,
  name = "document.pdf",
  showPreview = false,
  autoDownload = false,
}) => {
  const isMobile = getCookie("mobile");
  const [pdf, setPdf] = usePDF({ document: children });
  const ref = useRef(null);
  const handleDownloadForApp = () => {
    notifyReact({ url: pdf.url, name }, "DOWNLOAD_INVOICE");
  };
  const download = async () => {
    await axios.get("http://localhost:8000/api/orders/downloadInvoice/36");
  };
  // useEffect(() => {
  //   if (autoDownload && !pdf.loading) {
  //     ref.current.click();
  //   }
  // }, [autoDownload, pdf.loading]);
  return (
    <>
      {isMobile ? (
        <Button onClick={handleDownloadForApp}>
          {pdf.loading ? "Generating File..." : "Download"}
        </Button>
      ) : (
        // <button onClick={download}>download</button>
        <PDFDownloadLink document={children} fileName={name}>
          {({ blob, url, loading, error }) => (
            <div ref={ref} className="btn btn-primary">
              <span>{loading ? "Generating File..." : "Download"}</span>
            </div>
          )}
        </PDFDownloadLink>
      )}
      {showPreview && (
        <PDFViewer style={{ height: "70vh", width: "100%" }}>
          {children}
        </PDFViewer>
      )}
    </>
  );
};

export default GeneratePdf;
