// @flow
import {
  SERVICE_PROVIDER_LOGIN_USER,
  SERVICE_PROVIDER_LOGIN_USER_SUCCESS,
  SERVICE_PROVIDER_LOGIN_USER_FAILED,
  SERVICE_PROVIDER_LOGOUT_USER,
  SERVICE_PROVIDER_REGISTER_USER,
  SERVICE_PROVIDER_REGISTER_USER_SUCCESS,
  SERVICE_PROVIDER_REGISTER_USER_FAILED,
  SERVICE_PROVIDER_FORGET_PASSWORD,
  SERVICE_PROVIDER_FORGET_PASSWORD_SUCCESS,
  SERVICE_PROVIDER_FORGET_PASSWORD_FAILED,
  SERVICE_PROVIDER_VERIFY_OTP,
  SERVICE_PROVIDER_VERIFY_OTP_SUCCESS,
  SERVICE_PROVIDER_VERIFY_OTP_FAILED,
} from "./constants";

export const loginUser = (email, password) => ({
  type: SERVICE_PROVIDER_LOGIN_USER,
  payload: { email, password },
});

export const loginUserSuccess = (user) => ({
  type: SERVICE_PROVIDER_LOGIN_USER_SUCCESS,
  payload: user,
});

export const loginUserFailed = (error) => ({
  type: SERVICE_PROVIDER_LOGIN_USER_FAILED,
  payload: error,
});

export const registerUser = (data, history) => ({
  type: SERVICE_PROVIDER_REGISTER_USER,
  payload: { data, history },
});

export const registerUserSuccess = (user) => ({
  type: SERVICE_PROVIDER_REGISTER_USER_SUCCESS,
  payload: user,
});

export const registerUserFailed = (error) => ({
  type: SERVICE_PROVIDER_REGISTER_USER_FAILED,
  payload: error,
});

export const logoutUser = (history, token) => ({
  type: SERVICE_PROVIDER_LOGOUT_USER,
  payload: { history, token },
});

export const forgetPassword = (username) => ({
  type: SERVICE_PROVIDER_FORGET_PASSWORD,
  payload: { username },
});

export const forgetPasswordSuccess = (passwordResetStatus) => ({
  type: SERVICE_PROVIDER_FORGET_PASSWORD_SUCCESS,
  payload: passwordResetStatus,
});

export const forgetPasswordFailed = (error) => ({
  type: SERVICE_PROVIDER_FORGET_PASSWORD_FAILED,
  payload: error,
});

export const verifyOtp = (payload) => ({
  type: SERVICE_PROVIDER_VERIFY_OTP,
  payload,
});

export const verifyOtpSuccess = (user) => ({
  type: SERVICE_PROVIDER_VERIFY_OTP_SUCCESS,
  payload: user,
});

export const verifyOtpFailed = (error) => ({
  type: SERVICE_PROVIDER_VERIFY_OTP_FAILED,
  payload: error,
});
