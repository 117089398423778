export const GET_CHAT_MESSAGES = "GET_CHAT_MESSAGES";
export const GET_CHAT_MESSAGES_SUCCESS = "GET_CHAT_MESSAGES_SUCCESS";
export const GET_CHAT_MESSAGES_FAILED = "GET_CHAT_FAILED";

export const GET_CHAT_ROOM = "GET_CHAT_ROOM";
export const GET_CHAT_ROOM_SUCCESS = "GET_CHAT_ROOM_SUCCESS";
export const GET_CHAT_ROOM_FAILED = "GET_CHAT_FAILED";

export const ADD_CHAT_ROOM = "ADD_CHAT_ROOM";
export const ADD_CHAT_ROOM_SUCCESS = "ADD_CHAT_ROOM_SUCCESS";
export const ADD_CHAT_ROOM_FAILED = "ADD_CHAT_ROOM_FAILED";

export const ADD_CHAT_MESSAGE = "ADD_CHAT_MESSAGE";
export const ADD_CHAT_MESSAGE_SUCCESS = "ADD_CHAT_MESSAGE_SUCCESS";
export const ADD_CHAT_MESSAGE_FAILED = "ADD_CHAT_MESSAGE_FAILED";

export const ADD_USER_TO_CHAT_ROOM = "ADD_USER_TO_CHAT_ROOM";
export const ADD_USER_TO_CHAT_ROOM_SUCCESS = "ADD_USER_TO_CHAT_ROOM_SUCCESS";
export const ADD_USER_TO_CHAT_ROOM_FAILED = "ADD_USER_TO_CHAT_ROOM_FAILED";

export const REMOVE_USER_FROM_CHAT_ROOM = "REMOVE_USER_FROM_CHAT_ROOM";
export const REMOVE_USER_FROM_CHAT_ROOM_SUCCESS =
  "REMOVE_USER_FROM_CHAT_ROOM_SUCCESS";
export const REMOVE_USER_FROM_CHAT_ROOM_FAILED = "REMOVE_USER_FROM_CHAT_FAILED";
