// @flow
import {
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_FAILED,
  LOGOUT_USER,
  REGISTER_USER,
  REGISTER_USER_SUCCESS,
  REGISTER_USER_FAILED,
  FORGET_PASSWORD,
  FORGET_PASSWORD_SUCCESS,
  FORGET_PASSWORD_FAILED,
  PARTNER_LOGIN_USER,
  PARTNER_LOGIN_USER_FAILED,
  PARTNER_LOGOUT_USER,
  PARTNER_REGISTER_USER,
  PARTNER_REGISTER_USER_SUCCESS,
  PARTNER_REGISTER_USER_FAILED,
  PARTNER_FORGET_PASSWORD,
  PARTNER_FORGET_PASSWORD_SUCCESS,
  PARTNER_FORGET_PASSWORD_FAILED,
  PARTNER_VERIFY_OTP,
  PARTNER_VERIFY_OTP_SUCCESS,
  PARTNER_VERIFY_OTP_FAILED,
  PARTNER_LOGIN_USER_WITH_PHONE_SUCCESS,
  PARTNER_LOGIN_USER_WITH_EMAIL_SUCCESS,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILED,
  GOOGLE_LOGIN,
  GOOGLE_LOGIN_SUCCESS,
  GOOGLE_LOGIN_FAILED,
  ACTIVATE_USER,
  ACTIVATE_USER_SUCCESS,
  ACTIVATE_USER_FAILED,
  LOGOUT_USER_SUCCESS,
  LOGOUT_USER_FAILED,
} from "./constants";

import { getLoggedInUser } from "../../helpers/authUtils";

const INIT_STATE = {
  user: getLoggedInUser(),
  loading: false,
  otpData: null,
  forgotPasswordStatus: null,
  resetPasswordStatus: null,
  activateUserStatus: null,
};

const Auth = (state = INIT_STATE, action) => {
  switch (action.type) {
    case LOGIN_USER:
      return { ...state, loading: true };
    case LOGIN_USER_SUCCESS:
      return { ...state, user: action.payload, loading: false, error: null };
    case LOGIN_USER_FAILED:
      return { ...state, error: action.payload, loading: false };
    case REGISTER_USER:
      return { ...state, loading: true };
    case REGISTER_USER_SUCCESS:
      return { ...state, user: action.payload, loading: false, error: null };
    case REGISTER_USER_FAILED:
      return { ...state, error: action.payload, loading: false };
    case LOGOUT_USER:
      return { ...state, loading: true };
    case LOGOUT_USER_SUCCESS:
      return { ...state, user: null, loading: false };
    case LOGOUT_USER_FAILED:
      return { ...state, error: action.payload, loading: false };
    case FORGET_PASSWORD:
      return { ...state, forgotPasswordStatus: null, loading: true };
    case FORGET_PASSWORD_SUCCESS:
      return {
        ...state,
        forgotPasswordStatus: action.payload,
        loading: false,
        error: null,
      };
    case FORGET_PASSWORD_FAILED:
      return { ...state, error: action.payload, loading: false };
    case RESET_PASSWORD:
      return { ...state, resetPasswordStatus: null, loading: true };
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        resetPasswordStatus: action.payload,
        loading: false,
        error: null,
      };
    case RESET_PASSWORD_FAILED:
      return { ...state, error: action.payload, loading: false };

    case ACTIVATE_USER:
      return { ...state, activateUserStatus: null, loading: true };
    case ACTIVATE_USER_SUCCESS:
      return {
        ...state,
        activateUserStatus: action.payload,
        loading: false,
        error: null,
      };
    case ACTIVATE_USER_FAILED:
      return { ...state, error: action.payload, loading: false };
    //parnter case
    case PARTNER_LOGIN_USER:
      return { ...state, loading: true };
    case PARTNER_LOGIN_USER_WITH_PHONE_SUCCESS:
      return { ...state, user: action.payload, loading: false, error: null };
    case PARTNER_LOGIN_USER_WITH_EMAIL_SUCCESS:
      return { ...state, user: action.payload, loading: false, error: null };
    case PARTNER_LOGIN_USER_FAILED:
      return { ...state, error: action.payload, loading: false };
    case PARTNER_REGISTER_USER:
      return { ...state, loading: true };
    case PARTNER_REGISTER_USER_SUCCESS:
      return { ...state, otpData: action.payload, loading: false, error: null };
    case PARTNER_REGISTER_USER_FAILED:
      return { ...state, error: action.payload, loading: false };
    case PARTNER_LOGOUT_USER:
      return { ...state, user: null };
    case PARTNER_FORGET_PASSWORD:
      return { ...state, loading: true };
    case PARTNER_FORGET_PASSWORD_SUCCESS:
      return {
        ...state,
        passwordResetStatus: action.payload,
        loading: false,
        error: null,
      };
    case PARTNER_FORGET_PASSWORD_FAILED:
      return { ...state, error: action.payload, loading: false };

    case PARTNER_VERIFY_OTP:
      return { ...state, loading: true };
    case PARTNER_VERIFY_OTP_SUCCESS:
      return {
        ...state,
        user: action.payload,
        otpData: null,
        loading: false,
        error: null,
      };
    case PARTNER_VERIFY_OTP_FAILED:
      return { ...state, error: action.payload, loading: false };

    case GOOGLE_LOGIN:
      return { ...state, loading: true };
    case GOOGLE_LOGIN_SUCCESS:
      return { ...state, user: action.payload, loading: false, error: null };
    case GOOGLE_LOGIN_FAILED:
      return { ...state, error: action.payload, loading: false };
    default:
      return { ...state };
  }
};

export default Auth;
