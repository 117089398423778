import {
  EDIT_QUOTE_LIST,
  EDIT_QUOTE_LIST_FAILURE,
  EDIT_QUOTE_LIST_SUCCESS,
  FETCH_QUOTE_LIST,
  FETCH_QUOTE_LIST_FAILURE,
  FETCH_QUOTE_LIST_SUCCESS,
  SAVE_QUOTE_LIST,
  SAVE_QUOTE_LIST_FAILURE,
  SAVE_QUOTE_LIST_SUCCESS,
} from "./constants";
const initState = {
  data: {},
  error: null,
  loading: false,
  saveLoading: false,
};
const QuoteList = (state = initState, action) => {
  switch (action.type) {
    case FETCH_QUOTE_LIST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_QUOTE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null,
      };
    case FETCH_QUOTE_LIST_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case SAVE_QUOTE_LIST:
      return {
        ...state,
        saveLoading: true,
      };
    case SAVE_QUOTE_LIST_SUCCESS:
      return {
        ...state,
        saveLoading: false,
        data: state?.data?.data
          ? { ...state?.data, data: [action.payload, ...state?.data?.data] }
          : { ...state?.data, data: [action.payload] },
        error: null,
      };
    case SAVE_QUOTE_LIST_FAILURE:
      return {
        ...state,
        error: action.payload,
        saveLoading: false,
      };
    case EDIT_QUOTE_LIST:
      return {
        ...state,
        saveLoading: true,
      };
    case EDIT_QUOTE_LIST_SUCCESS:
      return {
        ...state,
        saveLoading: false,
        data: {
          ...state?.data,
          data: state?.data?.data.map((v) =>
            v.id === action.payload.id ? action.payload : v
          ),
        },
        error: null,
      };
    case EDIT_QUOTE_LIST_FAILURE:
      return {
        ...state,
        error: action.payload,
        saveLoading: false,
      };
    default:
      return state;
  }
};

export default QuoteList;
