import {
  all,
  call,
  fork,
  put,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";

import { authRequest } from "../../helpers/api";
import { appNotification } from "../app/actions";
import {
  deleteServiceProviderBusinessFailed,
  deleteServiceProviderBusinessSuccess,
  getServiceProviderBusinessesFailed,
  getServiceProviderBusinessesSuccess,
  getServiceProviderBusinessFailed,
  getServiceProviderBusinessSuccess,
  saveServiceProviderBusinessFailed,
  saveServiceProviderBusinessSuccess,
  updateServiceProviderBusinessStatusFailed,
  updateServiceProviderBusinessStatusSuccess,
} from "./actions";
import {
  DELETE_SERVICE_PROVIDER_BUSINESS,
  SAVE_SERVICE_PROVIDER_BUSINESS,
  SERVICE_PROVIDER_BUSINESS,
  GET_SERVICE_PROVIDER_BUSINESS,
  UPDATE_SERVICE_PROVIDER_BUSINESS_STATUS,
} from "./constants";
const base_url = `/service-provider-business`;
function* getServiceProviderBusinesses({ payload }) {
  try {
    let url = `${base_url}?`;
    if (payload)
      Object.keys(payload).map(
        (params) =>
          payload?.[params] && (url += `${params}=${payload[params]}&`)
      );
    const response = yield call(authRequest, url.slice(0, url.length - 1), {
      method: "get",
    });
    yield put(getServiceProviderBusinessesSuccess(response?.data));
  } catch (error) {
    let message = "Failed to get service provider business";
    yield put(getServiceProviderBusinessesFailed(message));
  }
}

function* saveServiceProviderBusiness({ payload: { data, history } }) {
  try {
    const formData = new FormData();
    Object.keys(data).map((key) => formData.append(key, data[key]));
    let url = `${base_url}`;
    if (data?.id) url += `/${data?.id}?_method=PUT`;

    const response = yield call(authRequest, url, {
      method: "post",
      data: formData,
      headers: {
        "Content-type": "multipart/form-data",
        Accept: "application/json",
      },
    });
    yield put(saveServiceProviderBusinessSuccess(response?.data));
    yield put(appNotification({ type: "success", message: response?.message }));
    history.goBack();
  } catch (error) {
    let message = "Failed to save user data";
    if (error?.errors)
      yield put(saveServiceProviderBusinessFailed(error?.errors));
    else yield put(saveServiceProviderBusinessFailed(message));
  }
}
function* getServiceProviderBusiness({ payload: { id } }) {
  try {
    const response = yield call(authRequest, `${base_url}/${id}`, {
      method: "get",
    });
    yield put(getServiceProviderBusinessSuccess(response?.data));
  } catch (error) {
    let message = "Failed to get service provider business";
    yield put(getServiceProviderBusinessFailed(message));
  }
}
function* deleteserviceProviderBusiness({ payload: { id } }) {
  try {
    const response = yield call(authRequest, `${base_url}/${id}`, {
      method: "delete",
    });
    yield put(appNotification({ type: "success", message: response?.message }));
    yield put(deleteServiceProviderBusinessSuccess({ id }));
  } catch (error) {
    console.log("error", error);
    let message = "Failed to get service provider business";
    yield put(deleteServiceProviderBusinessFailed(message));
  }
}

function* updateServiceProviderBusinessStatusSaga({ payload: { status, id } }) {
  try {
    const response = yield call(
      authRequest,
      `/service-provider-business/updateServiceProviderBusinessStatus/${id}?_method=PUT`,
      {
        method: "post",
        data: { status },
      }
    );
    yield put(appNotification({ type: "success", message: response?.message }));
    yield put(updateServiceProviderBusinessStatusSuccess({ id }));
  } catch (error) {
    console.log("error", error);
    let message = "Failed to get businesses";
    yield put(updateServiceProviderBusinessStatusFailed(message));
  }
}

export function* watchGetserviceProviderBusinesses() {
  yield takeLatest(SERVICE_PROVIDER_BUSINESS, getServiceProviderBusinesses);
}
export function* watchGetserviceProviderBusiness() {
  yield takeEvery(GET_SERVICE_PROVIDER_BUSINESS, getServiceProviderBusiness);
}
export function* watchSaveserviceProviderBusiness() {
  yield takeEvery(SAVE_SERVICE_PROVIDER_BUSINESS, saveServiceProviderBusiness);
}
export function* watchDeleteserviceProviderBusiness() {
  yield takeEvery(
    DELETE_SERVICE_PROVIDER_BUSINESS,
    deleteserviceProviderBusiness
  );
}
export function* watchUpdateServiceProviderBusinessStatus() {
  yield takeEvery(
    UPDATE_SERVICE_PROVIDER_BUSINESS_STATUS,
    updateServiceProviderBusinessStatusSaga
  );
}
function* sparePartsCategorySaga() {
  yield all([
    fork(watchGetserviceProviderBusinesses),
    fork(watchSaveserviceProviderBusiness),
    fork(watchGetserviceProviderBusiness),
    fork(watchDeleteserviceProviderBusiness),
    fork(watchUpdateServiceProviderBusinessStatus),
  ]);
}

export default sparePartsCategorySaga;
