import { Briefcase } from "react-feather";
import PrivateRoute from "../components/PrivateRoute";
import AddEditShop from "../pages/shop/AddEditShop";
import ListShop from "../pages/shop/ListShop";
import ViewShop from "../pages/shop/ViewShop";

const shops = [
  {
    path: "/supplier/business",
    name: "Supplier business",
    component: ListShop,
    icon: Briefcase,
    roles: ["admin", "supplier"],
    exact: true,
    route: PrivateRoute,
  },
  {
    path: "/supplier/business/add",
    component: AddEditShop,
    roles: ["admin", "supplier"],
    route: PrivateRoute,
  },
  {
    path: "/supplier/business/edit/:id",
    component: AddEditShop,
    roles: ["admin", "supplier"],
    route: PrivateRoute,
  },
  {
    path: "/supplier/business/view/:id",
    component: ViewShop,
    roles: ["admin", "supplier"],
    route: PrivateRoute,
  },
];

const supplierRoutes = [...shops];
export default supplierRoutes;
