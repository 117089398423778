import {
    DELETE_CAR_TRIM,
    DELETE_CAR_TRIM_FAILED,
    DELETE_CAR_TRIM_SUCCESS,
    EDIT_CAR_TRIM,
    EDIT_CAR_TRIM_FAILED,
    EDIT_CAR_TRIM_SUCCESS,
    SAVE_CAR_TRIM,
    SAVE_CAR_TRIM_FAILED,
    SAVE_CAR_TRIM_SUCCESS,
    CAR_TRIM,
    CAR_TRIM_FAILED,
    CAR_TRIM_SUCCESS,
    GET_CAR_TRIM,
    GET_CAR_TRIM_SUCCESS,
    GET_CAR_TRIM_FAILED,
  } from "./constants";

  export const getCarTrim = (id) => ({
    type: GET_CAR_TRIM,
    payload: { id },
  });

  export const getCarTrimSuccess = (data) => ({
    type: GET_CAR_TRIM_SUCCESS,
    payload: data,
  });

  export const getCarTrimFailed = (errorMessage) => ({
    type: GET_CAR_TRIM_FAILED,
    payload: errorMessage,
  });

  export const getCarTrims = (payload) => ({
    type: CAR_TRIM,
    payload,
  });

  export const getCarTrimsSuccess = (data) => ({
    type: CAR_TRIM_SUCCESS,
    payload: { data },
  });

  export const getCarTrimsFailed = (errorMessage) => ({
    type: CAR_TRIM_FAILED,
    payload: errorMessage,
  });

  export const saveCarTrim = ({ data, history }) => ({
    type: SAVE_CAR_TRIM,
    payload: { data, history },
  });

  export const saveCarTrimSuccess = (success) => ({
    type: SAVE_CAR_TRIM_SUCCESS,
    payload: success,
  });

  export const saveCarTrimFailed = (errorMessage) => ({
    type: SAVE_CAR_TRIM_FAILED,
    payload: errorMessage,
  });

  export const editCarTrim = (data) => ({
    type: EDIT_CAR_TRIM,
    payload: data,
  });

  export const editCarTrimSuccess = (success) => ({
    type: EDIT_CAR_TRIM_SUCCESS,
    payload: success,
  });

  export const editCarTrimFailed = (errorMessage) => ({
    type: EDIT_CAR_TRIM_FAILED,
    payload: errorMessage,
  });

  export const deleteCarTrim = (data) => ({
    type: DELETE_CAR_TRIM,
    payload: data,
  });

  export const deleteCarTrimSuccess = (success) => ({
    type: DELETE_CAR_TRIM_SUCCESS,
    payload: success,
  });

  export const deleteCarTrimFailed = (errorMessage) => ({
    type: DELETE_CAR_TRIM_FAILED,
    payload: errorMessage,
  });
