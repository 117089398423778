export const SERVICE_PROVIDER_BUSINESS = "SERVICE_PROVIDER_BUSINESS";
export const SERVICE_PROVIDER_BUSINESS_SUCCESS =
  "SERVICE_PROVIDER_BUSINESS_SUCCESS";
export const SERVICE_PROVIDER_BUSINESS_FAILED =
  "SERVICE_PROVIDER_BUSINESS_FAILED";
export const SAVE_SERVICE_PROVIDER_BUSINESS = "SAVE_SERVICE_PROVIDER_BUSINESS";
export const SAVE_SERVICE_PROVIDER_BUSINESS_SUCCESS =
  "SAVE_SERVICE_PROVIDER_BUSINESS_SUCCESS";
export const SAVE_SERVICE_PROVIDER_BUSINESS_FAILED =
  "SAVE_SERVICE_PROVIDER_BUSINESS_FAILED";
export const EDIT_SERVICE_PROVIDER_BUSINESS = "EDIT_SERVICE_PROVIDER_BUSINESS";
export const EDIT_SERVICE_PROVIDER_BUSINESS_SUCCESS =
  "EDIT_SERVICE_PROVIDER_BUSINESS_SUCCESS";
export const EDIT_SERVICE_PROVIDER_BUSINESS_FAILED =
  "EDIT_SERVICE_PROVIDER_BUSINESS_FAILED";
export const DELETE_SERVICE_PROVIDER_BUSINESS =
  "DELETE_SERVICE_PROVIDER_BUSINESS";
export const DELETE_SERVICE_PROVIDER_BUSINESS_SUCCESS =
  "DELETE_SERVICE_PROVIDER_BUSINESS_SUCCESS";
export const DELETE_SERVICE_PROVIDER_BUSINESS_FAILED =
  "DELETE_SERVICE_PROVIDER_BUSINESS_FAILED";
export const GET_SERVICE_PROVIDER_BUSINESS = "GET_SERVICE_PROVIDER_BUSINESS";
export const GET_SERVICE_PROVIDER_BUSINESS_SUCCESS =
  "GET_SERVICE_PROVIDER_BUSINESS_SUCCESS";
export const GET_SERVICE_PROVIDER_BUSINESS_FAILED =
  "GET_SERVICE_PROVIDER_BUSINESS_FAILED";

export const UPDATE_SERVICE_PROVIDER_BUSINESS_STATUS = "UPDATE_SERVICE_PROVIDER_BUSINESS_STATUS";
export const UPDATE_SERVICE_PROVIDER_BUSINESS_STATUS_SUCCESS = "UPDATE_SERVICE_PROVIDER_BUSINESS_STATUS_SUCCESS";
export const UPDATE_SERVICE_PROVIDER_BUSINESS_STATUS_FAILED = "UPDATE_SERVICE_PROVIDER_BUSINESS_STATUS_FAILED";
