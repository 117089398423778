import { authRequest } from "../../helpers/api";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { FETCH_CONTACT_QUERY } from "./actions";
import { fetchContactQuerySuccess } from "./actions";
import { appNotification } from "../app/actions";

function* fetchContactQuerySaga({ payload }) {
  try {
    let url = `/contact-query?`;
    if (payload)
      Object.keys(payload).map(
        (params) =>
          payload?.[params] && (url += `${params}=${payload[params]}&`)
      );
    const response = yield call(authRequest, url.slice(0, url.length - 1), {
      method: "get",
    });
    yield put(fetchContactQuerySuccess(response?.data));
  } catch (e) {
    yield put(
      appNotification({ type: "danger", message: "Oops something went wrong" })
    );
  }
}
export function* watchContactQuery() {
  yield takeEvery(FETCH_CONTACT_QUERY, fetchContactQuerySaga);
}

function* contactQuerySaga() {
  yield all([fork(watchContactQuery)]);
}
export default contactQuerySaga;
