import jsonToFormData from "json-form-data";

import {
  all,
  call,
  fork,
  put,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";

import { authRequest } from "../../helpers/api";
import {
  addRfqRequestApi,
  getRfqDetailApi,
  getRfqDetailResponsesApi,
} from "../../services/api";
import { appNotification } from "../app/actions";
import {
  addRfqRequestFailed,
  addRfqRequestSuccess,
  getListRfqFailed,
  getListRfqSuccess,
  getRfqFailed,
  getRfqRequestDetailFailed,
  getRfqRequestDetailResponsesFailed,
  getRfqRequestDetailResponsesSuccess,
  getRfqRequestDetailSuccess,
  getRfqSuccess,
  getRfqSupplierFailed,
  getRfqSupplierSuccess,
  notifyRfqSupplierFailed,
  notifyRfqSupplierSuccess,
} from "./actions";
import {
  GET_RFQ,
  LIST_RFQ,
  GET_RFQ_SUPPLIER,
  NOTIFY_RFQ_SUPPLIER,
  GET_RFQ_REQUEST_DETAIL,
  GET_RFQ_REQUEST_DETAIL_RESPONSES,
  ADD_RFQ_REQUEST,
} from "./constants";

function* getListRfq({ payload }) {
  try {
    let url = `/admin/rfq?`;
    if (payload)
      Object.keys(payload).map(
        (params) =>
          payload?.[params] && (url += `${params}=${payload[params]}&`)
      );
    const response = yield call(authRequest, url.slice(0, url.length - 1), {
      method: "get",
    });
    console.log("response", response);
    yield put(getListRfqSuccess(response?.data));
  } catch (error) {
    let message = "Failed to get service category";
    yield put(getListRfqFailed(message));
  }
}

function* saveRfq({ payload: { data, history } }) {
  try {
    console.log("data", data);
    const formData = new FormData();
    Object.keys(data).map((key) => formData.append(key, data[key]));
    let url = `/admin/rfq`;
    if (data?.id) url += `/${data?.id}?_method=PUT`;

    const response = yield call(authRequest, url, {
      method: "post",
      data: formData,
      headers: {
        "content-type": "multipart/form-data",
      },
    });
    yield put(getListRfqSuccess(response?.data));
    yield put(appNotification({ type: "success", message: response?.message }));
    history.goBack();
  } catch (error) {
    let message = "Failed to get service category";
    yield put(getListRfqFailed(message));
  }
}
function* getRfq({ payload: { id } }) {
  try {
    const response = yield call(authRequest, `/admin/rfq/${id}`, {
      method: "get",
    });
    yield put(getRfqSuccess(response?.data));
  } catch (error) {
    let message = "Failed to get service category";
    yield put(getRfqFailed(message));
  }
}

function* getRfqSupplier({ payload }) {
  try {
    let url = `/admin/preferredSupplier`;
    let formData = jsonToFormData(payload);
    const response = yield call(authRequest, url, {
      method: "post",
      data: formData,
    });
    console.log("response", response);
    yield put(getRfqSupplierSuccess(response?.data));
  } catch (error) {
    let message = "Failed to get service category";
    yield put(getRfqSupplierFailed(message));
  }
}

function* notifyRfqSupplierSaga({ payload }) {
  try {
    let url = `/admin/notifyRfqSupplier`;
    const response = yield call(authRequest, url, {
      method: "post",
      data: payload,
    });
    console.log("response", response);
    yield put(notifyRfqSupplierSuccess(response?.data));
    yield put(appNotification({ type: "success", message: response?.data }));
  } catch (error) {
    let message = "Failed to notify supplier";
    yield put(notifyRfqSupplierFailed(message));
    yield put(appNotification({ type: "error", message }));
  }
}

function* getRfqRequestDetailSaga({ payload }) {
  try {
    const data = yield call(getRfqDetailApi, payload);
    yield put(getRfqRequestDetailSuccess(data?.data));
  } catch (error) {
    if (error?.response?.status != 422) {
      let message = "";
      if (error) message = "Oops something went wrong";
      yield put(getRfqRequestDetailFailed(error));
    } else {
      const { errors } = error?.response?.data;
      console.log("errors", errors);
      yield put(getRfqRequestDetailFailed(errors));
    }
  }
}

function* getRfqRequestDetailResponsesSaga({ payload }) {
  try {
    const data = yield call(getRfqDetailResponsesApi, payload);
    yield put(getRfqRequestDetailResponsesSuccess(data?.data));
  } catch (error) {
    if (error?.response?.status != 422) {
      let message = "";
      if (error) message = "Oops something went wrong";
      yield put(getRfqRequestDetailResponsesFailed(error));
    } else {
      const { errors } = error?.response?.data;
      console.log("errors", errors);
      yield put(getRfqRequestDetailResponsesFailed(errors));
    }
  }
}

function* addRfqRequestSaga({ payload }) {
  try {
    const data = yield call(addRfqRequestApi, payload?.payload);
    yield put(addRfqRequestSuccess(data?.data));
    payload.onSuccess(data);
  } catch (error) {
    console.log("error", error);
    if (error.response.status != 422) {
      let message = "";
      if (error) message = "Oops something went wrong";
      yield put(addRfqRequestFailed(message));
    } else {
      const { errors } = error?.response?.data;
      console.log("errors", errors);

      yield put(addRfqRequestFailed(errors));
    }
    if (error?.response?.status === 417) {
      appNotification({
        message: "Oop something went wrong",
        title: "Error",
        type: "danger",
      });
    }
  }
}

export function* watchgetRfqRequestDetailSaga() {
  yield takeLatest(GET_RFQ_REQUEST_DETAIL, getRfqRequestDetailSaga);
}
export function* watchRfqRequestDetailResponsesSaga() {
  yield takeLatest(
    GET_RFQ_REQUEST_DETAIL_RESPONSES,
    getRfqRequestDetailResponsesSaga
  );
}
export function* watchGetListRfq() {
  yield takeLatest(LIST_RFQ, getListRfq);
}
export function* watchGetRfqSupplier() {
  yield takeLatest(GET_RFQ_SUPPLIER, getRfqSupplier);
}
export function* watchGetRfq() {
  yield takeEvery(GET_RFQ, getRfq);
}
export function* watchNotifyRfqSupplier() {
  yield takeEvery(NOTIFY_RFQ_SUPPLIER, notifyRfqSupplierSaga);
}

export function* watchAddRfqRequestSaga() {
  yield takeLatest(ADD_RFQ_REQUEST, addRfqRequestSaga);
}
function* rfqSaga() {
  yield all([
    fork(watchGetListRfq),
    fork(watchGetRfqSupplier),
    fork(watchGetRfq),
    fork(watchNotifyRfqSupplier),
    fork(watchgetRfqRequestDetailSaga),
    fork(watchRfqRequestDetailResponsesSaga),
    fork(watchAddRfqRequestSaga),
  ]);
}

export default rfqSaga;
