import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import Select from "react-select";
import Loader from "../../../components/Loader";

import {
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  CustomInput,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  Label,
  Row,
} from "reactstrap";
import PageTitle from "../../../components/PageTitle";
import {
  AvForm,
  AvGroup,
  AvInput,
  AvFeedback,
  AvField,
} from "availity-reactstrap-validation";
import { getUser, saveUser } from "../../../redux/users/actions";
import { getCountryCode } from "../../../services/api";

const AddEditUsers = () => {
  const params = useParams();
  const {
    data: users,
    user,
    loading,
    error,
  } = useSelector((state) => state.Users);
  const { data: roles } = useSelector((state) => state.Roles);
  const [defaultValue, setDefaultValue] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    phoneCode: 971,
    pin: null,
    status: false,
    role: "",
    provider: `motonexa_${users?.roles?.[0]?.role_slug}`,
    provider_id: "motonexa.com",
  });
  const isEdit = !!params?.id;
  const history = useHistory();
  const [countryCode, setCountryCode] = useState([]);
  const dispatch = useDispatch();
  const toggleSwitch = (e) =>
    setDefaultValue((s) => ({ ...s, status: !defaultValue.status }));

  const _getCountryCode = async () => {
    const codes = await getCountryCode();
    setCountryCode(codes);
  };
  const handleSubmit = (e, values) => {
    e.preventDefault();
    let data = { ...values, status: values?.status === "true" };
    console.log(data);
    if (params?.id) data["id"] = params?.id;
    if (data?.role) data["role"] = data?.role?.id;
    dispatch(
      saveUser({
        data,
        history,
      })
    );
  };

  useEffect(() => {
    //edit actions
    if (params?.id) {
      dispatch(getUser(params?.id));
    }
    _getCountryCode();
  }, []);

  useEffect(() => {
    if (user && !loading) {
      //   setStatus(users?.status === 1);
      const { name, email, phoneNumber, roles, status } = user;

      setDefaultValue((s) => ({
        name,
        role: { id: roles?.[0]?.id, role_name: roles?.[0]?.role_name },
        email,
        phoneNumber,
        status,
      }));
    }
  }, [user, loading]);
  return (
    <>
      <Row className="page-title">
        <Col md={12}>
          <PageTitle
            breadCrumbItems={[
              {
                label: "Users",
                path: "/users",
              },
              {
                label: `${isEdit ? "Edit" : "Add new"}`,
                path: "/users/add",
                active: true,
              },
            ]}
            title={`${isEdit ? "Edit" : "Add new"} user`}
          />
        </Col>
      </Row>
      <Card>
        <CardBody>
          <Row>
            <Col md={12} className="position-relative">
              {loading && <Loader />}
              <AvForm onValidSubmit={handleSubmit}>
                {error && typeof error === "string" && (
                  <Row>
                    <Col>
                      {
                        <Alert color="danger" isOpen={error ? true : false}>
                          <div>{error}</div>
                        </Alert>
                      }
                    </Col>
                  </Row>
                )}
                <Row>
                  <Col xs={12} md={6} lg={6}>
                    <FormGroup>
                      <Label for="role">Role</Label>
                      <AvField
                        tag={Select}
                        name="role"
                        id="role"
                        placeholder="Select role"
                        value={defaultValue.role || ""}
                        options={roles}
                        style={{ zIndex: 2 }}
                        getOptionLabel={(s) => s?.role_name}
                        getOptionValue={(s) => s?.id}
                        onChange={(v) =>
                          setDefaultValue((s) => ({
                            ...s,
                            role: v,
                          }))
                        }
                        blurInputOnSelect
                      />

                      <span className="text-danger" style={{ fontSize: 11 }}>
                        {error?.role?.[0]}
                      </span>
                    </FormGroup>
                  </Col>
                  <Col xs={12} md={6} lg={6}>
                    <FormGroup>
                      <Label for="text">Full name</Label>
                      <AvInput
                        type="text"
                        name="name"
                        id="text"
                        placeholder="John Doe"
                        value={defaultValue?.name || ""}
                        required
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={12} md={6} lg={6}>
                    <FormGroup>
                      <Label for="text">Email</Label>
                      <AvInput
                        type="email"
                        name="email"
                        id="email"
                        placeholder="JohnDoe@example.com"
                        value={defaultValue?.email || ""}
                      />

                      <span className="text-danger" style={{ fontSize: 11 }}>
                        {error?.email?.[0]}
                      </span>
                    </FormGroup>
                  </Col>
                  <Col xs={12} md={6} lg={6}>
                    <FormGroup>
                      <AvGroup className="">
                        <Label for="phoneNumber">Phone number</Label>
                        <InputGroup>
                          <InputGroupAddon addonType="prepend">
                            {/* <span className="input-group-text">
                                    <PhoneIcon className="icon-dual" />
                                  </span> */}
                            <AvField
                              tag={Input}
                              type="select"
                              defaultValue={971}
                              name="phoneCode"
                              id="phoneCode"
                              className="input-group-text"
                            >
                              {countryCode?.length &&
                                countryCode.map(({ phone, countryCode }) => (
                                  <option value={phone} key={countryCode}>
                                    {phone}
                                  </option>
                                ))}
                            </AvField>
                          </InputGroupAddon>
                          <AvInput
                            type="text"
                            name="phoneNumber"
                            id="phoneNumber"
                            placeholder="9876543210"
                            required
                            className={
                              error?.phoneNumber?.[0] ? "is-invalid" : null
                            }
                          />
                        </InputGroup>
                      </AvGroup>
                    </FormGroup>
                  </Col>
                  <Col xs={12} md={6} lg={6}>
                    <FormGroup>
                      <Label for="phoneNumber">Pin</Label>
                      <InputGroup>
                        <AvInput
                          type="password"
                          name="pin"
                          id="pin"
                          placeholder="Enter 6 digit pin"
                          required
                          className={error?.pin?.[0] ? "is-invalid" : null}
                        />
                      </InputGroup>
                      <span className="text-danger" style={{ fontSize: 11 }}>
                        {error?.pin?.[0]}
                      </span>
                    </FormGroup>
                  </Col>
                  <Col xs={12} md={6} lg={6}>
                    <FormGroup>
                      <Label for="status">Status</Label>
                      <div>
                        <AvField
                          tag={CustomInput}
                          type="switch"
                          id="status"
                          name="status"
                          label="Active"
                          checked={defaultValue.status}
                          value={defaultValue.status}
                          onChange={toggleSwitch}
                        />
                      </div>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col className="d-flex justify-content-end">
                    <FormGroup>
                      <Button
                        type="button"
                        className="btn btn-light mr-1"
                        onClick={(e) => history.goBack()}
                      >
                        Cancel
                      </Button>
                      <Button color="primary">Save</Button>
                    </FormGroup>
                  </Col>
                </Row>
              </AvForm>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  );
};

export default AddEditUsers;
