export const USER = "USER";
export const USER_SUCCESS = "USER_SUCCESS";
export const USER_FAILED = "USER_FAILED";
export const SAVE_USER = "SAVE_USER";
export const SAVE_USER_SUCCESS = "SAVE_USER_SUCCESS";
export const SAVE_USER_FAILED = "SAVE_USER_FAILED";
export const EDIT_USER = "EDIT_USER";
export const EDIT_USER_SUCCESS = "EDIT_USER_SUCCESS";
export const EDIT_USER_FAILED = "EDIT_USER_FAILED";
export const DELETE_USER = "DELETE_USER";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_FAILED = "DELETE_USER_FAILED";
export const GET_USER = "GET_USER";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const GET_USER_FAILED = "GET_USER_FAILED";
export const UPDATE_USER_STATUS = "UPDATE_USER_STATUS";
export const UPDATE_USER_STATUS_SUCCESS = "UPDATE_USER_STATUS_SUCCESS";
export const UPDATE_USER_STATUS_FAILED = "UPDATE_USER_STATUS_FAILED";

export const UPDATE_USER_WHATSAPP_GROUP = "UPDATE_USER_WHATSAPP_GROUP";
export const UPDATE_USER_WHATSAPP_GROUP_SUCCESS = "UPDATE_USER_WHATSAPP_GROUP_SUCCESS";
export const UPDATE_USER_WHATSAPP_GROUP_FAILED = "UPDATE_USER_WHATSAPP_GROUP_FAILED";
