import React from "react";
import { CustomInput, Input, Label } from "reactstrap";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import RichTextEditor from "../RichTextEditor";
import Flatpickr from "react-flatpickr";
import FieldErrorText from "./FieldErrorText";
import "./formField.css";
import PhoneNumber from "../PhoneNumber/PhoneNumber";
import MutliCheckboxSelect from "../MultiCheckboxSelect/MultiCheckboxSelect";
import AsyncCreatableSelect from "react-select/async-creatable";
import AudioRecorder from "../AudioRecorder/AudioRecorder";
import FileUpload from "../FileUpload/FileUpload";

export const Field = ({ field, error }) => {
  switch (field?.type) {
    case "switch": {
      const { ref, activeLabel, ...rest } = field;
      return (
        <>
          <CustomInput {...rest} innerRef={ref} label={activeLabel} />
        </>
      );
    }
    case "asyncSelect":
      return (
        <>
          <AsyncSelect {...field} cacheOptions defaultOptions />
        </>
      );
    case "asyncCreatableSelect":
      return (
        <>
          <AsyncCreatableSelect {...field} cacheOptions defaultOptions />
        </>
      );
    case "select":
    case "createableSelect":
      return (
        <>
          <Select {...field} />
        </>
      );
    case "richText":
      return <RichTextEditor {...field} />;
    case "file": {
      const { ref, ...rest } = field;

      return <FileUpload {...rest} ref={ref} />;
    }
    case "phoneNumber": {
      const { ref, ...rest } = field;

      return (
        <>
          <PhoneNumber inputRef={ref} {...rest} />
        </>
      );
    }
    case "audio": {
      const { ref, ...rest } = field;

      return <AudioRecorder audioRef={ref} {...rest} />;
    }
    case "timePicker":
      return (
        <Flatpickr
          options={{
            enableTime: true,
            noCalendar: true,
            dateFormat: "H:i",
            time_24hr: false,
          }}
          onReady={(_, __, fp) => {
            fp.calendarContainer.classList.add("timePicker-dropdown");
          }}
          className="form-control"
          {...field}
        />
      );
    case "checkbox": {
      const { ref, ...rest } = field;
      return <CustomInput innerRef={ref} {...rest} />;
    }
    case "multiSelectCheckbox": {
      console.log("multiSelectCheckbox", field);
      return <MutliCheckboxSelect {...field} />;
    }
    default:
      return <Input {...field} />;
  }
};

export const formField = ({ field, error }) => {
  return (
    <>
      {field?.label && field?.type !== "phoneNumber" && (
        <Label for={field?.id}>{field.label}</Label>
      )}
      <Field field={field} error={error} />
      {error && <FieldErrorText error={error} />}
    </>
  );
};
