import React from "react";
import { Route } from "react-router-dom";
const VerifyOtp = React.lazy(() => import("../pages/partner/auth/VerifyOtp"));

const Confirm = React.lazy(() => import("../pages/partner/auth/Confirm"));
const ForgetPassword = React.lazy(() =>
  import("../pages/partner/auth/ForgetPassword")
);
const Login = React.lazy(() => import("../pages/partner/auth/Login"));
const Logout = React.lazy(() => import("../pages/partner/auth/Logout"));
const Register = React.lazy(() => import("../pages/partner/auth/Register"));

// auth
const partnerAuthRoutes = {
  path: "/partner",
  name: "partner",
  children: [
    {
      path: "/partner/login",
      name: "Login",
      component: Login,
      route: Route,
    },
    {
      path: "/partner/logout",
      name: "Logout",
      component: Logout,
      route: Route,
    },
    {
      path: "/partner/register",
      name: "Register",
      component: Register,
      route: Route,
    },
    {
      path: "/partner/confirm",
      name: "Confirm",
      component: Confirm,
      route: Route,
    },
    {
      path: "/partner/forget-password",
      name: "Forget Password",
      component: ForgetPassword,
      route: Route,
    },
    {
      path: "/partner/verify-otp",
      name: "Verify otp",
      component: VerifyOtp,
      route: Route,
    },
  ],
};

export default partnerAuthRoutes;
