import {
  DELETE_SERVICE_CATEGORY,
  DELETE_SERVICE_CATEGORY_FAILED,
  DELETE_SERVICE_CATEGORY_SUCCESS,
  EDIT_SERVICE_CATEGORY,
  EDIT_SERVICE_CATEGORY_FAILED,
  EDIT_SERVICE_CATEGORY_SUCCESS,
  SAVE_SERVICE_CATEGORY,
  SAVE_SERVICE_CATEGORY_FAILED,
  SAVE_SERVICE_CATEGORY_SUCCESS,
  SERVICE_CATEGORY,
  SERVICE_CATEGORY_FAILED,
  SERVICE_CATEGORY_SUCCESS,
  GET_SERVICE_CATEGORY,
  GET_SERVICE_CATEGORY_SUCCESS,
  GET_SERVICE_CATEGORY_FAILED,
} from "./constants";

export const getSeviceCategory = (id) => ({
  type: GET_SERVICE_CATEGORY,
  payload: { id },
});

export const getSeviceCategorySuccess = (data) => ({
  type: GET_SERVICE_CATEGORY_SUCCESS,
  payload: data,
});

export const getSeviceCategoryFailed = (errorMessage) => ({
  type: GET_SERVICE_CATEGORY_FAILED,
  payload: errorMessage,
});

export const getSeviceCategories = (payload) => ({
  type: SERVICE_CATEGORY,
  payload,
});

export const getSeviceCategoriesSuccess = (data) => ({
  type: SERVICE_CATEGORY_SUCCESS,
  payload: { data },
});

export const getSeviceCategoriesFailed = (errorMessage) => ({
  type: SERVICE_CATEGORY_FAILED,
  payload: errorMessage,
});

export const saveSeviceCategory = ({ data, history }) => ({
  type: SAVE_SERVICE_CATEGORY,
  payload: { data, history },
});

export const saveSeviceCategorySuccess = (success) => ({
  type: SAVE_SERVICE_CATEGORY_SUCCESS,
  payload: success,
});

export const saveSeviceCategoryFailed = (errorMessage) => ({
  type: SAVE_SERVICE_CATEGORY_FAILED,
  payload: errorMessage,
});

export const editSeviceCategory = (data) => ({
  type: EDIT_SERVICE_CATEGORY,
  payload: data,
});

export const editSeviceCategorySuccess = (success) => ({
  type: EDIT_SERVICE_CATEGORY_SUCCESS,
  payload: success,
});

export const editSeviceCategoryFailed = (errorMessage) => ({
  type: EDIT_SERVICE_CATEGORY_FAILED,
  payload: errorMessage,
});

export const deleteSeviceCategory = (data) => ({
  type: DELETE_SERVICE_CATEGORY,
  payload: data,
});

export const deleteSeviceCategorySuccess = (success) => ({
  type: DELETE_SERVICE_CATEGORY_SUCCESS,
  payload: success,
});

export const deleteSeviceCategoryFailed = (errorMessage) => ({
  type: DELETE_SERVICE_CATEGORY_FAILED,
  payload: errorMessage,
});
