export const ORDER_STATUS = {
  1: "Order placed",
  2: "Order confirmed",
  3: "Dispatched",
  4: "Delivered",
  5: "Rejected",
  6: "Cancelled",
  7: "Return requested",
  8: "Return approved",
  9: "Item picked up",
  10: "Item received",
  13: "Return initiated",
  11: "Return complete",
  12: "Return cancelled",
};
