// Create Document Component
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFDownloadLink,
  PDFViewer,
  Image,
} from "@react-pdf/renderer";

import { useEffect, useState } from "react";
import { tryParseJSONObject } from "../../utils/helpers";
import moment from "moment";
import { useSelector } from "react-redux";
import { userSelector } from "../../redux/users/selectors";
const Strike = ({ children }) => (
  <span style={{ textDecoration: "line-through" }}>{children}</span>
);

const Invoice = ({ data, ...props }) => {
  const [orderData, setOrderData] = useState(null);
  let subTotal = 0;
  useEffect(() => {
    if (data?.order_item) {
      const items = data?.order_item?.map((v) => {
        const item = v?.sparePartDetail ? JSON.parse(v?.sparePartDetail) : {};
        return {
          ...v,
          ...item,
        };
      });
      const shippingAddress = tryParseJSONObject(data?.shippingAddress) || {
        addressLine1: data?.shippingAddress,
      };
      setOrderData({ ...data, order_item: items, shippingAddress });
    }
  }, [data]);

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={{ display: "flex", flexDirection: "row" }}>
          <View style={styles.section}>
            <Image
              source={require("../../assets/images/logo.png")}
              style={{ width: 150 }}
            />
            <Text
              style={{ ...styles.text, ...styles.bold, marginVertical: 10 }}
            >
              Shipping Address
            </Text>
            {orderData?.shippingAddress?.contactPersonName && (
              <Text style={styles.text}>
                {orderData?.shippingAddress?.contactPersonName},
              </Text>
            )}
            <Text style={styles.smallText}>
              {[
                orderData?.shippingAddress?.addressLine1,
                orderData?.shippingAddress?.addressLine2,
                orderData?.shippingAddress?.country?.countryName ===
                "United Arab Emirates"
                  ? ""
                  : orderData?.shippingAddress?.city?.name,
                orderData?.shippingAddress?.province?.provinceName,
                orderData?.shippingAddress?.country?.countryName,
                orderData?.shippingAddress?.postalCode,
              ]
                .filter((v) => v)
                .join(", ")}
            </Text>
            {orderData?.shippingAddress?.contactNumber && (
              <Text style={styles.smallText}>
                Phone:{" "}
                {`${orderData?.shippingAddress?.contactNumberCode} - ${orderData?.shippingAddress?.contactNumber}`}
              </Text>
            )}
          </View>
          <View style={{ ...styles.section }}>
            <View style={{ display: "flex", alignSelf: "flex-end" }}>
              <Text style={{ ...styles.bold, ...styles.text, fontSize: 16 }}>
                INVOICE
              </Text>
              <Text> </Text>
              <Text style={styles.text}>Invoice # {orderData?.invoiceNo}</Text>
              <Text style={styles.text}>
                Date: {moment(orderData?.created_at).format("DD/MM/YYYY")}
              </Text>
            </View>
          </View>
        </View>
        <View style={styles.container}>
          <View style={styles.tableRow}>
            <View style={{ ...styles.tableHeaderCell, flex: 1 }}>
              <Text
                style={{
                  ...styles.smallText,
                  ...styles.bold,
                }}
              >
                S.No
              </Text>
            </View>
            <View style={{ ...styles.tableHeaderCell, flex: 15 }}>
              <Text
                style={{
                  ...styles.smallText,
                  ...styles.bold,
                }}
              >
                Description
              </Text>
            </View>
            <View style={{ ...styles.tableHeaderCell }}>
              <Text
                style={{
                  ...styles.smallText,
                  ...styles.bold,
                }}
              >
                Warranty (in days)
              </Text>
            </View>
            <View style={{ ...styles.tableHeaderCell }}>
              <Text
                style={{
                  ...styles.smallText,
                  ...styles.bold,
                }}
              >
                Qauntity
              </Text>
            </View>
            <View style={{ ...styles.tableHeaderCell }}>
              <Text
                style={{
                  ...styles.smallText,
                  ...styles.bold,
                }}
              >
                Price
              </Text>
            </View>
            <View style={{ ...styles.tableHeaderCell }}>
              <Text
                style={{
                  ...styles.smallText,
                  ...styles.bold,
                }}
              >
                Amount (AED)
              </Text>
            </View>
          </View>
          {orderData?.order_item?.map((v, i) => {
            if (
              v?.orderStatus > 4 &&
              (v?.orderStatus !== 7 || v?.orderStatus !== 12)
            ) {
              return;
            } else {
              subTotal += v?.pricePerItem * v?.quantity;
            }
            return (
              <View style={styles.tableRow} key={`order-${v?.id}`}>
                <Text
                  style={{
                    ...styles.tableCell,
                    ...styles.smallText,
                    flex: 1,
                  }}
                >
                  {i + 1}
                </Text>
                <Text
                  style={{ ...styles.tableCell, ...styles.smallText, flex: 15 }}
                >
                  {`${v?.rfq?.vehicle_make?.name} ${v?.rfq?.vehicle_model?.name} ${v?.rfq?.vehicle_year?.year} - ${v?.itemName}`}
                </Text>
                <Text style={{ ...styles.tableCell, ...styles.smallText }}>
                  {v?.rfq_response?.warranty}
                </Text>
                <Text style={{ ...styles.tableCell, ...styles.smallText }}>
                  {v?.quantity}
                </Text>
                <Text style={{ ...styles.tableCell, ...styles.smallText }}>
                  {v?.pricePerItem}
                </Text>
                <Text style={{ ...styles.tableCell, ...styles.smallText }}>
                  {v?.pricePerItem * v?.quantity}
                </Text>
              </View>
            );
          })}

          {7 - orderData?.order_item?.length > 0 &&
            [...Array(7 - orderData?.order_item?.length)].map((element, i) => (
              <View key={`empty-${i}`} style={{ ...styles.tableRow }}>
                <Text
                  style={{ ...styles.tableCell, ...styles.smallText, flex: 1 }}
                >
                  {" "}
                </Text>
                <Text style={{ ...styles.tableCell, flex: 15 }}></Text>
                <Text style={styles.tableCell}></Text>
                <Text style={styles.tableCell}></Text>
                <Text style={styles.tableCell}></Text>
                <Text style={styles.tableCell}></Text>
              </View>
            ))}
          <View style={styles.tableRow}>
            <Text style={{ ...styles.tableCell, flex: 1 }}></Text>
            <Text style={{ ...styles.tableCell, flex: 15 }}></Text>
            <Text style={styles.tableCell}></Text>
            <Text style={styles.tableCell}></Text>
            <Text style={{ ...styles.tableCell, ...styles.smallText }}>
              Subtotal
            </Text>
            <Text style={{ ...styles.tableCell, ...styles.smallText }}>
              {parseFloat(subTotal)}
            </Text>
          </View>
          <View style={styles.tableRow}>
            <Text style={{ ...styles.tableCell, flex: 1 }}></Text>
            <Text style={{ ...styles.tableCell, flex: 15 }}></Text>
            <Text style={styles.tableCell}></Text>
            <Text style={styles.tableCell}></Text>
            <Text style={{ ...styles.tableCell, ...styles.smallText }}>
              Courier
            </Text>
            <Text style={{ ...styles.tableCell, ...styles.smallText }}>
              {orderData?.deliveryFee}
            </Text>
          </View>
          <View style={styles.tableRow}>
            <Text style={{ ...styles.tableCell, flex: 1 }}></Text>
            <Text style={{ ...styles.tableCell, flex: 15 }}></Text>
            <Text style={styles.tableCell}></Text>
            <Text style={styles.tableCell}></Text>
            <Text style={{ ...styles.tableCell, ...styles.smallText }}>
              Total
            </Text>
            <Text style={{ ...styles.tableCell, ...styles.smallText }}>
              {parseFloat(subTotal) + parseFloat(orderData?.deliveryFee)}
            </Text>
          </View>
        </View>
        <View style={styles.signContainer}>
          <Text style={{ flex: 1 }}> </Text>
          <View style={{ alignSelf: "flex-end" }}>
            <Text style={styles.sign}>Digitally generated invoice</Text>
            {orderData?.created_by?.userCode.indexOf("AEM") === -1 && (
              <Text
                style={{
                  ...styles.smallText,
                  marginVertical: 5,
                  alignSelf: "center",
                }}
              >
                (Generated by {orderData?.created_by?.userCode})
              </Text>
            )}
          </View>
        </View>
        <View style={styles.footer}>
          <Text style={{ ...styles.smallText, textAlign: "center" }}>
            If you have any questions concerning this invoice, contact to 054
            3077933 or 025579715
          </Text>
          <Text style={styles.footerText}>
            Nimath Portal Co. Block-1, M45, Mussafah, Abu Dhabi, UAE
          </Text>
          <Text style={styles.footerText}>www.motonexa.com</Text>
        </View>
      </Page>
    </Document>
  );
};

export default Invoice;

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#ffffff",
    justifyContent: "space-between",
  },
  heading: {
    fontSize: 16,
  },
  bold: {
    fontWeight: "bold",
  },
  text: {
    fontSize: 12,
  },
  smallText: {
    fontSize: 10,
  },
  section: {
    margin: 10,
    padding: 10,
    flex: 1,
  },

  container: {
    flex: 1,
    padding: 16,
  },
  tableRow: {
    flexDirection: "row",
  },
  tableHeaderCell: {
    flex: 2,
    fontWeight: "bold",
    padding: 4,
    borderWidth: 1,
    borderColor: "#000",
  },
  tableCell: {
    flex: 2,
    padding: 4,
    borderWidth: 1,
    borderColor: "#000",
  },
  signContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginVertical: 10,
  },
  sign: {
    fontSize: 12,
    padding: 10,
    flex: 1,
    alignSelf: "flex-end",
  },
  footer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 20,
  },
  footerText: {
    fontSize: 10,
    color: "grey",
  },
});
