import React, { useCallback } from "react";
import { Controller, useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import { Button, Col, FormGroup, Row } from "reactstrap";
import { formField } from "./formField";
import { useHistory } from "react-router-dom";
import Joi from "../../services/joi";

export const schema = (fields) => {
  const validationObject = {};
  Object.keys(fields).forEach((field) => {
    validationObject[field] = fields?.[field].validationRules?.label(
      `${fields?.[field]?.label}` || "field"
    );
  });
  return Joi.object(validationObject);
};

const FormBuilder = ({ fields, onSubmit }) => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    reValidateMode: "onChange",
    mode: "onChange",
    resolver: joiResolver(schema(fields)),
  });
  const history = useHistory();
  const handleCancel = useCallback((e) => history.goBack(), []);
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Row>
        {Object.keys(fields).map((field) => {
          const { columnProps, validationRules, children, ...restProps } =
            fields?.[field];
          if (children) {
            children?.map((f, i) => {
              const { columnProps, validationRules, ...restProps } = f;
              return (
                <Col key={restProps?.name} {...columnProps}>
                  <FormGroup>
                    <Controller
                      control={control}
                      name={restProps?.name?.[i]}
                      render={({ field: fieldProps, fieldState: { error } }) =>
                        formField({
                          field: { ...restProps, ...fieldProps },
                          error,
                        })
                      }
                    />
                  </FormGroup>
                </Col>
              );
            });
          }
          return (
            <Col key={restProps?.name} {...columnProps}>
              <FormGroup>
                <Controller
                  control={control}
                  name={restProps.name}
                  render={({ field: fieldProps, fieldState: { error } }) =>
                    formField({
                      field: { ...restProps, ...fieldProps },
                      error,
                    })
                  }
                />
              </FormGroup>
            </Col>
          );
        })}
        {/* {renderFields.map((fieldVal) => {
          const { columnProps, ...restProps } = fieldVal;
          return (
            <Col {...fieldVal.columnProps}>
              <FormGroup>
                <Controller
                  control={control}
                  name={restProps.name}
                  render={({ field, fieldState: { error } }) =>
                    formField({
                      field: { ...restProps, ...field },
                      error,
                    })
                  }
                ></Controller>
              </FormGroup>
            </Col>
          );
        })} */}
      </Row>
      <Row>
        <Col className="d-flex justify-content-end">
          <FormGroup>
            <Button
              type="button"
              className="btn btn-light mr-1"
              onClick={handleCancel}
            >
              Cancel
            </Button>
            <Button color="primary" type="submit">
              Save
            </Button>
          </FormGroup>
        </Col>
      </Row>
    </form>
  );
};

export default FormBuilder;
