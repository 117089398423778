import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";

import {
  MainContainer,
  Sidebar,
  Search,
  ConversationList,
  Conversation,
} from "@chatscope/chat-ui-kit-react";
import { useState } from "react";
import ChatBox from "../ChatBox/ChatBox";
import { Spinner } from "react-bootstrap";

const Chat = ({
  selectedRoom,
  rooms = [],
  loading = true,
  currentUser,
}) => {
  const [activeRoom, setActiveRoom] = useState(selectedRoom || null);
  console.log(rooms);
  const handleRoomChange = (room) => (e) => {
    setActiveRoom(room);
  };
  if (loading)
    return (
      <div className="d-flex justify-content-center align-items-center">
        <Spinner animation="grow" />
        <span className="text-secondary mt-2">
          Loading chat, please wait...
        </span>
      </div>
    );
  return (
    <MainContainer responsive style={{ height: "75vh", width: "100%" }}>
      {!rooms?.length ? (
        <div className="d-flex flex-column justify-content-center align-items-center">
          <i
            className="fa fa-comments-o text-secondary"
            style={{ fontSize: 66 }}
          ></i>
          <h3 className="text-secondary">No chat available</h3>
        </div>
      ) : (
        <>
          {
            <Sidebar position="left" scrollable={false}>
              <Search placeholder="Search..." />
              <ConversationList>
                {rooms?.map((v) => (
                  <Conversation
                    key={`room-${v?.id}`}
                    name={v?.name}
                    lastSenderName={v?.messages?.[0]?.user?.name || "User"}
                    info={
                      v?.messages?.[0]?.type === "text"
                        ? v?.messages?.[0]?.value?.message
                        : v?.messages?.[0]?.type
                    }
                    onClick={handleRoomChange(v)}
                  ></Conversation>
                ))}
              </ConversationList>
            </Sidebar>
          }
          <ChatBox activeRoom={activeRoom} currentUser={currentUser} />
        </>
      )}
    </MainContainer>
  );
};

export default Chat;
