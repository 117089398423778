import { useDispatch } from "react-redux";
import ReactSelect from "react-select";
import { VERIFICATION_STATUS } from "../constants/index";
import { updateSupplierVerificationStatus } from "../redux/supplierBusiness/actions";

const VerificationStatusDropdown = ({
  value,
  businessId,
  onUpdate,
  ...rest
}) => {
  const dispatch = useDispatch();
  const options = Object.keys(VERIFICATION_STATUS).map((status) => ({
    label: VERIFICATION_STATUS?.[status],
    value: status,
  }));

  const handleChange = (v) => {
    dispatch(
      updateSupplierVerificationStatus({
        id: businessId,
        status: v?.value,
      })
    );
    if (onUpdate) onUpdate(v?.value);
  };
  return (
    <ReactSelect
      options={options}
      defualtValue={VERIFICATION_STATUS?.[value]}
      onChange={handleChange}
      {...rest}
    />
  );
};

export default VerificationStatusDropdown;
