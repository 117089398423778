import styled from 'styled-components';
import { Button } from 'reactstrap';

export const StyledButton = styled(Button)`
  align-items: center;
  border-radius: 50%;
  display: flex;
  height: 24px;
  justify-content: center;
  padding: 5px;
  width: 24px;
  background-color: #ffffff;
  border:none;
  :hover {
    background-color: #dcdcdc;
  }
  :active {
    background-color: #dcdcdc;
  }
`;
