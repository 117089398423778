// @flow
import {
    DELETE_CAR_SERIE,
    DELETE_CAR_SERIE_FAILED,
    DELETE_CAR_SERIE_SUCCESS,
    CAR_SERIE,
    CAR_SERIE_FAILED,
    CAR_SERIE_SUCCESS,
    GET_CAR_SERIE,
    GET_CAR_SERIE_FAILED,
    GET_CAR_SERIE_SUCCESS,
  } from "./constants";

  const INIT_STATE = {
    data: [],
    carSerie: {},
    loading: false,
    error: null,
  };

  const CarSerie = (state = INIT_STATE, action) => {
    switch (action.type) {
      case CAR_SERIE:
        return { ...state, carSerie: {}, loading: true };
      case CAR_SERIE_SUCCESS:
        return {
          ...state,
          ...action.payload,
          loading: false,
          error: null,
        };
      case CAR_SERIE_FAILED:
        return {
          ...state,
          error: action.payload,
          loading: false,
        };
      case GET_CAR_SERIE:
        return { ...state, loading: true };
      case GET_CAR_SERIE_SUCCESS:
        return {
          ...state,
          carSerie: action.payload,
          loading: false,
          error: null,
        };
      case GET_CAR_SERIE_FAILED:
        return { ...state, error: action.payload, loading: false };
      case DELETE_CAR_SERIE:
        return { ...state, loading: true };
      case DELETE_CAR_SERIE_SUCCESS: {
        return {
          ...state,
          data: {
            ...state?.data,
            total: state?.data?.total - 1,
            data: state?.data?.data?.filter((v) => v?.id !== action?.payload?.id),
          },
          loading: false,
          error: null,
        };
      }
      case DELETE_CAR_SERIE_FAILED:
        return { ...state, error: action.payload, loading: false };
      default:
        return { ...state };
    }
  };

  export default CarSerie;
