import { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import Select, { components } from "react-select";
import { FormCheck, Spinner } from "react-bootstrap";
import CustomModal from "../CustomModal/CustomModal";
import { useModal } from "../../hooks/useModal";
import useBreakpoint from "../../hooks/useBreakpoint";

const CustomOption = (props) => {
  let extendedProps = props;
  if (Object.keys(props).indexOf("data-") !== -1) {
    let dataAttr = {};
    Object.keys(props)
      .filter((v) => v?.indexOf("data-") !== -1)
      .forEach((dataAttrKey) => {
        dataAttr[dataAttrKey] = props[dataAttrKey];
      });
    extendedProps = {
      ...extendedProps,
      ...dataAttr,
    };
  }
  return <components.Option {...extendedProps} />;
};

const MutliCheckboxSelect = ({
  dropdownFieldName,
  dropdownFieldLabel,
  checkboxFieldName,
  checkboxFieldLabel,
  checkboxFieldLabelKey,
  checkboxFieldIdKey,
  selectedDropDown,
  selectedCheckbox,
  control,
  setValue,
  getValues,
  options,
  getOptionLabel,
  getOptionValue,
  fetchCheckboxOptionHandler,
  clear = false,
  isLoading,
  optionLabelKey = "label",
  optionValueKey = "value",
  groupLabelKey = "label",
  isGroupOption = false,
}) => {
  const { open, setOpen, onClose, onOpen } = useModal();
  const { lg } = useBreakpoint();
  const [checkboxOptions, setCheckboxOptions] = useState(null);
  const [selectedDropDownOptions, setSelectedDownOptions] =
    useState(selectedDropDown);
  const [selectedCheckboxOptions, setSelectedCheckboxOptions] = useState(
    selectedCheckbox || null
  );
  const [checkboxOptionLoader, setCheckboxOptionLoader] = useState(false);
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const onOpenModal = (value) => async (e) => {
    setMenuIsOpen(false);
    const [name, id] = value?.split("_");
    if (parseInt(id) > 0) onOpen();
    setCheckboxOptionLoader(true);
    const checkboxData = await fetchCheckboxOptionHandler(id);
    setCheckboxOptions((s) => ({
      ...s,
      headerTitle: { name, id },
      options: {
        ...s?.options,
        [id]: checkboxData,
      },
    }));
    setCheckboxOptionLoader(false);
  };
  const handleOptionFocus = (e) => {
    setMenuIsOpen(true);
  };
  const handleOptionBlur = (handler) => (e) => {
    handler(e);
    setMenuIsOpen(false);
  };
  const handleOptionChange = (handler) => async (val, actionMeta) => {
    handler(val);
    console.log("category", val);
    setSelectedDownOptions(val);

    if (actionMeta.action === "clear") {
      setSelectedCheckboxOptions(null);
      setValue(checkboxFieldName, null);
    }
    if (actionMeta.action === "select-option") {
      const currentValue = actionMeta?.option?.[optionValueKey];
      // setMenuIsOpen(true);
      if (currentValue > 0)
        onOpenModal(
          `${actionMeta?.option?.[optionLabelKey]}_${actionMeta?.option?.[optionValueKey]}`
        )(val);
    }
    if (actionMeta.action === "remove-value") {
      const currentValue2 = actionMeta?.removedValue?.[optionValueKey];
      const newCheckboxOptions = checkboxOptions;
      if (newCheckboxOptions?.options?.[currentValue2])
        delete newCheckboxOptions?.options?.[currentValue2];
      setCheckboxOptions((s) => ({ ...s, options: newCheckboxOptions }));
      const newselectedCheckboxOptions = selectedCheckboxOptions;
      if (
        newselectedCheckboxOptions?.[
          `${actionMeta?.removedValue?.[optionLabelKey]}_${currentValue2}`
        ]
      )
        delete newselectedCheckboxOptions?.[
          `${actionMeta?.removedValue?.[optionLabelKey]}_${actionMeta?.removedValue?.[optionValueKey]}`
        ];
      setSelectedCheckboxOptions(newselectedCheckboxOptions);
    }
  };
  const handleCloseModal = () => {
    setMenuIsOpen(false);
    if (
      !selectedCheckboxOptions?.[
        `${checkboxOptions?.headerTitle?.name}_${checkboxOptions?.headerTitle?.id}`
      ]
    ) {
      const currentDropDownData = getValues(dropdownFieldName)?.filter(
        (v) =>
          v?.[optionValueKey] !== parseInt(checkboxOptions?.headerTitle?.id)
      );
      setValue(dropdownFieldName, currentDropDownData);
      setSelectedDownOptions(currentDropDownData);
    }
  };
  const handleModalSubmit = () => {
    setValue(checkboxFieldName, selectedCheckboxOptions);
  };
  const handleSelectAllChange = (e) => {
    let selectedOptions = [];
    if (e.target.checked)
      selectedOptions = checkboxOptions?.options?.[
        checkboxOptions?.headerTitle?.id
      ]?.map((v, i) => v?.[checkboxFieldIdKey]);
    console.log("selected", selectedOptions);
    setSelectedCheckboxOptions((s) => ({
      ...s,
      [`${checkboxOptions?.headerTitle?.name}_${checkboxOptions?.headerTitle?.id}`]:
        selectedOptions,
    }));
  };
  const handleCheckboxOptionChange = (handler) => (e) => {
    handler(e);
    console.log(
      "header",
      `${checkboxOptions?.headerTitle?.name}_${checkboxOptions?.headerTitle?.id}`
    );
    if (e.target.checked)
      setSelectedCheckboxOptions((s) => ({
        ...s,
        [`${checkboxOptions?.headerTitle?.name}_${checkboxOptions?.headerTitle?.id}`]:
          s?.[
            `${checkboxOptions?.headerTitle?.name}_${checkboxOptions?.headerTitle?.id}`
          ]
            ? [
                ...s?.[
                  `${checkboxOptions?.headerTitle?.name}_${checkboxOptions?.headerTitle?.id}`
                ],
                parseInt(e.target.value),
              ]
            : [parseInt(e.target.value)],
      }));
    else
      setSelectedCheckboxOptions((s) => ({
        ...s,
        [`${checkboxOptions?.headerTitle?.name}_${checkboxOptions?.headerTitle?.id}`]:
          s?.[
            `${checkboxOptions?.headerTitle?.name}_${checkboxOptions?.headerTitle?.id}`
          ]?.filter((v) => v !== parseInt(e.target.value)),
      }));
  };

  useEffect(() => {
    if (selectedDropDown) {
      setValue(dropdownFieldName, selectedDropDown);
      setSelectedDownOptions(selectedDropDown);
    }
    if (selectedCheckbox) {
      setValue(checkboxFieldName, selectedCheckbox);
      setSelectedCheckboxOptions(selectedCheckbox);
    }
  }, [selectedCheckbox, selectedDropDown]);
  useEffect(() => {
    if (clear) {
      setValue(dropdownFieldName, null);
      setSelectedDownOptions(null);
      setValue(checkboxFieldName, null);
      setSelectedCheckboxOptions(null);
    }
  }, [clear]);

  return (
    <div>
      <div>
        <label htmlFor={dropdownFieldName}>{dropdownFieldLabel}</label>
        <Controller
          name={dropdownFieldName}
          control={control}
          render={({
            field: { onChange, onBlur, ...rest },
            fieldState: { error },
          }) => {
            return (
              <>
                <Select
                  {...rest}
                  isMulti
                  // menuIsOpen={menuIsOpen}
                  isLoading={isLoading || false}
                  getOptionLabel={getOptionLabel}
                  getOptionValue={getOptionValue}
                  value={selectedDropDownOptions}
                  formatGroupLabel={(data) => {
                    if (isGroupOption)
                      return (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <span>{data?.[groupLabelKey]}</span>
                          <span
                            style={{
                              fontWeight: "bold",
                              padding: "6px 10px",
                              background: "#ececec",
                              borderRadius: 15,
                            }}
                          >
                            {data.options.length}
                          </span>
                        </div>
                      );
                    return undefined;
                  }}
                  formatOptionLabel={(option) => {
                    const optionLabelValueKey = `${option?.[optionLabelKey]}_${option?.[optionValueKey]}`;
                    return selectedCheckboxOptions?.[optionLabelValueKey] ? (
                      <div onClick={onOpenModal(optionLabelValueKey)}>
                        {`${option?.[optionLabelKey]} ( ${
                          Object.keys(
                            selectedCheckboxOptions?.[optionLabelValueKey]
                          )?.length
                        }
                          )`}
                      </div>
                    ) : (
                      <span onClick={onOpenModal(optionLabelValueKey)}>
                        {option?.[optionLabelKey]}
                      </span>
                    );
                  }}
                  components={{
                    Option: CustomOption,
                  }}
                  options={options || []}
                  classNamePrefix="react-select"
                  placeholder={`Select ${dropdownFieldLabel}`}
                  onFocus={handleOptionFocus}
                  onBlur={handleOptionBlur(onBlur)}
                  onChange={handleOptionChange(onChange)}
                />
                <span className="text-danger" style={{ fontSize: 11 }}>
                  {error?.message}
                </span>
              </>
            );
          }}
        />
      </div>
      {/* <div className="form-group mb-2">
        <label htmlFor={checkboxFieldName}>{checkboxFieldLabel}</label>
        {selectedCheckboxOptions !== null && Object.keys(selectedCheckboxOptions)?.length ? (
          <div className="d-flex">
            {Object.keys(selectedCheckboxOptions)?.map(v => (
              <div
                key={`${checkboxFieldName}-selected-${v}`}
                style={{ padding: '5px 10px', borderRadius: 10, cursor: 'pointer', fontSize: 14 }}
                className="bg-primary me-1 text-white"
                onClick={onOpenModal(v)}>
                {`${v?.split('_')?.[0]} (${selectedCheckboxOptions?.[v]?.length})`}
              </div>
            ))}
          </div>
        ) : (
          <p className="font-size-12 text-secondary">{`Please select ${dropdownFieldLabel} first`}</p>
        )}
      </div> */}

      <CustomModal
        open={open}
        fullscreen
        closeModal={onClose}
        noCloseIcon
        onSubmit={handleModalSubmit}
        title={checkboxOptions?.headerTitle?.name}
        handleOnClose={handleCloseModal}
        disableSubmit={
          selectedCheckboxOptions?.[
            `${checkboxOptions?.headerTitle?.name}_${checkboxOptions?.headerTitle?.id}`
          ]?.length === 0
        }
      >
        {checkboxOptionLoader ? (
          <div className="d-flex align-items-center justify-content-center">
            <Spinner animation="border" />
          </div>
        ) : (
          <>
            <FormCheck
              label="Select all"
              value={checkboxOptions?.headerTitle?.id}
              onChange={handleSelectAllChange}
              checked={
                selectedCheckboxOptions?.[
                  `${checkboxOptions?.headerTitle?.name}_${checkboxOptions?.headerTitle?.id}`
                ]?.length ===
                checkboxOptions?.options?.[checkboxOptions?.headerTitle?.id]
                  ?.length
              }
            />
            <div
              className={`d-flex flex-column ${lg ? "flex-wrap" : ""}`}
              style={{ maxHeight: "70vh", gap: 10, overflow: "auto" }}
            >
              {checkboxOptions?.options?.[
                checkboxOptions?.headerTitle?.id
              ]?.map((option, i) => (
                <Controller
                  key={`${checkboxFieldName}-${option?.[checkboxFieldIdKey]}`}
                  name={`${checkboxFieldName}.${
                    checkboxOptions?.headerTitle?.name +
                    "_" +
                    checkboxOptions?.headerTitle?.id
                  }.${i}`}
                  control={control}
                  render={({
                    field: { name, value, ref, onChange, onBlur },
                  }) => (
                    <FormCheck
                      ref={ref}
                      onChange={handleCheckboxOptionChange(onChange)}
                      name={name}
                      checked={
                        selectedCheckboxOptions?.[
                          `${checkboxOptions?.headerTitle?.name}_${checkboxOptions?.headerTitle?.id}`
                        ]?.includes(option?.[checkboxFieldIdKey]) || false
                      }
                      value={option?.[checkboxFieldIdKey] || ""}
                      label={option?.[checkboxFieldLabelKey]}
                    />
                  )}
                />
              ))}
            </div>
          </>
        )}
      </CustomModal>
    </div>
  );
};

export default MutliCheckboxSelect;
