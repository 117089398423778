import {
    all,
    call,
    fork,
    put,
    takeEvery,
    takeLatest,
  } from "redux-saga/effects";

  import { authRequest } from "../../helpers/api";
  import { appNotification } from "../app/actions";
  import {
    deleteCarGenerationFailed,
    deleteCarGenerationSuccess,
    getCarGenerationsFailed,
    getCarGenerationsSuccess,
    getCarGenerationFailed,
    getCarGenerationSuccess,
  } from "./actions";
  import {
    DELETE_CAR_GENERATION,
    GET_CAR_GENERATION,
    SAVE_CAR_GENERATION,
    CAR_GENERATION,
  } from "./constants";

  function* getCarGenerations({ payload }) {
    try {
      let url = `/vehicle/car-generation?`;
      if (payload)
        Object.keys(payload).map(
          (params) =>
            payload?.[params] && (url += `${params}=${payload[params]}&`)
        );
      const response = yield call(authRequest, url.slice(0, url.length - 1), {
        method: "get",
      });
      console.log("response", response);
      yield put(getCarGenerationsSuccess(response?.data));
    } catch (error) {
      let message = "Failed to get car generation";
      yield put(getCarGenerationsFailed(message));
    }
  }

  function* saveCarGeneration({ payload: { data, history } }) {
    try {
      console.log("data", data);
      const formData = new FormData();
      Object.keys(data).map((key) => formData.append(key, data[key]));
      let url = `/vehicle/car-generation`;
      if (data?.id) url += `/${data?.id}?_method=PUT`;

      const response = yield call(authRequest, url, {
        method: "post",
        data: formData,
        headers: {
          "content-type": "multipart/form-data",
        },
      });
      yield put(getCarGenerationsSuccess(response?.data));
      yield put(appNotification({ type: "success", message: response?.message }));
      history.goBack();
    } catch (error) {
      let message = "Failed to get car generation";
      yield put(getCarGenerationsFailed(message));
    }
  }
  function* getCarGeneration({ payload: { id } }) {
    try {
      const response = yield call(authRequest, `/vehicle/car-generation/${id}`, {
        method: "get",
      });
      yield put(getCarGenerationSuccess(response?.data));
    } catch (error) {
      let message = "Failed to get car generation";
      yield put(getCarGenerationFailed(message));
    }
  }
  function* deleteCarGeneration({ payload: { id } }) {
    try {
      const response = yield call(authRequest, `/vehicle/car-generation/${id}`, {
        method: "delete",
      });
      yield put(appNotification({ type: "success", message: response?.message }));
      yield put(deleteCarGenerationSuccess({ id }));
    } catch (error) {
        console.log('error',error)
      let message = "Failed to get car generation";
      yield put(deleteCarGenerationFailed(message));
    }
  }
  export function* watchGetCarGenerations() {
    yield takeLatest(CAR_GENERATION, getCarGenerations);
  }
  export function* watchGetCarGeneration() {
    yield takeEvery(GET_CAR_GENERATION, getCarGeneration);
  }
  export function* watchSaveCarGeneration() {
    yield takeEvery(SAVE_CAR_GENERATION, saveCarGeneration);
  }
  export function* watchDeleteCarGeneration() {
    yield takeEvery(DELETE_CAR_GENERATION, deleteCarGeneration);
  }
  function* carGenerationSaga() {
    yield all([
      fork(watchGetCarGenerations),
      fork(watchGetCarGeneration),
      fork(watchSaveCarGeneration),
      fork(watchDeleteCarGeneration),
    ]);
  }

  export default carGenerationSaga;
