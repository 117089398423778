// @flow

import { combineReducers } from "redux";
import Layout from "./layout/reducers";
import Auth from "./auth/reducers";
import AppMenu from "./appMenu/reducers";
import Roles from "./roles/reducers";
import PartnerAuth from "./serviceProviderAuth/reducers";
import ServiceCategory from "./serviceCategory/reducers";
import App from "./app/reducers";
import SparePartsCategory from "./sparePartsCategory/reducers";
import VehicleMake from "./vehicleMake/reducers";
import VehicleType from "./vehicleType/reducers";
import Users from "./users/reducers";
import ServiceProviderBusiness from "./serviceProviderBusiness/reducers";
import SupplierBusiness from "./supplierBusiness/reducers";
import CarModel from "./carModel/reducers";
import CarTrim from "./carTrim/reducers";
import CarGeneration from "./carGeneration/reducers";
import CarSerie from "./carSeries/reducers";
import Orders from "./orders/reducers";
import Rfq from "./rfq/reducers";
import SupplierBusinessUpdate from "./supplierBusinessUpdate/reducers";
import ContactQuery from "./contactQuery/reducers";
import WhatsAppGroup from "./whatsappGroup/reducers";
import QuoteList from "./quoteList/reducers";
import Chat from "./chat/reducers";
import RfqResponse from "./rfqResponse/reducers";
import Cart from "./cart/reducers";
import RewardPoints from "./rewardPoints/reducers";

export default combineReducers({
  App,
  Auth,
  AppMenu,
  Layout,
  Roles,
  PartnerAuth,
  ServiceCategory,
  SparePartsCategory,
  VehicleMake,
  VehicleType,
  Users,
  ServiceProviderBusiness,
  SupplierBusiness,
  CarModel,
  CarTrim,
  CarGeneration,
  CarSerie,
  Orders,
  Rfq,
  SupplierBusinessUpdate,
  ContactQuery,
  WhatsAppGroup,
  QuoteList,
  Chat,
  RfqResponse,
  Cart,
  RewardPoints,
});
