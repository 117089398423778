import { all, call, put, takeLatest } from "redux-saga/effects";

import {
  saveCartSuccess,
  getCartSuccess,
  GET_CART,
  SAVE_CART,
  getCartFailed,
  saveCartFailed,
  deleteCartSuccess,
  deleteCartFailed,
  REMOVE_CART,
  GET_USER_ADDRESS,
  getUserAddressFailed,
  getUserAddressSuccess,
  saveUserAddressSuccess,
  saveUserAddressFailed,
  editUserAddressSuccess,
  editUserAddressFailed,
  SAVE_USER_ADDRESS,
  EDIT_USER_ADDRESS,
} from "./actions";

import {
  deleteCartApi,
  getAllCartApi,
  getUserAddressApi,
  saveCartApi,
  saveUserAddressApi,
} from "../../services/api";
import { appNotification } from "../app/actions";

function* getCartSaga({ payload }) {
  try {
    const data = yield call(getAllCartApi, payload);
    const { data: result } = data;
    yield put(getCartSuccess(result));
  } catch (e) {
    yield put(getCartFailed(e?.response?.data));
  }
}

function* saveCartSaga({ payload: { payload, onSuccess, onError } }) {
  try {
    const response = yield call(saveCartApi, payload);
    if (response?.status === "Success") {
      if (onSuccess) onSuccess(response?.data);
      yield put(saveCartSuccess(response?.data));
    } else {
      yield put(
        appNotification({ type: "danger", message: response?.message })
      );
    }
  } catch (e) {
    if (onError) onError(e?.response);
    yield put(saveCartFailed(e?.response?.data));
  }
}

function* deleteCartSaga({ payload }) {
  try {
    const response = yield call(deleteCartApi, payload);
    if (response?.status === "Success")
      yield put(deleteCartSuccess(response?.data));
    else yield put(deleteCartFailed(response?.message));
  } catch (e) {
    yield put(deleteCartFailed(e?.response?.data));
  }
}

function* getUserAddressSaga({ payload }) {
  try {
    const response = yield call(getUserAddressApi, payload?.id);
    if (response?.status === "Success")
      yield put(getUserAddressSuccess(response?.data));
    else yield put(getUserAddressFailed(response?.error));
  } catch (error) {
    if (error.response.status != 422) {
      let message = "";
      if (error) message = "Oops something went wrong";
      yield put(getUserAddressFailed(message));
    } else {
      const { errors } = error?.response?.data;
      yield put(getUserAddressFailed(errors));
    }
  }
}

function* saveUserAddressSaga({ payload: { payload, onSuccess, onError } }) {
  try {
    const response = yield call(saveUserAddressApi, payload);
    console.log("success resp", response);

    yield put(saveUserAddressSuccess(response?.data));

    yield call(() => {
      onSuccess(response);
    });
  } catch (error) {
    console.log("error", error);

    if (error?.response?.status != 422) {
      let message = "";
      if (error) message = "Oops something went wrong";
      yield put(appNotification({ type: "danger", message }));
      yield put(saveUserAddressFailed(error?.response?.message));
      yield call(() => onError(error));
    } else {
      const { errors } = error?.response?.data;
      yield put(saveUserAddressFailed(errors));
    }
  }
}
function* editUserAddressSaga({ payload: { payload, onSuccess, onError } }) {
  try {
    const response = yield call(saveUserAddressApi, payload);
    console.log("success resp", response);

    yield put(editUserAddressSuccess(response?.data));

    yield call(() => {
      onSuccess(response);
    });
  } catch (error) {
    console.log("error", error);

    if (error?.response?.status != 422) {
      let message = "";
      if (error) message = "Oops something went wrong";
      yield put(appNotification({ type: "danger", message }));
      yield put(editUserAddressFailed(error?.response?.message));
      yield call(() => onError(error));
    } else {
      const { errors } = error?.response?.data;
      yield put(editUserAddressFailed(errors));
    }
  }
}

function* cartSaga() {
  yield all([
    takeLatest(GET_CART, getCartSaga),
    takeLatest(SAVE_CART, saveCartSaga),
    takeLatest(REMOVE_CART, deleteCartSaga),
    takeLatest(GET_USER_ADDRESS, getUserAddressSaga),
    takeLatest(SAVE_USER_ADDRESS, saveUserAddressSaga),
    takeLatest(EDIT_USER_ADDRESS, editUserAddressSaga),
  ]);
}

export default cartSaga;
