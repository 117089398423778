export const LIST_RFQ = "LIST_RFQ";
export const LIST_RFQ_SUCCESS = "LIST_RFQ_SUCCESS";
export const LIST_RFQ_FAILED = "LIST_RFQ_FAILED";

export const GET_RFQ = "GET_RFQ";
export const GET_RFQ_SUCCESS = "GET_RFQ_SUCCESS";
export const GET_RFQ_FAILED = "GET_RFQ_FAILED";

export const GET_RFQ_SUPPLIER = "GET_RFQ_SUPPLIER";
export const GET_RFQ_SUPPLIER_SUCCESS = "GET_RFQ_SUPPLIER_SUCCESS";
export const GET_RFQ_SUPPLIER_FAILED = "GET_RFQ_SUPPLIER_FAILED";

export const NOTIFY_RFQ_SUPPLIER = "NOTIFY_RFQ_SUPPLIER";
export const NOTIFY_RFQ_SUPPLIER_SUCCESS = "NOTIFY_RFQ_SUPPLIER_SUCCESS";
export const NOTIFY_RFQ_SUPPLIER_FAILED = "NOTIFY_RFQ_SUPPLIER_FAILED";

export const GET_RFQ_REQUEST_DETAIL = 'GET_RFQ_REQUEST_DETAIL';
export const GET_RFQ_REQUEST_DETAIL_SUCCESS = 'GET_RFQ_REQUEST_DETAIL_SUCCESS';
export const GET_RFQ_REQUEST_DETAIL_FAILED = 'GET_RFQ_REQUEST_DETAIL_FAILED';

export const GET_RFQ_REQUEST_DETAIL_RESPONSES = 'GET_RFQ_REQUEST_DETAIL_RESPONSES';
export const GET_RFQ_REQUEST_DETAIL_RESPONSES_SUCCESS = 'GET_RFQ_REQUEST_DETAIL_RESPONSES_SUCCESS';
export const GET_RFQ_REQUEST_DETAIL_RESPONSES_FAILED = 'GET_RFQ_REQUEST_DETAIL_RESPONSES_FAILED';

export const ADD_RFQ_REQUEST = 'ADD_RFQ_REQUEST';
export const ADD_RFQ_REQUEST_SUCCESS = 'ADD_RFQ_REQUEST_SUCCESS';
export const ADD_RFQ_REQUEST_FAILED = 'ADD_RFQ_REQUEST_FAILED';
