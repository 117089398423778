import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { chatRoomSelector } from "../../redux/chat/selectors";
import { getUserSession } from "../../helpers/authUtils";
import { Button, Spinner, Tab, Tabs } from "react-bootstrap";
import CustomModal from "../../components/CustomModal/CustomModal";
import { useModal } from "../../hooks/useModal";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";

import CheckBoxList from "../../components/CheckBoxList";
import { getUserListForChatApi } from "../../services/api";
import { appNotification } from "../../redux/app/actions";
import { addChatRoom, getChatRoom } from "../../redux/chat/actions";
import ReactSelect from "react-select";
import CheckBoxItem from "../../components/CheckBoxItem/CheckBoxItem";
import Chat from "../../components/Chat/Chat";

const Chats = () => {
  const dispatch = useDispatch();
  const {
    data: rooms,
    loading: roomLoading,
    error: roomError,
  } = useSelector(chatRoomSelector);

  const currentUser = getUserSession();
  const { open, onClose, onOpen } = useModal();
  const [users, setUsers] = useState({
    supplier: { data: [], refresh: false, loader: false },
    "service-provider": { data: [], refresh: false, loader: false },
    other: { data: [], refresh: false, loader: false },
  });
  const [selectedServiceProvider, setSelectedServiceProvider] = useState(null);
  const [selectedUser, setSelectedUser] = useState([]);
  const [activeTab, setActiveTab] = useState("supplier");
  const handleOpenUserModal =
    ({ type = "single" }) =>
    (e) => {
      onOpen();
      if (users?.["service-provider"]?.data?.length === 0)
        fetchChatUserList("service-provider");

      if (users?.supplier?.data?.length === 0) fetchChatUserList("supplier");
    };
  const fetchChatUserList = async (tab) => {
    setActiveTab(tab);
    try {
      if (users?.[tab]?.data?.length === 0 || users?.[tab]?.refresh) {
        setUsers((s) => ({ ...s, [tab]: { ...s?.[tab], loader: true } }));
        const response = await getUserListForChatApi({ type: tab });
        console.log("respones", response);
        setUsers((s) => ({
          ...s,
          [tab]: { loader: false, refresh: false, data: response?.data },
        }));
      }
    } catch (error) {
      dispatch(
        appNotification({
          type: "danger",
          message: `Oops something wenr wrong while fetching ${tab} data`,
        })
      );
    }
  };
  const handleActiveTabChange = (tab) => {
    fetchChatUserList(tab);
  };
  const handleSelectedUser = (e) => {
    if (e.target.checked) {
      setSelectedUser((s) => [...s, parseInt(e.target.value)]);
    } else {
      setSelectedUser((s) => s.filter((v) => v !== parseInt(e.target.value)));
    }
  };

  const handleSubmit = (e) => {
    dispatch(
      addChatRoom({ payload: { selectedUser, ...selectedServiceProvider } })
    );
    onClose();
  };
  const handleServiceProviderChange = (option, actionMeta) => {
    setSelectedServiceProvider({
      userId: option?.user?.id,
      name: option?.businessName,
    });
  };
  useEffect(() => {
    dispatch(getChatRoom());
  }, []);
  console.log("rooms", rooms);
  return (
    <div
      style={{
        height: "80vh",
        position: "relative",
      }}
    >
      <div>
        <Button onClick={handleOpenUserModal({ type: "single" })}>
          New chat
        </Button>
        <Button
          variant="secondary"
          onClick={handleOpenUserModal({ type: "group" })}
        >
          Create group
        </Button>
      </div>
      <Chat currentUser={currentUser} loading={roomLoading} rooms={rooms} />
      <CustomModal open={open} closeModal={onClose} noFooter>
        {users?.supplier?.loader ? (
          <Spinner animation="grow" />
        ) : (
          <ReactSelect
            getOptionLabel={(o) => (
              <span className="m-0">{`${o.businessName} (${o.user?.phoneCode} ${o?.user?.phoneNumber})`}</span>
            )}
            placeholder="Select Service provider"
            getOptionValue={(o) => o?.user?.id}
            options={users?.["service-provider"]?.data}
            name="service-provider"
            onChange={handleServiceProviderChange}
          />
        )}
        <br />
        <Tabs
          id="controlled-tab-example"
          activeKey={activeTab}
          onSelect={handleActiveTabChange}
          className="mb-3"
        >
          <Tab eventKey="supplier" title="Supplier">
            <PerfectScrollbar style={{ maxHeight: "376px" }}>
              {users?.supplier?.loader ? (
                <Spinner animation="grow" />
              ) : (
                <div style={{ position: "relative" }}>
                  <CheckBoxList>
                    {users?.supplier?.data?.map((v) => (
                      <CheckBoxItem
                        id={`supplier-${v?.id}`}
                        key={`supplier-${v?.id}`}
                        name="supplier"
                        containerStyle={{ position: "static" }}
                        value={v?.user?.id}
                        title={v?.businessName}
                        subTitle={`${v?.user?.phoneCode} ${v?.user?.phoneNumber}`}
                        onChange={handleSelectedUser}
                      />
                    ))}
                  </CheckBoxList>
                </div>
              )}
            </PerfectScrollbar>
          </Tab>
          <Tab eventKey="other" title="Other">
            <PerfectScrollbar style={{ maxHeight: "376px" }}>
              {users?.other?.loader ? (
                <Spinner animation="grow" />
              ) : (
                <CheckBoxList>
                  {users?.other?.data?.map((v) => (
                    <CheckBoxItem
                      id={`other-${v?.id}`}
                      key={`other-${v?.id}`}
                      title={v?.name}
                      name="other"
                      containerStyle={{ position: "static" }}
                      value={v?.id}
                      subTitle={`${v?.phoneCode} ${v?.phoneNumber}`}
                      onChange={handleSelectedUser}
                    />
                  ))}
                </CheckBoxList>
              )}
            </PerfectScrollbar>
          </Tab>
        </Tabs>
        <div className="d-flex justify-content-between align-items-center">
          <span
            style={{ visibility: selectedUser?.length ? "visible" : "hidden" }}
          >
            {selectedUser?.length} selected
          </span>
          <Button onClick={handleSubmit}>Create</Button>
        </div>
      </CustomModal>
    </div>
  );
};

export default Chats;
