import {
  GET_CART,
  GET_CART_SUCCESS,
  GET_CART_FAILED,
  SAVE_CART,
  SAVE_CART_SUCCESS,
  SAVE_CART_FAILED,
  REMOVE_CART,
  REMOVE_CART_SUCCESS,
  REMOVE_CART_FAILED,
  GET_USER_ADDRESS,
  GET_USER_ADDRESS_SUCCESS,
  GET_USER_ADDRESS_FAILED,
  SAVE_USER_ADDRESS,
  SAVE_USER_ADDRESS_SUCCESS,
  SAVE_USER_ADDRESS_FAILED,
  EDIT_USER_ADDRESS,
  EDIT_USER_ADDRESS_SUCCESS,
  EDIT_USER_ADDRESS_FAILED,
} from "./actions";

const initialState = {
  data: [],
  error: null,
  loading: false,
  userAddress: {
    data: [],
    error: null,
    loading: false,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_CART:
      return {
        ...state,
        loading: true,
      };
    case GET_CART_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null,
      };
    case GET_CART_FAILED:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case SAVE_CART:
      return {
        ...state,
        loading: true,
      };
    case SAVE_CART_SUCCESS:
      return {
        ...state,
        loading: false,
        data: [...state.data, action.payload],
        error: null,
      };
    case SAVE_CART_FAILED:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case REMOVE_CART:
      return {
        ...state,
        loading: true,
      };
    case REMOVE_CART_SUCCESS:
      return {
        ...state,
        data: state?.data?.filter((v) => v?.id !== action?.payload?.id),
        loading: false,
      };
    case REMOVE_CART_FAILED:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case GET_USER_ADDRESS:
      return {
        ...state,
        userAddress: {
          ...state?.userAddress,
          loading: true,
        },
      };
    case GET_USER_ADDRESS_SUCCESS:
      return {
        ...state,
        userAddress: {
          ...state?.userAddress,
          data: action.payload,
          error: null,
          loading: false,
        },
      };
    case GET_USER_ADDRESS_FAILED:
      return {
        ...state,
        userAddress: {
          ...state?.userAddress,
          error: action.payload,
          loading: false,
        },
      };
    case SAVE_USER_ADDRESS:
      return {
        ...state,
        userAddress: {
          ...state?.userAddress,
          loading: true,
        },
      };
    case SAVE_USER_ADDRESS_SUCCESS:
      return {
        ...state,
        userAddress: {
          ...state?.userAddress,
          data: [action.payload, ...state?.userAddress?.data],
          error: null,
          loading: false,
        },
      };
    case SAVE_USER_ADDRESS_FAILED:
      return {
        ...state,
        userAddress: {
          ...state?.userAddress,
          error: action.payload,
          loading: false,
        },
      };

    case EDIT_USER_ADDRESS:
      return {
        ...state,
        userAddress: {
          ...state?.userAddress,
          loading: true,
        },
      };
    case EDIT_USER_ADDRESS_SUCCESS:
      return {
        ...state,
        userAddress: {
          ...state?.userAddress,
          data: action?.payload?.id
            ? state?.userAddress?.data?.map((v) =>
                v?.id === action?.payload?.id ? action?.payload : v
              )
            : state?.userAddress?.data,
          error: null,
          loading: false,
        },
      };
    case EDIT_USER_ADDRESS_FAILED:
      return {
        ...state,
        userAddress: {
          ...state?.userAddress,
          error: action.payload,
          loading: false,
        },
      };
    default:
      return state;
  }
};
