import { useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import useQuery from "../../hooks/useQuery";
import { getOrder } from "../../redux/orders/actions";
import Invoice from "../orders/Invoice";
import GeneratePdf from "../../components/GeneratePdf/GeneratePdf";
import { userSelector } from "../../redux/users/selectors";

const DownloadPdf = () => {
  const params = useQuery();
  const dispatch = useDispatch();
  const { order, loading } = useSelector((state) => state.Orders);
  const { user } = useSelector(userSelector);
  useEffect(() => {
    if (params.get("type") === "invoice") {
      dispatch(getOrder(params?.get("identifier")));
    }
  }, []);
  if (loading)
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "80vh", width: "100%" }}
      >
        <Spinner animation="border" />
      </div>
    );
  else {
    return (
      <GeneratePdf name={order?.invoiceNo || order?.orderNo}>
        <Invoice data={order} digitalSign={user?.digital_sign} />
      </GeneratePdf>
    );
  }
};

export default DownloadPdf;
