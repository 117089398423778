import { createSelector } from "reselect";

export const pointsHistorySelector = createSelector(
  (state) => state.RewardPoints,
  ({ pointHistory }) => ({ ...pointHistory })
);

export const totalPointSelector = createSelector(
  (state) => state.RewardPoints,
  ({ totalPoints }) => ({ ...totalPoints })
);

export const addPointHistoryStateSelector = createSelector(
  (state) => state.RewardPoints,
  ({ addPointHistoryState }) => ({ ...addPointHistoryState })
);

export const updatePointHistoryStateSelector = createSelector(
    (state) => state.RewardPoints,
    ({ updatePointHistoryState }) => ({ ...updatePointHistoryState })
  );
  