import {
  GET_POINT_HISTORY,
  GET_POINT_HISTORY_FAILED,
  GET_POINT_HISTORY_SUCCESS,
  ADD_POINT_HISTORY,
  ADD_POINT_HISTORY_FAILED,
  ADD_POINT_HISTORY_SUCCESS,
  UPDATE_POINT_HISTORY,
  UPDATE_POINT_HISTORY_SUCCESS,
  UPDATE_POINT_HISTORY_FAILED,
} from "./constants";

const initialState = {
  pointHistory: {
    data: {
      current_page: 0,
      data: [],
      first_page_url: "",
      from: 0,
      next_page_url: "",
      path: null,
      per_page: 0,
      prev_page_url: null,
      to: null,
    },
    loading: false,
    error: null,
  },
  addPointHistoryState: {
    data: null,
    loading: false,
    error: null,
  },
  updatePointHistoryState: {
    data: null,
    loading: false,
    error: null,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_POINT_HISTORY:
      return {
        ...state,
        pointHistory: {
          ...state.pointHistory,
          loading: true,
        },
      };
    case GET_POINT_HISTORY_SUCCESS:
      return {
        ...state,
        pointHistory: {
          ...state.pointHistory,
          loading: false,
          data: action.payload,
        },
      };
    case GET_POINT_HISTORY_FAILED:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case ADD_POINT_HISTORY:
      return {
        ...state,
        addPointHistoryState: {
          ...state.addPointHistoryState,
          loading: true,
        },
      };
    case ADD_POINT_HISTORY_SUCCESS:
      return {
        ...state,

        pointHistory: {
          ...state.pointHistory,
          data: {
            ...state.pointHistory.data,
            data: [...state.pointHistory.data?.data, action.payload],
          },
        },
        addPointHistoryState: {
          ...state.addPointHistoryState,
          loading: false,
        },
      };
    case ADD_POINT_HISTORY_FAILED:
      return {
        ...state,
        addPointHistoryState: {
          ...state.addPointHistoryState,
          loading: false,
        },
      };

    case UPDATE_POINT_HISTORY:
      return {
        ...state,
        updatePointHistoryState: {
          ...state.updatePointHistoryState,
          data: action.payload,
          loading: true,
        },
      };

    case UPDATE_POINT_HISTORY_SUCCESS:
      return {
        ...state,
        pointHistory: {
          ...state.pointHistory,
          data: {
            ...state.pointHistory.data,
            data: state.pointHistory.data?.data.map((v) =>
              v.id === action.payload.id ? { ...v, status: "REDEEMED" } : v
            ),
          },
        },
        updatePointHistoryState: {
          ...state.updatePointHistoryState,
          data: null,
          loading: false,
        },
      };
    case UPDATE_POINT_HISTORY_FAILED:
      return {
        ...state,
        updatePointHistoryState: {
          ...state.updatePointHistoryState,
          data: null,
          error: action.payload,
          loading: false,
        },
      };

    default:
      return state;
  }
};
