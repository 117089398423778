import { useState } from "react";
import "./ReadMore.module.css";

const ReadMore = ({ children, charCount = 150 }) => {
  const text = children;
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  return (
    <div className="read-more-root">
      <p className="read-more-text">
        {isReadMore && text ? text.slice(0, charCount) : text}
        {text?.length > charCount && (
          <span onClick={toggleReadMore} className="read-or-hide">
            {isReadMore ? "...read more" : " show less"}
          </span>
        )}
      </p>
    </div>
  );
};

export default ReadMore;
