import { AvField, AvForm, AvInput } from "availity-reactstrap-validation";
import React, { useEffect } from "react";
import { Button, Card, Col, FormGroup, FormLabel, Row } from "react-bootstrap";
import { CardBody } from "reactstrap";
import Select from "react-select";
import PageTitle from "../../components/PageTitle";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toBase64 } from "../../helpers";
import { debounce } from "lodash";
import CustomModal from "../../components/CustomModal/CustomModal";
import { useHistory } from "react-router-dom";
import { useModal } from "../../hooks/useModal";
import Joi from "joi";
import { formField } from "../../components/FormBuilder/formField";
import {
  fetchServiceProviderBusiness,
  fetchSupplierBusiness,
  getCarYearList,
  getMakeAndModel,
  getSpartPartsCategory,
} from "../../services/api";
import { Controller, useForm } from "react-hook-form";
import { addRfqRequest } from "../../redux/rfq/actions";
import { appNotification } from "../../redux/app/actions";
import { addRfqSelector } from "../../redux/rfq/selectors";

const fields = ({
  handleMakeModelChange,
  handleLoadMakeAndModel,
  handleCategoryAndSubCategoryChange,
  handleVoiceNote,
  resetFiles,
  showOtherMakeAndModel,
  disableOtherMake,
  loadSpareParts,
  carYearOptions,
  updateFilesCb,
  handleBusinessChange,
}) => ({
  businessId: {
    type: "asyncSelect",
    name: "businessId",
    validationRules: Joi.object()
      .required()
      .messages({ "object.base": "Service provider is required" }),
    getOptionLabel: (option) => <span>{`${option.businessName}`}</span>,
    getOptionValue: (option) => option.id,
    loadOptions: fetchServiceProviderBusiness,
    id: "serviceProviderBusiness",
    label: "Garages",
    onChange: handleBusinessChange,
    columnProps: {
      sm: 12,
      md: 6,
      lg: 4,
    },
  },
  makeAndModel: {
    type: "asyncSelect",
    name: "makeAndModel",
    validationRules: Joi.object()
      .required()
      .messages({ "object.base": "Vehicle field is required" }),
    getOptionLabel: (option) => `${option?.make} ${option?.model}`,
    getOptionValue: (option) =>
      `${option?.id_car_make}_${option?.id_car_model}`,
    loadOptions: handleLoadMakeAndModel,
    onChange: handleMakeModelChange,
    id: "serviceProviderBusiness",
    label: "Select Vehicle",
    columnProps: {
      sm: 12,
      md: 6,
      lg: 4,
    },
  },
  otherBrand: {
    id: "otherBrand",
    type: "text",
    name: "otherBrand",
    disabled: disableOtherMake,
    validationRules: Joi.optional().allow("", 0, null).label("Make"),
    columnProps: {
      sm: 12,
      md: 6,
      lg: 4,
      style: { display: showOtherMakeAndModel ? "block" : "none" },
    },
  },
  otherModel: {
    id: "othrerModel",
    type: "text",
    name: "otherModel",
    validationRules: Joi.optional().allow("", 0, null).label("Model"),
    columnProps: {
      sm: 12,
      md: 6,
      lg: 4,
      style: { display: showOtherMakeAndModel ? "block" : "none" },
    },
  },
  carYear: {
    type: "select",
    name: "carYear",
    validationRules: Joi.object()
      .required()
      .messages({ "object.base": `"Car year" is required` }),
    getOptionLabel: (option) => option.year,
    getOptionValue: (option) => option.id,
    options: carYearOptions?.data,
    loading: carYearOptions?.loading,
    id: "carYear",
    label: "Year",
    columnProps: {
      sm: 12,
      md: 6,
      lg: 4,
    },
  },
  sparePartsCategory: {
    type: "asyncSelect",
    name: "categoryAndSubCategory",
    isMulti: true,
    onChange: handleCategoryAndSubCategoryChange,
    getOptionLabel: (option) => option?.categoryName,
    getOptionValue: (option) => option?.id,
    placeholder: "Search spare parts",
    loadOptions: loadSpareParts,
    cacheOptions: true,
    label: "Spare parts",
    noOptionsMessage: () => "Enter atleast 3 characters to search",
    validationRules: Joi.array().required(),
    columnProps: {
      sm: 12,
      md: 6,
      lg: 4,
    },
  },
  vinNumber: {
    type: "file",
    name: "vinNumber",
    id: "vinNumber",
    label: "Vin number",
    updateFilesCb,
    reset: resetFiles?.vinNumber,
    validationRules: Joi.array()
      .optional()
      .label("Vin number")
      .messages({ "array.base": "Vin number field is required" }),
    columnProps: {
      sm: 12,
      md: 6,
      lg: 4,
    },
  },

  photo: {
    type: "file",
    name: "photo",
    id: "photo",
    label: "Photos",
    multiple: true,
    updateFilesCb,
    validationRules: Joi.array().optional(),
    reset: resetFiles?.photos,
    columnProps: {
      sm: 12,
      md: 6,
      lg: 4,
    },
  },
  voiceNote: {
    type: "audio",
    name: "voiceNote",
    id: "voiceNote",
    label: "Voice note",
    onStop: handleVoiceNote("voiceNote"),
    maxRecordingTime: 60,
    reset: resetFiles.voiceNote,
    validationRules: Joi.array()
      .optional()
      .label("Vin number")
      .messages({ "array.base": "Vin number field is required" }),
    columnProps: {
      sm: 12,
      md: 6,
      lg: 4,
    },
  },
  description: {
    id: "description",
    type: "textarea",
    name: "description",
    label: "Description",
    columnProps: {
      sm: 12,
      md: 12,
      lg: 12,
    },
  },
});

const defaults = {
  brand: null,
  carModel: null,
  carYear: null,
  spec: "gulf-spec",
  vinNumber: null,
  categoryId: null,
  subCategoryId: null,
  makeAndModel: null,
  categoryAndSubCategory: null,
  voiceNote: null,
  description: null,
  photo: null,
  businessCode: null,
  businessId: null,
  otherBrand: null,
  otherModel: null,
  otherSubCategory: null,
};

const AddEditRfq = () => {
  const {
    handleSubmit,
    register,
    getValues,
    setValue,
    setError,
    setFocus,
    control,
    resetField,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: defaults,
  });

  const history = useHistory();
  const [preview, setPreview] = useState(null);
  const [image, setImage] = useState(null);
  const [showOtherMakeAndModel, setShowOtherMakeNModel] = useState(false);
  const [disableOtherMake, setDisableOtherMake] = useState(false);
  const [previewVoice, setPreviewVoice] = useState();
  const [resetFiles, setResetFiles] = useState({
    vinNumber: false,
    photo: false,
    voiceNote: false,
  });
  const {
    open: openRfqQouteDialog,
    onOpen: onOpenRfqQouteDialog,
    onClose: onRfqQouteDialog,
  } = useModal();

  const {
    loading: formLoading,
    error: formError,
    data: RfqAddData,
  } = useSelector(addRfqSelector);
  const dispatch = useDispatch();

  const handleMakeModelChange =
    (...event) =>
    (option, actionMeta) => {
      setValue("makeAndModel", option);
      if (option?.id_car_make !== -1 && option?.id_car_model === -1) {
        setValue("otherBrand", option?.make);
      } else {
        setValue("otherBrand", null);
        setValue("otherModel", null);
      }
      setDisableOtherMake(option?.id_car_make !== -1);
      setShowOtherMakeNModel(option?.id_car_model === -1);
      setValue("brand", option?.id_car_make);
      setValue("carModel", option?.id_car_model);
    };
  const handleVoiceNote =
    (name) =>
    ({ file, blobUrl }) => {
      setPreviewVoice((s) => ({ ...s, [name]: file }));
    };
  const handleBusinessChange =
    (...event) =>
    (option, actionMeta) => {
      setValue("businessId", option);
      setValue("businessCode", option.businessCode);
    };
  const handleLoadMakeAndModel = debounce((text, callback) => {
    if (text.length > 2) {
      getMakeAndModel(text).then((data) => {
        callback([
          ...data,
          {
            id_car_make: -1,
            id_car_model: -1,
            make: "Other",
            model: "make and model",
          },
        ]);
      });
    } else callback([]);
  }, 300);

  const loadSpareParts = debounce((text, callback) => {
    if (text.length > 2) {
      getSpartPartsCategory({ search: text, level: 3 })
        .then((data) => {
          callback([...data, { id: `other-${text}`, categoryName: text }]);
        })
        .catch((e) => {
          callback([]);
        });
    } else callback([]);
  }, 300);

  const handleCategoryAndSubCategoryChange =
    (handler) => (option, actionMeta) => {
      setValue("categoryAndSubCategory", option);
      const cat = [],
        subCat = [];
      option?.forEach((v) => {
        cat.push(v?.parentsparePartCategoryId);
        subCat.push(v?.id);
      });
      setValue("subCategoryId", subCat);
      setValue("categoryId", cat);
    };

  const onSubmit = (payload) => {
    const makeAndModel = `${payload?.makeAndModel?.id_car_make}_${payload?.makeAndModel?.id_car_model}`;
    const categoryAndSubCategory = payload?.categoryAndSubCategory?.id;
    if (makeAndModel === "-1_-1") {
      if (payload?.brand === -1 && !payload?.otherBrand?.length) {
        setError("otherBrand", {
          message: "Brand is required",
          type: "required",
        });
        setFocus("otherBrand");
      }
      if (payload?.carModel === -1 && !payload?.otherModel?.length) {
        setError("otherModel", {
          message: "Model is required",
          type: "required",
        });
        setFocus("otherModel");
      }
    }

    if (
      (payload?.brand === -1 && !payload?.otherBrand?.length) ||
      (payload?.carModel === -1 && !payload?.otherModel?.length)
    )
      return;

    const data = {
      ...payload,
      ...previewVoice,
      autoRfq: true,
      categoryAndSubCategory,
      makeAndModel,
      carYear: payload?.carYear?.id,
      categoryId: payload?.categoryId?.id || payload?.categoryId,
      businessId: payload?.businessId?.id || undefined,
      userId: payload?.businessId?.userId || undefined,
      role: "service-provider",
    };
    console.log("data", data);
    setResetFiles((s) => ({ ...s, photos: true, voiceNote: true }));
    dispatch(
      addRfqRequest({
        payload: data,
        onSuccess: (resp) => {
          onOpenRfqQouteDialog();
          reset();
        },
        onError: (err) => {
          dispatch(
            appNotification({
              type: "danger",
              message: "Oops something went wrong",
            })
          );
          console.log(err);
        },
      })
    );
  };

  const [carYear, setCarYear] = useState({ data: null, loading: false });

  const fetchCarYear = async () => {
    setCarYear((s) => ({ data: null, loading: true }));
    const data = await getCarYearList();
    setCarYear((s) => ({ data: data?.data, loading: false }));
  };

  const gotoRfqResponse = () => {
    history.push(
      `/rfq-response/add/${RfqAddData?.rfqRequest?.[0]}?r=${RfqAddData?.rfq?.id}`
    );
  };
  const updateFilesCb = async (files, event) => {
    if (event?.target?.name) setValue(event?.target?.name, files);
  };
  useEffect(() => {
    fetchCarYear();
  }, []);

  return (
    <div>
      <Row className="page-title">
        <Col md={12}>
          <PageTitle
            breadCrumbItems={[
              {
                label: "rfq",
                path: "/rfq/add",
              },
              {
                label: `Add Rfq`,
                path: `/rfq/add`,
                active: true,
              },
            ]}
            title={`Add Rfq`}
          />
        </Col>
      </Row>

      <Card>
        <CardBody>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Row>
              {Object.values(
                fields({
                  handleLoadMakeAndModel,
                  showOtherMakeAndModel,
                  disableOtherMake,
                  loadSpareParts,
                  carYearOptions: carYear,
                  resetFiles,
                  handleMakeModelChange,
                  handleCategoryAndSubCategoryChange,
                  handleVoiceNote,
                  updateFilesCb,
                  handleBusinessChange,
                  resetFiles,
                })
              ).map((fieldObj, j) => {
                const { columnProps, validationRules, ...restFieldProps } =
                  fieldObj;

                return (
                  <Col key={`${fieldObj.id}-${j}`} {...columnProps}>
                    <Controller
                      name={`${restFieldProps?.name}`}
                      control={control}
                      rules={validationRules}
                      render={({ field, fieldState: { error } }) => {
                        let fieldProps = field;
                        if (fieldObj.type === "file") {
                          const { value, onChange, ...rest } = field;
                          fieldProps = rest;
                        }
                        if (fieldObj?.onChange)
                          fieldProps.onChange = fieldObj.onChange(
                            field.onChange
                          );
                        return formField({
                          field: {
                            ...restFieldProps,
                            ...fieldProps,
                          },
                          error,
                        });
                      }}
                    />
                  </Col>
                );
              })}
            </Row>
            <Button type="submit" variant="primary" disabled={formLoading}>
              {formLoading ? "Saving..." : "Create"}
            </Button>
          </form>
        </CardBody>
      </Card>

      <CustomModal
        open={openRfqQouteDialog}
        handleOnClose={onRfqQouteDialog}
        closeModal={onRfqQouteDialog}
        submitButtonText={"Submit qoute"}
        cancelButtonText={"No"}
        onSubmit={gotoRfqResponse}
      >
        <h2>Do you want to submit qoute for this Rfq?</h2>
      </CustomModal>
    </div>
  );
};

export default AddEditRfq;
