import {
    all,
    call,
    fork,
    put,
    takeEvery,
    takeLatest,
  } from "redux-saga/effects";

  import { authRequest } from "../../helpers/api";
  import { appNotification } from "../app/actions";
  import {
    deleteCarSerieFailed,
    deleteCarSerieSuccess,
    getCarSeriesFailed,
    getCarSeriesSuccess,
    getCarSerieFailed,
    getCarSerieSuccess,
  } from "./actions";
  import {
    DELETE_CAR_SERIE,
    GET_CAR_SERIE,
    SAVE_CAR_SERIE,
    CAR_SERIE,
  } from "./constants";

  function* getCarSeries({ payload }) {
    try {
      let url = `/vehicle/car-series?`;
      if (payload)
        Object.keys(payload).map(
          (params) =>
            payload?.[params] && (url += `${params}=${payload[params]}&`)
        );
      const response = yield call(authRequest, url.slice(0, url.length - 1), {
        method: "get",
      });
      console.log("response", response);
      yield put(getCarSeriesSuccess(response?.data));
    } catch (error) {
      let message = "Failed to get car series";
      yield put(getCarSeriesFailed(message));
    }
  }

  function* saveCarSerie({ payload: { data, history } }) {
    try {
      console.log("data", data);
      const formData = new FormData();
      Object.keys(data).map((key) => formData.append(key, data[key]));
      let url = `/vehicle/car-series`;
      if (data?.id) url += `/${data?.id}?_method=PUT`;

      const response = yield call(authRequest, url, {
        method: "post",
        data: formData,
        headers: {
          "content-type": "multipart/form-data",
        },
      });
      yield put(getCarSeriesSuccess(response?.data));
      yield put(appNotification({ type: "success", message: response?.message }));
      history.goBack();
    } catch (error) {
      let message = "Failed to get car series";
      yield put(getCarSeriesFailed(message));
    }
  }
  function* getCarSerie({ payload: { id } }) {
    try {
      const response = yield call(authRequest, `/vehicle/car-series/${id}`, {
        method: "get",
      });
      yield put(getCarSerieSuccess(response?.data));
    } catch (error) {
      let message = "Failed to get car series";
      yield put(getCarSerieFailed(message));
    }
  }
  function* deleteCarSerie({ payload: { id } }) {
    try {
      const response = yield call(authRequest, `/vehicle/car-series/${id}`, {
        method: "delete",
      });
      yield put(appNotification({ type: "success", message: response?.message }));
      yield put(deleteCarSerieSuccess({ id }));
    } catch (error) {
        console.log('error',error)
      let message = "Failed to get car series";
      yield put(deleteCarSerieFailed(message));
    }
  }
  export function* watchGetCarSeries() {
    yield takeLatest(CAR_SERIE, getCarSeries);
  }
  export function* watchGetCarSerie() {
    yield takeEvery(GET_CAR_SERIE, getCarSerie);
  }
  export function* watchSaveCarSerie() {
    yield takeEvery(SAVE_CAR_SERIE, saveCarSerie);
  }
  export function* watchDeleteCarSerie() {
    yield takeEvery(DELETE_CAR_SERIE, deleteCarSerie);
  }
  function* carSerieSaga() {
    yield all([
      fork(watchGetCarSeries),
      fork(watchGetCarSerie),
      fork(watchSaveCarSerie),
      fork(watchDeleteCarSerie),
    ]);
  }

  export default carSerieSaga;
