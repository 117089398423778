export const GET_CART = "GET_CART";
export const GET_CART_SUCCESS = "GET_CART_SUCCESS";
export const GET_CART_FAILED = "GET_CART_FAILED";

export const SAVE_CART = "SAVE_CART";
export const SAVE_CART_SUCCESS = "SAVE_CART_SUCCESS";
export const SAVE_CART_FAILED = "SAVE_CART_FAILED";

export const REMOVE_CART = "REMOVE_CART";
export const REMOVE_CART_SUCCESS = "REMOVE_CART_SUCCESS";
export const REMOVE_CART_FAILED = "REMOVE_CART_FAILED";

export const GET_USER_ADDRESS = "GET_USER_ADDRESS";
export const GET_USER_ADDRESS_SUCCESS = "GET_USER_ADDRESS_SUCCESS";
export const GET_USER_ADDRESS_FAILED = "GET_USER_ADDRESS_FAILED";

export const SAVE_USER_ADDRESS = "SAVE_USER_ADDRESS";
export const SAVE_USER_ADDRESS_SUCCESS = "SAVE_USER_ADDRESS_SUCCESS";
export const SAVE_USER_ADDRESS_FAILED = "SAVE_USER_ADDRESS_FAILED";

export const EDIT_USER_ADDRESS = "EDIT_USER_ADDRESS";
export const EDIT_USER_ADDRESS_SUCCESS = "EDIT_USER_ADDRESS_SUCCESS";
export const EDIT_USER_ADDRESS_FAILED = "EDIT_USER_ADDRESS_FAILED";

export const getCart = (payload) => ({
  type: GET_CART,
  payload,
});

export const getCartSuccess = (payload) => ({
  type: GET_CART_SUCCESS,
  payload,
});

export const getCartFailed = (payload) => ({
  type: GET_CART_FAILED,
  payload,
});

export const saveCart = (payload) => ({
  type: SAVE_CART,
  payload,
});

export const saveCartSuccess = (payload) => ({
  type: SAVE_CART_SUCCESS,
  payload,
});

export const saveCartFailed = (payload) => ({
  type: SAVE_CART_FAILED,
  payload,
});

export const deleteCart = (payload) => ({
  type: REMOVE_CART,
  payload,
});

export const deleteCartSuccess = (payload) => ({
  type: REMOVE_CART_SUCCESS,
  payload,
});

export const deleteCartFailed = (payload) => ({
  type: REMOVE_CART_FAILED,
  payload,
});

export const getUserAddress = (payload) => ({
  type: GET_USER_ADDRESS,
  payload,
});

export const getUserAddressSuccess = (user) => ({
  type: GET_USER_ADDRESS_SUCCESS,
  payload: user,
});

export const getUserAddressFailed = (error) => ({
  type: GET_USER_ADDRESS_FAILED,
  payload: error,
});

export const saveUserAddress = (payload) => ({
  type: SAVE_USER_ADDRESS,
  payload,
});

export const saveUserAddressSuccess = (payload) => ({
  type: SAVE_USER_ADDRESS_SUCCESS,
  payload,
});

export const saveUserAddressFailed = (error) => ({
  type: SAVE_USER_ADDRESS_FAILED,
  payload: error,
});

export const editUserAddress = (payload) => ({
  type: EDIT_USER_ADDRESS,
  payload,
});

export const editUserAddressSuccess = (payload) => ({
  type: EDIT_USER_ADDRESS_SUCCESS,
  payload,
});

export const editUserAddressFailed = (error) => ({
  type: EDIT_USER_ADDRESS_FAILED,
  payload: error,
});
