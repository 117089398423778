import { joiResolver } from "@hookform/resolvers/joi";
import React, { useCallback, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { Button, Card, CardBody, Col, FormGroup, Row } from "reactstrap";
import { formField } from "../../../components/FormBuilder/formField";
import PreLoaderWidget from "../../../components/Loader";
import Joi from "joi";
import { getRole, saveRoles } from "../../../redux/actions";
import PageTitle from "../../../components/PageTitle";
import { schema } from "../../../components/FormBuilder/FormBuilder";

const fields = {
  role_name: {
    type: "text",
    name: "role_name",
    validationRules: Joi.string().required(),
    id: "role_name",
    label: "Role name",
    placeholder: "E.g. Team member",
    columnProps: {
      sm: 12,
      md: 6,
      lg: 6,
    },
  },
  role_slug: {
    type: "text",
    name: "role_slug",
    validationRules: Joi.string()
      .pattern(/^[a-z0-9]+(?:-[a-z0-9]+)*$/)
      .required()
      .messages({
        "string.pattern.base":
          "Please write role slug with ( - ) between two words",
      }),
    id: "role_slug",
    label: "Role slug",
    placeholder: "E.g. team-member",
    columnProps: {
      sm: 12,
      md: 6,
      lg: 6,
    },
  },
  prefix: {
    type: "text",
    name: "prefix",
    validationRules: Joi.string().required(),
    id: "prefix",
    label: "Prefix",
    placeholder: "E.g. TM",
    columnProps: {
      sm: 12,
      md: 6,
      lg: 6,
    },
  },
};

const AddEditUserRoles = () => {
  const baseUrl = `/roles`;
  const isEdit = false;
  const { error, data, roles, loading } = useSelector((state) => state.Roles);
  const {
    error: roleError,
    data: roleData,
    loading: roleLoading,
  } = useSelector((state) => state.Roles.role);
  const params = useParams();

  const [defaultData, setDefaultData] = useState({
    role_slug: "",
    role_name: "",
    prefix: "",
  });

  const {
    register,
    handleSubmit,
    control,
    setFocus,
    setError,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    reValidateMode: "onChange",
    mode: "onChange",
    defaultValues: defaultData,
    resolver: joiResolver(schema(fields)),
  });
  const history = useHistory();
  const handleCancel = useCallback((e) => history.goBack(), []);
  const dispatch = useDispatch();

  const onSubmit = (data) => {
    console.log("data", data);
    dispatch(saveRoles({ ...roleData, ...data }));
  };

  useEffect(() => {
    if (error) {
      Object.keys(error).map((field) =>
        setError(field, { message: error?.[field]?.[0] })
      );
    }
  }, [error]);
  useEffect(() => {
    //edit actions
    if (params?.id) {
      dispatch(getRole(params?.id));
    }
  }, []);

  useEffect(() => {
    //edit actions
    if (roleData) {
      Object.keys(roleData).forEach((key) => {
        if (Object.keys(fields).includes(key)) setValue(key, roleData[key]);
      });
    }
  }, [roleData]);

  return (
    <>
      <Row className="page-title">
        <Col md={12}>
          <PageTitle
            breadCrumbItems={[
              { label: "Roles", path: baseUrl },
              {
                label: "Add",
                path: `${baseUrl}/add`,
                active: true,
              },
            ]}
            title={`${isEdit ? "Edit" : "Add new"} roles`}
          />
        </Col>
      </Row>
      <Card>
        <CardBody>
          {loading && <PreLoaderWidget />}
          <form onSubmit={handleSubmit(onSubmit)}>
            {
              <Row>
                {Object.keys(fields).map((key) => {
                  const fieldVal = fields[key];
                  const { columnProps, validationRules, ...fieldProps } =
                    fieldVal;

                  return (
                    <Col {...columnProps} key={`${fieldVal?.id}-${key}`}>
                      <FormGroup>
                        <Controller
                          control={control}
                          name={fieldProps?.name}
                          render={({
                            field: { onChange, onBlur, value, ref, ...rest },
                            fieldState: { error },
                          }) => {
                            let formFieldState = {
                              field: {
                                ...fieldProps,
                                ...rest,
                                innerRef: ref,
                                onChange: fieldProps?.onChange
                                  ? fieldProps?.onChange(onChange)
                                  : onChange,
                                onBlur: fieldProps?.onBlur
                                  ? (e) => onBlur(fieldProps?.onBlur)
                                  : onBlur,
                                value: value || "",
                              },
                              error,
                            };
                            if (fieldProps.type === "switch")
                              formFieldState = {
                                ...formFieldState,
                                field: {
                                  ...formFieldState.field,
                                  checked: value,
                                },
                              };
                            if (fieldProps.type === "richText")
                              formFieldState = {
                                ...formFieldState,
                                field: {
                                  ...formFieldState.field,
                                  initialContent: defaultData?.description,
                                  onEditorContentChange: onChange,
                                },
                              };
                            return formField(formFieldState);
                          }}
                        ></Controller>
                      </FormGroup>
                    </Col>
                  );
                })}
              </Row>
            }
            <Row>
              <Col className="d-flex justify-content-end">
                <FormGroup>
                  <Button
                    type="button"
                    className="btn btn-light mr-1"
                    onClick={handleCancel}
                  >
                    Cancel
                  </Button>
                  <Button color="primary">Save</Button>
                </FormGroup>
              </Col>
            </Row>
          </form>

          {/* <FormBuilder
            schema={schema}
            fields={fields}
            onSubmit={handleSubmit}
          /> */}
        </CardBody>
      </Card>
    </>
  );
};

export default AddEditUserRoles;
