import moment from "moment";
import React from "react";
import { selectFilter, textFilter } from "react-bootstrap-table2-filter";
import ListView from "../../components/ListView";
import StatusDropdown from "../../components/StatusDropdown/StatusDropdown";
import { DEFAULT_STATUS } from "../../constants";
import {
  deleteServiceProviderBusiness,
  getServiceProviderBusinesses,
  updateServiceProviderBusinessStatus,
} from "../../redux/serviceProviderBusiness/actions";
import { Button } from "react-bootstrap";
import { ShoppingCart } from "react-feather";
import { useHistory } from "react-router-dom";
const columnData = ({ hasFilter = false }) => [
  {
    dataField: "id",
    text: "ID",
    sort: true,
  },
  {
    dataField: "businessName",
    text: "Businees Name",
    sort: true,
    filter: textFilter(),
  },
  {
    dataField: "registerNumber",
    text: "Register Number",
    sort: true,
    filter: textFilter(),
  },
  {
    dataField: "licenceNumber",
    text: "Licence Number",
    sort: true,
    filter: textFilter(),
  },
  {
    dataField: "businessEmail",
    text: "Business email",
    sort: true,
    filter: textFilter(),
  },
  {
    dataField: "businessContactNumber",
    text: "Contact number",
    sort: true,
    filter: textFilter(),
  },
  {
    dataField: "businessContactPerson",
    text: "Contact Person name",
    sort: true,
    filter: textFilter(),
  },
  {
    dataField: "businessAddress1",
    text: "Address",
    sort: true,
    formatter: (cell, row) => (
      <span>{`${row?.businessAddress1}, ${row?.businessAddress2}, ${row?.businessCity}`}</span>
    ),
  },
  {
    dataField: "country.countryName",
    text: "Country",
    sort: true,
  },
  {
    dataField: "province.provinceName",
    text: "Province",
    sort: true,
  },
  {
    dataField: "created_at",
    text: "Created at",
    sort: true,
    filter: textFilter(),
    formatter: (cell, row) =>
      row?.created_at
        ? moment(row?.created_at).format("DD-MM-YYYY hh:mm:A")
        : null,
  },
  {
    dataField: "updated_at",
    text: "Updated at",
    sort: true,
    filter: textFilter(),
    formatter: (cell, row) =>
      row?.updated_at
        ? moment(row?.updated_at).format("DD-MM-YYYY hh:mm:A")
        : null,
  },
  {
    dataField: "status",
    text: "Status",
    sort: true,
    editable: true,
    formatter: (cel, row) => {
      console.log(row?.status);
      return (
        <StatusDropdown
          defaultValue={{
            value: row?.status,
            label: row?.status ? "Active" : "Inactive",
          }}
          options={[
            { label: "Active", value: true },
            { label: "Inactive", value: false },
          ]}
          dispatchOnChange={(val) =>
            updateServiceProviderBusinessStatus({
              status: val?.value,
              id: row?.id,
            })
          }
        />
      );
    },

    filter: hasFilter && selectFilter({ options: DEFAULT_STATUS }),
  },
];
const ListGarages = () => {
  const history = useHistory();
  const handleCartClick = (id) => (e) => {
    history.push(`/cart/${id}`);
  };
  return (
    <ListView
      columnData={columnData({ hasFilter: false })}
      deleteAction={deleteServiceProviderBusiness}
      getAction={getServiceProviderBusinesses}
      baseUrl={"/service-provider/business"}
      actionAccess={{ canDelete: false }}
      renderAction={(row) => (
        <Button
          variant="outline-secondary"
          size="sm"
          className="m-2"
          onClick={handleCartClick(row?.userId)}
        >
          <ShoppingCart size={14} />
        </Button>
      )}
      breadCrumbLabel="List service provider business"
      breadCrumbTitle="List of Service Provider Businesses"
      reducerName={"ServiceProviderBusiness"}
    />
  );
};

export default ListGarages;
