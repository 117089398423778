import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import { fetchJSON } from "../../helpers/api";
import {
  deleteRolesFailed,
  deleteRolesSuccess,
  getRoleFailed,
  getRoleSuccess,
  getRolesFailed,
  getRolesSuccess,
  saveRolesFailed,
  saveRolesSuccess,
} from "./actions";
import { DELETE_ROLES, GET_ROLE, GET_ROLES, SAVE_ROLES } from "./constants";
import { deleteRolesApi, saveRolesApi } from "../../services/api";
import { appNotification } from "../app/actions";

function* getRolesSaga() {
  try {
    const response = yield call(fetchJSON, "/roles", {
      method: "get",
      headers: {
        "content-type": "application/json",
      },
    });
    yield put(getRolesSuccess(response));
  } catch (error) {
    let message = "Failed to get user roles";
    yield put(getRolesFailed(message));
  }
}

function* getRoleSaga({ payload }) {
  try {
    const response = yield call(fetchJSON, `/roles/${payload?.id}`, {
      method: "get",
      headers: {
        "content-type": "application/json",
      },
    });
    yield put(getRoleSuccess(response));
  } catch (error) {
    let message = "Failed to get user roles";
    yield put(getRoleFailed(message));
  }
}

function* saveRolesSaga({ payload }) {
  try {
    const response = yield call(saveRolesApi, payload);
    console.log("success resp", response);

    yield put(saveRolesSuccess(response?.data));
  } catch (error) {
    console.log("error", error);

    if (error?.response?.status != 422) {
      let message = "";
      if (error) message = "Oops something went wrong";
      yield put(appNotification({ type: "danger", message }));
      yield put(saveRolesFailed(error?.response?.message));
    } else {
      const { errors } = error?.response?.data;
      yield put(saveRolesFailed(errors));
    }
  }
}
function* deleteRolesSaga({ payload: { id } }) {
  try {
    const response = yield call(deleteRolesApi, { id });
    if (response?.status === "Success")
      yield put(deleteRolesSuccess(response?.data));
    else yield put(deleteRolesFailed(response?.message));
  } catch (e) {
    yield put(deleteRolesFailed(e?.response?.data));
  }
}

export function* watchGetRoles() {
  yield takeEvery(GET_ROLES, getRolesSaga);
  yield takeEvery(GET_ROLE, getRoleSaga);
  yield takeEvery(SAVE_ROLES, saveRolesSaga);
  yield takeEvery(DELETE_ROLES, deleteRolesSaga);
}

function* rolesSaga() {
  yield all([fork(watchGetRoles)]);
}

export default rolesSaga;
