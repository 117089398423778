import { all, call, put, takeLatest } from "redux-saga/effects";

import {
  deleteRfqResponseFailed,
  deleteRfqResponseSuccess,
  DELETE_RFQ_RESPONSE,
  getRfqResponseDetailFailed,
  getRfqResponseDetailQuotationFailed,
  getRfqResponseDetailQuotationSuccess,
  getRfqResponseDetailSuccess,
  getRfqResponseFailed,
  getRfqResponseSuccess,
  GET_RFQ_RESPONSE,
  GET_RFQ_RESPONSE_DETAIL,
  GET_RFQ_RESPONSE_QUOTATION_DETAIL,
  saveRfqResponseFailed,
  saveRfqResponseSuccess,
  SAVE_RFQ_RESPONSE,
} from "./actions";

import {
  deleteRfqResponseApi,
  getRfqResponseApi,
  getRfqResponseDetailApi,
  getRfqResponseQuotationDetailApi,
  saveRfqResponseApi,
} from "../../services/api";

import { appNotification } from "../app/actions";

function* saveRfqResponseSaga({ payload }) {
  try {
    const data = yield call(saveRfqResponseApi, payload?.payload);
    yield put(saveRfqResponseSuccess(data));
    if (payload?.onSuccess) payload?.onSuccess(data);
  } catch (error) {
    if (error?.response?.status != 422) {
      let message = "";
      if (error) message = "Oops something went wrong";
      yield put(saveRfqResponseFailed(message));
    } else {
      const { errors } = error?.response?.data;
      console.log("errors", errors);

      yield put(saveRfqResponseFailed(errors));
    }
    if (error?.response?.status === 417) {
      appNotification({
        message: "Oops something went wrong",
        title: "Error",
        type: "danger",
      });
    }
  }
}
function* getRfqResponseSaga({ payload }) {
  try {
    const data = yield call(getRfqResponseApi, payload);
    yield put(getRfqResponseSuccess(data?.data));
  } catch (error) {
    if (error?.response?.status != 422) {
      let message = "";
      if (error) message = "Oops something went wrong";
      yield put(getRfqResponseFailed(error));
    } else {
      const { errors } = error?.response?.data;
      console.log("errors", errors);
      yield put(getRfqResponseFailed(errors));
    }
  }
}

function* getRfqResponseDetailSaga({ payload }) {
  try {
    const data = yield call(getRfqResponseDetailApi, payload);
    yield put(getRfqResponseDetailSuccess(data?.data));
  } catch (error) {
    if (error?.response?.status != 422) {
      let message = "";
      if (error) message = "Oops something went wrong";
      yield put(getRfqResponseDetailFailed(error));
    } else {
      const { errors } = error?.response?.data;
      console.log("errors", errors);
      yield put(getRfqResponseDetailFailed(errors));
    }
  }
}
function* getRfqResponseDetailQuotationSaga({ payload }) {
  try {
    const data = yield call(getRfqResponseQuotationDetailApi, payload);
    yield put(getRfqResponseDetailQuotationSuccess(data?.data));
  } catch (error) {
    if (error?.response?.status != 422) {
      let message = "";
      if (error) message = "Oops something went wrong";
      yield put(getRfqResponseDetailQuotationFailed(error));
    } else {
      const { errors } = error?.response?.data;
      console.log("errors", errors);
      yield put(getRfqResponseDetailQuotationFailed(errors));
    }
  }
}

function* deleteRfqResponseSaga({ payload }) {
  try {
    console.log("payload", payload);

    const response = yield call(deleteRfqResponseApi, payload);
    if (response?.status === "Success")
      yield put(deleteRfqResponseSuccess(response?.data));
    else yield put(deleteRfqResponseFailed(response?.message));
  } catch (e) {
    yield put(deleteRfqResponseFailed(e?.response?.data));
  }
}

function* rfqResponseSaga() {
  yield all([
    takeLatest(SAVE_RFQ_RESPONSE, saveRfqResponseSaga),
    takeLatest(GET_RFQ_RESPONSE, getRfqResponseSaga),
    takeLatest(GET_RFQ_RESPONSE_DETAIL, getRfqResponseDetailSaga),
    takeLatest(
      GET_RFQ_RESPONSE_QUOTATION_DETAIL,
      getRfqResponseDetailQuotationSaga
    ),
    takeLatest(DELETE_RFQ_RESPONSE, deleteRfqResponseSaga),
  ]);
}

export default rfqResponseSaga;
