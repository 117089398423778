// @flow
import { Cookies } from "react-cookie";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import { fetchJSON } from "../../helpers/api";

import {
  SERVICE_PROVIDER_LOGIN_USER,
  SERVICE_PROVIDER_LOGOUT_USER,
  SERVICE_PROVIDER_REGISTER_USER,
  SERVICE_PROVIDER_FORGET_PASSWORD,
  SERVICE_PROVIDER_VERIFY_OTP,
} from "./constants";

import {
  loginUserSuccess,
  loginUserFailed,
  registerUserSuccess,
  registerUserFailed,
  forgetPasswordSuccess,
  forgetPasswordFailed,
  verifyOtpSuccess,
  verifyOtpFailed,
} from "./actions";

/**
 * Sets the session
 * @param {*} user
 */
const setSession = (data) => {
  let cookies = new Cookies();
  if (data?.user)
    cookies.set("user", JSON.stringify(data?.user), { path: "/" });
  else cookies.remove("user", { path: "/" });
};

/**
 * Login the user
 * @param {*} payload - username and password
 */
function* login({ payload: { email, password } }) {
  const options = {
    data: { email, password },
    method: "POST",
    headers: { "Content-Type": "application/json" },
  };

  try {
    const response = yield call(fetchJSON, "/api/auth/login", options);
    if (response?.status === "Error")
      yield put(loginUserFailed(response?.errors));
    else {
      setSession({ user: response.data });
      yield put(loginUserSuccess(response?.data));
    }
  } catch (error) {
    let message = "Oops something went wrong";

    yield put(loginUserFailed(message));
    setSession(null);
  }
}

/**
 * Logout the user
 * @param {*} param0
 */
function* logout({ payload: { history, token } }) {
  try {
    const response = yield call(fetchJSON, "/api/auth/logout", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    console.log("response", response);

    if (response.status !== "Error") {
      setSession(null);
      yield call(() => {
        history.push("/account/login");
      });
    } else {
      console.log("error", response);
    }
  } catch (error) {
    console.log("error", error);
  }
}

/**
 * Register the user
 */
function* register({ payload: { history, data } }) {
  console.log("p", data);
  const options = {
    data,
    method: "POST",
    headers: { "Content-Type": "application/json", Accept: "application/json" },
  };

  try {
    const response = yield call(
      fetchJSON,
      `/partner/${data.role}/register`,
      options
    );
    if (response?.status === "Error")
      yield put(registerUserFailed(response?.errors));
    else {
      console.log(response);
      yield put(registerUserSuccess({ ...data, otpId: response?.data?.id }));
      yield call(() => {
        history.push(`/partner/${data.role}/verify-otp`);
      });
    }
  } catch (error) {
    console.log(error);
    let message = "Oops something went wrong";
    yield put(registerUserFailed(message));
  }
}

/**
 * Verify the user with otp
 */
function* verifyOtp({ payload }) {
  const options = {
    data: payload,
    method: "POST",
    headers: { "Content-Type": "application/json" },
  };

  try {
    const response = yield call(
      fetchJSON,
      `/partner/${payload.role}/confirm-otp`,
      options
    );
    if (response?.status === "Error")
      yield put(verifyOtpFailed(response?.errors));
    else {
      setSession(response);
      yield put(verifyOtpSuccess(response));
    }
  } catch (error) {
    let message = "Oops something went wrong";
    yield put(registerUserFailed(message));
  }
}

/**
 * forget password
 */
function* forgetPassword({ payload: { username } }) {
  const options = {
    body: JSON.stringify({ username }),
    method: "POST",
    headers: { "Content-Type": "application/json" },
  };

  try {
    const response = yield call(fetchJSON, "/users/password-reset", options);
    yield put(forgetPasswordSuccess(response.message));
  } catch (error) {
    let message;
    switch (error.status) {
      case 500:
        message = "Internal Server Error";
        break;
      case 401:
        message = "Invalid credentials";
        break;
      default:
        message = error;
    }
    yield put(forgetPasswordFailed(message));
  }
}

export function* watchLoginUser() {
  yield takeEvery(SERVICE_PROVIDER_LOGIN_USER, login);
}

export function* watchLogoutUser() {
  yield takeEvery(SERVICE_PROVIDER_LOGOUT_USER, logout);
}

export function* watchRegisterUser() {
  yield takeEvery(SERVICE_PROVIDER_REGISTER_USER, register);
}

export function* watchForgetPassword() {
  yield takeEvery(SERVICE_PROVIDER_FORGET_PASSWORD, forgetPassword);
}

export function* watchVerifyOtp() {
  yield takeEvery(SERVICE_PROVIDER_VERIFY_OTP, verifyOtp);
}
function* partnerAuthSaga() {
  yield all([
    fork(watchLoginUser),
    fork(watchLogoutUser),
    fork(watchRegisterUser),
    fork(watchVerifyOtp),
    fork(watchForgetPassword),
  ]);
}

export default partnerAuthSaga;
