export const GET_POINT_HISTORY = "GET_POINT_HISTORY";
export const GET_POINT_HISTORY_SUCCESS = "GET_POINT_HISTORY_SUCCESS";
export const GET_POINT_HISTORY_FAILED = "GET_POINT_HISTORY_FAILED";

export const ADD_POINT_HISTORY = "ADD_POINT_HISTORY";
export const ADD_POINT_HISTORY_SUCCESS = "ADD_POINT_HISTORY_SUCCESS";
export const ADD_POINT_HISTORY_FAILED = "ADD_POINT_HISTORY_FAILED";

export const UPDATE_POINT_HISTORY = "UPDATE_POINT_HISTORY";
export const UPDATE_POINT_HISTORY_SUCCESS = "UPDATE_POINT_HISTORY_SUCCESS";
export const UPDATE_POINT_HISTORY_FAILED = "UPDATE_POINT_HISTORY_FAILED";
