import { all, call, put, takeLatest } from "redux-saga/effects";

import {
  getPointHistoryFailed,
  getPointHistorySuccess,
  addPointHistoryFailed,
  addPointHistorySuccess,
  addPointHistory,
  updatePointHistorySuccess,
  updatePointHistoryFailed,
} from "./actions";

import {
  addPointHistoryApi,
  getPointHistoryApi,
  updatePointHistoryApi,
} from "../../services/api";
import { appNotification } from "../app/actions";
import {
  ADD_POINT_HISTORY,
  GET_POINT_HISTORY,
  UPDATE_POINT_HISTORY,
} from "./constants";
import { authRequest } from "../../helpers/api";

function* getPointHistorySaga({ payload }) {
  try {
    const data = yield call(getPointHistoryApi, payload);
    yield put(getPointHistorySuccess(data?.data));
  } catch (e) {
    yield put(getPointHistoryFailed(e?.response?.data));

    appNotification({
      message: "Oops something went wrong",
      type: "danger",
    });
  }
}

function* addPointSaga({ payload }) {
  try {
    const data = yield call(addPointHistoryApi, payload);
    yield put(addPointHistorySuccess(data));
    yield put(
      appNotification({
        type: "success",
        message:
          "Redeem point request has been successfully placed, you will get your rewards within 3 to 5 business days",
      })
    );
  } catch (e) {
    yield put(addPointHistoryFailed(e?.response?.data));

    appNotification({
      message: "Oops something went wrong",
      type: "danger",
    });
  }
}

function* updatePointSaga({ payload }) {
  try {
    let url = `/user-points`;
    if (payload?.id) url += `/${payload?.id}?_method=PUT`;

    const response = yield call(authRequest, url, {
      method: "post",
      data: payload,
      headers: {
        "content-type": "application/json",
      },
    });
    yield put(updatePointHistorySuccess(response?.data));
    yield put(
      appNotification({
        type: "success",
        message:
          "Redeem point request has been successfully placed, you will get your rewards within 3 to 5 business days",
      })
    );
  } catch (e) {
    yield put(updatePointHistoryFailed(e?.response?.data));

    appNotification({
      message: "Oops something went wrong",
      type: "danger",
    });
  }
}

function* pointHistorySaga() {
  yield all([
    takeLatest(GET_POINT_HISTORY, getPointHistorySaga),
    takeLatest(ADD_POINT_HISTORY, addPointSaga),
    takeLatest(UPDATE_POINT_HISTORY, updatePointSaga),
  ]);
}

export default pointHistorySaga;
