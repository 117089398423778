import Joi from "joi";
import formValidationRules from "../../utils/formValidationRules";
import {
  getCitiesWithPaginateApi,
  getCityOption,
  getCountryOption,
  getsparePartsSubCategories,
  getSpartPartsCategory,
  getSupplierDealershipTypeOption,
  getSupplierList,
  getVehicleMakeOption,
} from "../../services/api";
export const verificationStatusOptions = {
  pending: { label: "Pending", value: "pending" },
  verified: { label: "Verified", value: "verified" },
  unverified: { label: "Not Verified", value: "unverified" },
};
export const fields = {
  businessName: {
    type: "text",
    name: "businessName",
    id: "businessName",
    label: "Business name",
    placeholder: "xyz company",
    validationRules: Joi.string().required(),
    columnProps: {
      sm: 12,
      md: 6,
      lg: 6,
    },
  },

  businessContactNumber: {
    type: "phoneNumber",
    name: "businessContactNumber",
    id: "businessContactNumber",
    label: "Business contact number",
    phoneCodeProps: {
      name: "businessContactNumberCode",
    },
    validationRules: formValidationRules.phoneNumber.allow("", 0),
    columnProps: {
      sm: 12,
      md: 6,
      lg: 6,
    },
  },
  businessContactPerson: {
    type: "text",
    name: "businessContactPerson",
    id: "businessContactPerson",
    label: "Business contact person",
    validationRules: formValidationRules.name,
    columnProps: {
      sm: 12,
      md: 6,
      lg: 6,
    },
  },
  businessEmail: {
    type: "email",
    name: "businessEmail",
    id: "businessEmail",
    label: "Business email (Optional)",
    validationRules: formValidationRules.email.allow("", 0),
    columnProps: {
      sm: 12,
      md: 6,
      lg: 6,
    },
  },
  businessWebsite: {
    type: "url",
    name: "businessWebsite",
    id: "businessWebsite",
    label: "Website url (Optional)",
    placeholder: "https://www.example.com",
    validationRules: formValidationRules.optionalField,
    columnProps: {
      sm: 12,
      md: 6,
      lg: 6,
    },
  },

  businessCity: {
    type: "asyncSelect",
    name: "businessCity",
    id: "businessCity",
    label: "City",
    getOptionLabel: (option) => option.name,
    getOptionValue: (option) => option.id,
    validationRules: Joi.object()
      .required()
      .messages({ "object.base": `City is required` }),
    loadOptions: (search) => getCitiesWithPaginateApi({ search }),
    columnProps: {
      sm: 12,
      md: 6,
      lg: 6,
    },
  },

  gMapLink: {
    type: "url",
    name: "gMapLink",
    id: "gMapLink",
    label: "Google map link (Optional)",
    validationRules: formValidationRules.optionalField,
    columnProps: {
      sm: 12,
      md: 6,
      lg: 6,
    },
  },

  dealershipTypeId: {
    type: "asyncSelect",
    name: "dealershipTypeId",
    validationRules: Joi.required(),
    getOptionLabel: (option) => option.type,
    getOptionValue: (option) => option.id,
    id: "dealershipTypeId",
    label: "Type of dealership",
    loadOptions: getSupplierDealershipTypeOption,
    columnProps: {
      sm: 12,
      md: 6,
      lg: 6,
    },
  },
  vehicleMake: {
    type: "multiSelectCheckbox",
    name: "vehicleMake",
    dropdownFieldLabel: "Vehicle make",
    dropdownFieldName: "vehicleMake",
    checkboxFieldLabel: "Vehicle model",
    checkboxFieldName: "vehicleModel",
    checkboxFieldLabelKey: "name",
    checkboxFieldIdKey: "id_car_model",
    getOptionLabel: (option) => option.name,
    getOptionValue: (option) => option.id_car_make,
    labelField: "dropdownFieldLabel",
    // loadOptions: getVehicleMakeOption,
    validationRules: Joi.array()
      .required()
      .messages({ "array.base": `Vehicle make is required` }),
    columnProps: {
      sm: 12,
      md: 6,
      lg: 6,
    },
  },
  supplierType: {
    type: "asyncSelect",
    name: "supplierType",
    isMulti: true,
    validationRules: Joi.array()
      .required()
      .messages({ "array.base": `Supplier type is required` }),
    getOptionLabel: (option) => option.categoryName,
    getOptionValue: (option) => option.id,
    id: "supplierType",
    label: "Supplier type",
    loadOptions: () => getSpartPartsCategory({ level: 1, search: "" }),
    columnProps: {
      sm: 12,
      md: 6,
      lg: 6,
    },
  },
  category: {
    type: "multiSelectCheckbox",
    name: "category",
    dropdownFieldLabel: "Spare parts category",
    dropdownFieldName: "category",
    checkboxFieldLabel: "Sub category",
    checkboxFieldName: "subCategory",
    checkboxFieldLabelKey: "categoryName",
    checkboxFieldIdKey: "id",
    getOptionLabel: (option) => option.categoryName,
    getOptionValue: (option) => option.id,
    labelField: "dropdownFieldLabel",
    validationRules: Joi.array()
      .required()
      .messages({ "array.base": `Category is required` }),
    columnProps: {
      sm: 12,
      md: 6,
      lg: 6,
    },
  },
  userId: {
    type: "hidden",
    name: "userId",
    validationRules: Joi.required(),
    label: "user id",
  },
  provider: {
    type: "hidden",
    name: "provider",
    validationRules: Joi.required(),
    label: "provider",
  },
  photos: {
    type: "image",
    multiple: true,
    name: "photos",
    id: "photos",
    label: "Photos",
    validationRules: Joi.any().optional(),
    accept: "image/*",
    columnProps: {
      sm: 12,
      md: 6,
      lg: 6,
    },
  },
};
export const schema = (fields) => {
  const validationObject = {};
  Object.keys(fields).forEach(
    (v) =>
      fields[v]?.validationRules &&
      (validationObject[v] = fields[v].validationRules.label(
        fields[v]?.label
          ? fields[v]?.label
          : fields[v]?.labelField
          ? fields[v]?.[fields[v]?.labelField]
          : "field"
      ))
  );
  return Joi.object({
    ...validationObject,
    id: Joi.allow(""),
    vehicleModel: Joi.required(),
    subCategory: Joi.optional(),
    businessContactNumberCode: Joi.number().required(),
  }).options({
    abortEarly: false,
  });

  //   Joi.object({
  //     userId: Joi.object()
  //       .required()
  //       .label("Service provider")
  //       .messages({ "object.base": `"Service provider" is required` }),
  //     businessName: formValidationRules.name.label("Business name"),
  //     businessEmail: formValidationRules.email.label("Business email"),
  //     businessContactPerson: formValidationRules.name.label(
  //       "Business contact person"
  //     ),
  //     businessContactNumber: formValidationRules.phoneNumber.label(
  //       "Business contact number"
  //     ),
  //     businessCountryId: Joi.object().required().label("Country"),
  //     businessProvinceId: Joi.object().required().label("Province"),
  //     businessCity: Joi.string().required(),
  //     businessPostalCode: Joi.number(),
  //     businessAddress1: Joi.string().required(),
  //     businessAddress2: formValidationRules.optionalField,
  //     specialization: Joi.array().required(),
  //     vehicleMake: Joi.array().required(),
  //     description: formValidationRules.optionalField,
  //     licenceNumber: Joi.string().required(),
  //     businessWebsite: Joi.string().optional(),
  //     logo: formValidationRules.optionalField,
  //     // latitude: formValidationRules.optionalField,
  //     // longitude: formValidationRules.optionalField,
  //     status: Joi.boolean(),
  //     licenceDocument: Joi.any(),
  //     photos: Joi.any().optional(),
  //     workingDays: Joi.array(),
  //     // workingStartHours: Joi.array(),
  //     // workingEndHours: Joi.array(),
  //     gMapLink: formValidationRules.optionalField,
  //     gMapPlusCode: formValidationRules.optionalField,
  //   }).options({
  //     abortEarly: false,
  //   });
};
