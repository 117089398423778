import {
  all,
  call,
  fork,
  put,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";

import { authRequest } from "../../helpers/api";
import { appNotification } from "../app/actions";
import {
  deleteVehicleTypeFailed,
  deleteVehicleTypeSuccess,
  getVehicleTypesFailed,
  getVehicleTypesSuccess,
  getVehicleTypeFailed,
  getVehicleTypeSuccess,
} from "./actions";
import {
  DELETE_VEHICLE_TYPE,
  GET_VEHICLE_TYPE,
  SAVE_VEHICLE_TYPE,
  VEHICLE_TYPE,
} from "./constants";

function* getVehicleTypes({ payload }) {
  try {
    let url = `/vehicle-type?`;
    if (payload)
      Object.keys(payload).map(
        (params) =>
          payload?.[params] && (url += `${params}=${payload[params]}&`)
      );
    const response = yield call(authRequest, url.slice(0, url.length - 1), {
      method: "get",
    });
    console.log("response", response);
    yield put(getVehicleTypesSuccess(response?.data));
  } catch (error) {
    let message = "Failed to get vehicle type";
    yield put(getVehicleTypesFailed(message));
  }
}

function* saveVehicleType({ payload: { data, history } }) {
  try {
    console.log("data", data);
    const formData = new FormData();
    Object.keys(data).map((key) => formData.append(key, data[key]));
    let url = `/vehicle-type`;
    if (data?.id) url += `/${data?.id}?_method=PUT`;

    const response = yield call(authRequest, url, {
      method: "post",
      data: formData,
      headers: {
        "content-type": "multipart/form-data",
      },
    });
    yield put(getVehicleTypesSuccess(response?.data));
    yield put(appNotification({ type: "success", message: response?.message }));
    history.goBack();
  } catch (error) {
    let message = "Failed to get vehicle type";
    yield put(getVehicleTypesFailed(message));
  }
}
function* getVehicleType({ payload: { id } }) {
  try {
    const response = yield call(authRequest, `/vehicle-type/${id}`, {
      method: "get",
    });
    yield put(getVehicleTypeSuccess(response?.data));
  } catch (error) {
    let message = "Failed to get vehicle type";
    yield put(getVehicleTypeFailed(message));
  }
}
function* deleteVehicleType({ payload: { id } }) {
  try {
    const response = yield call(authRequest, `/vehicle-type/${id}`, {
      method: "delete",
    });
    yield put(appNotification({ type: "success", message: response?.message }));
    yield put(deleteVehicleTypeSuccess({ id }));
  } catch (error) {
      console.log('error',error)
    let message = "Failed to get vehicle type";
    yield put(deleteVehicleTypeFailed(message));
  }
}
export function* watchGetVehicleTypes() {
  yield takeLatest(VEHICLE_TYPE, getVehicleTypes);
}
export function* watchGetServiceCategory() {
  yield takeEvery(GET_VEHICLE_TYPE, getVehicleType);
}
export function* watchSaveServiceCategory() {
  yield takeEvery(SAVE_VEHICLE_TYPE, saveVehicleType);
}
export function* watchDeleteServiceCategory() {
  yield takeEvery(DELETE_VEHICLE_TYPE, deleteVehicleType);
}
function* vehicleTypeSaga() {
  yield all([
    fork(watchGetVehicleTypes),
    fork(watchSaveServiceCategory),
    fork(watchGetServiceCategory),
    fork(watchDeleteServiceCategory),
  ]);
}

export default vehicleTypeSaga;
