import moment from "moment";
import React, { useEffect, useState } from "react";
import { selectFilter, textFilter } from "react-bootstrap-table2-filter";
import ListView from "../../components/ListView";
import OrderStatusDropDown from "../../components/OrderStatusDropDown";
import Status from "../../components/Status";
import VerificationStatusDropdown from "../../components/VerificationStatusDropdown";
import { VERIFICATION_STATUS } from "../../constants";
import {
  deleteSupplierBusiness,
  getSupplierBusinesses,
} from "../../redux/supplierBusiness/actions";

const columnData = [
  {
    dataField: "id",
    text: "ID",
    sort: true,
  },
  {
    dataField: "businessName",
    text: "Businees Name",
    sort: true,
    filter: textFilter(),
  },
  {
    dataField: "dealership_type.type",
    text: "dealership type",
    sort: true,
    filter: textFilter(),
  },
  {
    dataField: "licenceNumber",
    text: "Licence Number",
    sort: true,
    filter: textFilter(),
  },
  {
    dataField: "businessEmail",
    text: "Business email",
    sort: true,
    filter: textFilter(),
  },
  {
    dataField: "businessContactNumber",
    text: "Contact number",
    sort: true,
    filter: textFilter(),
  },
  {
    dataField: "businessContactPerson",
    text: "Contact Person name",
    sort: true,
    filter: textFilter(),
  },
  {
    dataField: "businessAddress1",
    text: "Address",
    sort: true,
    formatter: (cell, row) => (
      <span>{`${row?.businessAddress1}, ${row?.businessAddress2}, ${row?.businessCity}`}</span>
    ),
  },
  {
    dataField: "country.countryName",
    text: "Country",
    sort: true,
  },
  {
    dataField: "province.provinceName",
    text: "Province",
    sort: true,
  },

  {
    dataField: "updated_at",
    text: "Updated at",
    sort: true,
    filter: textFilter(),
    formatter: (cell, row) =>
      row?.updated_at
        ? moment(row?.updated_at).format("DD-MM-YYYY hh:mm:A")
        : null,
  },
  {
    dataField: "verification_status",
    text: "Verification Status",
    sort: true,
    editable: true,
    formatter: (cell, row) => (
      <span className="badge badge-soft-warning py-1">
        {VERIFICATION_STATUS[row?.verification_status]}
      </span>
    ),
    editorRenderer: (
      editorProps,
      value,
      row,
      column,
      rowIndex,
      columnIndex
    ) => (
      <div style={{ width: 150 }}>
        <VerificationStatusDropdown
          {...editorProps}
          value={value}
          businessId={row?.id}
        />
      </div>
    ),

  },
  {
    dataField: "status",
    text: "Status",
    sort: true,
    formatter: (cel, row) => <Status status={row?.status} />,
    filter: selectFilter({ options: { 1: "Active", 0: "Inactive" } }),
  },
];

const ListShop = () => {
  return (
    <ListView
      columnData={columnData}
      deleteAction={deleteSupplierBusiness}
      getAction={getSupplierBusinesses}
      baseUrl={"/supplier/business"}
      actionAccess={{ canDelete: false }}
      breadCrumbLabel="List Supplier business"
      breadCrumbTitle="List of Supplier Businesses"
      reducerName={"SupplierBusiness"}
      canCellEdit={true}
    />
  );
};

export default ListShop;
