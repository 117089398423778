// @flow
import {
  SERVICE_PROVIDER_LOGIN_USER,
  SERVICE_PROVIDER_LOGIN_USER_SUCCESS,
  SERVICE_PROVIDER_LOGIN_USER_FAILED,
  SERVICE_PROVIDER_LOGOUT_USER,
  SERVICE_PROVIDER_REGISTER_USER,
  SERVICE_PROVIDER_REGISTER_USER_SUCCESS,
  SERVICE_PROVIDER_REGISTER_USER_FAILED,
  SERVICE_PROVIDER_FORGET_PASSWORD,
  SERVICE_PROVIDER_FORGET_PASSWORD_SUCCESS,
  SERVICE_PROVIDER_FORGET_PASSWORD_FAILED,
  SERVICE_PROVIDER_VERIFY_OTP,
  SERVICE_PROVIDER_VERIFY_OTP_SUCCESS,
  SERVICE_PROVIDER_VERIFY_OTP_FAILED,
} from "./constants";

import { getLoggedInUser } from "../../helpers/authUtils";

const INIT_STATE = {
  user: getLoggedInUser(),
  otpData: null,
  loading: false,
};

const Auth = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SERVICE_PROVIDER_LOGIN_USER:
      return { ...state, loading: true };
    case SERVICE_PROVIDER_LOGIN_USER_SUCCESS:
      return { ...state, user: action.payload, loading: false, error: null };
    case SERVICE_PROVIDER_LOGIN_USER_FAILED:
      return { ...state, error: action.payload, loading: false };
    case SERVICE_PROVIDER_REGISTER_USER:
      return { ...state, loading: true };
    case SERVICE_PROVIDER_REGISTER_USER_SUCCESS:
      return { ...state, otpData: action.payload, loading: false, error: null };
    case SERVICE_PROVIDER_REGISTER_USER_FAILED:
      return { ...state, error: action.payload, loading: false };
    case SERVICE_PROVIDER_LOGOUT_USER:
      return { ...state, user: null };
    case SERVICE_PROVIDER_FORGET_PASSWORD:
      return { ...state, loading: true };
    case SERVICE_PROVIDER_FORGET_PASSWORD_SUCCESS:
      return {
        ...state,
        passwordResetStatus: action.payload,
        loading: false,
        error: null,
      };
    case SERVICE_PROVIDER_FORGET_PASSWORD_FAILED:
      return { ...state, error: action.payload, loading: false };

    case SERVICE_PROVIDER_VERIFY_OTP:
      return { ...state, loading: true };
    case SERVICE_PROVIDER_VERIFY_OTP_SUCCESS:
      return {
        ...state,
        user: action.payload,
        otpData: null,
        loading: false,
        error: null,
      };
    case SERVICE_PROVIDER_VERIFY_OTP_FAILED:
      return { ...state, error: action.payload, loading: false };

    default:
      return { ...state };
  }
};

export default Auth;
