// @flow
import {
  DELETE_SPARE_PARTS_CATEGORY,
  DELETE_SPARE_PARTS_CATEGORY_FAILED,
  DELETE_SPARE_PARTS_CATEGORY_SUCCESS,
  SPARE_PARTS_CATEGORY,
  SPARE_PARTS_CATEGORY_FAILED,
  SPARE_PARTS_CATEGORY_SUCCESS,
  GET_SPARE_PARTS_CATEGORY,
  GET_SPARE_PARTS_CATEGORY_FAILED,
  GET_SPARE_PARTS_CATEGORY_SUCCESS,
  GET_SPARE_PARTS_PARENT_CATEGORY,
  GET_SPARE_PARTS_PARENT_CATEGORY_SUCCESS,
  GET_SPARE_PARTS_PARENT_CATEGORY_FAILED,
} from "./constants";

const INIT_STATE = {
  data: [],
  loading: false,
  error: null,
  sparePartsCategory: {},
  sparePartParentCategory: {
    loading: false,
    error: null,
    data: [],
  },
};

const SparePartsCategory = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SPARE_PARTS_CATEGORY:
      return { ...state, sparePartsCategory: {}, loading: true };
    case SPARE_PARTS_CATEGORY_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
        error: null,
      };
    case SPARE_PARTS_CATEGORY_FAILED:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case GET_SPARE_PARTS_CATEGORY:
      return { ...state, loading: true };
    case GET_SPARE_PARTS_CATEGORY_SUCCESS:
      return {
        ...state,
        sparePartsCategory: action.payload,
        loading: false,
        error: null,
      };
    case GET_SPARE_PARTS_CATEGORY_FAILED:
      return { ...state, error: action.payload, loading: false };
    case DELETE_SPARE_PARTS_CATEGORY:
      return { ...state, loading: true };
    case DELETE_SPARE_PARTS_CATEGORY_SUCCESS: {
      return {
        ...state,
        data: {
          ...state?.data,
          total: state?.data?.total - 1,
          data: state?.data?.data?.filter((v) => v?.id !== action?.payload?.id),
        },
        loading: false,
        error: null,
      };
    }
    case DELETE_SPARE_PARTS_CATEGORY_FAILED:
      return { ...state, error: action.payload, loading: false };
    case GET_SPARE_PARTS_PARENT_CATEGORY:
      return {
        ...state,
        sparePartParentCategory: {
          ...state.sparePartParentCategory,
          loading: true,
        },
      };
    case GET_SPARE_PARTS_PARENT_CATEGORY_SUCCESS:
      return {
        ...state,
        sparePartParentCategory: {
          error: null,
          data: action.payload,
          loading: false,
        },
      };
    case GET_SPARE_PARTS_PARENT_CATEGORY_FAILED:
      return {
        ...state,
        sparePartParentCategory: {
          error: action.payload,
          data: [],
          loading: false,
        },
      };
    default:
      return { ...state };
  }
};

export default SparePartsCategory;
