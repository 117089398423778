import { useEffect } from "react";
import { Button, Col, Row, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import useQuery from "../../hooks/useQuery";
import { getOrder } from "../../redux/orders/actions";
import Invoice from "../orders/Invoice";
import GeneratePdf from "../../components/GeneratePdf/GeneratePdf";
import { userSelector } from "../../redux/users/selectors";
import { API_URL } from "../../constants";
import { useState } from "react";
import AllInvoice from "../orders/AllInvoice";
import { downloadAllInvoiceApi } from "../../services/api";
import ReactSelect from "react-select";
import DatePicker from "react-flatpickr";
import { appNotification } from "../../redux/app/actions";
import PageTitle from "../../components/PageTitle";
import moment from "moment";
const dateRangeOptions = [
  { label: "Last month", value: "last_month" },
  { label: "Last 3 month", value: "last_3_month" },
  { label: "Last 6 month", value: "last_6_month" },
  { label: "Last year", value: "previous_year" },
  { label: "Custom", value: "custom" },
];
const DownloadAllInvoice = () => {
  const baseUrl = `/orders`;

  const { user } = useSelector(userSelector);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [showCustomDate, setShowCustomDate] = useState(false);
  const [order, setOrder] = useState([]);
  const [dateRange, setDateRange] = useState(null);
  const [dateRange1, setDateRange1] = useState(null);
  const [dateRange2, setDateRange2] = useState(null);
  const PER_PAGE = 100;

  const _downloadAllInvoice =
    ({ dateRange, dateRange1, dateRange2 }) =>
    async (e) => {
      try {
        setLoading(true);
        const data = await downloadAllInvoiceApi({
          page: 1,
          perPage: PER_PAGE,
          dateRangeType: dateRange,
          startDate: dateRange1,
          endDate: dateRange2,
        });
        dispatch(
          appNotification({
            type: "success",
            message: "All invoices are sent to mail at motonexa@gmail.com",
          })
        );
        //   if (data.total !== -1) {
        //     const totalPages = Math.ceil(data.total / PER_PAGE);
        //     for (let index = 1; index < totalPages; index++) {
        //      fetchAllInvoice({ page: index + 1, perPage: PER_PAGE });
        //     }
        //   }
      } catch (error) {
        dispatch(
          appNotification({
            type: "error",
            message: "An error occured",
          })
        );
      }
      setLoading(false);
    };
  const onDateRangeChange = (e) => {
    if (e.value === "custom") {
      setShowCustomDate(true);
    } else {
      setShowCustomDate(false);
      setDateRange1(null);
      setDateRange2(null);
    }
    setDateRange(e.value);
  };
  const onDateChange1 = (e) => {
    setDateRange1(moment(new Date(e)).format("YYYY-MM-DD"));
  };
  const onDateChange2 = (e) => {
    setDateRange2(moment(new Date(e)).format("YYYY-MM-DD"));
  };

  return (
    <div className="container">
      <Row className="page-title">
        <Col md={12}>
          <PageTitle
            breadCrumbItems={[
              { label: "Orders", path: baseUrl },
              {
                label: "download invoices",
                path: `${baseUrl}/download-all-invoice`,
                active: true,
              },
            ]}
            title={`Download invoices`}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={4}>
          <div className="d-flex flex-column justify-content-center">
            <div style={{ width: "100%" }}>
              <ReactSelect
                name="dateRangeType"
                // getOptionLabel={(option) => option.label}
                // getOptionValue={(option) => option.value}
                placeholder={"Select date range"}
                options={dateRangeOptions}
                // value={dateRange}
                onChange={onDateRangeChange}
              />
            </div>
            {showCustomDate && (
              <div className="p-1">
                <DatePicker
                  className="form-control"
                  onChange={onDateChange1}
                  placeholder="Select from"
                />
                <DatePicker
                  className="form-control"
                  onChange={onDateChange2}
                  placeholder="Select to"
                />
              </div>
            )}
            <br />
            <Button
              disabled={loading}
              onClick={_downloadAllInvoice({
                dateRange,
                dateRange1,
                dateRange2,
              })}
            >
              {!loading ? "Download invoice" : "Downloading..."}
            </Button>
          </div>
        </Col>
      </Row>
    </div>
  );
  // return (
  //   <GeneratePdf
  //     name={order?.invoiceNo || order?.orderNo}
  //     autoDownload={true}
  //   >
  //     <AllInvoice data={order} digitalSign={user?.digital_sign} />
  //   </GeneratePdf>
  // );
};

export default DownloadAllInvoice;
