import { useDispatch } from "react-redux";
import ReactSelect from "react-select";

const StatusDropdown = ({ dispatchOnChange, ...props }) => {
  const dispatch = useDispatch();
  const handleChange = (e) => {
    if (dispatchOnChange) {
      dispatch(dispatchOnChange(e));
    }
    if (props?.onChange) props?.onChange(e);
  };
  return <ReactSelect {...props} onChange={handleChange} />;
};

export default StatusDropdown;
