import "./DetailRfq.scss";
import { useQueryParam } from "beautiful-react-hooks";
import moment from "moment";
import { useEffect } from "react";
import { useState } from "react";
import { Button, Row, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import { Col } from "reactstrap";
import ImageSlider from "../../components/ImageSlider/ImageSlider";
import PageTitle from "../../components/PageTitle";
import { useModal } from "../../hooks/useModal";
import {
  getRfqRequestDetail,
  getRfqRequestDetailResponses,
} from "../../redux/rfq/actions";
import { getRfqNos } from "../../services/api";
import NoImage from "../../assets/images/no-image.png";
import LoadAsyncImage from "../../components/LoadAsyncImage";
import useQuery from "../../hooks/useQuery";
const DetailRfq = () => {
  const params = useParams();
  const history = useHistory();
  const search = useQuery();

  const [rfqOptions, setRfqOptions] = useState(null);
  const [addedInCart, setAddedInCart] = useState();
  const [loader, setLoader] = useState(0);
  const [itemImages, setImages] = useState([]);
  const [tabData, setTabData] = useState({
    activeTab: search?.get("active"),
    data: null,
  });
  const {
    rfqDetail: { data, error, loading },
    rfqDetailResponses: {
      data: rfqResponseData,
      error: rfqResponseError,
      loading: rfqResponseLoading,
    },
  } = useSelector((s) => s.Rfq);
  const dispatch = useDispatch();
  const { open: imageOpen, onClose, onOpen } = useModal();

  const setImagesModal = (photo) => (e) => {
    setImages(photo);
    onOpen();
  };
  const onTabChange = (tab) => (e) => {
    setTabData((s) => ({ ...s, activeTab: tab }));
    dispatch(getRfqRequestDetailResponses({ payload: { rfqRequestId: tab } }));
  };
  const handleRfqNoChange = (e) => {
    dispatch(getRfqRequestDetail({ payload: { id: e.target.value } }));
  };

  const fetchRfqOptions = async () => {
    const options = await getRfqNos();
    console.log(options);
    setRfqOptions(options);
  };

  useEffect(() => {
    dispatch(getRfqRequestDetail({ payload: { id: params?.id } }));
    dispatch(
      getRfqRequestDetailResponses({
        payload: { rfqRequestId: tabData?.activeTab },
      })
    );

    fetchRfqOptions();
  }, []);

  if (loading)
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "70vh" }}
      >
        <Spinner animation="border" />
      </div>
    );
  return (
    <div>
      <Row className="page-title">
        <Col md={12}>
          <PageTitle
            breadCrumbItems={[
              {
                label: "rfq",
                path: "/rfq",
              },
              {
                label: `${"detail"}`,
                path: `/rfq/detail/${params?.id}`,
                active: true,
              },
            ]}
            title={`Rfq no`}
          />
        </Col>
      </Row>

      <div className="rfq-request-detail-root">
        <div className="rfqlistMain">
          <div className="panel panel-default mt-5 mb-4">
            <div className="rfqdetailBox mb-3">
              <div className="rfqidTittle">
                <div className="d-flex justify-content-between">
                  <h5 className="mb-0 me-3">
                    <b>RFQ ID</b>
                  </h5>
                  <select
                    className="form-control"
                    id="exampleFormControlSelect1"
                    onChange={handleRfqNoChange}
                    value={data?.id || null}
                  >
                    {rfqOptions &&
                      rfqOptions?.map((v) => (
                        <option key={v?.id} value={v.id}>
                          {v.rfqNo}
                        </option>
                      ))}
                  </select>
                </div>
                <h5 className="rfqdate">
                  <b>Date: {moment(data?.created_at).format("DD MMM YY")}</b>
                </h5>
              </div>
              <div className="otherdetailsBox mt-4">
                <div className="row">
                  <div className="col-lg-4 col-6 col-md-4 col-sm-4 col-xl-2">
                    <div className="otherinfos">
                      <h5 className="otherdetailsredtittle">Car Maker</h5>
                      <h5 className="otherdetailsblacktittle">
                        <b>{data?.vehicle_make?.name}</b>
                      </h5>
                    </div>
                  </div>
                  <div className="col-lg-4 col-6 col-md-4 col-sm-4 col-xl-2">
                    <div className="otherinfos">
                      <h5 className="otherdetailsredtittle">Model Line</h5>
                      <h5 className="otherdetailsblacktittle">
                        <b>{data?.vehicle_model?.name}</b>
                      </h5>
                    </div>
                  </div>
                  <div className="col-lg-4 col-6 col-md-4 col-sm-4 col-xl-2">
                    <div className="otherinfos">
                      <h5 className="otherdetailsredtittle">Year</h5>
                      <h5 className="otherdetailsblacktittle">
                        <b>{data?.vehicle_year?.year}</b>
                      </h5>
                    </div>
                  </div>
                  <div className="col-lg-4 col-6 col-md-4 col-sm-4 col-xl-2">
                    <div className="otherinfos">
                      <h5 className="otherdetailsredtittle">Modification</h5>
                      <h5 className="otherdetailsblacktittle">
                        <b>{`${data?.modification?.car_serie?.name} ${data?.modification?.name}`}</b>
                      </h5>
                    </div>
                  </div>
                  <div className="col-lg-4 col-6 col-md-4 col-sm-4 col-xl-2">
                    <div className="otherinfos">
                      <h5 className="otherdetailsredtittle">Vin Number</h5>
                      <h5 className="otherdetailsblacktittle">
                        <b>{data?.vinNumber || "-"}</b>
                      </h5>
                    </div>
                  </div>
                  <div className="col-lg-4 col-6 col-md-4 col-sm-4 col-xl-2">
                    <div className="otherinfos">
                      <h5 className="otherdetailsredtittle">
                        No. of spare part
                      </h5>
                      <h5 className="otherdetailsblacktittle">
                        <b>{data?.rfq_request?.length}</b>
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="rfqlisttabs">
              <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                {data?.rfq_request &&
                  data?.rfq_request?.map((v, i) => (
                    <li key={v?.id} className="nav-item">
                      <Button
                        onClick={onTabChange(v?.id)}
                        className={`nav-link ${
                          parseInt(tabData?.activeTab) === v?.id ? "active" : ""
                        }`}
                        id="pills-home-tab"
                        data-toggle="pill"
                        variant="link"
                        role="tab"
                        aria-controls="pills-home"
                        aria-selected="true"
                      >
                        {v?.otherSparePartCategory ||
                          v?.sub_category?.categoryName ||
                          v?.category?.categoryName}
                      </Button>
                    </li>
                  ))}
              </ul>
              <div className="tab-content mt-4" id="pills-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="pills-home"
                  role="tabpanel"
                  aria-labelledby="pills-home-tab"
                >
                  <div className="steeringTab">
                    <div className="steeringTittle mb-3">
                      <h5 className="mb-0">
                        <b>Supplier Response</b>{" "}
                        <span>({rfqResponseData?.length || 0} Response)</span>
                      </h5>
                      <select
                        className="form-control"
                        id="exampleFormControlSelect2"
                      >
                        <option>Best match</option>
                        <option>Best Offer</option>
                        <option>Price Low to high</option>
                        <option>Price High to low</option>
                        <option>More Relevent</option>
                      </select>
                    </div>
                    <div className="row">
                      {rfqResponseLoading ? (
                        <div className="d-flex justify-content-center align-items-center">
                          <Spinner animation="border" />
                        </div>
                      ) : rfqResponseData ? (
                        rfqResponseData?.map((v, i) => (
                          <div
                            className="col-lg-6 col-xl-4"
                            key={`rfq-response-${v?.id}`}
                          >
                            <div className="steeringBox p-4 mb-3">
                              <div className="steeringtittle mb-3">
                                <div>
                                  {
                                    <span className="mb-0 h5 text-primary">
                                      <b>
                                        {v?.supplier_business?.businessName}
                                      </b>
                                    </span>
                                  }

                                  {/* <h5 className="mb-0 rattinglabel">
                                    <i className="fa fa-star" aria-hidden="true"></i>
                                    {v?.supplier_business?.googleRating} <span>(0 Views)</span>
                                  </h5> */}
                                </div>
                              </div>
                              <div
                                className="steeringBoximg mb-3 flex-column align-items-start"
                                onClick={setImagesModal(
                                  v?.photos?.map((photo) => photo?.filePath)
                                )}
                              >
                                {v?.photos?.length ? (
                                  <ImageSlider
                                    className="img-fluid product-image"
                                    paths={v?.photos
                                      ?.map((photo) => photo?.filePath)
                                      .filter((v1) => v1)}
                                    imageProps={{
                                      style: {
                                        height: 250,
                                        objectFit: "contain",
                                        backgroundColor: "#d8d8d8",
                                      },
                                    }}
                                  />
                                ) : (
                                  <img
                                    src={NoImage}
                                    style={{ width: "100%", height: 250 }}
                                    className="img-fluid me-3"
                                  />
                                )}
                                <div>
                                  <h5 className="mb-0">
                                    {v?.manufacturer ||
                                      "Manufacturer not specified"}
                                  </h5>
                                  <p className="mb-0">
                                    {v?.sparePartNameOrNumber || ""}
                                  </p>
                                  <p className="mb-0">{v?.quantity} Qty</p>
                                  <h5 className="text-primary-2 m-0">
                                    <b>AED {v?.price * v?.quantity} </b>
                                  </h5>
                                  <p className="font-size-11 p-0 m-0 text-grey">{`AED ${v?.price} per qty`}</p>
                                  <p className="font-size-11 p-0 m-0 text-bold">
                                    {" "}
                                    <b>{`Return valid for ${
                                      v?.return || 0
                                    } days`}</b>
                                  </p>

                                  <p className="font-size-11 p-0 m-0 text-bold">
                                    <b>{`Warranty ${v?.warranty || 0} days`}</b>
                                  </p>
                                </div>
                              </div>
                              <div className="streengdetials mb-3">
                                <p>{v?.description}</p>
                              </div>
                              <div className="steeringaudio">
                                {v?.voice_note ? (
                                  <LoadAsyncImage
                                    id={`audio-${v?.id}`}
                                    fileType="audio"
                                    path={v?.voice_note?.filePath}
                                  />
                                ) : (
                                  <audio controls />
                                )}
                              </div>
                            </div>
                          </div>
                        ))
                      ) : (
                        <h5>No reponses found</h5>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <PhotoEnlarger
        images={itemImages}
        open={imageOpen}
        handleOnClose={onClose}
      /> */}
    </div>
  );
};

export default DetailRfq;
