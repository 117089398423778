import React, { Component, useEffect, useState } from "react";
import Routes from "./routes/Routes";

// setup fake backend
import { configureFakeBackend } from "./helpers";

// Themes

// default
import "./assets/scss/theme.scss";
import { useDispatch, useSelector } from "react-redux";
import { getRoles } from "./redux/actions";
import { Toast, ToastBody } from "reactstrap";
import { appNotification, fetchCsrfToken } from "./redux/app/actions";
import { userSelector } from "./redux/users/selectors";

// dark
// import './assets/scss/theme-dark.scss';

// rtl
// import './assets/scss/theme-rtl.scss';

// configure fake backend
// configureFakeBackend();

/**
 * Main app component
 */
const App = () => {
  const dispatch = useDispatch();
  const { user } = useSelector(userSelector);
  const { type, message } = useSelector((state) => state.App);
  useEffect(() => {
    dispatch(getRoles());
  }, []);

  useEffect(() => {
    dispatch(fetchCsrfToken());
  }, [user]);

  useEffect(() => {
    if (message) {
      setTimeout(() => {
        dispatch(appNotification({ type, message: "" }));
      }, 2000);
    }
  }, [message]);
  return (
    <>
      <Routes></Routes>
      {message && (
        <Toast
          className={`bg-${type} text-white`}
          style={{ position: "fixed", top: 60, right: 10, zIndex: 100 }}
        >
          <ToastBody>{message}</ToastBody>
        </Toast>
      )}
    </>
  );
};

export default App;
