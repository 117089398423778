import styled from "styled-components";

export const DragDropText = styled.p`
  font-weight: bold;
  letter-spacing: 2.2px;
  margin-top: 0;
  text-align: center;
`;

export const FormField = styled.input`
  border: none;
  bottom: 0;
  display: block;
  font-size: 18px;
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  text-transform: none;
  top: 0;
  width: 100%;
  &:focus {
    outline: none;
  }
`;

export const FileMetaData = styled.div`
  aside {
    display: flex;
    justify-content: space-between;
    // margin-top: auto;
  }
  background-color: rgba(5, 5, 5, 0.55);
  border-radius: 6px;
  bottom: 0;
  color: white;
  display: ${({ isImageFile }) => (isImageFile ? "none" : "flex")};
  flex-direction: column;
  font-weight: bold;
  left: 0;
  padding: 6px;
  position: absolute;
  right: 0;
  top: 0;
`;

export const FilePreviewContainer = styled.article`
  margin-bottom: 35px;

  span {
    font-size: 14px;
  }
`;

export const FileUploadContainer = styled.section`
  align-items: center;
  background-color: white;
  border: 2px dotted #244e77;
  cursor: pointer;
  border-radius: 6px;
  display: ${({ isHidden }) => (isHidden ? "none" : "flex")};
  flex-basis: 50% !important;
  flex-direction: column;
  height: 100px;
  // margin: 5px;
  max-width: calc(50% - 2.5px);
  padding: 35px 20px;
  position: relative;
  // width: 100px;
`;

export const ImagePreview = styled.img`
  border-radius: 6px;
  height: 100px;
  object-fit: contain;
  width: 100%;
`;

export const InputLabel = styled.label`
  left: 0;
  position: absolute;
  top: 0px;
  white-space: nowrap;
`;

export const PreviewContainer = styled.section`
  border-radius: 6px;
  box-sizing: border-box;
  position: relative;
  flex-basis: 50% !important;
  height: 100px;
  max-width: calc(50% - 2.5px);

  &:hover {
    // opacity: 0.55;

    ${FileMetaData} {
      display: flex;
    }
  }

  & > div:first-of-type {
    align-items: center;
    display: none;
    height: 100px;
    justify-content: center;
    // position: relative;
    // width: 100px;
  }

  @media only screen and (max-width: 750px) {
    width: 25%;
  }

  @media only screen and (max-width: 500px) {
    width: 50%;
  }

  @media only screen and (max-width: 400px) {
    padding: 0 0 0.4em;
    width: 100%;
  }
`;

export const PreviewList = styled.section`
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  width: 100%;
  @media only screen and (max-width: 400px) {
    // flex-direction: column;
  }
`;

export const RemoveFileIcon = styled.i`
  background-color: #be3850;
  border-radius: 12px;
  color: white;
  cursor: pointer;
  display: flex;
  padding: 2px 2px;
  position: absolute;
  right: -5px;
  top: -5px;
  align-items: center;
  justify-content: center;
  &:hover {
    transform: scale(1.3);
  }
`;

export const UploadFileBtn = styled.button`
  align-items: center;
  appearance: none;
  background-color: transparent;
  border: none;
  border-radius: 6px;
  box-sizing: border-box;
  color: #244e77;
  cursor: pointer;
  display: flex;
  font-family: "DM Sans", sans-serif;
  font-size: 1rem;
  font-weight: 700;
  justify-content: center;
  line-height: 1;
  overflow: hidden;
  padding: 0.9em 1.2em;
  padding-right: 0;
  position: relative;
  text-align: center;
  text-transform: uppercase;
  transition: color 250ms ease-in-out;

  svg {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &:focus {
    background: transparent;
    outline: 0;
  }
  &:hover {
    background: grey;
  }

  &:disabled {
    filter: grayscale(100%);
    opacity: 0.4;
    pointer-events: none;
  }
`;

export const FileName = styled.span`
  font-size: 11px;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
`;
