import { csrfToken, fetchJSON } from "../../helpers/api";
import { all, call, fork, put, takeEvery, takeLatest } from "redux-saga/effects";
import { fetchCountryCodeSuccess, FETCH_COUNTRY_CODE, FETCH_CSRF_TOKEN } from "./actions";


function* countryCodeSaga({ payload }) {
  try {
    const data = yield call(
      fetchJSON,
      `/country-code?search=${payload?.search}`
    );
    yield put(fetchCountryCodeSuccess(data));
  } catch (e) {
    console.log("country code error", e);
  }
}
function* csrfTokenSaga() {
  try {
    yield call(csrfToken);
    // yield put(fetchUserSuccess(data));
    // @todo define exact type
  } catch (e) {
    console.log("xsrf error", e);

  }
}
export function* watchcountryCode() {
  yield takeEvery(FETCH_COUNTRY_CODE, countryCodeSaga);
}
export function* watchXsrf() {
  yield takeLatest(FETCH_CSRF_TOKEN, csrfTokenSaga);
}

function* appSaga() {
  yield all([
    fork(watchcountryCode),
    fork(watchXsrf),
  ]);
}
export default appSaga;
