import * as FeatherIcon from "react-feather";

import ListServiceCategory from "../pages/masters/serviceCategory/ListServiceCategory";
import ListSparePartsCategory from "../pages/masters/sparePartsCategory/ListSparePartsCategory";
import ListVehicleType from "../pages/masters/vehicleType/ListVehicleType";
import ListVehicleMake from "../pages/masters/vehicleMake/ListVehicleMake";
import ListVehicleModel from "../pages/masters/vehicleModel/ListVehicleModel";
import ListUsers from "../pages/userManagement/users/ListUsers";
import AddEditUsers from "../pages/userManagement/users/AddEditUsers";
import ViewUsers from "../pages/userManagement/users/ViewUsers";
import ListUserRoles from "../pages/userManagement/userRoles/ListUserRoles";
import AddEditUserRoles from "../pages/userManagement/userRoles/AddEditUserRoles";
import ViewUserRoles from "../pages/userManagement/userRoles/ViewUserRoles";
import AddEditServiceCategory from "../pages/masters/serviceCategory/AddEditServiceCategory";
import AddEditSparePartsCategory from "../pages/masters/sparePartsCategory/AddEditSparePartsCategory";
import AddEditVehicleType from "../pages/masters/vehicleType/AddEditVehicleType";
import AddEditVehicleMake from "../pages/masters/vehicleMake/AddEditVehicleMake";
import AddEditVehicleModel from "../pages/masters/vehicleModel/AddEditVehicleModel";
import PrivateRoute from "../components/PrivateRoute";
import ListSpareParts from "../pages/spareParts/ListSpareParts";
import AddEditSpareParts from "../pages/spareParts/AddEditSpareParts";
import ViewSpareParts from "../pages/spareParts/ViewSpareParts";
import AddEditVehicleTrim from "../pages/masters/vehicleTrim/AddEditVehicleTrim";
import ListVehicleTrim from "../pages/masters/vehicleTrim/ListVehicleTrim";
import ListVehicleGeneration from "../pages/masters/vehicleGeneration/ListVehicleGeneration";
import AddEditVehicleGeneration from "../pages/masters/vehicleGeneration/AddEditVehicleGeneration";
import ListVehicleSeries from "../pages/masters/vehicleSeries/ListVehicleSeries";
import AddEditVehicleSeries from "../pages/masters/vehicleSeries/AddEditVehicleSeries";
import ListOrders from "../pages/orders/ListOrders";
import Orders from "../pages/dashboard/Orders";
import ViewOrders from "../pages/orders/ViewOrders";
import ListRfq from "../pages/rfq/ListRfq";
import ViewRfq from "../pages/rfq/ViewRfq";
import ContactQueryList from "../pages/ContactQueryList/ContactQueryList";
import DetailRfq from "../pages/rfq/DetailRfq";
import ListWhatsAppGroup from "../pages/masters/whatsAppGroup/ListWhatsAppGroup";
import AddEditWhatsAppGroup from "../pages/masters/whatsAppGroup/AddEditWhatsAppGroup";
import DownloadPdf from "../pages/DownloadPdf/DownloadPdf";
import Chats from "../pages/chats/Chats";
import AddEditRfq from "../pages/rfq/AddEditRfq";
import AddEditRfqResponse from "../pages/RfqResponse/AddEditRfqResponse";
import RfqResponseDetail from "../pages/RfqResponseDetail/RfqResponseDetail";
import RfqResponseList from "../pages/RfqResponse/RfqResponseList";
import Cart from "../pages/cart/Cart";
import RewardPoints from "../pages/rewardPoints/RewardPoints";

const userManagement = [
  {
    path: "/user",
    header: "User management",
  },
  {
    path: "/users",
    name: "Users",
    component: ListUsers,
    exact: true,
    icon: FeatherIcon.User,
    roles: ["admin"],
    route: PrivateRoute,
  },
  {
    path: "/users/add",
    component: AddEditUsers,
    roles: ["admin"],
    route: PrivateRoute,
  },
  {
    path: "/users/edit/:id",
    component: AddEditUsers,
    roles: ["admin"],
    route: PrivateRoute,
  },
  {
    path: "/users/view/:id",
    component: ViewUsers,
    roles: ["admin"],
    route: PrivateRoute,
  },
  // user roles
  {
    path: "/roles",
    name: "Roles",
    component: ListUserRoles,
    icon: FeatherIcon.UserCheck,
    roles: ["admin"],
    exact: true,
    route: PrivateRoute,
  },
  {
    path: "/roles/add",
    component: AddEditUserRoles,
    roles: ["admin"],
    route: PrivateRoute,
  },
  {
    path: "/roles/edit/:id",
    component: AddEditUserRoles,
    roles: ["admin"],
    route: PrivateRoute,
  },
  {
    path: "/roles/view/:id",
    component: ViewUserRoles,
    roles: ["admin"],
    route: PrivateRoute,
  },
];
const mastersRoutes = [
  {
    path: "/masters",
    header: "Masters",
  },
  // service category master
  {
    path: "/service-category",
    name: "Service category",
    icon: FeatherIcon.Codepen,
    component: ListServiceCategory,
    roles: ["admin"],
    route: PrivateRoute,
    exact: true,
  },
  {
    path: "/rfq",
    name: "RFQ",
    icon: FeatherIcon.Codepen,
    component: ListRfq,
    roles: ["admin", "agent"],
    route: PrivateRoute,
    exact: true,
  },
  {
    path: "/rfq/add",
    component: AddEditRfq,
    route: PrivateRoute,
    roles: ["admin", "agent"],
  },
  {
    path: "/rfq/view/:id",
    component: ViewRfq,
    route: PrivateRoute,
    roles: ["admin"],
  },
  {
    path: "/rfq/detail/:id",
    component: DetailRfq,
    route: PrivateRoute,
    roles: ["admin"],
  },
  {
    path: "/service-category/add",
    component: AddEditServiceCategory,
    route: PrivateRoute,
    roles: ["admin"],
  },
  {
    path: "/service-category/edit/:id",
    exact: true,
    component: AddEditServiceCategory,
    route: PrivateRoute,
    roles: ["admin"],
  },
  // Rfq response
  {
    path: "/rfq-response",
    name: "Qoute submitted",
    icon: FeatherIcon.Codepen,
    component: RfqResponseList,
    route: PrivateRoute,
    roles: ["admin", "agent"],
    exact: true,
  },
  {
    path: "/rfq-response/add/:id",
    component: RfqResponseDetail,
    route: PrivateRoute,
    roles: ["admin", "agent"],
  },

  // Cart
  {
    path: "/cart/:userId",
    component: Cart,
    route: PrivateRoute,
    roles: ["admin", "agent"],
  },
  // spare parts category
  {
    path: "/spare-parts-category",
    name: "Spare parts category",
    icon: FeatherIcon.Codepen,
    component: ListSparePartsCategory,
    route: PrivateRoute,
    roles: ["admin"],
    exact: true,
  },
  {
    path: "/spare-parts-category/add",
    component: AddEditSparePartsCategory,
    route: PrivateRoute,
    roles: ["admin"],
  },
  {
    path: "/spare-parts-category/edit/:id",
    component: AddEditSparePartsCategory,
    route: PrivateRoute,
    roles: ["admin"],
  },
  // vehicles routes
  {
    path: "/vehicles",
    name: "Vehicles",
    icon: FeatherIcon.Codepen,
    children: [
      {
        path: "/vehicles/type",
        name: "Vehicle type",
        icon: FeatherIcon.Codepen,
        component: ListVehicleType,
        route: PrivateRoute,
        roles: ["admin"],
        exact: true,
      },
      {
        path: "/vehicles/type/add",
        component: AddEditVehicleType,
        route: PrivateRoute,
        roles: ["admin"],
      },
      {
        path: "/vehicles/type/edit/:id",
        component: AddEditVehicleType,
        route: PrivateRoute,
        roles: ["admin"],
      },
      {
        path: "/vehicles/make",
        name: "Brands/ Make",
        icon: FeatherIcon.Codepen,
        component: ListVehicleMake,
        route: PrivateRoute,
        roles: ["admin"],
        exact: true,
      },
      {
        path: "/vehicles/make/add",
        component: AddEditVehicleMake,
        route: PrivateRoute,
        roles: ["admin"],
      },
      {
        path: "/vehicles/make/edit/:id",
        component: AddEditVehicleMake,
        route: PrivateRoute,
        roles: ["admin"],
      },
      {
        path: "/vehicles/model",
        name: "Vehicle Models",
        icon: FeatherIcon.Codepen,
        component: ListVehicleModel,
        route: PrivateRoute,
        roles: ["admin"],
        exact: true,
      },
      {
        path: "/vehicles/model/add",
        component: AddEditVehicleModel,
        route: PrivateRoute,
        roles: ["admin"],
      },
      {
        path: "/vehicles/model/edit/:id",
        component: AddEditVehicleModel,
        route: PrivateRoute,
        roles: ["admin"],
      },
      {
        path: "/vehicles/trim",
        name: "Vehicle Trim",
        icon: FeatherIcon.Codepen,
        component: ListVehicleTrim,
        route: PrivateRoute,
        roles: ["admin"],
        exact: true,
      },
      {
        path: "/vehicles/trim/add",
        component: AddEditVehicleTrim,
        route: PrivateRoute,
        roles: ["admin"],
      },
      {
        path: "/vehicles/trim/edit/:id",
        component: AddEditVehicleTrim,
        route: PrivateRoute,
        roles: ["admin"],
      },
      {
        path: "/vehicles/generation",
        name: "Vehicle Generation",
        icon: FeatherIcon.Codepen,
        component: ListVehicleGeneration,
        route: PrivateRoute,
        roles: ["admin"],
        exact: true,
      },
      {
        path: "/vehicles/generation/add",
        component: AddEditVehicleGeneration,
        route: PrivateRoute,
        roles: ["admin"],
      },
      {
        path: "/vehicles/generation/edit/:id",
        component: AddEditVehicleGeneration,
        route: PrivateRoute,
        roles: ["admin"],
      },
      {
        path: "/vehicles/series",
        name: "Vehicle Series",
        icon: FeatherIcon.Codepen,
        component: ListVehicleSeries,
        route: PrivateRoute,
        roles: ["admin"],
        exact: true,
      },
      {
        path: "/vehicles/series/add",
        component: AddEditVehicleSeries,
        route: PrivateRoute,
        roles: ["admin"],
      },
      {
        path: "/vehicles/series/edit/:id",
        component: AddEditVehicleSeries,
        route: PrivateRoute,
        roles: ["admin"],
      },
    ],
  },
  {
    path: "/spare-parts",
    name: "Spare parts",
    icon: FeatherIcon.Codepen,
    component: ListSpareParts,
    route: PrivateRoute,
    roles: ["admin", "supplier"],
    exact: true,
  },
  {
    path: "/spare-parts/add",
    component: AddEditSpareParts,
    route: PrivateRoute,
    roles: ["admin", "supplier"],
  },
  {
    path: "/spare-parts/edit/:id",
    component: AddEditSpareParts,
    route: PrivateRoute,
    roles: ["admin", "supplier"],
  },
  {
    path: "/spare-parts/view/:id",
    component: ViewSpareParts,
    route: PrivateRoute,
    roles: ["admin", "supplier"],
  },
  // reward points route
  {
    path: "/reward-points",
    name: "User reward points",
    icon: FeatherIcon.DollarSign,
    component: RewardPoints,
    route: PrivateRoute,
    roles: ["admin", "agent"],
    exact: true,
  },
];
const orderRoutes = [
  {
    path: "/orders",
    component: ListOrders,
    icon: FeatherIcon.Codepen,
    name: "Orders",
    roles: ["admin", "agent"],
    route: PrivateRoute,
    exact: true,
  },
  {
    path: "/orders/view/:id",
    component: ViewOrders,
    route: PrivateRoute,
    roles: ["admin", "agent"],
  },
];
const contactUsQuery = {
  path: "/contact-query",
  component: ContactQueryList,
  icon: FeatherIcon.Codepen,
  name: "Contact query",
  roles: ["admin"],
  route: PrivateRoute,
  exact: true,
};
const chat = {
  path: "/chat",
  component: Chats,
  icon: FeatherIcon.Codepen,
  name: "Chat",
  roles: ["admin"],
  route: PrivateRoute,
  exact: true,
};

const whatsAppGroupRoutes = [
  {
    path: "/whatsapp-group",
    component: ListWhatsAppGroup,
    icon: FeatherIcon.Codepen,
    name: "WhatsApp group",
    roles: ["admin"],
    route: PrivateRoute,
    exact: true,
  },
  {
    path: "/whatsapp-group/add",
    component: AddEditWhatsAppGroup,
    route: PrivateRoute,
    roles: ["admin"],
  },
  {
    path: "/download-pdf",
    component: DownloadPdf,
    route: PrivateRoute,
    roles: ["admin", "agent"],
  },
];
const adminRoutes = [
  ...whatsAppGroupRoutes,
  contactUsQuery,
  chat,
  ...orderRoutes,
  ...userManagement,
  ...mastersRoutes,
];
export default adminRoutes;
