export const VEHICLE_MAKE = "VEHICLE_MAKE";
export const VEHICLE_MAKE_SUCCESS = "VEHICLE_MAKE_SUCCESS";
export const VEHICLE_MAKE_FAILED = "VEHICLE_MAKE_FAILED";
export const SAVE_VEHICLE_MAKE = "SAVE_VEHICLE_MAKE";
export const SAVE_VEHICLE_MAKE_SUCCESS = "SAVE_VEHICLE_MAKE_SUCCESS";
export const SAVE_VEHICLE_MAKE_FAILED = "SAVE_VEHICLE_MAKE_FAILED";
export const EDIT_VEHICLE_MAKE = "EDIT_VEHICLE_MAKE";
export const EDIT_VEHICLE_MAKE_SUCCESS = "EDIT_VEHICLE_MAKE_SUCCESS";
export const EDIT_VEHICLE_MAKE_FAILED = "EDIT_VEHICLE_MAKE_FAILED";
export const DELETE_VEHICLE_MAKE = "DELETE_VEHICLE_MAKE";
export const DELETE_VEHICLE_MAKE_SUCCESS =
  "DELETE_VEHICLE_MAKE_SUCCESS";
export const DELETE_VEHICLE_MAKE_FAILED = "DELETE_VEHICLE_MAKE_FAILED";
export const GET_VEHICLE_MAKE = "GET_VEHICLE_MAKE";
export const GET_VEHICLE_MAKE_SUCCESS = "GET_VEHICLE_MAKE_SUCCESS";
export const GET_VEHICLE_MAKE_FAILED = "GET_VEHICLE_MAKE_FAILED";
