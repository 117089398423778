import {
    DELETE_CAR_GENERATION,
    DELETE_CAR_GENERATION_FAILED,
    DELETE_CAR_GENERATION_SUCCESS,
    EDIT_CAR_GENERATION,
    EDIT_CAR_GENERATION_FAILED,
    EDIT_CAR_GENERATION_SUCCESS,
    SAVE_CAR_GENERATION,
    SAVE_CAR_GENERATION_FAILED,
    SAVE_CAR_GENERATION_SUCCESS,
    CAR_GENERATION,
    CAR_GENERATION_FAILED,
    CAR_GENERATION_SUCCESS,
    GET_CAR_GENERATION,
    GET_CAR_GENERATION_SUCCESS,
    GET_CAR_GENERATION_FAILED,
  } from "./constants";

  export const getCarGeneration = (id) => ({
    type: GET_CAR_GENERATION,
    payload: { id },
  });

  export const getCarGenerationSuccess = (data) => ({
    type: GET_CAR_GENERATION_SUCCESS,
    payload: data,
  });

  export const getCarGenerationFailed = (errorMessage) => ({
    type: GET_CAR_GENERATION_FAILED,
    payload: errorMessage,
  });

  export const getCarGenerations = (payload) => ({
    type: CAR_GENERATION,
    payload,
  });

  export const getCarGenerationsSuccess = (data) => ({
    type: CAR_GENERATION_SUCCESS,
    payload: { data },
  });

  export const getCarGenerationsFailed = (errorMessage) => ({
    type: CAR_GENERATION_FAILED,
    payload: errorMessage,
  });

  export const saveCarGeneration = ({ data, history }) => ({
    type: SAVE_CAR_GENERATION,
    payload: { data, history },
  });

  export const saveCarGenerationSuccess = (success) => ({
    type: SAVE_CAR_GENERATION_SUCCESS,
    payload: success,
  });

  export const saveCarGenerationFailed = (errorMessage) => ({
    type: SAVE_CAR_GENERATION_FAILED,
    payload: errorMessage,
  });

  export const editCarGeneration = (data) => ({
    type: EDIT_CAR_GENERATION,
    payload: data,
  });

  export const editCarGenerationSuccess = (success) => ({
    type: EDIT_CAR_GENERATION_SUCCESS,
    payload: success,
  });

  export const editCarGenerationFailed = (errorMessage) => ({
    type: EDIT_CAR_GENERATION_FAILED,
    payload: errorMessage,
  });

  export const deleteCarGeneration = (data) => ({
    type: DELETE_CAR_GENERATION,
    payload: data,
  });

  export const deleteCarGenerationSuccess = (success) => ({
    type: DELETE_CAR_GENERATION_SUCCESS,
    payload: success,
  });

  export const deleteCarGenerationFailed = (errorMessage) => ({
    type: DELETE_CAR_GENERATION_FAILED,
    payload: errorMessage,
  });
