import {
  all,
  call,
  fork,
  put,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";

import { authRequest } from "../../helpers/api";
import { appNotification } from "../app/actions";
import {
  deleteUserFailed,
  deleteUserSuccess,
  getUsersFailed,
  getUsersSuccess,
  getUserFailed,
  getUserSuccess,
  updateUserStatusSuccess,
  updateUserStatusFailed,
  updateUserWhatsAppGroupSuccess,
  updateUserWhatsAppGroupFailed,
} from "./actions";
import {
  DELETE_USER,
  GET_USER,
  SAVE_USER,
  UPDATE_USER_STATUS,
  UPDATE_USER_WHATSAPP_GROUP,
  USER,
} from "./constants";

function* getUsers({ payload }) {
  try {
    let url = `/users?`;
    if (payload)
      Object.keys(payload).map(
        (params) =>
          payload?.[params] && (url += `${params}=${payload[params]}&`)
      );
    const response = yield call(authRequest, url.slice(0, url.length - 1), {
      method: "get",
    });
    yield put(getUsersSuccess(response?.data));
  } catch (error) {
    let message = "Failed to get users";
    yield put(getUsersFailed(message));
  }
}

function* saveUser({ payload: { data, history } }) {
  try {
    const formData = new FormData();
    Object.keys(data).map((key) => formData.append(key, data[key]));
    let url = `/users`;
    if (data?.id) url += `/${data?.id}?_method=PUT`;

    const response = yield call(authRequest, url, {
      method: "post",
      data,
      //   headers: {
      //     "content-type": "multipart/form-data",
      //   },
    });
    yield put(getUsersSuccess(response?.data));
    yield put(appNotification({ type: "success", message: response?.message }));
    history.goBack();
  } catch (error) {
    let message = "Failed to save user data";
    if (error?.errors) yield put(getUsersFailed(error?.errors));
    else yield put(getUsersFailed(message));
  }
}
function* getUser({ payload: { id } }) {
  try {
    const response = yield call(authRequest, `/users/${id}`, {
      method: "get",
    });
    yield put(getUserSuccess(response?.data));
  } catch (error) {
    let message = "Failed to get users";
    yield put(getUserFailed(message));
  }
}
function* deleteUser({ payload: { id } }) {
  try {
    const response = yield call(authRequest, `/users/${id}`, {
      method: "delete",
    });
    yield put(appNotification({ type: "success", message: response?.message }));
    yield put(deleteUserSuccess({ id }));
  } catch (error) {
    console.log("error", error);
    let message = "Failed to get users";
    yield put(deleteUserFailed(message));
  }
}

function* updateUserStatusSaga({ payload: { status, id } }) {
  try {
    const response = yield call(
      authRequest,
      `/users/updateUserStatus/${id}?_method=PUT`,
      {
        method: "post",
        data: { status },
      }
    );
    yield put(appNotification({ type: "success", message: response?.message }));
    yield put(updateUserStatusSuccess({ id }));
  } catch (error) {
    console.log("error", error);
    let message = "Failed to get users";
    yield put(updateUserStatusFailed(message));
  }
}

function* updateUserWhatsAppGroupSaga({ payload: { id, whatsAppGroupId } }) {
  try {
    const response = yield call(
      authRequest,
      `/users/updateUserWhatsAppGroup/${id}?_method=PUT`,
      {
        method: "post",
        data: { whatsAppGroupId },
      }
    );
    yield put(appNotification({ type: "success", message: response?.message }));
    yield put(updateUserWhatsAppGroupSuccess(response?.data));
  } catch (error) {
    console.log("error", error);
    let message = "Failed to get users";
    yield put(updateUserWhatsAppGroupFailed(message));
  }
}
export function* watchGetUsers() {
  yield takeLatest(USER, getUsers);
}
export function* watchGetUser() {
  yield takeEvery(GET_USER, getUser);
}
export function* watchSaveUser() {
  yield takeEvery(SAVE_USER, saveUser);
}
export function* watchDeleteUser() {
  yield takeEvery(DELETE_USER, deleteUser);
}

export function* watchUpdateUserStatus() {
  yield takeEvery(UPDATE_USER_STATUS, updateUserStatusSaga);
}
export function* watchUpdateUserWhatsAppGroup() {
  yield takeEvery(UPDATE_USER_WHATSAPP_GROUP, updateUserWhatsAppGroupSaga);
}
function* usersSaga() {
  yield all([
    fork(watchGetUsers),
    fork(watchSaveUser),
    fork(watchGetUser),
    fork(watchDeleteUser),
    fork(watchUpdateUserStatus),
    fork(watchUpdateUserWhatsAppGroup),
  ]);
}

export default usersSaga;
