import {
  DELETE_USER,
  DELETE_USER_FAILED,
  DELETE_USER_SUCCESS,
  EDIT_USER,
  EDIT_USER_FAILED,
  EDIT_USER_SUCCESS,
  SAVE_USER,
  SAVE_USER_FAILED,
  SAVE_USER_SUCCESS,
  USER,
  USER_FAILED,
  USER_SUCCESS,
  GET_USER,
  GET_USER_SUCCESS,
  GET_USER_FAILED,
  UPDATE_USER_STATUS,
  UPDATE_USER_STATUS_SUCCESS,
  UPDATE_USER_STATUS_FAILED,
  UPDATE_USER_WHATSAPP_GROUP,
  UPDATE_USER_WHATSAPP_GROUP_SUCCESS,
  UPDATE_USER_WHATSAPP_GROUP_FAILED,
} from "./constants";

export const getUser = (id) => ({
  type: GET_USER,
  payload: { id },
});

export const getUserSuccess = (data) => ({
  type: GET_USER_SUCCESS,
  payload: data,
});

export const getUserFailed = (errorMessage) => ({
  type: GET_USER_FAILED,
  payload: errorMessage,
});

export const getUsers = (payload) => ({
  type: USER,
  payload,
});

export const getUsersSuccess = (data) => ({
  type: USER_SUCCESS,
  payload: { data },
});

export const getUsersFailed = (errorMessage) => ({
  type: USER_FAILED,
  payload: errorMessage,
});

export const saveUser = ({ data, history }) => ({
  type: SAVE_USER,
  payload: { data, history },
});

export const saveUserSuccess = (success) => ({
  type: SAVE_USER_SUCCESS,
  payload: success,
});

export const saveUserFailed = (errorMessage) => ({
  type: SAVE_USER_FAILED,
  payload: errorMessage,
});

export const editUser = (data) => ({
  type: EDIT_USER,
  payload: data,
});

export const editUserSuccess = (success) => ({
  type: EDIT_USER_SUCCESS,
  payload: success,
});

export const editUserFailed = (errorMessage) => ({
  type: EDIT_USER_FAILED,
  payload: errorMessage,
});

export const deleteUser = (data) => ({
  type: DELETE_USER,
  payload: data,
});

export const deleteUserSuccess = (success) => ({
  type: DELETE_USER_SUCCESS,
  payload: success,
});

export const deleteUserFailed = (errorMessage) => ({
  type: DELETE_USER_FAILED,
  payload: errorMessage,
});

export const updateUserStatus = (data) => ({
  type: UPDATE_USER_STATUS,
  payload: data,
});

export const updateUserStatusSuccess = (success) => ({
  type: UPDATE_USER_STATUS_SUCCESS,
  payload: success,
});

export const updateUserStatusFailed = (errorMessage) => ({
  type: UPDATE_USER_STATUS_FAILED,
  payload: errorMessage,
});

export const updateUserWhatsAppGroup = (data) => ({
  type: UPDATE_USER_WHATSAPP_GROUP,
  payload: data,
});

export const updateUserWhatsAppGroupSuccess = (success) => ({
  type: UPDATE_USER_WHATSAPP_GROUP_SUCCESS,
  payload: success,
});

export const updateUserWhatsAppGroupFailed = (errorMessage) => ({
  type: UPDATE_USER_WHATSAPP_GROUP_FAILED,
  payload: errorMessage,
});
