import {
    all,
    call,
    fork,
    put,
    takeEvery,
    takeLatest,
  } from "redux-saga/effects";

  import { authRequest } from "../../helpers/api";
  import { appNotification } from "../app/actions";
  import {
    deleteCarTrimFailed,
    deleteCarTrimSuccess,
    getCarTrimsFailed,
    getCarTrimsSuccess,
    getCarTrimFailed,
    getCarTrimSuccess,
  } from "./actions";
  import {
    DELETE_CAR_TRIM,
    GET_CAR_TRIM,
    SAVE_CAR_TRIM,
    CAR_TRIM,
  } from "./constants";

  function* getCarTrims({ payload }) {
    try {
      let url = `/vehicle/car-trim?`;
      if (payload)
        Object.keys(payload).map(
          (params) =>
            payload?.[params] && (url += `${params}=${payload[params]}&`)
        );
      const response = yield call(authRequest, url.slice(0, url.length - 1), {
        method: "get",
      });
      console.log("response", response);
      yield put(getCarTrimsSuccess(response?.data));
    } catch (error) {
      let message = "Failed to get car trim";
      yield put(getCarTrimsFailed(message));
    }
  }

  function* saveCarTrim({ payload: { data, history } }) {
    try {
      console.log("data", data);
      const formData = new FormData();
      Object.keys(data).map((key) => formData.append(key, data[key]));
      let url = `/vehicle/car-trim`;
      if (data?.id) url += `/${data?.id}?_method=PUT`;

      const response = yield call(authRequest, url, {
        method: "post",
        data: formData,
        headers: {
          "content-type": "multipart/form-data",
        },
      });
      yield put(getCarTrimsSuccess(response?.data));
      yield put(appNotification({ type: "success", message: response?.message }));
      history.goBack();
    } catch (error) {
      let message = "Failed to get car trim";
      yield put(getCarTrimsFailed(message));
    }
  }
  function* getCarTrim({ payload: { id } }) {
    try {
      const response = yield call(authRequest, `/vehicle/car-trim/${id}`, {
        method: "get",
      });
      yield put(getCarTrimSuccess(response?.data));
    } catch (error) {
      let message = "Failed to get car trim";
      yield put(getCarTrimFailed(message));
    }
  }
  function* deleteCarTrim({ payload: { id } }) {
    try {
      const response = yield call(authRequest, `/vehicle/car-trim/${id}`, {
        method: "delete",
      });
      yield put(appNotification({ type: "success", message: response?.message }));
      yield put(deleteCarTrimSuccess({ id }));
    } catch (error) {
        console.log('error',error)
      let message = "Failed to get car trim";
      yield put(deleteCarTrimFailed(message));
    }
  }
  export function* watchGetCarTrims() {
    yield takeLatest(CAR_TRIM, getCarTrims);
  }
  export function* watchGetCarTrim() {
    yield takeEvery(GET_CAR_TRIM, getCarTrim);
  }
  export function* watchSaveCarTrim() {
    yield takeEvery(SAVE_CAR_TRIM, saveCarTrim);
  }
  export function* watchDeleteCarTrim() {
    yield takeEvery(DELETE_CAR_TRIM, deleteCarTrim);
  }
  function* carTrimSaga() {
    yield all([
      fork(watchGetCarTrims),
      fork(watchGetCarTrim),
      fork(watchSaveCarTrim),
      fork(watchDeleteCarTrim),
    ]);
  }

  export default carTrimSaga;
