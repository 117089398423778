export const CAR_SERIE = "CAR_SERIE";
export const CAR_SERIE_SUCCESS = "CAR_SERIE_SUCCESS";
export const CAR_SERIE_FAILED = "CAR_SERIE_FAILED";
export const SAVE_CAR_SERIE = "SAVE_CAR_SERIE";
export const SAVE_CAR_SERIE_SUCCESS = "SAVE_CAR_SERIE_SUCCESS";
export const SAVE_CAR_SERIE_FAILED = "SAVE_CAR_SERIE_FAILED";
export const EDIT_CAR_SERIE = "EDIT_CAR_SERIE";
export const EDIT_CAR_SERIE_SUCCESS = "EDIT_CAR_SERIE_SUCCESS";
export const EDIT_CAR_SERIE_FAILED = "EDIT_CAR_SERIE_FAILED";
export const DELETE_CAR_SERIE = "DELETE_CAR_SERIE";
export const DELETE_CAR_SERIE_SUCCESS =
  "DELETE_CAR_SERIE_SUCCESS";
export const DELETE_CAR_SERIE_FAILED = "DELETE_CAR_SERIE_FAILED";
export const GET_CAR_SERIE = "GET_CAR_SERIE";
export const GET_CAR_SERIE_SUCCESS = "GET_CAR_SERIE_SUCCESS";
export const GET_CAR_SERIE_FAILED = "GET_CAR_SERIE_FAILED";
