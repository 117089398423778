// @flow
import {
  DELETE_SUPPLIER_BUSINESS,
  DELETE_SUPPLIER_BUSINESS_FAILED,
  DELETE_SUPPLIER_BUSINESS_SUCCESS,
  SUPPLIER_BUSINESS,
  SUPPLIER_BUSINESS_FAILED,
  SUPPLIER_BUSINESS_SUCCESS,
  GET_SUPPLIER_BUSINESS,
  GET_SUPPLIER_BUSINESS_FAILED,
  GET_SUPPLIER_BUSINESS_SUCCESS,
  SAVE_SUPPLIER_BUSINESS_SUCCESS,
  SAVE_SUPPLIER_BUSINESS,
  SAVE_SUPPLIER_BUSINESS_FAILED,
  UPDATE_SUPPLIER_VERIFICATION_STATUS,
  UPDATE_SUPPLIER_VERIFICATION_STATUS_SUCCESS,
  UPDATE_SUPPLIER_VERIFICATION_STATUS_FAILED,
  GET_ALL_SUPPLIER_BUSINESS,
  GET_ALL_SUPPLIER_BUSINESS_SUCCESS,
  GET_ALL_SUPPLIER_BUSINESS_FAILED,
} from "./constants";

const INIT_STATE = {
  data: [],
  supplierBusiness: {},
  businesses: {
    data: [],
    error: null,
    loading: false,
  },
  loading: false,
  error: null,
};

const SupplierBusiness = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ALL_SUPPLIER_BUSINESS:
      return {
        ...state,
        businesses: {
          error: null,
          loading: true,
        },
      };
    case GET_ALL_SUPPLIER_BUSINESS_SUCCESS:
      return {
        ...state,
        businesses: {
          data: action.payload,
          error: null,
          loading: false,
        },
      };
    case GET_ALL_SUPPLIER_BUSINESS_FAILED:
      return {
        ...state,
        businesses: {
          data: null,
          error: action.payload,
          loading: false,
        },
      };

    case SUPPLIER_BUSINESS:
      return { ...state, supplierBusiness: {}, loading: true };
    case SUPPLIER_BUSINESS_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
        error: null,
      };
    case SUPPLIER_BUSINESS_FAILED:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case SAVE_SUPPLIER_BUSINESS:
      return { ...state, loading: true };
    case SAVE_SUPPLIER_BUSINESS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case SAVE_SUPPLIER_BUSINESS_FAILED:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case GET_SUPPLIER_BUSINESS:
      return { ...state, loading: true };
    case GET_SUPPLIER_BUSINESS_SUCCESS:
      return {
        ...state,
        supplierBusiness: action.payload,
        loading: false,
        error: null,
      };
    case GET_SUPPLIER_BUSINESS_FAILED:
      return { ...state, error: action.payload, loading: false };
    case DELETE_SUPPLIER_BUSINESS:
      return { ...state, loading: true };
    case DELETE_SUPPLIER_BUSINESS_SUCCESS: {
      return {
        ...state,
        data: {
          ...state?.data,
          total: state?.data?.total - 1,
          data: state?.data?.data?.filter((v) => v?.id !== action?.payload?.id),
        },
        loading: false,
        error: null,
      };
    }
    case DELETE_SUPPLIER_BUSINESS_FAILED:
      return { ...state, error: action.payload, loading: false };
    default:
      return { ...state };
    case UPDATE_SUPPLIER_VERIFICATION_STATUS:
      return { ...state, loading: true };
    case UPDATE_SUPPLIER_VERIFICATION_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case UPDATE_SUPPLIER_VERIFICATION_STATUS_FAILED:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
  }
};

export default SupplierBusiness;
