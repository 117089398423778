import {
  GET_CHAT_MESSAGES,
  GET_CHAT_MESSAGES_SUCCESS,
  GET_CHAT_MESSAGES_FAILED,
  GET_CHAT_ROOM,
  GET_CHAT_ROOM_SUCCESS,
  GET_CHAT_ROOM_FAILED,
  ADD_CHAT_MESSAGE,
  ADD_CHAT_MESSAGE_SUCCESS,
  ADD_CHAT_MESSAGE_FAILED,
  REMOVE_USER_FROM_CHAT_ROOM,
  REMOVE_USER_FROM_CHAT_ROOM_SUCCESS,
  REMOVE_USER_FROM_CHAT_ROOM_FAILED,
  ADD_USER_TO_CHAT_ROOM,
  ADD_USER_TO_CHAT_ROOM_SUCCESS,
  ADD_USER_TO_CHAT_ROOM_FAILED,
  ADD_CHAT_ROOM,
  ADD_CHAT_ROOM_SUCCESS,
  ADD_CHAT_ROOM_FAILED,
} from "./constants";

export const getChatRoom = (payload) => ({
  type: GET_CHAT_ROOM,
  payload,
});

export const getChatRoomSuccess = (payload) => ({
  type: GET_CHAT_ROOM_SUCCESS,
  payload,
});

export const getChatRoomFailed = (payload) => ({
  type: GET_CHAT_ROOM_FAILED,
  payload,
});

export const getChatMessages = ({ chat_room_id }) => ({
  type: GET_CHAT_MESSAGES,
  payload: { chat_room_id },
});

export const getChatMessagesSuccess = (payload) => ({
  type: GET_CHAT_MESSAGES_SUCCESS,
  payload,
});

export const getChatMessagesFailed = (payload) => ({
  type: GET_CHAT_MESSAGES_FAILED,
  payload,
});

export const addChatRoom = (payload) => ({
  type: ADD_CHAT_ROOM,
  payload,
});

export const addChatRoomSuccess = (payload) => ({
  type: ADD_CHAT_ROOM_SUCCESS,
  payload,
});

export const addChatRoomFailed = (payload) => ({
  type: ADD_CHAT_ROOM_FAILED,
  payload,
});

export const addChatMessage = (payload) => ({
  type: ADD_CHAT_MESSAGE,
  payload,
});

export const addChatMessageSuccess = (payload) => ({
  type: ADD_CHAT_MESSAGE_SUCCESS,
  payload,
});

export const addChatMessageFailed = (payload) => ({
  type: ADD_CHAT_MESSAGE_FAILED,
  payload,
});

export const addUserToChatRoom = (payload) => ({
  type: ADD_USER_TO_CHAT_ROOM,
  payload,
});

export const addUserToChatRoomSuccess = (payload) => ({
  type: ADD_USER_TO_CHAT_ROOM_SUCCESS,
  payload,
});

export const addUserToChatRoomFailed = (payload) => ({
  type: ADD_USER_TO_CHAT_ROOM_FAILED,
  payload,
});

export const removeUserFromChatRoom = (payload) => ({
  type: REMOVE_USER_FROM_CHAT_ROOM,
  payload,
});

export const removeUserFromChatRoomSuccess = (payload) => ({
  type: REMOVE_USER_FROM_CHAT_ROOM_SUCCESS,
  payload,
});

export const removeUserFromChatRoomFailed = (payload) => ({
  type: REMOVE_USER_FROM_CHAT_ROOM_FAILED,
  payload,
});
