export const APP_NOTIFICATION = "APP_NOTIFICATION";

export const FETCH_COUNTRY_CODE = "FETCH_COUNTRY_CODE";
export const FETCH_COUNTRY_CODE_SUCCESS = "FETCH_COUNTRY_CODE_SUCCESS";
export const FETCH_COUNTRY_CODE_FAILURE = "FETCH_COUNTRY_CODE_FAILURE";

export const FETCH_CSRF_TOKEN = 'FETCH_CSRF_TOKEN';
export const FETCH_CSRF_TOKEN_SUCCESS = 'FETCH_CSRF_TOKEN_SUCCESS';
export const FETCH_CSRF_TOKEN_FAILURE = 'FETCH_CSRF_TOKEN_FAILURE';

export const appNotification = ({ type, message }) => ({
  type: APP_NOTIFICATION,
  payload: { type, message },
});

export const fetchCountryCode = (payload) => ({
  type: FETCH_COUNTRY_CODE,
  payload,
});

export const fetchCountryCodeSuccess = (payload) => ({
  type: FETCH_COUNTRY_CODE_SUCCESS,
  payload,
});

export const fetchCountryCodeFailure = (payload) => ({
  type: FETCH_COUNTRY_CODE_FAILURE,
  payload,
});

export const fetchCsrfToken = () => ({
  type: FETCH_CSRF_TOKEN,
});
