export const GET_SUPPLIER_BUSINESS_UPDATE = "GET_SUPPLIER_BUSINESS_UPDATE";
export const GET_SUPPLIER_BUSINESS_UPDATE_SUCCESS =
  "GET_SUPPLIER_BUSINESS_UPDATE_SUCCESS";
export const GET_SUPPLIER_BUSINESS_UPDATE_FAILED =
  "GET_SUPPLIER_BUSINESS_UPDATE_FAILED";

export const SAVE_SUPPLIER_BUSINESS_UPDATE = "SAVE_SUPPLIER_BUSINESS_UPDATE";
export const SAVE_SUPPLIER_BUSINESS_UPDATE_SUCCESS =
  "SAVE_SUPPLIER_BUSINESS_UPDATE_SUCCESS";
export const SAVE_SUPPLIER_BUSINESS_UPDATE_FAILED =
  "SAVE_SUPPLIER_BUSINESS_UPDATE_FAILED";

export const CLEAR_SUPPLIER_BUSINESS_UPDATE = "CLEAR_SUPPLIER_BUSINESS_UPDATE";
