import {
  DELETE_SERVICE_PROVIDER_BUSINESS,
  DELETE_SERVICE_PROVIDER_BUSINESS_FAILED,
  DELETE_SERVICE_PROVIDER_BUSINESS_SUCCESS,
  EDIT_SERVICE_PROVIDER_BUSINESS,
  EDIT_SERVICE_PROVIDER_BUSINESS_FAILED,
  EDIT_SERVICE_PROVIDER_BUSINESS_SUCCESS,
  SAVE_SERVICE_PROVIDER_BUSINESS,
  SAVE_SERVICE_PROVIDER_BUSINESS_FAILED,
  SAVE_SERVICE_PROVIDER_BUSINESS_SUCCESS,
  SERVICE_PROVIDER_BUSINESS,
  SERVICE_PROVIDER_BUSINESS_FAILED,
  SERVICE_PROVIDER_BUSINESS_SUCCESS,
  GET_SERVICE_PROVIDER_BUSINESS,
  GET_SERVICE_PROVIDER_BUSINESS_SUCCESS,
  GET_SERVICE_PROVIDER_BUSINESS_FAILED,
  UPDATE_SERVICE_PROVIDER_BUSINESS_STATUS,
  UPDATE_SERVICE_PROVIDER_BUSINESS_STATUS_SUCCESS,
  UPDATE_SERVICE_PROVIDER_BUSINESS_STATUS_FAILED,
} from "./constants";

export const getServiceProviderBusiness = (id) => ({
  type: GET_SERVICE_PROVIDER_BUSINESS,
  payload: { id },
});

export const getServiceProviderBusinessSuccess = (data) => ({
  type: GET_SERVICE_PROVIDER_BUSINESS_SUCCESS,
  payload: data,
});

export const getServiceProviderBusinessFailed = (errorMessage) => ({
  type: GET_SERVICE_PROVIDER_BUSINESS_FAILED,
  payload: errorMessage,
});

export const getServiceProviderBusinesses = (payload) => ({
  type: SERVICE_PROVIDER_BUSINESS,
  payload,
});

export const getServiceProviderBusinessesSuccess = (data) => ({
  type: SERVICE_PROVIDER_BUSINESS_SUCCESS,
  payload: { data },
});

export const getServiceProviderBusinessesFailed = (errorMessage) => ({
  type: SERVICE_PROVIDER_BUSINESS_FAILED,
  payload: errorMessage,
});

export const saveServiceProviderBusiness = ({ data, history }) => ({
  type: SAVE_SERVICE_PROVIDER_BUSINESS,
  payload: { data, history },
});

export const saveServiceProviderBusinessSuccess = (success) => ({
  type: SAVE_SERVICE_PROVIDER_BUSINESS_SUCCESS,
  payload: success,
});

export const saveServiceProviderBusinessFailed = (errorMessage) => ({
  type: SAVE_SERVICE_PROVIDER_BUSINESS_FAILED,
  payload: errorMessage,
});

export const editServiceProviderBusiness = (data) => ({
  type: EDIT_SERVICE_PROVIDER_BUSINESS,
  payload: data,
});

export const editServiceProviderBusinessSuccess = (success) => ({
  type: EDIT_SERVICE_PROVIDER_BUSINESS_SUCCESS,
  payload: success,
});

export const editServiceProviderBusinessFailed = (errorMessage) => ({
  type: EDIT_SERVICE_PROVIDER_BUSINESS_FAILED,
  payload: errorMessage,
});

export const deleteServiceProviderBusiness = (data) => ({
  type: DELETE_SERVICE_PROVIDER_BUSINESS,
  payload: data,
});

export const deleteServiceProviderBusinessSuccess = (success) => ({
  type: DELETE_SERVICE_PROVIDER_BUSINESS_SUCCESS,
  payload: success,
});

export const deleteServiceProviderBusinessFailed = (errorMessage) => ({
  type: DELETE_SERVICE_PROVIDER_BUSINESS_FAILED,
  payload: errorMessage,
});

export const updateServiceProviderBusinessStatus = (data) => ({
  type: UPDATE_SERVICE_PROVIDER_BUSINESS_STATUS,
  payload: data,
});

export const updateServiceProviderBusinessStatusSuccess = (success) => ({
  type: UPDATE_SERVICE_PROVIDER_BUSINESS_STATUS_SUCCESS,
  payload: success,
});

export const updateServiceProviderBusinessStatusFailed = (errorMessage) => ({
  type: UPDATE_SERVICE_PROVIDER_BUSINESS_STATUS_FAILED,
  payload: errorMessage,
});
