export const FETCH_CONTACT_QUERY = "FETCH_CONTACT_QUERY";
export const FETCH_CONTACT_QUERY_SUCCESS = "FETCH_CONTACT_QUERY_SUCCESS";
export const FETCH_CONTACT_QUERY_FAILURE = "FETCH_CONTACT_QUERY_FAILURE";

export const fetchContactQuery = (payload) => ({
  type: FETCH_CONTACT_QUERY,
  payload,
});

export const fetchContactQuerySuccess = (payload) => ({
  type: FETCH_CONTACT_QUERY_SUCCESS,
  payload,
});

export const fetchContactQueryFailure = (payload) => ({
  type: FETCH_CONTACT_QUERY_FAILURE,
  payload,
});
