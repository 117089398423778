// @flow
import {
  DELETE_SERVICE_PROVIDER_BUSINESS,
  DELETE_SERVICE_PROVIDER_BUSINESS_FAILED,
  DELETE_SERVICE_PROVIDER_BUSINESS_SUCCESS,
  SERVICE_PROVIDER_BUSINESS,
  SERVICE_PROVIDER_BUSINESS_FAILED,
  SERVICE_PROVIDER_BUSINESS_SUCCESS,
  GET_SERVICE_PROVIDER_BUSINESS,
  GET_SERVICE_PROVIDER_BUSINESS_FAILED,
  GET_SERVICE_PROVIDER_BUSINESS_SUCCESS,
  SAVE_SERVICE_PROVIDER_BUSINESS_SUCCESS,
  SAVE_SERVICE_PROVIDER_BUSINESS,
  SAVE_SERVICE_PROVIDER_BUSINESS_FAILED,
  UPDATE_SERVICE_PROVIDER_BUSINESS_STATUS,
  UPDATE_SERVICE_PROVIDER_BUSINESS_STATUS_SUCCESS,
  UPDATE_SERVICE_PROVIDER_BUSINESS_STATUS_FAILED,
} from "./constants";

const INIT_STATE = {
  data: [],
  serviceProviderBusiness: {},
  loading: false,
  error: null,
};

const ServiceProviderBusiness = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SERVICE_PROVIDER_BUSINESS:
      return { ...state, serviceProviderBusiness: {}, loading: true };
    case SERVICE_PROVIDER_BUSINESS_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
        error: null,
      };
    case SERVICE_PROVIDER_BUSINESS_FAILED:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case SAVE_SERVICE_PROVIDER_BUSINESS:
      return { ...state, loading: true };
    case SAVE_SERVICE_PROVIDER_BUSINESS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case SAVE_SERVICE_PROVIDER_BUSINESS_FAILED:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case GET_SERVICE_PROVIDER_BUSINESS:
      return { ...state, loading: true };
    case GET_SERVICE_PROVIDER_BUSINESS_SUCCESS:
      return {
        ...state,
        serviceProviderBusiness: action.payload,
        loading: false,
        error: null,
      };
    case GET_SERVICE_PROVIDER_BUSINESS_FAILED:
      return { ...state, error: action.payload, loading: false };
    case DELETE_SERVICE_PROVIDER_BUSINESS:
      return { ...state, loading: true };
    case DELETE_SERVICE_PROVIDER_BUSINESS_SUCCESS: {
      return {
        ...state,
        data: {
          ...state?.data,
          total: state?.data?.total - 1,
          data: state?.data?.data?.filter((v) => v?.id !== action?.payload?.id),
        },
        loading: false,
        error: null,
      };
    }
    case DELETE_SERVICE_PROVIDER_BUSINESS_FAILED:
      return { ...state, error: action.payload, loading: false };

    case UPDATE_SERVICE_PROVIDER_BUSINESS_STATUS:
      return { ...state, loading: true };
    case UPDATE_SERVICE_PROVIDER_BUSINESS_STATUS_SUCCESS: {
      return {
        ...state,
        data: {
          ...state?.data,
          data: state?.data?.data?.map((v) =>
            v?.id === action?.payload?.id
              ? {
                  ...v,
                  status: action.payload.status,
                }
              : { ...v }
          ),
        },
        loading: false,
        error: null,
      };
    }
    case UPDATE_SERVICE_PROVIDER_BUSINESS_STATUS_FAILED:
      return { ...state, error: action.payload, loading: false };

    default:
      return { ...state };
  }
};

export default ServiceProviderBusiness;
