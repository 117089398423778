import { all, call, fork, put, takeLatest } from "redux-saga/effects";

import {
  getChatRoomSuccess,
  getChatRoomFailed,
  addChatMessageSuccess,
  addChatMessageFailed,
  getChatMessagesSuccess,
  getChatMessagesFailed,
  addUserToChatRoomSuccess,
  addUserToChatRoomFailed,
  removeUserFromChatRoomSuccess,
  removeUserFromChatRoomFailed,
  addChatRoomSuccess,
  addChatRoomFailed,
} from "./actions";

import {
  addChatMessageApi,
  addChatRoomApi,
  addUserToChatRoomApi,
  getChatMessagesApi,
  getChatRoomApi,
  removeUserFromChatRoomApi,
} from "../../services/api";
import { appNotification } from "../app/actions";
import {
  ADD_CHAT_MESSAGE,
  ADD_CHAT_ROOM,
  ADD_USER_TO_CHAT_ROOM,
  GET_CHAT_MESSAGES,
  GET_CHAT_ROOM,
  REMOVE_USER_FROM_CHAT_ROOM,
} from "./constants";

function* getChatRoomSaga({ payload }) {
  try {
    const response = yield call(getChatRoomApi);
    if (response?.status === "Success") {
      yield put(getChatRoomSuccess(response?.data));
    } else {
      yield put(
        appNotification({ type: "danger", message: response?.message })
      );
    }
  } catch (e) {
    yield put(getChatRoomFailed(e?.response?.data));
  }
}

function* getChatMessagesSaga({ payload: { chat_room_id } }) {
  try {
    const response = yield call(getChatMessagesApi, { chat_room_id });
    if (response?.status === "Success") {
      yield put(getChatMessagesSuccess(response?.data));
    } else {
      yield put(
        appNotification({ type: "danger", message: response?.message })
      );
    }
  } catch (e) {
    yield put(getChatMessagesFailed(e?.response?.data));
  }
}

function* addChatRoomSaga({ payload: { payload, onSuccess, onError } }) {
  try {
    const response = yield call(addChatRoomApi, payload);
    if (response?.status === "Success") {
      if (onSuccess) onSuccess(response?.data);
      yield put(addChatRoomSuccess(response?.data));
    } else {
      yield put(
        appNotification({ type: "danger", message: response?.message })
      );
    }
  } catch (e) {
    if (onError) onError(e?.response);
    yield put(addChatRoomFailed(e?.response?.data));
  }
}

function* addChatMessageSaga({ payload: { payload, onSuccess, onError } }) {
  try {
    const response = yield call(addChatMessageApi, payload);
    if (response?.status === "Success") {
      if (onSuccess) onSuccess(response?.data);
      yield put(addChatMessageSuccess(response?.data));
    } else {
      yield put(
        appNotification({ type: "danger", message: response?.message })
      );
    }
  } catch (e) {
    if (onError) onError(e?.response);
    yield put(addChatMessageFailed(e?.response?.data));
  }
}

function* addUserToChatRoomSaga({ payload: { payload, onSuccess, onError } }) {
  try {
    const response = yield call(addUserToChatRoomApi, payload);
    if (response?.status === "Success") {
      if (onSuccess) onSuccess(response?.data);
      yield put(addUserToChatRoomSuccess(response?.data));
    } else {
      yield put(
        appNotification({ type: "danger", message: response?.message })
      );
    }
  } catch (e) {
    if (onError) onError(e?.response);
    yield put(addUserToChatRoomFailed(e?.response?.data));
  }
}

function* removeUserToChatRoomSaga({
  payload: { payload, onSuccess, onError },
}) {
  try {
    const response = yield call(removeUserFromChatRoomApi, payload);
    if (response?.status === "Success") {
      if (onSuccess) onSuccess(response?.data);
      yield put(removeUserFromChatRoomSuccess(response?.data));
    } else {
      yield put(
        appNotification({ type: "danger", message: response?.message })
      );
    }
  } catch (e) {
    if (onError) onError(e?.response);
    yield put(removeUserFromChatRoomFailed(e?.response?.data));
  }
}
export function* watchAddChatRoom() {
  yield takeLatest(ADD_CHAT_ROOM, addChatRoomSaga);
}
export function* watchGetChatRoom() {
  yield takeLatest(GET_CHAT_ROOM, getChatRoomSaga);
}
export function* watchGetChatMessages() {
  yield takeLatest(GET_CHAT_MESSAGES, getChatMessagesSaga);
}
export function* watchAddChatMessage() {
  yield takeLatest(ADD_CHAT_MESSAGE, addChatMessageSaga);
}
export function* watchAddUserToChatRoom() {
  yield takeLatest(ADD_USER_TO_CHAT_ROOM, addUserToChatRoomSaga);
}

export function* watchRemoveUserToChatRoom() {
  yield takeLatest(REMOVE_USER_FROM_CHAT_ROOM, removeUserToChatRoomSaga);
}

function* chatSaga() {
  yield all([
    fork(watchAddChatRoom),
    fork(watchGetChatRoom),
    fork(watchGetChatMessages),
    fork(watchAddChatMessage),
    fork(watchAddUserToChatRoom),
    fork(watchRemoveUserToChatRoom),
  ]);
}

export default chatSaga;
