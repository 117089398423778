import styles from "./styles/ViewRfq.module.scss";
import useMediaQuery from "beautiful-react-hooks/useMediaQuery";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  Spinner,
  TabContent,
  TabPane,
} from "reactstrap";
import PageTitle from "../../components/PageTitle";
import {
  getRfq,
  getRfqSupplier,
  notifyRfqSupplier,
} from "../../redux/rfq/actions";
import classnames from "classnames";
import LoadAsyncImage from "../../components/LoadAsyncImage";
import ReadMore from "../../components/ReadMore/ReadMore";
import { ChevronDown, ChevronUp } from "react-feather";
import Table from "../../components/table/Table";
import { appNotification } from "../../redux/app/actions";

const columns = () => [
  {
    dataField: "businessName",
    text: "Business name",
    sort: true,
    editable: false,
    formatter: (cel, row) => (
      <span style={{ minWidth: "20vw", display: "block" }}>
        {row?.businessName}
      </span>
    ),
  },
  {
    dataField: "verification_status",
    text: "Status",
    sort: true,
    editable: false,
  },
  {
    dataField: "googleRating",
    text: "google rating",
    sort: true,
    editable: false,
  },
  {
    dataField: "orderCount",
    text: "Order count",
    sort: true,
    editable: false,
  },
  {
    dataField: "rfqCount",
    text: "RFQ count",
    sort: true,
    editable: false,
  },
];

const ViewRfq = () => {
  const params = useParams();
  const [showTab, setShowTab] = useState(false);
  const [filters, setFilters] = useState({
    category: { value: null, apply: false },
    vehicleMake: { value: null, apply: false },
    vehicleModel: { value: null, apply: false },
    verification_status: { value: false, apply: false },
  });
  const {
    rfq,
    loading,
    error,
    rfqSupplier,
    notifyRfqSupplier: notifySupplier,
  } = useSelector((state) => state.Rfq);
  const sm = useMediaQuery({ maxWidth: 992 });
  const id = params?.id;
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState(rfq?.rfq_request?.[0]?.id);
  const toggleTab = (e) => setShowTab(!showTab);
  const [pagination, setPagination] = useState({
    page: 1,
    searchText: "",
    sizePerPage: 10,
    sortField: "id",
    sortOrder: "asc",
  });
  const handleTableChange = (e, v) => {
    console.log(e, v);
    const {
      page: pageNumber,
      searchText,
      sizePerPage,
      sortField,
      sortOrder,
    } = v;
    let page = pageNumber;
    if (searchText) page = 1;

    setPagination({ page, searchText, sizePerPage, sortField, sortOrder });
    dispatch(
      getRfqSupplier({
        page,
        searchText,
        sizePerPage,
        sortField,
        sortOrder,
        rfqId: rfq?.id,
        vehicleMake: filters?.vehicleMake?.apply
          ? filters?.vehicleMake?.value
          : null,
        vehicleModel: filters?.vehicleModel?.apply
          ? filters?.vehicleModel?.value
          : null,
        category: filters?.category?.apply ? filters?.category?.value : null,
        verification_status: filters?.verification_status?.apply
          ? filters?.verification_status?.value
          : null,
      })
    );
  };
  const handleFilterChange = (e) => {
    setFilters((s) => ({
      ...s,
      [e.target.name]: { ...s?.[e.target.name], apply: e.target.checked },
    }));
    setPagination((s) => ({ ...s, page: 1 }));
    dispatch(
      getRfqSupplier({
        ...pagination,
        page: 1,
        rfqId: rfq?.id,
        vehicleMake: filters?.vehicleMake?.apply
          ? filters?.vehicleMake?.value
          : null,
        vehicleModel: filters?.vehicleModel?.apply
          ? filters?.vehicleModel?.value
          : null,
        category: filters?.category?.apply ? filters?.category?.value : null,
        [e.target.name]: e.target.checked
          ? filters?.[e.target.name]?.value
          : null,
      })
    );
  };
  const [selectedSupplier, setSelectedSupplier] = useState([]);
  const handleRowSelect = (isSelected, row) => {
    if (isSelected) setSelectedSupplier((s) => [...s, row]);
    else setSelectedSupplier((s) => s.filter((v) => v.id !== row.id));
  };
  const handleSelectAll = (isSelected, rows) => {
    console.log(rows);
    if (isSelected) {
      setSelectedSupplier(rows);
    } else setSelectedSupplier([]);
  };

  const handleSubmit = (e) => {
    if (selectedSupplier.length) {
      const payload = {
        rfqId: rfq?.id,
        rfqNo: rfq?.rfqNo,
        rfqType: rfq?.rfqType,
        supplier: selectedSupplier.map((v) => ({
          id: v.id,
          userId: v.userId,
        })),
      };
      dispatch(notifyRfqSupplier(payload));
      setSelectedSupplier([]);
    } else
      dispatch(
        appNotification({
          type: "info",
          message: "Please select atleast one supplier",
        })
      );
  };
  useEffect(() => {
    dispatch(getRfq(id));
  }, []);

  useEffect(() => {
    if (rfq?.rfq_request) {
      const category = rfq?.rfq_request
        ?.map((v) => [v?.category?.id, v?.sub_category?.id])
        .flat();
      setFilters((s) => ({
        ...s,
        category: { value: category, apply: true },
        vehicleMake: { value: rfq?.vehicle_make?.id_car_make, apply: true },
        vehicleModel: { value: rfq?.vehicle_model?.id_car_model, apply: true },
      }));
      dispatch(
        getRfqSupplier({
          ...pagination,
          rfqId: rfq?.id,
          vehicleMake: rfq?.vehicle_make?.id_car_make,
          vehicleModel: rfq?.vehicle_model?.id_car_model,
          category,
        })
      );
    }
  }, [rfq]);

  if (loading)
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "70vh" }}
      >
        <Spinner />
      </div>
    );
  return (
    <>
      <Row className="page-title">
        <Col md={12}>
          <PageTitle
            breadCrumbItems={[
              {
                label: "rfq",
                path: "/rfq",
              },
              {
                label: `${"View"}`,
                path: `/rfq/view/${id}`,
                active: true,
              },
            ]}
            title={`Rfq no:${rfq?.rfqNo}`}
          />
        </Col>
      </Row>

      <Card>
        <CardHeader
          className={`${styles.card_header_container}`}
          onClick={toggleTab}
        >
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <h4>
                {[
                  rfq?.vehicle_make?.name,
                  rfq?.vehicle_model?.name,
                  rfq?.modification?.name,
                  rfq?.vehicle_year?.year,
                ]
                  .filter((v) => v)
                  .join(" - ")}
              </h4>
              <span>{rfq?.rfq_request?.length} parts</span>
            </div>
            {showTab ? <ChevronUp /> : <ChevronDown />}
          </div>
        </CardHeader>
        {showTab && (
          <CardBody>
            <Nav tabs>
              {rfq?.rfq_request?.map((v) => (
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: v?.id === activeTab,
                    })}
                    onClick={() => {
                      setActiveTab(v?.id);
                    }}
                  >
                    {v?.sub_category?.categoryName || v?.category?.categoryName}
                  </NavLink>
                </NavItem>
              ))}
            </Nav>
            <TabContent activeTab={activeTab}>
              {rfq?.rfq_request?.map((v) => (
                <TabPane tabId={v?.id} key={v?.id}>
                  <Row>
                    <Col lg={3} sm={4}>
                      <LoadAsyncImage
                        path={v?.photos?.[0]?.filePath}
                        id={v?.photos?.[0]?.id}
                        width={120}
                        height={91}
                      />
                    </Col>
                    <Col lg={9} sm={8}>
                      <div className="col-lg-8 col-md-7 col-sm-12">
                        <div className="">
                          <h5>
                            {v?.category?.categoryName} -{" "}
                            {v?.otherSparePartCategory ||
                              v?.sub_category?.categoryName ||
                              ""}
                          </h5>
                        </div>
                        <div className="">
                          <b>Spare part no: </b>
                          {v?.category?.sparePartNameOrNumber || "NA"}
                        </div>

                        <p className="">
                          <b>Required quantity: </b>
                          {v?.quantity}
                        </p>
                        <div className="">
                          <LoadAsyncImage
                            id={v?.voice_note?.id}
                            path={v?.voice_note?.filePath}
                            fileType="audio"
                          />
                        </div>
                        <div className="">
                          <ReadMore>{v?.description || ""}</ReadMore>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </TabPane>
              ))}
            </TabContent>
          </CardBody>
        )}
      </Card>
      <h4>List of suppliers</h4>
      <Card>
        <CardBody>
          <div className="d-flex" style={{ gap: 10 }}>
            <div className="form-group">
              <label
                htmlFor="category"
                className="form-control d-flex justify-content-center"
              >
                <input
                  id="category"
                  type="checkbox"
                  name="category"
                  onChange={handleFilterChange}
                  checked={filters.category.apply}
                />
                <span className="ml-2">Category</span>
              </label>
            </div>
            <div className="form-group">
              <label
                htmlFor="vehicleMake"
                className="form-control d-flex justify-content-center"
              >
                <input
                  id="vehicleMake"
                  type="checkbox"
                  name="vehicleMake"
                  onChange={handleFilterChange}
                  checked={filters.vehicleMake.apply}
                />
                <span className="ml-2">Make</span>
              </label>
            </div>
            <div className="form-group">
              <label
                htmlFor="vehicleModel"
                className="form-control d-flex justify-content-center"
              >
                <input
                  id="vehicleModel"
                  type="checkbox"
                  name="vehicleModel"
                  onChange={handleFilterChange}
                  checked={filters.vehicleModel.apply}
                />
                <span className="ml-2">Model</span>
              </label>
            </div>
            <div className="form-group">
              <label
                htmlFor="verification_status"
                className="form-control d-flex justify-content-center"
              >
                <input
                  id="verification_status"
                  type="checkbox"
                  name="verification_status"
                  onChange={handleFilterChange}
                  checked={filters.verification_status.apply}
                />
                <span className="ml-2">Verified only</span>
              </label>
            </div>
            <div>
              <button
                onClick={handleSubmit}
                disabled={notifySupplier.loading}
                className="btn btn-primary"
              >
                {notifySupplier?.loading ? (
                  <Spinner />
                ) : (
                  `Send notification ( ${selectedSupplier?.length} suppliers)`
                )}
              </button>
            </div>
          </div>

          <div className="d-flex">
            <Table
              loading={rfqSupplier?.loading}
              records={rfqSupplier?.data?.data}
              columns={columns()}
              handleTableChange={handleTableChange}
              pagination={{
                ...pagination,
                totalSize: rfqSupplier?.data?.total,
              }}
              canCellEdit={false}
              enableSelection={true}
              handleRowSelect={handleRowSelect}
              handleSelectAll={handleSelectAll}
              disableExport={true}
            />

            {/* {rfqSupplier?.data?.length ? (
              rfqSupplier?.data?.map((v) => (
                <div key={v?.id} className={styles.rfq_supplier_card}>
                  <h4>{v?.businessName}</h4>
                  <div className={styles.checkbox}>
                    <input
                      id={`supplier-${v?.id}`}
                      name={`supplier[]`}
                      type="checkbox"
                    />
                  </div>
                  <div>
                    <span className="mr-3">
                      <b> Orders:</b> {v?.orderCount}
                    </span>
                    <span>
                      <b>RFQs:</b> {v?.rfqCount}
                    </span>
                  </div>
                </div>
              ))
            ) : (
              <div>
                <span>No suppliers found</span>
              </div>
            )} */}
          </div>
        </CardBody>
      </Card>
    </>
  );
};

export default ViewRfq;
