import moment from "moment/moment";
import { useEffect, useState } from "react";
import { Alert, Col, Row } from "react-bootstrap";
import { textFilter } from "react-bootstrap-table2-filter";
import { useDispatch, useSelector } from "react-redux";
import PageTitle from "../../components/PageTitle";
import Table from "../../components/table/Table";
import { fetchContactQuery } from "../../redux/contactQuery/actions";

const columns = ({ hasFilter = false }) => [
  {
    dataField: "id",
    text: "Id",
    sort: true,
    filter: hasFilter && textFilter(),
    editable: false,
  },
  {
    dataField: "fullName",
    text: "Name",
    sort: true,
    filter: hasFilter && textFilter(),
    editable: false,
  },
  {
    dataField: "contactNumber",
    text: "Phone number",
    sort: true,
    formatter: (cell, row) => (
      <span>{`+${row?.contactNumberCode} ${row?.contactNumber}`}</span>
    ),
  },
  {
    dataField: "email",
    text: "Email address",
    sort: true,
    filter: hasFilter && textFilter(),
    editable: false,
  },
  {
    dataField: "message",
    text: "Message",
    sort: true,
    filter: hasFilter && textFilter(),
    editable: false,
  },
  {
    dataField: "created_at",
    text: "Created at",
    sort: true,
    filter: hasFilter && textFilter(),
    formatter: (cell, row) =>
      row?.created_at
        ? moment(row?.created_at).format("DD-MM-YYYY hh:mm:A")
        : null,
    editable: false,
  },
];
const ContactQueryList = () => {
  const baseUrl = "/contact-query";
  const { data, loading, error } = useSelector((s) => s?.ContactQuery);
  const [hasFilter, setHasFilter] = useState(false);
  const dispatch = useDispatch();
  const [pagination, setPagination] = useState({
    page: 1,
    searchText: "",
    sizePerPage: 10,
    sortField: "id",
    sortOrder: "desc",
  });

  const handleTableChange = (e, v) => {
    const { page, searchText, sizePerPage, sortField, sortOrder } = v;
    setPagination({ page, searchText, sizePerPage, sortField, sortOrder });
    dispatch(
      fetchContactQuery({
        page,
        searchText,
        sizePerPage,
        sortField,
        sortOrder,
      })
    );
  };

  useEffect(() => {
    dispatch(fetchContactQuery(pagination));
  }, []);
  
  return (
    <>
      <Row className="page-title">
        <Col md={12}>
          <PageTitle
            breadCrumbItems={[
              {
                label: "List users",
                path: "/users",
              },
            ]}
            title={"List of Users"}
          />
        </Col>
      </Row>
      {error && <Alert color="danger">Oops something went wrong</Alert>}
      <Table
        loading={loading}
        records={data?.data}
        columns={columns({ hasFilter })}
        handleTableChange={handleTableChange}
        addUrl={`${baseUrl}/add`}
        pagination={{ ...pagination, totalSize: data?.total }}
        canCellEdit={true}
      />
    </>
  );
};

export default ContactQueryList;
