import {
  EDIT_QUOTE_LIST,
  EDIT_QUOTE_LIST_FAILURE,
  EDIT_QUOTE_LIST_SUCCESS,
  FETCH_QUOTE_LIST,
  FETCH_QUOTE_LIST_FAILURE,
  FETCH_QUOTE_LIST_SUCCESS,
  SAVE_QUOTE_LIST,
  SAVE_QUOTE_LIST_FAILURE,
  SAVE_QUOTE_LIST_SUCCESS,
} from "./constants";

export const fetchQuoteList = (payload) => ({
  type: FETCH_QUOTE_LIST,
  payload,
});

export const fetchQuoteListSuccess = (payload) => ({
  type: FETCH_QUOTE_LIST_SUCCESS,
  payload,
});

export const fetchQuoteListFailure = (payload) => ({
  type: FETCH_QUOTE_LIST_FAILURE,
  payload,
});

export const saveQuoteList = ({ payload, onSuccess, onError }) => ({
  type: SAVE_QUOTE_LIST,
  payload,
  onSuccess,
  onError,
});

export const saveQuoteListSuccess = (payload) => ({
  type: SAVE_QUOTE_LIST_SUCCESS,
  payload,
});

export const saveQuoteListFailure = (payload) => ({
  type: SAVE_QUOTE_LIST_FAILURE,
  payload,
});

export const editQuoteList = ({ payload, onSuccess, onError }) => ({
  type: EDIT_QUOTE_LIST,
  payload,
  onSuccess,
  onError,
});

export const editQuoteListSuccess = (payload) => ({
  type: EDIT_QUOTE_LIST_SUCCESS,
  payload,
});

export const editQuoteListFailure = (payload) => ({
  type: EDIT_QUOTE_LIST_FAILURE,
  payload,
});
