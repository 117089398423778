import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { saveQuoteList } from "../../redux/quoteList/actions";
import QouteListForm from "./QouteListForm";

const AddEditQuoteList = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const handleSubmit = (data) => {
    const formattedData = data?.allfields?.map((val) => {
      console.log("data", data);

      const f = {
        ...val,
        supplier_contact_number: `${val?.supplier_contact_number_code}${val?.supplier_contact_number}`,
        service_provider_business_name:
          val?.serviceProviderBusiness?.businessName,
        service_provider_business_id: val?.serviceProviderBusiness?.id,
        supplier_business_id: val?.supplierBusiness?.id || -1,
        supplier_business_name:
          val?.supplierBusiness?.businessName || val?.supplierBusiness?.label,
        qoute_submited: val?.qoute_submited,
        order_received: val?.order_received,
      };
      delete f?.supplier_contact_number_code;
      delete f?.serviceProviderBusiness;
      delete f?.supplierBusiness;
      return f;
    });
    dispatch(
      saveQuoteList({
        payload: { allfields: formattedData },
        onSuccess: (response) => {
          history.goBack();
        },
      })
    );
  };
  return (
    <div>
      <QouteListForm onSubmit={handleSubmit} />
    </div>
  );
};

export default AddEditQuoteList;
