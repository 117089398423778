import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Alert, Col, Row } from "reactstrap";
import PageTitle from "../../../components/PageTitle";
import Status from "../../../components/Status";
import Actions from "../../../components/table/Actions";
import Table from "../../../components/table/Table";
import useTableActions from "../../../components/table/useActions";
import { deleteRoles } from "../../../redux/actions";

const ListUserRoles = () => {
  const baseUrl = "/roles";
  const { open, toggleModal, modalOpen, handleDelete } =
    useTableActions(deleteRoles);
  const columns = [
    {
      dataField: "id",
      text: "ID",
      sort: true,
    },
    {
      dataField: "role_name",
      text: "Role name",
      sort: true,
    },
    {
      dataField: "role_slug",
      text: "Role slug",
      sort: true,
    },
    {
      dataField: "prefix",
      text: "Prefix",
      sort: true,
    },

    {
      dataField: "actions",
      text: "Actions",
      sort: false,
      isDummyField: true,
      exportCSV: false,
      formatter: Actions,
      formatExtraData: {
        open,
        toggleModal,
        handleDelete,
        canView: false,
        baseUrl,
        modalOpen,
      },
    },
  ];
  const dispatch = useDispatch();
  const [pagination, setPagination] = useState({
    page: 1,
    searchText: "",
    sizePerPage: 10,
    sortField: "id",
    sortOrder: "asc",
  });
  const { data, error, loading } = useSelector((state) => state.Roles);

  const handleTableChange = (e, v) => {};
  return (
    <>
      <Row className="page-title">
        <Col md={12}>
          <PageTitle
            breadCrumbItems={[
              {
                label: "Roles",
                path: "/roles",
              },
            ]}
            title={"List of spare parts category"}
          />
        </Col>
      </Row>
      {error && <Alert color="danger">Oops something went wrong</Alert>}
      <Table
        loading={loading}
        records={data}
        columns={columns}
        handleTableChange={handleTableChange}
        addUrl={`${baseUrl}/add`}
        pagination={{ ...pagination, totalSize: data?.length }}
      />
    </>
  );
};

export default ListUserRoles;
