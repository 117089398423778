// @flow
import {
  DELETE_WHATSAPP_GROUP,
  DELETE_WHATSAPP_GROUP_FAILED,
  DELETE_WHATSAPP_GROUP_SUCCESS,
  SAVE_WHATSAPP_GROUP,
  SAVE_WHATSAPP_GROUP_FAILED,
  SAVE_WHATSAPP_GROUP_SUCCESS,
  GET_WHATSAPP_GROUP,
  GET_WHATSAPP_GROUP_FAILED,
  GET_WHATSAPP_GROUP_SUCCESS,
  EDIT_WHATSAPP_GROUP,
  EDIT_WHATSAPP_GROUP_SUCCESS,
  EDIT_WHATSAPP_GROUP_FAILED,
} from "./constants";

const INIT_STATE = {
  data: [],
  loading: false,
  error: null,
};

const whatsAppGroup = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_WHATSAPP_GROUP:
      return { ...state, sparePartsCategory: {}, loading: true };
    case GET_WHATSAPP_GROUP_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: null,
      };
    case GET_WHATSAPP_GROUP_FAILED:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case DELETE_WHATSAPP_GROUP:
      return { ...state, loading: true };
    case DELETE_WHATSAPP_GROUP_SUCCESS: {
      return {
        ...state,
        data: {
          ...state?.data,
          total: state?.data?.total - 1,
          data: state?.data?.data?.filter((v) => v?.id !== action?.payload?.id),
        },
        loading: false,
        error: null,
      };
    }
    case DELETE_WHATSAPP_GROUP_FAILED:
      return { ...state, error: action.payload, loading: false };
    case EDIT_WHATSAPP_GROUP:
      return { ...state, loading: true };
    case EDIT_WHATSAPP_GROUP_SUCCESS: {
      return {
        ...state,
        data: {
          ...state?.data,
          data: state?.data?.data?.map((v) =>
            v?.id == action?.payload?.id
              ? { ...v, ...action.payload }
              : { ...v }
          ),
        },
        loading: false,
        error: null,
      };
    }
    case EDIT_WHATSAPP_GROUP_FAILED:
      return { ...state, error: action.payload, loading: false };

    case SAVE_WHATSAPP_GROUP:
      return { ...state, loading: true };
    case SAVE_WHATSAPP_GROUP_SUCCESS: {
      return {
        ...state,
        data: {
          ...state?.data,
          total: state?.data?.total + 1,
          data: [action.payload, ...state?.data?.data],
        },
        loading: false,
        error: null,
      };
    }
    case SAVE_WHATSAPP_GROUP_FAILED:
      return { ...state, error: action.payload, loading: false };

    default:
      return { ...state };
  }
};

export default whatsAppGroup;
