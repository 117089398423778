import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import {
  deleteCart,
  editUserAddress,
  getCart,
  getUserAddress,
  saveUserAddress,
} from "../../redux/cart/actions";
import { useDispatch, useSelector } from "react-redux";
import { appNotification } from "../../redux/app/actions";
import LoadAsyncImage from "../../components/LoadAsyncImage";
import { Button, Col, Form, FormControl, Row } from "react-bootstrap";
import CustomModal from "../../components/CustomModal/CustomModal";
import { useModal } from "../../hooks/useModal";
import "./Cart.scss";
import PageTitle from "../../components/PageTitle";
import { MAIN_SITE_URL } from "../../constants";
import { CopyAndShareText } from "../../components/CopyAndShareText/CopyAndShareText";
import { Edit, Plus, RefreshCcw, Trash2 } from "react-feather";
import { userAddressSelector } from "../../redux/cart/selectors";
import { saveOrder } from "../../redux/orders/actions";
import AddressForm from "../../components/AddressForm/AddressForm";

const Cart = () => {
  const params = useParams();
  const history = useHistory();
  const deliveryFee = 25;
  const dispatch = useDispatch();
  const { data, loading, error } = useSelector((s) => s?.Cart);
  const {
    data: userAddress,
    loading: userAddressLoading,
    error: userAddressError,
  } = useSelector(userAddressSelector);
  const { loading: loader } = useSelector((s) => s.Orders);
  const [updatedCart, setUpdatedCart] = useState(null);
  const [cartItemToDelete, setCartItemToDelete] = useState(null);
  const [totalDeliveryFee, setTotalDeliveryFee] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [actionItem, setActionItem] = useState(null);
  const [deliveryFees, setDeliveryFees] = useState({});
  const {
    open: cartOpen,
    onClose: onCartDeleteClose,
    onOpen: openCartModal,
  } = useModal();
  const {
    open: showAddressForm,
    onClose: onCloseAddressForm,
    onOpen: onOpenAddressForm,
  } = useModal();

  const onDecrement = (item, businessId) => (e) => {
    if (updatedCart?.[businessId]?.item?.[item?.id]?.qty > 1) {
      setUpdatedCart((s) => ({
        ...s,
        [businessId]: {
          ...s?.[businessId],
          item: {
            ...s?.[businessId]?.item,
            [item?.id]: {
              ...s?.[businessId]?.item?.[item?.id],
              qty: s?.[businessId]?.item?.[item?.id]?.qty - 1,
            },
          },
        },
      }));
      setTotalPrice((s) => s - item?.price);
    } else {
      dispatch(
        appNotification({
          type: "danger",
          message: "1 quantity atleast be added",
        })
      );
    }
  };
  const onIncrement = (item, businessId, maxValue) => (e) => {
    if (maxValue) {
      if (updatedCart?.[businessId]?.item?.[item?.id]?.qty < maxValue) {
        setUpdatedCart((s) => ({
          ...s,
          [businessId]: {
            ...s?.[businessId],
            item: {
              ...s?.[businessId]?.item,
              [item?.id]: {
                ...s?.[businessId]?.item?.[item?.id],
                qty: s?.[businessId]?.item?.[item?.id]?.qty + 1,
              },
            },
          },
        }));
        setTotalPrice((s) => s + parseFloat(item?.price));
      } else {
        dispatch(
          appNotification({
            type: "danger",
            message:
              "Can't be added additional item as supplier is not quoted for more than this",
          })
        );
      }
    }
  };
  const handleOpenRemoveCartModal = (item) => (e) => {
    setCartItemToDelete(item);
    openCartModal();
  };

  const handleRemoveFromCart = (e) => {
    dispatch(deleteCart({ id: cartItemToDelete?.id }));
    onCartDeleteClose();
  };

  const makePayment = (e) => {
    const formData = {
      shippingAddress: JSON.stringify(selectedAddress),
      cart: Object.values(updatedCart),
      paymentMode: "COD",
      paymentCardId: null,
      userId: params.userId,
    };

    dispatch(
      saveOrder({
        payload: formData,
        onSuccess: (res) => {
          dispatch(getCart());
          history.push("/orders");
        },
        onError: (err) => {
          console.log(("erro", err));
          dispatch(
            appNotification({
              type: "danger",
              message: "Oops something went wrong",
            })
          );
        },
      })
    );
  };

  const handleEditAddress = (item) => (e) => {
    onOpenAddressForm();
    setActionItem(item);
  };
  const handleShowAddressForm =
    (value = false) =>
    (e) => {
      setActionItem(null);
      value ? onOpenAddressForm() : onCloseAddressForm();
    };
  const handleSubmitAddress = (formData) => {
    const f = {
      ...formData,
      cityId: formData?.cityId?.id,
      provinceId: formData?.provinceId?.id,
      countryId: formData?.countryId?.id,
      userId: params.userId,
    };
    if (formData?.id) {
      dispatch(
        editUserAddress({
          payload: f,
          onSuccess: (res) => {
            onCloseAddressForm();
            console.log("address", res?.data);
            if (res?.status === "Success") setSelectedAddress(res?.data);
          },
          onError: (e) => {
            console.log("e", e);
          },
        })
      );
    } else {
      dispatch(
        saveUserAddress({
          payload: f,
          onSuccess: (res) => {
            onCloseAddressForm();
            console.log("address", res?.data);
            if (res?.status === "Success") setSelectedAddress(res?.data);
          },
          onError: (e) => {
            console.log("e", e);
          },
        })
      );
    }
  };

  const handleSelectedAddress = (v) => (e) => setSelectedAddress(v);

  const handleDeliveryFeeChange = (businessId) => (e) => {
    if (e.target.value === "") {
      const s = {
        ...updatedCart,
        [businessId]: {
          ...updatedCart[businessId],
          deliveryFee: 0,
        },
      };
      setUpdatedCart(s);
      let total = 0;
      Object.values(s).forEach(
        (business) => (total += parseFloat(business.deliveryFee))
      );
      setTotalDeliveryFee(total);
    } else if (parseFloat(e.target.value) >= 0) {
      const s = {
        ...updatedCart,
        [businessId]: {
          ...updatedCart[businessId],
          deliveryFee: parseFloat(e.target.value),
        },
      };
      setUpdatedCart(s);
      let total = 0;
      Object.values(s).forEach(
        (business) => (total += parseFloat(business.deliveryFee))
      );
      setTotalDeliveryFee(total);
    }
  };

  useEffect(() => {
    if (params.userId) {
      dispatch(getCart({ id: params.userId }));
      dispatch(getUserAddress({ id: params.userId }));
    }
  }, []);

  useEffect(() => {
    if (data) {
      console.log("data", data);
      let t = 0;
      let q = {};
      let d = 0;
      let subTotal = {};
      data?.forEach((v) => {
        if (!v?.rfq_response?.deleted_at) {
          let totalItemPrice = v?.qty * parseFloat(v?.price);
          t += totalItemPrice;
          subTotal[v?.rfq_response?.supplier_business?.id] =
            (subTotal?.[v?.rfq_response?.supplier_business?.id] || 0) +
            totalItemPrice;
          if (!q?.[v?.rfq_response?.supplier_business?.id]) {
            d += deliveryFee;
            q[v?.rfq_response?.supplier_business?.id] = {
              businessId: v?.rfq_response?.supplier_business?.id,
              businessName: v?.rfq_response?.supplier_business?.businessName,
              soldByUserId: v?.rfq_response?.supplierId,
              soldByBusinessId: v?.rfq_response?.supplier_business?.id,
              purchaseByUserId: v?.userId,
              purchaseByBussinessId: v?.spBusinessId,
              deliveryFee,
              item: {},
            };
          }
          q = {
            ...q,
            [v?.rfq_response?.supplier_business?.id]: {
              ...q?.[v?.rfq_response?.supplier_business?.id],
              item: {
                ...q?.[v?.rfq_response?.supplier_business?.id]?.item,
                [v?.id]: {
                  ...v,
                  qty: v?.qty,
                  price: parseFloat(v?.price),
                  initialQty: v?.qty,
                  totalAmount: totalItemPrice,
                },
              },
              subTotal: subTotal?.[v?.rfq_response?.supplier_business?.id] || 0,
              totalAmount:
                (subTotal?.[v?.rfq_response?.supplier_business?.id] || 0) +
                deliveryFee,
            },
          };
        }
      });
      setTotalPrice(t);
      setUpdatedCart(q);
      setTotalDeliveryFee(d);
    }
  }, [data]);
  useEffect(() => {
    if (userAddress) {
      userAddress?.forEach((v) => {
        if (v?.isDefaultAddress) {
          setSelectedAddress(v);
        }
      });
    }
  }, [userAddress]);
  return (
    <div className="cart-root">
      <Row className="page-title">
        <Col md={12}>
          <PageTitle
            breadCrumbItems={[
              {
                label: "Cart",
                path: "/cart",
              },
            ]}
            title={"Cart"}
          />
        </Col>
      </Row>
      <Row className="my-2">
        <Col>
          <span>Share payment link</span>
          <CopyAndShareText text={`${MAIN_SITE_URL}/cart`} />
        </Col>
      </Row>
      <div className="lef-right-margin">
        <div className="row mobile-view">
          <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12">
            <div className="detailsRightPart ">
              <div className="contactBox mb-2 ">
                {updatedCart && Object.keys(updatedCart)?.length ? (
                  Object.keys(updatedCart)?.map((businessId, i) => (
                    <div key={`shipment-${businessId}`}>
                      <div className="bg-grey p-1">
                        <h6 className="m-0">Shipment no. {i + 1}</h6>
                        <span className="font-size-11">
                          <b>{`Delivery charges: ${deliveryFee} AED`}</b>
                        </span>
                      </div>
                      <br />
                      {updatedCart?.[businessId]?.item &&
                        Object.keys(updatedCart?.[businessId]?.item).map(
                          (cartId, index) => {
                            const item =
                              updatedCart?.[businessId]?.item?.[cartId];

                            return (
                              <div key={`cart-item-${item?.id}`}>
                                <div className="row product-img">
                                  <div className="col-lg-4 col-md-4 col-sm-4 col-xs-3 p-2">
                                    <LoadAsyncImage
                                      id={item?.rfq_response?.photos?.[0]?.id}
                                      path={
                                        item?.rfq_response?.photos?.[0]
                                          ?.thumbUrl
                                      }
                                      isPublic={true}
                                    />
                                  </div>

                                  <div className="col-lg-5 col-md-5 col-sm-5 col-xs-3 p-1">
                                    <b>
                                      {item?.rfq_response?.rfq_request
                                        ?.otherSparePartCategory ||
                                        item?.rfq_response?.rfq_request
                                          ?.sub_category?.categoryName ||
                                        item?.rfq_response?.rfq_request
                                          ?.category?.categoryName}
                                    </b>
                                    <span className="text-muted font-size-11 d-block">
                                      {`${item?.rfq_response?.rfq_request?.rfq?.vehicle_make?.name} ${item?.rfq_response?.rfq_request?.rfq?.vehicle_model?.name} ${item?.rfq_response?.rfq_request?.rfq?.vehicle_year?.year}`}
                                    </span>
                                    {item?.rfq_response?.deleted_at ? (
                                      <p className="text-primary-2 text-bold m-0 font-size-11">
                                        Out of stock
                                      </p>
                                    ) : (
                                      <div className="counting">
                                        <button
                                          type="button"
                                          onClick={onDecrement(
                                            item,
                                            businessId
                                          )}
                                          className="btn-sm btn-flat"
                                        >
                                          -
                                        </button>
                                        <span className="font-size-11">
                                          {item?.qty}
                                        </span>
                                        <button
                                          className="btn-sm btn-flat"
                                          onClick={onIncrement(
                                            item,
                                            businessId,
                                            item?.initialQty
                                          )}
                                        >
                                          +
                                        </button>
                                      </div>
                                    )}
                                  </div>

                                  <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3 p-0">
                                    <Trash2
                                      onClick={handleOpenRemoveCartModal(item)}
                                    />

                                    <span className="product-name">
                                      AED{" "}
                                      {item?.qty ? item?.price * item?.qty : 0}
                                    </span>
                                  </div>
                                </div>
                                <div className="rqef-grey-line2"></div>
                              </div>
                            );
                          }
                        )}
                    </div>
                  ))
                ) : (
                  <div>
                    <span>Cart is empty</span>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="detailsRightPart col-xl-5 col-lg-5 col-md-12 col-sm-12">
            <div className="contactBox">
              <div className="d-flex justify-content-between">
                <span>
                  <b>Delivery at :</b>{" "}
                </span>
                <Button size="sm" onClick={handleShowAddressForm(true)}>
                  <Plus size={14} /> Add new
                </Button>
              </div>
              <br />
              {userAddress?.length && (
                <>
                  <ul className="list p-0">
                    {userAddress?.map((v) => (
                      <li
                        key={`address-${v?.id}`}
                        className={`list-item ${v?.id} d-flex align-items-center`}
                      >
                        <input
                          type="radio"
                          className="mr-2"
                          value={v}
                          onChange={handleSelectedAddress(v)}
                          checked={selectedAddress?.id === v?.id || false}
                        />
                        <span>
                          {[
                            v?.addressLine1,
                            v?.addressLine2,
                            v?.city?.name,
                            v?.province?.provinceName,
                            v?.country?.countryName,
                          ]
                            .filter((v1) => v1)
                            .join(", ")}
                        </span>
                        <Button
                          size="sm"
                          className="ml-2"
                          onClick={handleEditAddress(v)}
                        >
                          <Edit size={14} />
                        </Button>
                      </li>
                    ))}
                  </ul>
                </>
              )}
            </div>
            <div className="detailsRightPart mt-2">
              <div className="contactBox  mb-2 bill">
                <div className="row rqefbildetail">
                  <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                    <span>Bill Detail</span>
                    <p>Subtotal</p>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                    <br />
                    <span className="rqefproduct">AED {totalPrice} </span>
                  </div>
                </div>
                <div className="row rqefbildetail">
                  <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                    <p className="m-0">Delivery charges</p>
                  </div>
                </div>
                {updatedCart &&
                  Object.keys(updatedCart).map((businessId, index) => (
                    <div
                      key={`delivery-charge-${businessId}`}
                      className="row rqefbildetail"
                    >
                      <div className="col-6 col-md-6 col-sm-6">
                        <span className="font-size-11">
                          Courier charges {index + 1}
                        </span>
                      </div>
                      <div className="d-flex align-items-center justify-content-end col-6 col-md-6 col-sm-6">
                        <span className="rqefproduct mr-2">AED</span>
                        <input
                          style={{ width: 100 }}
                          className="form-control"
                          placeholder="Courier charges"
                          value={updatedCart[businessId].deliveryFee || ""}
                          type="number"
                          onChange={handleDeliveryFeeChange(businessId)}
                        />
                      </div>
                    </div>
                  ))}
                <div className="rqef-grey-line-bill"></div>
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                    <span>You Pay</span>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                    <span className="youpay">
                      AED {totalPrice + totalDeliveryFee}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="contactBox">
              <span>
                <b>Payment mode</b>
              </span>
              <span>COD</span>
            </div>
            <br />
            <div className="contactBox">
              <Button
                className="btn-block"
                variant="secondary"
                disabled={!data?.length || loader || !userAddress?.length}
                onClick={data?.length ? makePayment : () => {}}
              >
                {loader ? "Saving..." : "Place order"}
              </Button>
            </div>
          </div>
        </div>

        <div className="desktop-view">
          <div className="row">
            <div className="detailsRightPart col-xl-7 col-lg-7 col-md-12 col-sm-12">
              <div className="contactBox mb-2 ">
                {updatedCart && Object.keys(updatedCart)?.length ? (
                  Object.keys(updatedCart)?.map((businessId, i) => (
                    <div key={`shipment-${businessId}`}>
                      <div className="bg-grey p-1">
                        <h6 className="m-0">
                          <b>Shipment no. {i + 1}</b>
                        </h6>
                        <span className="font-size-11">
                          <b>{`Delivery charges: ${deliveryFee} AED`}</b>
                        </span>
                      </div>
                      <br />
                      {updatedCart?.[businessId]?.item &&
                        Object.keys(updatedCart?.[businessId]?.item).map(
                          (cartId, index) => {
                            const item =
                              updatedCart?.[businessId]?.item?.[cartId];
                            return (
                              <div key={`cart-item-${item?.id}`}>
                                <div className="row product-img">
                                  <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3 p-1">
                                    <LoadAsyncImage
                                      id={item?.rfq_response?.photos?.[0]?.id}
                                      path={
                                        item?.rfq_response?.photos?.[0]
                                          ?.thumbUrl
                                      }
                                      isPublic={true}
                                    />
                                  </div>
                                  <div className="col-lg-6 col-md-6 col-sm-6 col-xs-3 p-1">
                                    <b>
                                      {item?.rfq_response?.rfq_request
                                        ?.sub_category?.categoryName ||
                                        item?.rfq_response?.rfq_request
                                          ?.category?.categoryName}
                                    </b>
                                    <span className="text-muted font-size-11 d-block">
                                      {`${item?.rfq_response?.rfq_request?.rfq?.vehicle_make?.name} ${item?.rfq_response?.rfq_request?.rfq?.vehicle_model?.name} ${item?.rfq_response?.rfq_request?.rfq?.vehicle_year?.year}`}
                                    </span>
                                    {item?.rfq_response?.deleted_at ? (
                                      <p className="text-primary-2 text-bold m-0 font-size-11">
                                        Out of stock
                                      </p>
                                    ) : (
                                      <div className="counting">
                                        <button
                                          type="button"
                                          onClick={onDecrement(
                                            item,
                                            businessId
                                          )}
                                          className="btn-sm btn-flat"
                                        >
                                          -
                                        </button>
                                        <span className="font-size-11">
                                          {item?.qty}
                                        </span>
                                        <button
                                          className="btn-sm btn-flat"
                                          onClick={onIncrement(
                                            item,
                                            businessId,
                                            item?.initialQty
                                          )}
                                        >
                                          +
                                        </button>
                                      </div>
                                    )}
                                  </div>
                                  <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3 p-0">
                                    <span className="product-name">
                                      AED{" "}
                                      {item?.qty ? item?.price * item?.qty : 0}
                                    </span>
                                    <Button
                                      type="button"
                                      variant="outline-primary"
                                      size="sm"
                                      onClick={handleOpenRemoveCartModal(item)}
                                    >
                                      <Trash2 size={14} />
                                    </Button>
                                  </div>
                                </div>
                                <div className="rqef-grey-line2"></div>
                              </div>
                            );
                          }
                        )}
                    </div>
                  ))
                ) : (
                  <div>
                    <span>Cart is empty</span>
                  </div>
                )}
              </div>
            </div>

            <div className="detailsRightPart col-xl-5 col-lg-5 col-md-12 col-sm-12">
              <div className="contactBox">
                <div className="d-flex justify-content-between">
                  <span>
                    <b>Delivery at :</b>{" "}
                  </span>
                  <Button size="sm" onClick={handleShowAddressForm(true)}>
                    <Plus size={14} /> Add new address
                  </Button>
                </div>
                {userAddress?.length && (
                  <>
                    <ul className="list p-0">
                      {userAddress?.map((v) => (
                        <li
                          key={`address-${v?.id}`}
                          className={`list-item ${v?.id} d-flex align-items-center`}
                        >
                          <input
                            type="radio"
                            className="mr-2"
                            value={v}
                            onChange={handleSelectedAddress(v)}
                            checked={selectedAddress?.id === v?.id || false}
                          />
                          <span>
                            {[
                              v?.addressLine1,
                              v?.addressLine2,
                              v?.city?.name,
                              v?.province?.provinceName,
                              v?.country?.countryName,
                            ]
                              .filter((v1) => v1)
                              .join(", ")}
                          </span>
                          <Button size="sm" onClick={handleEditAddress(v)}>
                            <Edit size={14} />
                          </Button>
                        </li>
                      ))}
                    </ul>
                  </>
                )}
              </div>
              <br />
              <div className="contactBox  mb-2 bill">
                <div className="row rqefbildetail">
                  <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                    <span>Bill Detail</span>
                    <p>Subtotal</p>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                    <br />
                    <span className="rqefproduct">AED {totalPrice} </span>
                  </div>
                </div>
                <div className="row rqefbildetail">
                  <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                    <p className="m-0">Delivery charges</p>
                  </div>
                </div>
                {updatedCart &&
                  Object.keys(updatedCart).map((businessId, index) => (
                    <div
                      key={`delivery-charge-${index}`}
                      className="row rqefbildetail"
                    >
                      <div className="col-6 col-md-6 col-sm-6">
                        <span className="font-size-11">
                          Courier charges {index + 1}
                        </span>
                      </div>
                      <div className="d-flex align-items-center justify-content-end col-6 col-md-6 col-sm-6">
                        <span className="rqefproduct mr-2">AED</span>
                        <input
                          style={{
                            width: 100,
                          }}
                          className="form-control"
                          placeholder="Courier charges"
                          value={updatedCart[businessId].deliveryFee || ""}
                          type="number"
                          onChange={handleDeliveryFeeChange(businessId)}
                        />
                      </div>
                    </div>
                  ))}
                <div className="rqef-grey-line-bill"></div>
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                    <span>Total</span>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                    <span className="youpay">
                      AED {totalPrice + totalDeliveryFee}
                    </span>
                  </div>
                </div>
              </div>
              <br />
              <div className="contactBox">
                <span>
                  <b>Payment mode</b>
                </span>
                <span>COD</span>
              </div>
              <br />
              <div className="contactBox">
                <Button
                  className="btn-block"
                  variant="secondary"
                  disabled={!data?.length || loader || !userAddress?.length}
                  onClick={data?.length ? makePayment : () => {}}
                >
                  {loader ? "Saving..." : "Place order"}
                </Button>
              </div>
            </div>
          </div>
        </div>

        <CustomModal
          open={cartOpen}
          closeModal={onCartDeleteClose}
          onSubmit={handleRemoveFromCart}
          cancelButtonText={"Cancel"}
          submitButtonText={"Delete"}
        >
          <h4>
            {` Do you want to delete "
          ${
            cartItemToDelete?.rfq_response?.rfq_request
              ?.otherSparePartCategory ||
            cartItemToDelete?.rfq_response?.rfq_request?.sub_category
              ?.categoryName ||
            cartItemToDelete?.rfq_response?.rfq_request?.category?.categoryName
          }
          " from cart?`}
          </h4>
        </CustomModal>

        <CustomModal
          open={showAddressForm}
          onCancel={onCloseAddressForm}
          closeModal={onCloseAddressForm}
          openModal={onOpenAddressForm}
          noFooter
        >
          <AddressForm
            addressFormDefaultData={actionItem}
            handleClose={handleShowAddressForm(false)}
            loader={userAddressLoading}
            onSubmit={handleSubmitAddress}
          />
        </CustomModal>
      </div>
    </div>
  );
};

export default Cart;
