export const RFQ_REQUEST_STATUS = {
    1: 'Awaiting quote', // 1
    2: 'Ordered', //3
    3: 'Partial ordered', //4
    4: 'Cancelled', //5
    5: 'Completed', //6
    6: 'Quote received', //2
  };
  
  export const RFQ_RESPONSE_STATUS = {
    1: 'New quote',
    2: 'Submitted',
    3: 'Order received',
    4: 'Cancelled',
    5: 'Completed',
    6: 'Order confirmed',
    7: 'Closed',
  };
  export const RFQ_SERVICE_PROVIDER_STATUS = {
    ...RFQ_REQUEST_STATUS,
    5: 'Ordered',
  };
  export const ORDER_STATUS = {
    1: 'Order placed',
    2: 'Order confirmed',
    3: 'Dispatched',
    4: 'Delivered',
    5: 'Rejected',
    6: 'Cancelled',
    7: 'Return requested',
    8: 'Return approved',
    9: 'Item picked up',
    10: 'Item received',
    13: 'Return initiated',
    11: 'Return complete',
    12: 'Return cancelled',
  };
  
  export const ROLES = {
    SUPPLIER: 'supplier',
    SERVICE_PROVIDER: 'service-provider',
    USER: 'user',
    ADMIN: 'admin',
    INSURANCE_PROVIDER: 'insurance-provider',
  };
  
  export const workingDaysOptions = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday',
  ];
  
  export const NO_IMAGE = require('../assets/images/no-image.png');
  