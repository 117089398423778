// @flow
import { Cookies } from "react-cookie";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import { fetchJSON } from "../../helpers/api";

import {
  LOGIN_USER,
  LOGOUT_USER,
  REGISTER_USER,
  FORGET_PASSWORD,
  PARTNER_LOGIN_USER,
  PARTNER_LOGOUT_USER,
  PARTNER_REGISTER_USER,
  PARTNER_FORGET_PASSWORD,
  PARTNER_VERIFY_OTP,
  RESET_PASSWORD,
  GOOGLE_LOGIN,
  ACTIVATE_USER,
} from "./constants";

import {
  loginUserSuccess,
  loginUserFailed,
  registerUserSuccess,
  registerUserFailed,
  forgetPasswordSuccess,
  forgetPasswordFailed,
  //partner
  partnerLoginUserFailed,
  partnerRegisterUserSuccess,
  partnerRegisterUserFailed,
  partnerForgetPasswordSuccess,
  partnerForgetPasswordFailed,
  partnerVerifyOtpSuccess,
  partnerVerifyOtpFailed,
  partnerLoginUserWithPhoneSuccess,
  partnerLoginUserWithEmailSuccess,
  resetPasswordSuccess,
  resetPasswordFailed,
  googleLoginFailed,
  googleLoginSuccess,
  activateUserFailed,
  activateUserSuccess,
  logoutUserSuccess,
  logoutUserFailed,
} from "./actions";
import { logoutGoogleUser } from "../../lib/firebase";
import { clearUserSession } from "../../helpers/authUtils";

/**
 * Sets the session
 * @param {*} user
 */
const setSession = (data) => {
  try {
    let cookies = new Cookies();
    if (data?.user)
      cookies.set("user", JSON.stringify(data?.user), { path: "/" });
    else cookies.remove("user", { path: "/" });
  } catch (error) {
    console.log("cookie error", error);
  }
};
/**
 * Login the user
 * @param {*} payload - username and password
 */
function* login({ payload: { email, password } }) {
  const options = {
    data: { email, password },
    method: "POST",
    headers: { "Content-Type": "application/json" },
  };

  try {
    const response = yield call(fetchJSON, "/auth/login", options);
    console.log("response?.data", response);
    if (response?.status === "Error")
      yield put(loginUserFailed(response?.errors));
    else {
      yield put(loginUserSuccess(response?.data));

      setSession({ user: response.data });
    }
  } catch (error) {
    let message = "Oops something went wrong";

    yield put(loginUserFailed(message));
    setSession(null);
  }
}

/**
 * Google Login the user
 * @param {*} payload - username and password
 */
function* googleLogin({ payload }) {
  const options = {
    data: payload,
    method: "POST",
    headers: { "Content-Type": "application/json", Accept: "application/json" },
  };

  try {
    const response = yield call(fetchJSON, "/user/login/google", options);
    if (response?.status === "Error")
      yield put(googleLoginFailed(response?.errors));
    else {
      setSession({ user: response.data });
      yield put(googleLoginSuccess(response?.data));
      yield call(async () => {
        const result = await logoutGoogleUser();
        console.log("google,", result);
      });
    }
  } catch (error) {
    let message = "Oops something went wrong";

    yield put(loginUserFailed(message));
    setSession(null);
  }
}

/**
 * Logout the user
 * @param {*} param0
 */
function* logout({ payload: { history, loginUrl, token } }) {
  try {
    const response = yield call(fetchJSON, "/auth/logout", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status !== "Error") {
      clearUserSession();
      yield put(logoutUserSuccess(response?.data));
      yield call(() => {
        history.push(loginUrl);
      });
    } else {
      console.log("error", response);
    }
  } catch (error) {
    yield put(logoutUserFailed(error));
    console.log("error", error);
    history.push(loginUrl);
  }
}

/**
 * Register the user
 */
function* register({ payload: { fullname, email, password } }) {
  const options = {
    body: JSON.stringify({ fullname, email, password }),
    method: "POST",
    headers: { "Content-Type": "application/json" },
  };

  try {
    const response = yield call(fetchJSON, "/users/register", options);
    yield put(registerUserSuccess(response));
  } catch (error) {
    let message;
    switch (error.status) {
      case 500:
        message = "Internal Server Error";
        break;
      case 401:
        message = "Invalid credentials";
        break;
      default:
        message = error;
    }
    yield put(registerUserFailed(message));
  }
}

/**
 * forget password
 */
function* forgetPassword({ payload: { email } }) {
  const options = {
    data: { email },
    method: "POST",
    headers: { "Content-Type": "application/json" },
  };

  try {
    const response = yield call(fetchJSON, "/forgot-password", options);
    if (response?.status !== "Error")
      yield put(forgetPasswordSuccess(response?.data?.status));
    else yield put(forgetPasswordFailed(response?.errors));
  } catch (error) {
    let message = "Oops something went wrong";
    yield put(forgetPasswordFailed(message));
  }
}

/**
 * reset password
 */
function* resetPassword({ payload }) {
  const options = {
    data: payload,
    method: "POST",
    headers: { "Content-Type": "application/json", Accept: "application/json" },
  };

  try {
    const response = yield call(fetchJSON, "/reset-password", options);
    if (response?.status === "Error")
      yield put(resetPasswordFailed(response?.errors));
    else yield put(resetPasswordSuccess(response?.data?.status));
  } catch (error) {
    let message = "Oops something went wrong";
    yield put(resetPasswordFailed(message));
  }
}
/**
 * activate user
 */
function* activateUser({ payload }) {
  const options = {
    data: payload,
    method: "POST",
    headers: { "Content-Type": "application/json", Accept: "application/json" },
  };

  try {
    const response = yield call(fetchJSON, "/activate", options);
    if (response?.status === "Error")
      yield put(activateUserFailed(response?.errors));
    else yield put(activateUserSuccess(response?.message));
  } catch (error) {
    let message = "Oops something went wrong";
    yield put(activateUserFailed(message));
  }
}
//partner sagas
/**
 * Login the user
 * @param {*} payload - username and password
 */
function* partnerLogin({ payload: { data, history } }) {
  const options = {
    data,
    method: "POST",
    headers: { "Content-Type": "application/json" },
  };

  try {
    const response = yield call(fetchJSON, `/partner/login`, options);
    if (response?.status === "Error")
      yield put(partnerLoginUserFailed(response?.errors));
    else {
      if (data?.signupprovider === "phone") {
        yield put(partnerLoginUserWithPhoneSuccess(response?.data));
        setSession({ user: response.data });
        yield call(() => {
          if (response?.data?.roles?.[0]?.role_slug === "agent")
            history.push(`/updateSupplier`);
          else history.push(`/`);
        });
      }
      if (data?.signupprovider === "email") {
        setSession({ user: response.data });
        yield put(partnerLoginUserWithEmailSuccess(response?.data));
      }
    }
  } catch (error) {
    let message = "Oops something went wrong";

    yield put(partnerLoginUserFailed(message));
    setSession(null);
  }
}

/**
 * Logout the user
 * @param {*} param0
 */
function* partnerLogout({ payload: { history, token } }) {
  try {
    const response = yield call(fetchJSON, "/auth/logout", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    console.log("response", response);

    if (response.status !== "Error") {
      setSession(null);
      yield call(() => {
        history.push("/account/login");
      });
    } else {
      console.log("error", response);
    }
  } catch (error) {
    console.log("error", error);
  }
}

/**
 * Register the user
 */
function* partnerRegister({ payload: { history, data } }) {
  console.log("p", data);
  const options = {
    data,
    method: "POST",
    headers: { "Content-Type": "application/json" },
  };

  try {
    const response = yield call(fetchJSON, `/partner/register`, options);
    if (response?.status === "Error")
      yield put(partnerRegisterUserFailed(response?.errors));
    else {
      console.log(response);
      yield put(
        partnerRegisterUserSuccess({ ...data, id: response?.data?.id })
      );
      yield call(() => {
        history.push(`/partner/verify-otp`);
      });
    }
  } catch (error) {
    console.log(error);
    let message = "Oops something went wrong";
    yield put(partnerRegisterUserFailed(message));
  }
}

/**
 * Verify the user with otp
 */
function* partnerVerifyOtp({ payload }) {
  console.log("payload", payload);
  const options = {
    data: payload,
    method: "POST",
    headers: { "Content-Type": "application/json" },
  };

  try {
    const response = yield call(fetchJSON, `/partner/confirm-otp`, options);
    if (response?.status === "Error")
      yield put(partnerVerifyOtpFailed(response?.errors));
    else {
      setSession({ user: response?.data });
      yield put(partnerVerifyOtpSuccess(response?.data));
    }
  } catch (error) {
    let message = "Oops something went wrong";
    yield put(partnerRegisterUserFailed(message));
  }
}

/**
 * forget password
 */
function* partnerForgetPassword({ payload: { username } }) {
  const options = {
    body: JSON.stringify({ username }),
    method: "POST",
    headers: { "Content-Type": "application/json" },
  };

  try {
    const response = yield call(fetchJSON, "/users/password-reset", options);
    yield put(partnerForgetPasswordSuccess(response.message));
  } catch (error) {
    let message;
    switch (error.status) {
      case 500:
        message = "Internal Server Error";
        break;
      case 401:
        message = "Invalid credentials";
        break;
      default:
        message = error;
    }
    yield put(partnerForgetPasswordFailed(message));
  }
}

export function* watchLoginUser() {
  yield takeEvery(LOGIN_USER, login);
}

export function* watchLogoutUser() {
  yield takeEvery(LOGOUT_USER, logout);
}

export function* watchRegisterUser() {
  yield takeEvery(REGISTER_USER, register);
}

export function* watchForgetPassword() {
  yield takeEvery(FORGET_PASSWORD, forgetPassword);
}

export function* watchResetPassword() {
  yield takeEvery(RESET_PASSWORD, resetPassword);
}
export function* watchActivateUser() {
  yield takeEvery(ACTIVATE_USER, activateUser);
}
// partner watcher
export function* watchPartnerLoginUser() {
  yield takeEvery(PARTNER_LOGIN_USER, partnerLogin);
}

export function* watchPartnerLogoutUser() {
  yield takeEvery(PARTNER_LOGOUT_USER, partnerLogout);
}

export function* watchPartnerRegisterUser() {
  yield takeEvery(PARTNER_REGISTER_USER, partnerRegister);
}

export function* watchPartnerForgetPassword() {
  yield takeEvery(PARTNER_FORGET_PASSWORD, partnerForgetPassword);
}

export function* watchPartnerVerifyOtp() {
  yield takeEvery(PARTNER_VERIFY_OTP, partnerVerifyOtp);
}
export function* watchGoogleLogin() {
  yield takeEvery(GOOGLE_LOGIN, googleLogin);
}
function* authSaga() {
  yield all([
    fork(watchLoginUser),
    fork(watchLogoutUser),
    fork(watchRegisterUser),
    fork(watchForgetPassword),
    fork(watchResetPassword),
    fork(watchActivateUser),
    //partner
    fork(watchPartnerLoginUser),
    fork(watchPartnerLogoutUser),
    fork(watchPartnerRegisterUser),
    fork(watchPartnerVerifyOtp),
    fork(watchPartnerForgetPassword),
    fork(watchGoogleLogin),
  ]);
}

export default authSaga;
