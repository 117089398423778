export const SPARE_PARTS_CATEGORY = "SPARE_PARTS_CATEGORY";
export const SPARE_PARTS_CATEGORY_SUCCESS = "SPARE_PARTS_CATEGORY_SUCCESS";
export const SPARE_PARTS_CATEGORY_FAILED = "SPARE_PARTS_CATEGORY_FAILED";
export const SAVE_SPARE_PARTS_CATEGORY = "SAVE_SPARE_PARTS_CATEGORY";
export const SAVE_SPARE_PARTS_CATEGORY_SUCCESS = "SAVE_SPARE_PARTS_CATEGORY_SUCCESS";
export const SAVE_SPARE_PARTS_CATEGORY_FAILED = "SAVE_SPARE_PARTS_CATEGORY_FAILED";
export const EDIT_SPARE_PARTS_CATEGORY = "EDIT_SPARE_PARTS_CATEGORY";
export const EDIT_SPARE_PARTS_CATEGORY_SUCCESS = "EDIT_SPARE_PARTS_CATEGORY_SUCCESS";
export const EDIT_SPARE_PARTS_CATEGORY_FAILED = "EDIT_SPARE_PARTS_CATEGORY_FAILED";
export const DELETE_SPARE_PARTS_CATEGORY = "DELETE_SPARE_PARTS_CATEGORY";
export const DELETE_SPARE_PARTS_CATEGORY_SUCCESS =
  "DELETE_SPARE_PARTS_CATEGORY_SUCCESS";
export const DELETE_SPARE_PARTS_CATEGORY_FAILED = "DELETE_SPARE_PARTS_CATEGORY_FAILED";
export const GET_SPARE_PARTS_CATEGORY = "GET_SPARE_PARTS_CATEGORY";
export const GET_SPARE_PARTS_CATEGORY_SUCCESS = "GET_SPARE_PARTS_CATEGORY_SUCCESS";
export const GET_SPARE_PARTS_CATEGORY_FAILED = "GET_SPARE_PARTS_CATEGORY_FAILED";
export const GET_SPARE_PARTS_PARENT_CATEGORY = "GET_SPARE_PARTS_PARENT_CATEGORY";
export const GET_SPARE_PARTS_PARENT_CATEGORY_SUCCESS = "GET_SPARE_PARTS_PARENT_CATEGORY_SUCCESS";
export const GET_SPARE_PARTS_PARENT_CATEGORY_FAILED = "GET_SPARE_PARTS_PARENT_CATEGORY_FAILED";
