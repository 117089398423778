export * from "./layout";

export const RFQ_STATUS = {
  1: "Requested",
  2: "Pending",
  3: "Approved",
};

export const VERIFICATION_STATUS = {
  pending: "pending",
  verified: "verified",
  unverified: "unverified",
};

export const DEFAULT_STATUS = { 1: "Active", 0: "Inactive" };


export const BASE_URL = process.env.REACT_APP_BACKEND_URL;
export const API_URL = process.env.REACT_APP_BACKEND_API_URL;
export const MAIN_SITE_URL = process.env.REACT_APP_MAIN_SITE_URL;
