export const SAVE_WHATSAPP_GROUP = "SAVE_WHATSAPP_GROUP";
export const SAVE_WHATSAPP_GROUP_SUCCESS = "SAVE_WHATSAPP_GROUP_SUCCESS";
export const SAVE_WHATSAPP_GROUP_FAILED = "SAVE_WHATSAPP_GROUP_FAILED";
export const EDIT_WHATSAPP_GROUP = "EDIT_WHATSAPP_GROUP";
export const EDIT_WHATSAPP_GROUP_SUCCESS = "EDIT_WHATSAPP_GROUP_SUCCESS";
export const EDIT_WHATSAPP_GROUP_FAILED = "EDIT_WHATSAPP_GROUP_FAILED";
export const DELETE_WHATSAPP_GROUP = "DELETE_WHATSAPP_GROUP";
export const DELETE_WHATSAPP_GROUP_SUCCESS =
  "DELETE_WHATSAPP_GROUP_SUCCESS";
export const DELETE_WHATSAPP_GROUP_FAILED = "DELETE_WHATSAPP_GROUP_FAILED";
export const GET_WHATSAPP_GROUP = "GET_WHATSAPP_GROUP";
export const GET_WHATSAPP_GROUP_SUCCESS = "GET_WHATSAPP_GROUP_SUCCESS";
export const GET_WHATSAPP_GROUP_FAILED = "GET_WHATSAPP_GROUP_FAILED";
