// @flow
import {
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_FAILED,
  LOGOUT_USER,
  REGISTER_USER,
  REGISTER_USER_SUCCESS,
  REGISTER_USER_FAILED,
  FORGET_PASSWORD,
  FORGET_PASSWORD_SUCCESS,
  FORGET_PASSWORD_FAILED,
  PARTNER_LOGIN_USER,
  PARTNER_LOGIN_USER_FAILED,
  PARTNER_LOGOUT_USER,
  PARTNER_REGISTER_USER,
  PARTNER_REGISTER_USER_SUCCESS,
  PARTNER_REGISTER_USER_FAILED,
  PARTNER_FORGET_PASSWORD,
  PARTNER_FORGET_PASSWORD_SUCCESS,
  PARTNER_FORGET_PASSWORD_FAILED,
  PARTNER_VERIFY_OTP,
  PARTNER_VERIFY_OTP_SUCCESS,
  PARTNER_VERIFY_OTP_FAILED,
  PARTNER_LOGIN_USER_WITH_PHONE_SUCCESS,
  PARTNER_LOGIN_USER_WITH_EMAIL_SUCCESS,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILED,
  GOOGLE_LOGIN,
  GOOGLE_LOGIN_SUCCESS,
  GOOGLE_LOGIN_FAILED,
  ACTIVATE_USER,
  ACTIVATE_USER_SUCCESS,
  ACTIVATE_USER_FAILED,
  LOGOUT_USER_FAILED,
  LOGOUT_USER_SUCCESS,
} from "./constants";

export const loginUser = (email, password) => ({
  type: LOGIN_USER,
  payload: { email, password },
});

export const loginUserSuccess = (user) => ({
  type: LOGIN_USER_SUCCESS,
  payload: user,
});

export const loginUserFailed = (error) => ({
  type: LOGIN_USER_FAILED,
  payload: error,
});

export const registerUser = (payload, history) => ({
  type: REGISTER_USER,
  payload: { payload, history },
});

export const registerUserSuccess = (user) => ({
  type: REGISTER_USER_SUCCESS,
  payload: user,
});

export const registerUserFailed = (error) => ({
  type: REGISTER_USER_FAILED,
  payload: error,
});

export const logoutUser = (history, loginUrl, token) => ({
  type: LOGOUT_USER,
  payload: { history, loginUrl, token },
});

export const logoutUserSuccess = (payload) => ({
  type: LOGOUT_USER_SUCCESS,
  payload,
});

export const logoutUserFailed = (error) => ({
  type: LOGOUT_USER_FAILED,
  payload: error,
});
export const forgetPassword = ({ email }) => ({
  type: FORGET_PASSWORD,
  payload: { email },
});

export const forgetPasswordSuccess = (passwordResetStatus) => ({
  type: FORGET_PASSWORD_SUCCESS,
  payload: passwordResetStatus,
});

export const forgetPasswordFailed = (error) => ({
  type: FORGET_PASSWORD_FAILED,
  payload: error,
});

export const resetPassword = (payload) => ({
  type: RESET_PASSWORD,
  payload,
});

export const resetPasswordSuccess = (passwordResetStatus) => ({
  type: RESET_PASSWORD_SUCCESS,
  payload: passwordResetStatus,
});

export const resetPasswordFailed = (error) => ({
  type: RESET_PASSWORD_FAILED,
  payload: error,
});

export const activateUser = (payload) => ({
  type: ACTIVATE_USER,
  payload,
});

export const activateUserSuccess = (passwordResetStatus) => ({
  type: ACTIVATE_USER_SUCCESS,
  payload: passwordResetStatus,
});

export const activateUserFailed = (error) => ({
  type: ACTIVATE_USER_FAILED,
  payload: error,
});
//parnter auth actions
export const partnerLoginUser = (data, history) => ({
  type: PARTNER_LOGIN_USER,
  payload: { data, history },
});

export const partnerLoginUserWithPhoneSuccess = (user) => ({
  type: PARTNER_LOGIN_USER_WITH_PHONE_SUCCESS,
  payload: user,
});
export const partnerLoginUserWithEmailSuccess = (user) => ({
  type: PARTNER_LOGIN_USER_WITH_EMAIL_SUCCESS,
  payload: user,
});

export const partnerLoginUserFailed = (error) => ({
  type: PARTNER_LOGIN_USER_FAILED,
  payload: error,
});

export const partnerRegisterUser = (data, history) => ({
  type: PARTNER_REGISTER_USER,
  payload: { data, history },
});

export const partnerRegisterUserSuccess = (user) => ({
  type: PARTNER_REGISTER_USER_SUCCESS,
  payload: user,
});

export const partnerRegisterUserFailed = (error) => ({
  type: PARTNER_REGISTER_USER_FAILED,
  payload: error,
});

export const partnerLogoutUser = (history, token) => ({
  type: PARTNER_LOGOUT_USER,
  payload: { history, token },
});

export const partnerForgetPassword = (username) => ({
  type: PARTNER_FORGET_PASSWORD,
  payload: { username },
});

export const partnerForgetPasswordSuccess = (passwordResetStatus) => ({
  type: PARTNER_FORGET_PASSWORD_SUCCESS,
  payload: passwordResetStatus,
});

export const partnerForgetPasswordFailed = (error) => ({
  type: PARTNER_FORGET_PASSWORD_FAILED,
  payload: error,
});

export const partnerVerifyOtp = (payload) => ({
  type: PARTNER_VERIFY_OTP,
  payload,
});

export const partnerVerifyOtpSuccess = (user) => ({
  type: PARTNER_VERIFY_OTP_SUCCESS,
  payload: user,
});

export const partnerVerifyOtpFailed = (error) => ({
  type: PARTNER_VERIFY_OTP_FAILED,
  payload: error,
});

export const googleLogin = (payload) => ({
  type: GOOGLE_LOGIN,
  payload,
});

export const googleLoginSuccess = (user) => ({
  type: GOOGLE_LOGIN_SUCCESS,
  payload: user,
});

export const googleLoginFailed = (error) => ({
  type: GOOGLE_LOGIN_FAILED,
  payload: error,
});
