import jsonToFormData from "json-form-data";

import {
  all,
  call,
  fork,
  put,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";

import { authRequest } from "../../helpers/api";
import { appNotification } from "../app/actions";
import {
  deleteSupplierBusinessFailed,
  deleteSupplierBusinessSuccess,
  getAllSupplierBusinessFailed,
  getAllSupplierBusinessSuccess,
  getSupplierBusinessesFailed,
  getSupplierBusinessesSuccess,
  getSupplierBusinessFailed,
  getSupplierBusinessSuccess,
  saveSupplierBusinessFailed,
  saveSupplierBusinessSuccess,
  updateSupplierVerificationStatusFailed,
  updateSupplierVerificationStatusSuccess,
} from "./actions";
import {
  DELETE_SUPPLIER_BUSINESS,
  SAVE_SUPPLIER_BUSINESS,
  SUPPLIER_BUSINESS,
  GET_SUPPLIER_BUSINESS,
  UPDATE_SUPPLIER_VERIFICATION_STATUS,
  GET_ALL_SUPPLIER_BUSINESS,
} from "./constants";
import {
  getAllServiceProviderBusinessApi,
  getAllSupplierBusinessApi,
} from "../../services/api";
const base_url = `/supplier-business`;
function* getSupplierBusinesses({ payload }) {
  try {
    let url = `${base_url}?`;
    if (payload)
      Object.keys(payload).map(
        (params) =>
          payload?.[params] && (url += `${params}=${payload[params]}&`)
      );
    const response = yield call(authRequest, url.slice(0, url.length - 1), {
      method: "get",
    });
    yield put(getSupplierBusinessesSuccess(response?.data));
  } catch (error) {
    let message = "Failed to get supplier business";
    yield put(getSupplierBusinessesFailed(message));
  }
}

function* saveSupplierBusiness({ payload: { data, history } }) {
  try {
    const formData = jsonToFormData(data);
    let url = `${base_url}`;
    if (data?.id) url += `/${data?.id}?_method=PUT`;

    const response = yield call(authRequest, url, {
      method: "post",
      data: formData,
      headers: {
        "Content-type": "multipart/form-data",
        Accept: "application/json",
      },
    });
    yield put(saveSupplierBusinessSuccess(response?.data));
    yield put(appNotification({ type: "success", message: response?.message }));
    history.goBack();
  } catch (error) {
    let message = "Failed to save user data";
    if (error?.errors) yield put(saveSupplierBusinessFailed(error?.errors));
    else yield put(saveSupplierBusinessFailed(message));
  }
}
function* getSupplierBusiness({ payload: { id } }) {
  try {
    const response = yield call(authRequest, `${base_url}/${id}`, {
      method: "get",
    });
    yield put(getSupplierBusinessSuccess(response?.data));
  } catch (error) {
    let message = "Failed to get supplier business";
    yield put(getSupplierBusinessFailed(message));
  }
}
function* deleteSupplierBusiness({ payload: { id } }) {
  try {
    const response = yield call(authRequest, `${base_url}/${id}`, {
      method: "delete",
    });
    yield put(appNotification({ type: "success", message: response?.message }));
    yield put(deleteSupplierBusinessSuccess({ id }));
  } catch (error) {
    console.log("error", error);
    let message = "Failed to get supplier business";
    yield put(deleteSupplierBusinessFailed(message));
  }
}
function* updateSupplierVerificationStatus({ payload: { status, id } }) {
  try {
    const response = yield call(
      authRequest,
      `${base_url}/verificationStatusUpdate/${id}?_method=PUT`,
      {
        method: "post",
        data: { verification_status: status },
      }
    );
    yield put(updateSupplierVerificationStatusSuccess(response?.data));
    yield put(appNotification({ type: "success", message: response?.message }));
  } catch (error) {
    let message = "Failed to update verification status";
    if (error?.errors)
      yield put(updateSupplierVerificationStatusFailed(error?.errors));
    else yield put(updateSupplierVerificationStatusFailed(message));
  }
}

function* getAllSupplierBusinessSaga({ payload }) {
  try {
    let data;
    if (payload === "supplier") data = yield call(getAllSupplierBusinessApi);

    yield put(getAllSupplierBusinessSuccess(data?.data));
  } catch (error) {
    if (error?.response?.status != 422) {
      let message = "";
      if (error) message = "Oops something went wrong";
      yield put(getAllSupplierBusinessFailed(error));
    } else {
      const { errors } = error?.response?.data;
      console.log("errors", errors);
      yield put(getAllSupplierBusinessFailed(errors));
    }
  }
}

export function* watchGetSupplierBusinesses() {
  yield takeLatest(SUPPLIER_BUSINESS, getSupplierBusinesses);
}
export function* watchGetSupplierBusiness() {
  yield takeEvery(GET_SUPPLIER_BUSINESS, getSupplierBusiness);
}
export function* watchSaveSupplierBusiness() {
  yield takeEvery(SAVE_SUPPLIER_BUSINESS, saveSupplierBusiness);
}
export function* watchDeleteSupplierBusiness() {
  yield takeEvery(DELETE_SUPPLIER_BUSINESS, deleteSupplierBusiness);
}
export function* watchUpdateSupplierVerificationStatus() {
  yield takeEvery(
    UPDATE_SUPPLIER_VERIFICATION_STATUS,
    updateSupplierVerificationStatus
  );
}
export function* watchGetAllSupplierBusinessSaga() {
  yield takeEvery(GET_ALL_SUPPLIER_BUSINESS, getAllSupplierBusinessSaga);
}
function* supplierBusinessSaga() {
  yield all([
    fork(watchGetSupplierBusinesses),
    fork(watchSaveSupplierBusiness),
    fork(watchGetSupplierBusiness),
    fork(watchDeleteSupplierBusiness),
    fork(watchUpdateSupplierVerificationStatus),
    fork(watchGetAllSupplierBusinessSaga),
  ]);
}

export default supplierBusinessSaga;
