import {
  all,
  call,
  fork,
  put,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";

import { authRequest } from "../../helpers/api";
import { appNotification } from "../app/actions";
import {
  deleteVehicleMakeFailed,
  deleteVehicleMakeSuccess,
  getVehicleMakesFailed,
  getVehicleMakesSuccess,
  getVehicleMakeFailed,
  getVehicleMakeSuccess,
} from "./actions";
import {
  DELETE_VEHICLE_MAKE,
  GET_VEHICLE_MAKE,
  SAVE_VEHICLE_MAKE,
  VEHICLE_MAKE,
} from "./constants";

function* getVehicleMakes({ payload }) {
  try {
    let url = `/vehicle/car-make?`;
    if (payload)
      Object.keys(payload).map(
        (params) =>
          payload?.[params] && (url += `${params}=${payload[params]}&`)
      );
    const response = yield call(authRequest, url.slice(0, url.length - 1), {
      method: "get",
    });
    console.log("response", response);
    yield put(getVehicleMakesSuccess(response?.data));
  } catch (error) {
    let message = "Failed to get vehicle make";
    yield put(getVehicleMakesFailed(message));
  }
}

function* saveVehicleMake({ payload: { data, history } }) {
  try {
    console.log("data", data);
    const formData = new FormData();
    Object.keys(data).map((key) => formData.append(key, data[key]));
    let url = `/vehicle/car-make`;
    if (data?.id) url += `/${data?.id}?_method=PUT`;

    const response = yield call(authRequest, url, {
      method: "post",
      data: formData,
      headers: {
        "content-type": "multipart/form-data",
      },
    });
    yield put(getVehicleMakesSuccess(response?.data));
    yield put(appNotification({ type: "success", message: response?.message }));
    history.goBack();
  } catch (error) {
    let message = "Failed to get vehicle make";
    yield put(getVehicleMakesFailed(message));
  }
}
function* getVehicleMake({ payload: { id } }) {
  try {
    const response = yield call(authRequest, `/vehicle/car-make/${id}`, {
      method: "get",
    });
    yield put(getVehicleMakeSuccess(response?.data));
  } catch (error) {
    let message = "Failed to get vehicle make";
    yield put(getVehicleMakeFailed(message));
  }
}
function* deleteVehicleMake({ payload: { id } }) {
  try {
    const response = yield call(authRequest, `/vehicle/car-make/${id}`, {
      method: "delete",
    });
    yield put(appNotification({ type: "success", message: response?.message }));
    yield put(deleteVehicleMakeSuccess({ id }));
  } catch (error) {
      console.log('error',error)
    let message = "Failed to get vehicle make";
    yield put(deleteVehicleMakeFailed(message));
  }
}
export function* watchGetVehicleMakes() {
  yield takeLatest(VEHICLE_MAKE, getVehicleMakes);
}
export function* watchGetServiceCategory() {
  yield takeEvery(GET_VEHICLE_MAKE, getVehicleMake);
}
export function* watchSaveServiceCategory() {
  yield takeEvery(SAVE_VEHICLE_MAKE, saveVehicleMake);
}
export function* watchDeleteServiceCategory() {
  yield takeEvery(DELETE_VEHICLE_MAKE, deleteVehicleMake);
}
function* vehicleMakeSaga() {
  yield all([
    fork(watchGetVehicleMakes),
    fork(watchSaveServiceCategory),
    fork(watchGetServiceCategory),
    fork(watchDeleteServiceCategory),
  ]);
}

export default vehicleMakeSaga;
