import React from "react";
import classNames from "classnames";

/**
 * CheckBoxItem
 */
const CheckBoxItem = ({
  id,
  title,
  subTitle,
  containerStyle = {},
  containerClassName = "",
  ...restProps
}) => {
  return (
    <div
      className={classNames(
        "custom-control",
        "custom-checkbox",
        containerClassName
      )}
      style={containerStyle}
    >
      <input
        type="checkbox"
        className="custom-control-input"
        id={`task-${id}`}
        {...restProps}
      />
      <label className="custom-control-label" htmlFor={`task-${id}`}>
        {title}
      </label>
      <p className="font-size-13 text-muted"> {subTitle}</p>
    </div>
  );
};

export default CheckBoxItem;
