import {
    DELETE_CAR_MODEL,
    DELETE_CAR_MODEL_FAILED,
    DELETE_CAR_MODEL_SUCCESS,
    EDIT_CAR_MODEL,
    EDIT_CAR_MODEL_FAILED,
    EDIT_CAR_MODEL_SUCCESS,
    SAVE_CAR_MODEL,
    SAVE_CAR_MODEL_FAILED,
    SAVE_CAR_MODEL_SUCCESS,
    CAR_MODEL,
    CAR_MODEL_FAILED,
    CAR_MODEL_SUCCESS,
    GET_CAR_MODEL,
    GET_CAR_MODEL_SUCCESS,
    GET_CAR_MODEL_FAILED,
  } from "./constants";

  export const getCarModel = (id) => ({
    type: GET_CAR_MODEL,
    payload: { id },
  });

  export const getCarModelSuccess = (data) => ({
    type: GET_CAR_MODEL_SUCCESS,
    payload: data,
  });

  export const getCarModelFailed = (errorMessage) => ({
    type: GET_CAR_MODEL_FAILED,
    payload: errorMessage,
  });

  export const getCarModels = (payload) => ({
    type: CAR_MODEL,
    payload,
  });

  export const getCarModelsSuccess = (data) => ({
    type: CAR_MODEL_SUCCESS,
    payload: { data },
  });

  export const getCarModelsFailed = (errorMessage) => ({
    type: CAR_MODEL_FAILED,
    payload: errorMessage,
  });

  export const saveCarModel = ({ data, history }) => ({
    type: SAVE_CAR_MODEL,
    payload: { data, history },
  });

  export const saveCarModelSuccess = (success) => ({
    type: SAVE_CAR_MODEL_SUCCESS,
    payload: success,
  });

  export const saveCarModelFailed = (errorMessage) => ({
    type: SAVE_CAR_MODEL_FAILED,
    payload: errorMessage,
  });

  export const editCarModel = (data) => ({
    type: EDIT_CAR_MODEL,
    payload: data,
  });

  export const editCarModelSuccess = (success) => ({
    type: EDIT_CAR_MODEL_SUCCESS,
    payload: success,
  });

  export const editCarModelFailed = (errorMessage) => ({
    type: EDIT_CAR_MODEL_FAILED,
    payload: errorMessage,
  });

  export const deleteCarModel = (data) => ({
    type: DELETE_CAR_MODEL,
    payload: data,
  });

  export const deleteCarModelSuccess = (success) => ({
    type: DELETE_CAR_MODEL_SUCCESS,
    payload: success,
  });

  export const deleteCarModelFailed = (errorMessage) => ({
    type: DELETE_CAR_MODEL_FAILED,
    payload: errorMessage,
  });
