import { forwardRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Input, InputGroup, InputGroupAddon, Label } from "reactstrap";
import { fetchCountryCode } from "../../redux/app/actions";

const PhoneNumber = ({
  error,
  phoneCodeProps,
  label,
  inputRef,
  ...phoneNumberProps
}) => {
  const { data: countryCode, loading } = useSelector(
    (state) => state.App.countryCode
  );
  const [phoneCode, setPhoneCode] = useState(
    phoneCodeProps?.defaultValue || null
  );
  const [phoneNumber, setPhoneNumber] = useState(
    phoneNumberProps?.defaultValue || null
  );
  const dispatch = useDispatch();
  useEffect(() => {
    if (!countryCode) dispatch(fetchCountryCode());
  }, []);
  const handleChange = (handler) => (e) => {
    if (handler)
      handler({
        ...e,
        target: {
          ...e.target,
          value: phoneCodeProps?.onChange
            ? e.target.value
            : `${phoneCode}${e.target.value}`,
        },
      });
    setPhoneNumber(e.target.value);
  };
  const handlePhoneCodeChange = (handler) => (e) => {
    if (handler) {
      handler(e);
    }
    setPhoneCode(e.target.value);
  };
  return (
    <div>
      {label && (
        <Label for={phoneNumberProps?.id || "phoneNumber"}>{label}</Label>
      )}
      <InputGroup>
        <InputGroupAddon addonType="prepend">
          <Input
            type="select"
            {...phoneCodeProps}
            onChange={handlePhoneCodeChange(phoneCodeProps?.onChange)}
            value={phoneCode}
            className="input-group-text"
          >
            {countryCode?.length &&
              countryCode.map(({ phone, countryCode }) => (
                <option value={phone} key={countryCode}>
                  {phone}
                </option>
              ))}
          </Input>
        </InputGroupAddon>
        <Input
          ref={inputRef}
          type="text"
          placeholder="Enter phone number"
          id="phoneNumber"
          {...phoneNumberProps}
          value={phoneNumber}
          onChange={handleChange(phoneNumberProps.onChange)}
          className={`${error?.phoneNumber?.[0] ? "is-invalid" : null} ${
            phoneNumberProps?.className
          }`}
        />
      </InputGroup>
    </div>
  );
};

export default forwardRef((props, ref) => (
  <PhoneNumber {...props} inputRef={ref} />
));
