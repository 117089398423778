import {
  DELETE_SUPPLIER_BUSINESS,
  DELETE_SUPPLIER_BUSINESS_FAILED,
  DELETE_SUPPLIER_BUSINESS_SUCCESS,
  EDIT_SUPPLIER_BUSINESS,
  EDIT_SUPPLIER_BUSINESS_FAILED,
  EDIT_SUPPLIER_BUSINESS_SUCCESS,
  SAVE_SUPPLIER_BUSINESS,
  SAVE_SUPPLIER_BUSINESS_FAILED,
  SAVE_SUPPLIER_BUSINESS_SUCCESS,
  SUPPLIER_BUSINESS,
  SUPPLIER_BUSINESS_FAILED,
  SUPPLIER_BUSINESS_SUCCESS,
  GET_SUPPLIER_BUSINESS,
  GET_SUPPLIER_BUSINESS_SUCCESS,
  GET_SUPPLIER_BUSINESS_FAILED,
  UPDATE_SUPPLIER_VERIFICATION_STATUS,
  UPDATE_SUPPLIER_VERIFICATION_STATUS_SUCCESS,
  UPDATE_SUPPLIER_VERIFICATION_STATUS_FAILED,
  GET_ALL_SUPPLIER_BUSINESS,
  GET_ALL_SUPPLIER_BUSINESS_SUCCESS,
  GET_ALL_SUPPLIER_BUSINESS_FAILED,
} from "./constants";

export const getAllSupplierBusiness = (payload) => ({
  type: GET_ALL_SUPPLIER_BUSINESS,
  payload,
});

export const getAllSupplierBusinessSuccess = (data) => ({
  type: GET_ALL_SUPPLIER_BUSINESS_SUCCESS,
  payload: data,
});

export const getAllSupplierBusinessFailed = (error) => ({
  type: GET_ALL_SUPPLIER_BUSINESS_FAILED,
  payload: error,
});

export const getSupplierBusiness = (id) => ({
  type: GET_SUPPLIER_BUSINESS,
  payload: { id },
});

export const getSupplierBusinessSuccess = (data) => ({
  type: GET_SUPPLIER_BUSINESS_SUCCESS,
  payload: data,
});

export const getSupplierBusinessFailed = (errorMessage) => ({
  type: GET_SUPPLIER_BUSINESS_FAILED,
  payload: errorMessage,
});

export const getSupplierBusinesses = (payload) => ({
  type: SUPPLIER_BUSINESS,
  payload,
});

export const getSupplierBusinessesSuccess = (data) => ({
  type: SUPPLIER_BUSINESS_SUCCESS,
  payload: { data },
});

export const getSupplierBusinessesFailed = (errorMessage) => ({
  type: SUPPLIER_BUSINESS_FAILED,
  payload: errorMessage,
});

export const saveSupplierBusiness = ({ data, history }) => ({
  type: SAVE_SUPPLIER_BUSINESS,
  payload: { data, history },
});

export const saveSupplierBusinessSuccess = (success) => ({
  type: SAVE_SUPPLIER_BUSINESS_SUCCESS,
  payload: success,
});

export const saveSupplierBusinessFailed = (errorMessage) => ({
  type: SAVE_SUPPLIER_BUSINESS_FAILED,
  payload: errorMessage,
});

export const editSupplierBusiness = (data) => ({
  type: EDIT_SUPPLIER_BUSINESS,
  payload: data,
});

export const editSupplierBusinessSuccess = (success) => ({
  type: EDIT_SUPPLIER_BUSINESS_SUCCESS,
  payload: success,
});

export const editSupplierBusinessFailed = (errorMessage) => ({
  type: EDIT_SUPPLIER_BUSINESS_FAILED,
  payload: errorMessage,
});

export const deleteSupplierBusiness = (data) => ({
  type: DELETE_SUPPLIER_BUSINESS,
  payload: data,
});

export const deleteSupplierBusinessSuccess = (success) => ({
  type: DELETE_SUPPLIER_BUSINESS_SUCCESS,
  payload: success,
});

export const deleteSupplierBusinessFailed = (errorMessage) => ({
  type: DELETE_SUPPLIER_BUSINESS_FAILED,
  payload: errorMessage,
});

export const updateSupplierVerificationStatus = ({ status, id }) => ({
  type: UPDATE_SUPPLIER_VERIFICATION_STATUS,
  payload: { status, id },
});

export const updateSupplierVerificationStatusSuccess = (success) => ({
  type: UPDATE_SUPPLIER_VERIFICATION_STATUS_SUCCESS,
  payload: success,
});

export const updateSupplierVerificationStatusFailed = (errorMessage) => ({
  type: UPDATE_SUPPLIER_VERIFICATION_STATUS_FAILED,
  payload: errorMessage,
});
