import "./AudioRecorder.scss";
import MicRecorder from "mic-recorder-to-mp3";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { Mic, StopCircle } from "react-feather";
import { notifyReact } from "../../services/mobileService";

const AudioRecorder = ({ onStop, maxRecordingTime = 60, reset }) => {
  let timer;
  const [recording, setRecording] = useState({
    isRecording: false,
    blobUrl: "",
    isBlocked: false,
  });
  const [Mp3Recorder] = useState(new MicRecorder({ bitRate: 128 }));
  const [recordingTime, setRecordingTime] = useState(maxRecordingTime);
  const audioRef = useRef(null);
  const start = () => {
    if (recording.isBlocked) {
      console.log("Permission Denied");
    } else {
      // used for permission in react native
      notifyReact({ recording }, 'AUDIO_REQUEST');

      setRecording((s) => ({
        isRecording: false,
        blobUrl: "",
        isBlocked: false,
      }));

      Mp3Recorder.start()
        .then(() => {
          setRecording((s) => ({ ...s, isRecording: true }));
        })
        .catch((e) => {
          notifyReact({ recording: { error: e } }, 'AUDIO_REQUEST');

          console.error(e);
        });
    }
  };
  const stop = () => {
    Mp3Recorder.stop()
      .getMp3()
      .then(([buffer, blob]) => {
        const name = `RFQ-Audio-${moment().format("DDMMYYYYHHmmss")}`;
        const file = new File(buffer, `${name}.mp3`, {
          type: blob.type,
          lastModified: Date.now(),
        });

        const blobUrl = URL.createObjectURL(file);
        setRecording((s) => ({ ...s, blobUrl, isRecording: false }));
        onStop({ file, blobUrl });
        clearInterval(timer);
        setRecordingTime(maxRecordingTime);
      })
      .catch((e) => console.log(e));
  };
  useEffect(() => {
    // navigator.mediaDevices
    //   .getUserMedia({ audio: true })
    //   .then(() => {
    //     console.log('Permission Granted');
    //     setRecording(s => ({ ...s, isBlocked: false }));
    //   })
    //   .catch(() => {
    //     console.log('Permission Denied');
    //     setRecording(s => ({ ...s, isBlocked: true }));
    //   });
    if (maxRecordingTime && recording?.isRecording) {
      timer = setInterval(() => {
        console.log("s", recordingTime);
        if (recordingTime > 0) {
          setRecordingTime((s) => s - 1);
        }
      }, 1000);
    }
    return () => (timer ? clearInterval(timer) : null);
  }, [recording?.isRecording]);

  useEffect(() => {
    if (maxRecordingTime) {
      if (recordingTime < 0) {
        stop();
      }
    }
  }, [recordingTime]);
  useEffect(() => {
    if (reset) {
      setRecording({
        isRecording: false,
        blobUrl: "",
        isBlocked: false,
      });
      onStop({ file: null, blobUrl: null });
      audioRef.current.load();
    }
  }, [reset]);
  return (
    <div className="audio-recorder-root d-flex">
      <audio ref={audioRef} src={recording.blobUrl} controls />
      {!recording?.isRecording ? (
        <div className="spekericon" onClick={start}>
          <Mic color="white" />
        </div>
      ) : (
        <div className="spekericon" onClick={stop}>
          <StopCircle color="white" />
          {maxRecordingTime && (
            <span style={{ color: "white" }}>{recordingTime}s</span>
          )}
        </div>
      )}
    </div>
  );
};

export default AudioRecorder;
