import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import Select from "react-select";
import Loader from "../../../components/Loader";

import {
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  CustomInput,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import PageTitle from "../../../components/PageTitle";
import {
  AvForm,
  AvGroup,
  AvInput,
  AvFeedback,
  AvField,
} from "availity-reactstrap-validation";
import { getUser, saveUser } from "../../../redux/users/actions";
import Status from "../../../components/Status";

const ViewUsers = () => {
  const params = useParams();
  const {
    data: users,
    user,
    loading,
    error,
  } = useSelector((state) => state.Users);
  const { data: roles } = useSelector((state) => state.Roles);
  const [defaultValue, setDefaultValue] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    status: false,
    role: "",
    provider: `motonexa_${users?.roles?.[0]?.role_slug}`,
    provider_id: "motonexa.com",
  });
  const isEdit = !!params?.id;
  const history = useHistory();
  const dispatch = useDispatch();
  const toggleSwitch = (e) =>
    setDefaultValue((s) => ({ ...s, status: !defaultValue.status }));

  const handleSubmit = (e, values) => {
    e.preventDefault();
    let data = { ...values, status: values?.status === "true" };
    console.log(data);
    if (params?.id) data["id"] = params?.id;
    if (data?.role) data["role"] = data?.role?.id;
    dispatch(
      saveUser({
        data,
        history,
      })
    );
  };

  useEffect(() => {
    //edit actions
    if (params?.id) {
      dispatch(getUser(params?.id));
    }
  }, []);
  //   useEffect(() => {
  //     let options = [];
  //     if (data?.data) {
  //       options = data?.data
  //         ?.map((v) => {
  //           if (isEdit) {
  //             if (v?.status === 1 && v?.id !== parseInt(params?.id))
  //               return { value: v?.id, label: v?.categoryName };
  //           } else if (v?.status === 1)
  //             return { value: v?.id, label: v?.categoryName };
  //         })
  //         .filter((v) => v);
  //       setParentCategoryList(options);
  //     }
  //   }, [data?.data]);
  useEffect(() => {
    if (user && !loading) {
      //   setStatus(users?.status === 1);
      const { name, email, phoneNumber, roles, status } = user;

      setDefaultValue((s) => ({
        name,
        role: { id: roles?.[0]?.id, role_name: roles?.[0]?.role_name },
        email,
        phoneNumber,
        status: status === 1,
      }));
    }
  }, [user, loading]);
  return (
    <>
      <Row className="page-title">
        <Col md={12}>
          <PageTitle
            breadCrumbItems={[
              {
                label: "Users",
                path: "/users",
              },
              {
                label: `${"View"}`,
                path: "/users/view",
                active: true,
              },
            ]}
            title={`View user`}
          />
        </Col>
      </Row>
      <Card>
        <CardBody>
          <Row>
            <Col md={12} className="position-relative">
              {loading && <Loader />}
              {error && typeof error === "string" && (
                <Row>
                  <Col>
                    {
                      <Alert color="danger" isOpen={error ? true : false}>
                        <div>{error}</div>
                      </Alert>
                    }
                  </Col>
                </Row>
              )}
              <Row>
                <Col xs={12} md={6} lg={6}>
                  <FormGroup>
                    <h6 for="role">Role</h6>
                    <p>{defaultValue.role?.role_name}</p>
                  </FormGroup>
                </Col>
                <Col xs={12} md={6} lg={6}>
                  <FormGroup>
                    <h6 for="text">Full name</h6>
                    <p>{defaultValue?.name}</p>
                  </FormGroup>
                </Col>
                <Col xs={12} md={6} lg={6}>
                  <FormGroup>
                    <h6 for="text">Email</h6>
                    <p>{defaultValue?.email}</p>
                  </FormGroup>
                </Col>
                <Col xs={12} md={6} lg={6}>
                  <FormGroup>
                    <h6 for="text">Phone number</h6>
                    <p>{defaultValue?.phoneNumber}</p>
                  </FormGroup>
                </Col>
                <Col xs={12} md={6} lg={6}>
                  <FormGroup>
                    <h6 for="status">Status</h6>
                    <p><Status status={defaultValue?.status} /></p>
                  </FormGroup>
                </Col>
              </Row>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  );
};

export default ViewUsers;
