import styles from "./RfqResponseDetail.module.scss";
import ImageSlider from "../../components/ImageSlider/ImageSlider";

import { useCallback, useEffect, useState } from "react";
import { Button, Card, Image, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import {
  deleteRfqResponse,
  getRfqResponseDetail,
  getRfqResponseDetailQuotation,
} from "../../redux/rfqResponse/actions";
import "./RfqResponseDetail.scss";
import RfqResponseDetailForm from "./RfqResponseDetailForm";
import { getFilePath } from "../../utils/helpers";
import { NO_IMAGE, RFQ_RESPONSE_STATUS } from "../../utils/constants";
import moment from "moment";
import ReadMore from "../../components/ReadMore/ReadMore";
import React from "react";
import { getRfqRequestNos } from "../../services/api";
import { getUserSession } from "../../helpers/authUtils";
import LoadAsyncImage from "../../components/LoadAsyncImage";
import IconButton from "../../components/IconButton/IconButton";
import { ArrowLeft, Trash2 } from "react-feather";
import { getAllSupplierBusiness } from "../../redux/supplierBusiness/actions";
import { useModal } from "../../hooks/useModal";
import { getCart, saveCart } from "../../redux/cart/actions";
import { cartIds } from "../../redux/cart/selectors";
import useQuery from "../../hooks/useQuery";

const rfqResponseStatusBadge = {
  1: "newQuoteBg",
  2: "quotedBg",
  3: "orderReceivedBg",
  4: "cancelledBg",
  5: "completed",
  6: "orderedBg",
  7: "closedBg",
};

const RfqResponseDetail = () => {
  const params = useParams();
  const history = useHistory();
  const qs = useQuery();
  const [rfqOptions, setRfqOptions] = useState([]);
  const [openedform, setOpenedform] = useState(false);
  const [currentRfq, setCurrentRfq] = useState(params?.id);
  const [loader, setLoader] = useState(0);
  const { cartIdList } = useSelector(cartIds);

  const { open: imageOpen, onClose, onOpen } = useModal();
  const {
    rfqResponseDetail: { data: rfqData, loading: rfqLoader },
    rfqResponseDetailQuotation: { data: quotation, loading: quotationLoader },
  } = useSelector((s) => s.RfqResponse);

  const [itemImages, setImages] = useState([]);
  const setImagesModal = (photos) => (e) => {
    setImages(photos?.map((photo) => photo?.url));
    onOpen();
  };
  const dispatch = useDispatch();

  const handleFormOpen = (v) => (e) => {
    setOpenedform(v);
  };
  const afterRfqFormSubmit = (successData) => {
    dispatch(
      getRfqResponseDetailQuotation({
        payload: { id: currentRfq },
      })
    );
    setOpenedform(false);
  };
  const handleRfqNoChange = useCallback((e) => {
    setCurrentRfq(e.target.value);
  }, []);

  const fetchRfqOptions = useCallback(async () => {
    const rfqId = qs?.get("r");
    const options = await getRfqRequestNos(rfqId);
    setRfqOptions(options);
  }, []);

  const handleDeleteQuote = useCallback(
    (id) => async (e) => {
      dispatch(deleteRfqResponse({ id }));
      dispatch(
        getRfqResponseDetailQuotation({
          payload: { id: currentRfq },
        })
      );
    },
    []
  );

  const navigate = (route) => (e) => history.push(route);
  const goBack = (e) => history.goBack();

  const handleAddToCart = (rfqDetail) => (e) => {
    e.preventDefault();
    console.log("rfq", rfqDetail);
    if (rfqDetail?.rfqResponseStatus === 2) {
      const {
        price,
        quantity: qty,
        id: rfqResponseId,
        spBusinessId,
        userId,
      } = rfqDetail;
      setLoader(rfqResponseId);
      dispatch(
        saveCart({
          payload: { price, qty, rfqResponseId, spBusinessId, userId },
          onSuccess: (result) => {
            // setAddedInCart(rfqResponseId);
            setLoader(0);
          },
          onError: (result) => {
            setLoader(0);
          },
        })
      );
    }
  };

  useEffect(() => {
    fetchRfqOptions();
  }, []);

  useEffect(() => {
    if (rfqData?.rfq_request?.rfq?.requestedBy)
      dispatch(getCart({ id: rfqData?.rfq_request?.rfq?.requestedBy }));
  }, [rfqData?.rfq_request?.rfq?.requestedBy]);

  useEffect(() => {
    dispatch(getRfqResponseDetail({ payload: { id: currentRfq } }));
    dispatch(
      getRfqResponseDetailQuotation({
        payload: { id: currentRfq },
      })
    );
  }, [currentRfq]);
  return (
    <>
      <div className="rfq-response-detail-root">
        <div className="rfqlistMain">
          <div className="panel panel-default mt-2 mb-4">
            <Button
              onClick={goBack}
              variant="flat"
              className="text-primary d-flex align-items-center"
            >
              <ArrowLeft size={14}/> <span>Back</span>
            </Button>
            <div className="d-flex justify-content-between align-items-baseline p-2">
              <div className="d-flex flex-column">
                <div className="d-flex mr-2">
                  <h5 className={` text-primary-2 ${styles.headerText}`}>
                    RFQ ID
                  </h5>
                  <select
                    className={styles.headerSelect}
                    id="exampleFormControlSelect1"
                    onChange={handleRfqNoChange}
                    value={rfqData?.rfq_request?.id || null}
                  >
                    {rfqOptions?.length &&
                      rfqOptions?.map((v) => (
                        <option key={v?.id} value={v.id}>
                          {v.rfqRequestNo}
                          {/* ({RFQ_RESPONSE_STATUS?.[v.rfqRequestStatus]}) */}
                        </option>
                      ))}
                  </select>
                </div>

                <h5 className={`${styles.headerText}`}>
                  <b>
                    Date:{" "}
                    {moment(rfqData?.rfq_request?.created_at).format(
                      "DD MMM YY"
                    )}
                  </b>
                </h5>
              </div>
              <Button onClick={navigate("/rfq/add")} className="btn-sm">
                Create new Rfq
              </Button>
            </div>

            <Card
              key={`quote-${rfqData?.rfq_request?.id}`}
              className={styles.quotationCard}
            >
              {rfqLoader && (
                <div className={styles.screenLoader}>
                  <Spinner animation="border" />
                </div>
              )}
              <div className="d-flex justify-content-start">
                <div className={styles.imageContainer}>
                  {rfqData?.rfq_request?.photos && (
                    <div
                      style={{ position: "relative" }}
                      onClick={setImagesModal([
                        rfqData?.rfq_request?.rfq?.vin_number_photo,
                        ...rfqData?.rfq_request?.photos,
                      ])}
                    >
                      <img
                        className={styles.productImage}
                        src={
                          rfqData?.rfq_request?.photos?.[0]?.thumbUrl ||
                          rfqData?.rfq_request?.photos?.[0]?.url ||
                          NO_IMAGE
                        }
                        id={rfqData?.rfq_request?.id}
                        width={150}
                      />
                      {/* <div className={styles.viewButton}>
                        <Button variant="outline-primary-2 btn-sm">View</Button>
                      </div> */}
                    </div>
                  )}
                </div>

                <Card.Body className="quotation-card-content px-0 py-1">
                  <div className="d-flex flex-column">
                    <div>
                      <h3 className={`mt-1 mb-0 ${styles.title}`}>
                        {rfqData?.rfq_request?.otherSparePartCategory ||
                          `${rfqData?.rfq_request?.sub_category?.categoryName}`}
                        <span className="font-size-12 text-secondary ms-2">
                          (
                          {rfqData?.rfq_request?.category?.categoryName ||
                            "Other"}
                          )
                        </span>
                      </h3>
                    </div>
                    <div>
                      <h4 className={`mt-0 mb-0 ${styles.subTitle}`}>
                        {[
                          rfqData?.rfq_request?.rfq?.vehicle_make?.name,
                          rfqData?.rfq_request?.rfq?.vehicle_model?.name,
                          rfqData?.rfq_request?.rfq?.vehicle_year?.year,
                        ]
                          .filter((vv) => vv)
                          .join(" ")}
                      </h4>
                    </div>
                  </div>
                  <div className="d-flex flex-column">
                    <span
                      className={`badge text-white ${styles.rfqStatusBadge} ${
                        styles?.[
                          rfqResponseStatusBadge?.[rfqData?.rfqRequestStatus]
                        ] || "bg-primary"
                      }`}
                    >
                      {RFQ_RESPONSE_STATUS?.[rfqData?.rfqRequestStatus]}
                    </span>
                  </div>
                </Card.Body>
              </div>
            </Card>
            <br />
            <div className="rfqlisttabs">
              {quotationLoader && (
                <div className={styles.screenLoader}>
                  <Spinner animation="border" />
                </div>
              )}
              <div className="tab-content" id="pills-tabContent">
                <div>
                  {quotation?.length ? (
                    <div className="mt-3">
                      {openedform ? (
                        <Button
                          variant="link"
                          className="text-primary-2"
                          onClick={handleFormOpen(false)}
                        >
                          <i className="fa fa-arrow-left mr-2"></i>
                          <span>Back to quotes</span>
                        </Button>
                      ) : (
                        <div className="d-flex justify-content-between align-items-baseline">
                          <h4 className="text-primary-2">
                            Submitted quotations
                          </h4>
                          {quotation?.find((q) => q?.rfqResponseStatus < 3) && (
                            <Button
                              variant="secondary"
                              onClick={handleFormOpen(true)}
                              className="btn-sm"
                            >
                              {!quotation ? "Submit quote" : "Add new quote"}
                            </Button>
                          )}
                        </div>
                      )}
                      <div
                        className={`row ${openedform ? "d-none" : "d-flex"}`}
                      >
                        {quotation?.map((quote, index) => (
                          <Card
                            key={`quote-${quote?.id}`}
                            className="quotation-card col-md-6 col-sm-12"
                          >
                            <Card.Header className="bg-white">
                              <div className="d-flex justify-content-between align-items-center">
                                <h5>Quotation No. {index + 1}</h5>
                                {quote?.rfqResponseStatus < 3 && (
                                  <Button
                                    variant="outline-primary"
                                    className="delete-btn"
                                    onClick={handleDeleteQuote(quote?.id)}
                                  >
                                    <Trash2 size={16} />
                                  </Button>
                                )}
                              </div>
                            </Card.Header>
                            {quote?.photos && (
                              <div
                                key={`photo-${quote?.id}`}
                                onClick={setImagesModal(quote?.photos)}
                              >
                                <ImageSlider
                                  className="img-fluid product-image"
                                  paths={getFilePath(quote?.photos, "thumbUrl")}
                                  isPublic
                                  imageProps={{
                                    style: {
                                      width: "100%",
                                      height: 250,
                                      objectFit: "contain",
                                      backgroundColor: "#d8d8d8",
                                    },
                                  }}
                                />
                              </div>
                            )}
                            <Card.Body className="quotation-card-content">
                              <span className="badge bg-primary text-white">
                                {quote?.rfqResponseStatus === 6
                                  ? "Ordered"
                                  : "Quoted"}
                              </span>
                              <div className="d-flex justify-content-between align-items-center">
                                <span className="h3 text-primary-2">
                                  AED {quote?.price || 0}
                                </span>
                                <span className="text-black">
                                  <b>Qty:{quote?.quantity}</b>
                                </span>
                              </div>

                              <p className="m-0">
                                <b>Type:</b> {quote?.manufacturer || "NA"}
                              </p>
                              <span className="text-black">
                                <b>Warranty: </b> {quote?.warranty || 0} Days
                              </span>
                              {/* <div>
                                <span>
                                  <small className="text-grey">
                                    <b>Part number:</b> {quote?.sparePartNameOrNumber || 'NA'}
                                  </small>
                                </span>
                              </div> */}

                              <ReadMore>{quote?.description}</ReadMore>
                            </Card.Body>

                            <div className="card-footer bg-white">
                              <LoadAsyncImage
                                ref={null}
                                id={quote?.voice_note?.id}
                                fileType="audio"
                                path={quote?.voice_note?.filePath}
                              />
                            </div>
                            {!cartIdList?.includes(quote?.id) ? (
                              <button
                                className="btn btn-outline-danger"
                                type="button"
                                onClick={handleAddToCart({
                                  ...quote,
                                  spBusinessId: rfqData?.rfq_request?.rfq?.spId,
                                  userId:
                                    rfqData?.rfq_request?.rfq?.requestedBy,
                                })}
                              >
                                {loader === quote?.id ? (
                                  <Spinner animation="border" />
                                ) : quote?.rfqResponseStatus >= 3 ? (
                                  RFQ_RESPONSE_STATUS?.[
                                    quote?.rfqResponseStatus
                                  ]
                                ) : (
                                  "Add to Cart"
                                )}
                              </button>
                            ) : (
                              <button
                                className="btn btn-outline-danger"
                                onClick={navigate(
                                  `/cart/${rfqData?.rfq_request?.rfq?.requestedBy}`
                                )}
                              >
                                {quote?.rfqResponseStatus === 2
                                  ? "Go to cart"
                                  : RFQ_RESPONSE_STATUS?.[
                                      quote?.rfqResponseStatus
                                    ]}
                              </button>
                            )}
                          </Card>
                        ))}
                      </div>
                      <div className={`${openedform ? "d-block" : "d-none"}`}>
                        <RfqResponseDetailForm
                          rfqRequestId={currentRfq}
                          rfqRequest={rfqData}
                          afterSubmit={afterRfqFormSubmit}
                        />
                      </div>
                    </div>
                  ) : (
                    <RfqResponseDetailForm
                      rfqRequestId={currentRfq}
                      rfqRequest={rfqData}
                      afterSubmit={afterRfqFormSubmit}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RfqResponseDetail;
