import { joiResolver } from "@hookform/resolvers/joi";
import Joi from "joi";
import _ from "lodash";
import { useEffect, useState } from "react";
import { Button, FormControl, InputGroup } from "react-bootstrap";
import ReactCountryFlag from "react-country-flag";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import ReactSelect from "react-select";
import AsyncSelect from "react-select/async";
import { InputGroupAddon } from "reactstrap";
import {
  getCitiesWithPaginateApi,
  getProvince,
  getSingleCountry,
} from "../../services/api";
import { getCountryCode } from "../../services/api";
import formValidationRules from "../../utils/formValidationRules";

const schema = Joi.object({
  id: Joi.allow("", 0),
  addressTag: Joi.string().required().label("address Tag"),
  addressLine1: Joi.string()
    .required()
    .label("address line 1")
    .messages({ "string.base": "Address line 1 is required" }),
  contactPersonName: Joi.string()
    .required()
    .label("Full name")
    .messages({ "string.base": "Full name is required" }),
  addressLine2: formValidationRules.optionalField,
  cityId: Joi.object()
    .required()
    .label("City")
    .messages({ "object.base": "City field is required" }),
  gMapLink: Joi.optional().allow("", 0),
  contactNumberCode: formValidationRules.phoneCode.label("Code"),
  contactNumber: formValidationRules.phoneNumber
    .label("contact number")
    .messages({ "string.base": "Contact number is required" }),
  countryId: Joi.required().label("country"),
  provinceId: formValidationRules.optionalField.label("Province"),
  postalCode: formValidationRules.optionalField.label("Postal code"),
  isDefaultAddress: Joi.bool().default(false),
});

const AddressForm = ({
  onSubmit,
  handleClose,
  loader,
  addressFormDefaultData,
}) => {
  const [countryOption, setCountryOption] = useState([]);
  const [provinceOption, setProvinceOption] = useState([]);
  const [countryCode, setCountryCode] = useState([]);
  const _getCountryCode = async () => {
    const codes = await getCountryCode();
    setCountryCode(codes);
  };
  const dispatch = useDispatch();

  const [defaultData, setDefaultData] = useState({
    id: null,
    countryId: null,
    provinceId: null,
    contactPersonName: null,
    cityId: 0,
    addressLine1: "",
    contactNumberCode: 971,
    contactNumber: null,
    gMapLink: "",
    addressTag: "Home",
    addressLine2: "",
    postalCode: "",
    isDefaultAddress: false,
  });

  const {
    handleSubmit,
    reset,
    control,
    register,

    formState: { errors },
    setError,
    setValue,
    watch,
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    criteriaMode: "all",
    defaultValues: defaultData,
    resolver: joiResolver(schema),
  });

  const fetchCountry = async (id) => {
    const c = await getSingleCountry({ id });
    if (c) {
      setCountryOption([c]);
      setValue("countryId", c);
    }
  };
  const fetchProvince = async (provinceId) => {
    const provinces = await getProvince({ search: "", provinceId });
    if (provinces) {
      setProvinceOption([provinces]);
      setValue("provinceId", provinces);
    }
  };

  const handleChangeCity = (onChange) => async (e) => {
    onChange({
      type: "change",
      target: {
        name: "cityId",
        value: e,
      },
    });
    await fetchProvince(e?.provinceId);
    await fetchCountry(e?.countryId);
  };
  const afterchangeCity = async (e) => {
    await fetchProvince(e?.provinceId);
    await fetchCountry(e?.countryId);
  };

  const loadCity = (query, callback) => {
    const qs = !query ? { search: "", countryId: 234 } : { search: query };
    getCitiesWithPaginateApi(qs)
      .then((resp) => callback(resp))
      .catch((e) => {
        console.log("city err", e);
        callback([]);
      });
  };
  const loadSuggestions = _.debounce(loadCity, 300);

  useEffect(() => {
    if (addressFormDefaultData) {
      delete addressFormDefaultData?.created_at;
      delete addressFormDefaultData?.updated_at;
      delete addressFormDefaultData?.cityId;
      delete addressFormDefaultData?.provinceId;
      delete addressFormDefaultData?.countryId;
      delete addressFormDefaultData?.userId;

      Object.keys(addressFormDefaultData)?.forEach((key) => {
        switch (key) {
          case "city":
            setValue("cityId", addressFormDefaultData[key]);
            break;
          case "province":
            setValue("provinceId", addressFormDefaultData[key]);
            break;
          case "country":
            setValue("countryId", addressFormDefaultData[key]);
            break;
          case "isDefaultAddress":
            setValue("isDefaultAddress", addressFormDefaultData[key] === 1);
            break;
          default:
            setValue(key, addressFormDefaultData[key]);
            break;
        }
      });
    }
  }, [addressFormDefaultData]);

  const handleCountryCodeChange = (options) => {
    setValue("contactNumberCode", options.phone);
  };
  const city = watch("cityId");
  useEffect(() => {
    console.log("city", city);
    afterchangeCity(city);
  }, [city]);
  useEffect(() => {
    _getCountryCode();
  }, []);
  return (
    <div className="user-address-form-root">
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <div className="form-group mb-2">
          <label htmlFor="exampleInputcontact5">Address tag*</label>
          <Controller
            name="addressTag"
            control={control}
            render={({
              field: { name, value, ref, onChange },
              fieldState: { error },
            }) => (
              <>
                <select
                  placeholder={"Select Address tag"}
                  name={name}
                  value={value}
                  ref={ref}
                  onChange={onChange}
                  className="form-control"
                >
                  <option value="Home">Home</option>
                  <option value="Office">Office</option>
                </select>
                <span className="text-danger">{error?.message}</span>
              </>
            )}
          />
        </div>
        <div className="form-group mb-2">
          <label htmlFor="contactNumber">Full name*</label>

          <Controller
            name="contactPersonName"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <>
                <input
                  type="text"
                  {...field}
                  className="form-control"
                  id="contactPersonName"
                  placeholder="Full name"
                />
                <span className="text-danger">
                  {errors?.contactPersonName?.message}
                </span>
              </>
            )}
          />
        </div>
        <div className="mb-2">
          <label htmlFor="contactNumber">Contact number*</label>
          <div className="d-flex">
            <Controller
              name="contactNumber"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <>
                  <InputGroup>
                    <InputGroupAddon addonType="append">
                      <ReactSelect
                        options={countryCode || []}
                        defaultValues={{ phone: 971, countryCode: "AE" }}
                        getOptionLabel={(options) => (
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <ReactCountryFlag
                              countryCode={options?.countryCode}
                              svg
                            />
                            <span className="ms-1">{options?.phone}</span>
                          </div>
                        )}
                        getOptionValue={(option) => option.phone}
                        onChange={handleCountryCodeChange}
                      />
                    </InputGroupAddon>
                    <FormControl type="number" {...field} />
                  </InputGroup>
                </>
              )}
            />
          </div>
          <span className="text-danger">
            {errors?.contactNumberCode?.message ||
              errors?.contactNumber?.message}
          </span>
        </div>
        <div className="form-group mb-2">
          <label htmlFor="exampleInputcontact5">Address Line 1*</label>
          <Controller
            name="addressLine1"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <>
                <input
                  type="text"
                  {...field}
                  className="form-control"
                  id="exampleInputcontact5"
                  placeholder="Address line 1"
                />
                <span className="text-danger">{error?.message}</span>
              </>
            )}
          />
        </div>
        <div className="form-group mb-2">
          <label htmlFor="exampleInputcontact6">Address Line 2</label>
          <Controller
            name="addressLine2"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <>
                <input
                  type="text"
                  {...field}
                  className="form-control"
                  id="exampleInputcontact6"
                  placeholder="Enter Address Line 2"
                />
                <span className="text-danger">{error?.message}</span>
              </>
            )}
          />
        </div>
        <div className="form-group mb-2">
          <label htmlFor="exampleInputcontact4">City*</label>
          <Controller
            name="cityId"
            control={control}
            render={({
              field: { name, value, onChange, onBlur, ref },
              fieldState: { error },
            }) => (
              <>
                <AsyncSelect
                  placeholder={"Select city"}
                  loadOptions={loadSuggestions}
                  defaultOptions={true}
                  pageSize={20}
                  getOptionLabel={(option) => option?.name}
                  getOptionValue={(option) => option?.id}
                  name={name}
                  value={value}
                  ref={ref}
                  onChange={onChange}
                  className="form-control"
                />
                <span className="text-danger">{error?.message}</span>
              </>
            )}
          />
        </div>
        <div className="form-group mb-2">
          <label htmlFor="exampleFormControlSelect2">
            Province / Emirates*
          </label>
          <Controller
            name="provinceId"
            control={control}
            render={({
              field: { name, value, onChange, onBlur, ref },
              fieldState: { error },
            }) => (
              <>
                <ReactSelect
                  placeholder={"Select province"}
                  options={provinceOption}
                  getOptionLabel={(option) => option.provinceName}
                  getOptionValue={(option) => option.id}
                  name={name}
                  value={value}
                  ref={ref}
                  onChange={onChange}
                  className="form-control"
                />
                <span className="text-danger">{error?.message}</span>
              </>
            )}
          />
        </div>
        <div className="form-group mb-2">
          <label htmlFor="exampleFormControlSelect1">Country*</label>

          <Controller
            name="countryId"
            control={control}
            render={({
              field: { name, value, onChange, onBlur, ref },
              fieldState: { error },
            }) => (
              <>
                <ReactSelect
                  placeholder={"Select country"}
                  options={countryOption}
                  getOptionLabel={(option) => option.countryName}
                  getOptionValue={(option) => option.id}
                  name={name}
                  value={value}
                  ref={ref}
                  onChange={onChange}
                  className="form-control"
                />
                <span className="text-danger">{error?.message}</span>
              </>
            )}
          />
        </div>

        <div className="form-group mb-2">
          <label htmlFor="exampleInputcontact7">P.O Box</label>
          <Controller
            name="postalCode"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <>
                <input
                  type="number"
                  {...field}
                  className="form-control"
                  id="exampleInputcontact7"
                  placeholder="Enter P.O Box"
                />
                <span className="text-danger">{error?.message}</span>
              </>
            )}
          />
        </div>
        <div className="form-group mb-2">
          <label htmlFor="exampleInputcontact8">Google map link</label>
          <Controller
            name="gMapLink"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <>
                <input
                  type="text"
                  {...field}
                  className="form-control"
                  id="exampleInputcontact8"
                  placeholder="enter Google map link"
                />
                <span className="text-danger">{error?.message}</span>
              </>
            )}
          />
        </div>
        <div className="form-group mb-2">
          <input
            type="checkbox"
            {...register("isDefaultAddress")}
            className=""
            id="exampleInputcontact7"
          />
          <span className="ms-2"> Make this default address?</span>
        </div>
        <div className="d-flex p-3" style={{ gap: 15 }}>
          <Button
            variant="outline-secondary"
            style={{ flex: 1 }}
            type="button"
            onClick={handleClose}
            disabled={loader}
          >
            CANCEL
          </Button>
          <Button
            variant="secondary"
            disabled={loader}
            style={{ flex: 1 }}
            type="submit"
          >
            {loader ? "SAVING..." : "SAVE"}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default AddressForm;
