import React, { useEffect, useState } from "react";
import { forwardRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Alert, Col, Row } from "reactstrap";
import { EditableCell } from "../../../components/EditableCell/EditableCell";
import PageTitle from "../../../components/PageTitle";
import StatusDropdown from "../../../components/StatusDropdown/StatusDropdown";
import Actions from "../../../components/table/Actions";
import Table from "../../../components/table/Table";
import useTableActions from "../../../components/table/useActions";
import { DEFAULT_STATUS } from "../../../constants";
import {
  deleteWhatsAppGroup,
  getWhatsAppGroup,
  updateWhatsAppGroup,
} from "../../../redux/whatsappGroup/actions";

const ListWhatsAppGroup = () => {
  const dispatch = useDispatch();
  const baseUrl = "/whatsapp-group";
  const { open, toggleModal, modalOpen, handleDelete } =
    useTableActions(deleteWhatsAppGroup);
  const handleUpdateWhatsAppGroup =
    ({ id, name }) =>
    (value) => {
      console.log(id, name, value);

      dispatch(updateWhatsAppGroup({ [name]: value, id }));
    };
  const handleTableChange = (e, v) => {
    const { page, searchText, sizePerPage, sortField, sortOrder } = v;
    setPagination({ page, searchText, sizePerPage, sortField, sortOrder });
    dispatch(
      getWhatsAppGroup({
        page,
        searchText,
        sizePerPage,
        sortField,
        sortOrder,
      })
    );
  };
  const columns = [
    {
      dataField: "id",
      text: "ID",
      sort: true,
      editable: false,
    },
    {
      dataField: "groupName",
      text: "Group name",
      sort: true,
      editable: true,
      editorRenderer: (
        editorProps,
        value,
        row,
        column,
        rowIndex,
        columnIndex
      ) => (
        <EditableCell
          {...editorProps}
          value={value}
          onSave={handleUpdateWhatsAppGroup({
            id: row?.id,
            name: "groupName",
          })}
        />
      ),
    },
    {
      dataField: "groupUrl",
      text: "Group Url",
      sort: true,
      editable: true,
      editorRenderer: (
        editorProps,
        value,
        row,
        column,
        rowIndex,
        columnIndex
      ) => (
        <EditableCell
          {...editorProps}
          value={value}
          onSave={handleUpdateWhatsAppGroup({
            id: row?.id,
            name: "groupUrl",
          })}
        />
      ),
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
      editable: false,
      formatter: (cel, row) => {
        console.log(row?.status);
        return (
          <StatusDropdown
            defaultValue={{
              value: row?.status,
              label: row?.status ? "Active" : "Inactive",
            }}
            options={[
              { label: "Active", value: true },
              { label: "Inactive", value: false },
            ]}
            dispatchOnChange={(val) =>
              updateWhatsAppGroup({ status: val?.value, id: row?.id })
            }
          />
        );
      },
    },

    {
      dataField: "actions",
      text: "Actions",
      sort: false,
      isDummyField: true,
      exportCSV: false,
      formatter: Actions,
      formatExtraData: {
        open,
        toggleModal,
        handleDelete,
        canView: false,
        canEdit: false,
        baseUrl,
        modalOpen,
      },
    },
  ];
  const [pagination, setPagination] = useState({
    page: 1,
    searchText: "",
    sizePerPage: 10,
    sortField: "id",
    sortOrder: "asc",
  });
  const { data, error, loading } = useSelector((state) => state.WhatsAppGroup);
  console.log("data", data);
  useEffect(() => {
    dispatch(getWhatsAppGroup(pagination));
  }, []);

  return (
    <>
      <Row className="page-title">
        <Col md={12}>
          <PageTitle
            breadCrumbItems={[
              {
                label: "WhatsApp groups",
                path: "/whatsapp-group",
              },
            ]}
            title={"List of whatsapp groups"}
          />
        </Col>
      </Row>
      {error && <Alert color="danger">Oops something went wrong</Alert>}
      <Table
        loading={loading}
        records={data?.data}
        columns={columns}
        addUrl={`${baseUrl}/add`}
        canCellEdit
        handleTableChange={handleTableChange}
        pagination={{ ...pagination, totalSize: data?.total }}
      />
    </>
  );
};

export default ListWhatsAppGroup;
