// @flow
import {
  DELETE_VEHICLE_TYPE,
  DELETE_VEHICLE_TYPE_FAILED,
  DELETE_VEHICLE_TYPE_SUCCESS,
  VEHICLE_TYPE,
  VEHICLE_TYPE_FAILED,
  VEHICLE_TYPE_SUCCESS,
  GET_VEHICLE_TYPE,
  GET_VEHICLE_TYPE_FAILED,
  GET_VEHICLE_TYPE_SUCCESS,
} from "./constants";

const INIT_STATE = {
  data: [],
  vehicleType: {},
  loading: false,
  error: null,
};

const VehicleType = (state = INIT_STATE, action) => {
  switch (action.type) {
    case VEHICLE_TYPE:
      return { ...state, vehicleType: {}, loading: true };
    case VEHICLE_TYPE_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
        error: null,
      };
    case VEHICLE_TYPE_FAILED:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case GET_VEHICLE_TYPE:
      return { ...state, loading: true };
    case GET_VEHICLE_TYPE_SUCCESS:
      return {
        ...state,
        vehicleType: action.payload,
        loading: false,
        error: null,
      };
    case GET_VEHICLE_TYPE_FAILED:
      return { ...state, error: action.payload, loading: false };
    case DELETE_VEHICLE_TYPE:
      return { ...state, loading: true };
    case DELETE_VEHICLE_TYPE_SUCCESS: {
      return {
        ...state,
        data: {
          ...state?.data,
          total: state?.data?.total - 1,
          data: state?.data?.data?.filter((v) => v?.id !== action?.payload?.id),
        },
        loading: false,
        error: null,
      };
    }
    case DELETE_VEHICLE_TYPE_FAILED:
      return { ...state, error: action.payload, loading: false };
    default:
      return { ...state };
  }
};

export default VehicleType;
