import { useCallback, useEffect, useMemo, useReducer, useState } from "react";
import useTableActions from "./useActions";
const useGetColumns = ({
  hasFilter,
  open,
  toggleModal,
  modalOpen,
  handleDelete,
  baseUrl,
  columnData,
  Actions,
  actionAccess = {},
  renderAction,
}) => {
  let columns = useMemo(
    () =>
      columnData.map((col) => {
        let column = { ...col };
        if (!hasFilter) {
          const { filter, ...rest } = column;
          column = rest;
        }
        return column;
      }),
    [hasFilter]
  );

  if (Actions) {
    columns = [
      ...columns,
      {
        dataField: "actions",
        text: "Actions",
        sort: false,
        isDummyField: true,
        exportCSV: false,
        formatter: Actions,
        formatExtraData: {
          open,
          toggleModal,
          handleDelete,
          baseUrl,
          modalOpen,
          renderAction,
          ...actionAccess,
        },
      },
    ];
  }
  return columns;
};
const useColumn = ({ deleteAction, ...rest }) => {
  const [hasFilter, setHasFilter] = useState(false);
  const toggleFilter = (e) => setHasFilter(!hasFilter);
  const { open, toggleModal, modalOpen, handleDelete } =
    useTableActions(deleteAction);

  const columns = useCallback(
    useGetColumns({
      hasFilter,
      open,
      toggleModal,
      modalOpen,
      handleDelete,
      ...rest,
    }),
    [hasFilter]
  );
  return { columns, toggleFilter, hasFilter };
};

export default useColumn;
