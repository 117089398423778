import { Card } from "react-bootstrap";
import styled from "styled-components";

export const StyledCard = styled(Card)`
  box-shadow: 5px 5px 9px 4px rgb(75 75 90 / 8%);
  border-radius: 10px;
`;

export const StyledCardHeader = styled(Card.Header)`
  border-radius: 10px 10px 0 0;
  border-bottom: 1px solid lightgrey;
`;
