import { createSelector } from "reselect";

export const chatRoomSelector = createSelector(
  (state) => state.Chat,
  ({ chatRoom }) => ({ ...chatRoom })
);

export const chatMessagesSelector = createSelector(
  (state) => state.Chat,
  ({ chatMessages }) => ({ ...chatMessages })
);
