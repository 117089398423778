/* AUTH */
export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGIN_USER_FAILED = "LOGIN_USER_FAILED";
export const REGISTER_USER = "REGISTER_USER";
export const REGISTER_USER_SUCCESS = "REGISTER_USER_SUCCESS";
export const REGISTER_USER_FAILED = "REGISTER_USER_FAILED";
export const LOGOUT_USER = "LOGOUT_USER";
export const LOGOUT_USER_SUCCESS = "LOGOUT_USER_SUCCESS";
export const LOGOUT_USER_FAILED = "LOGOUT_USER_FAILED";
export const FORGET_PASSWORD = "FORGET_PASSWORD";
export const FORGET_PASSWORD_SUCCESS = "FORGET_PASSWORD_SUCCESS";
export const FORGET_PASSWORD_FAILED = "FORGET_PASSWORD_FAILED";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILED = "RESET_PASSWORD_FAILED";
export const ACTIVATE_USER = "ACTIVATE_USER";
export const ACTIVATE_USER_SUCCESS = "ACTIVATE_USER_SUCCESS";
export const ACTIVATE_USER_FAILED = "ACTIVATE_USER_FAILED";

//partner auth
export const PARTNER_LOGIN_USER = "PARTNER_LOGIN_USER";
export const PARTNER_LOGIN_USER_WITH_EMAIL_SUCCESS =
  "PARTNER_LOGIN_USER_WITH_EMAIL_SUCCESS";
export const PARTNER_LOGIN_USER_WITH_PHONE_SUCCESS =
  "PARTNER_LOGIN_USER_WITH_PHONE_SUCCESS";
export const PARTNER_LOGIN_USER_FAILED = "PARTNER_LOGIN_USER_FAILED";
export const PARTNER_REGISTER_USER = "PARTNER_REGISTER_USER";
export const PARTNER_REGISTER_USER_SUCCESS = "PARTNER_REGISTER_USER_SUCCESS";
export const PARTNER_REGISTER_USER_FAILED = "PARTNER_REGISTER_USER_FAILED";
export const PARTNER_LOGOUT_USER = "PARTNER_LOGOUT_USER";
export const PARTNER_FORGET_PASSWORD = "PARTNER_FORGET_PASSWORD";
export const PARTNER_FORGET_PASSWORD_SUCCESS =
  "PARTNER_FORGET_PASSWORD_SUCCESS";
export const PARTNER_FORGET_PASSWORD_FAILED = "PARTNER_FORGET_PASSWORD_FAILED";
export const PARTNER_VERIFY_OTP = "PARTNER_VERIFY_OTP";
export const PARTNER_VERIFY_OTP_SUCCESS = "PARTNER_VERIFY_OTP_SUCCESS";
export const PARTNER_VERIFY_OTP_FAILED = "PARTNER_VERIFY_OTP_FAILED";

// Google login
export const GOOGLE_LOGIN = "GOOGLE_LOGIN";
export const GOOGLE_LOGIN_SUCCESS = "GOOGLE_LOGIN_SUCCESS";
export const GOOGLE_LOGIN_FAILED = "GOOGLE_LOGIN_FAILED";
