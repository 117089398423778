import {
  forwardRef,
  ImgHTMLAttributes,
  memo,
  Ref,
  useCallback,
  useEffect,
  useState,
} from "react";
import { Carousel } from "react-bootstrap";
import { getImageUrls } from "../../services/api";

const image = require("../../assets/images/no-image.png");

const LoadImage = ({
  paths,
  imageSliderRef,
  imageProps,
  interval = 2000,
  isPublic = false,
  defaultImage = image,
  ...rest
}) => {
  const { itemProps, ...restProps } = rest;
  const [images, setImages] = useState([]);
  const [loader, setLoader] = useState(false);
  const loadS3Image = useCallback(async (filePaths) => {
    try {
      setLoader(true);
      let imageUrls = filePaths;
      if (!isPublic) {
        const imgPaths = await getImageUrls({ paths: filePaths });
        if (imgPaths) {
          let remoteImage = [];
          imgPaths.forEach(async (path) => {
            remoteImage.push(path);
          });
          imageUrls = remoteImage;
        }
      }
      setImages(imageUrls);
      setLoader(false);
    } catch (e) {
      setLoader(true);

      let imageUrls = [];
      paths?.forEach((path) => {
        imageUrls.push(defaultImage);
      });
      setImages(imageUrls);
      setLoader(false);
    }
  }, []);
  useEffect(() => {
    if (paths) loadS3Image(paths);
  }, [paths]);
  if (loader || !paths?.length) {
    return (
      <Carousel ref={imageSliderRef}>
        <Carousel.Item>
          <img src={defaultImage} alt={"No-image-found"} {...imageProps} />
        </Carousel.Item>
      </Carousel>
    );
  }
  return (
    <Carousel ref={imageSliderRef} {...restProps} nextLabel="" prevLabel="">
      {images?.map((v, i) => (
        <Carousel.Item
          key={`image-${images?.length - i}`}
          {...itemProps}
          interval={interval}
        >
          <img src={v} alt={v} {...imageProps} />
        </Carousel.Item>
      ))}
    </Carousel>
  );
};

const ImageSlider = memo(LoadImage);
export default forwardRef((props, ref) => (
  <ImageSlider {...props} imageSliderRef={ref} />
));
