export const FETCH_QUOTE_LIST = "FETCH_QUOTE_LIST";
export const FETCH_QUOTE_LIST_SUCCESS = "FETCH_QUOTE_LIST_SUCCESS";
export const FETCH_QUOTE_LIST_FAILURE = "FETCH_QUOTE_LIST_FAILURE";

export const SAVE_QUOTE_LIST = "SAVE_QUOTE_LIST";
export const SAVE_QUOTE_LIST_SUCCESS = "SAVE_QUOTE_LIST_SUCCESS";
export const SAVE_QUOTE_LIST_FAILURE = "SAVE_QUOTE_LIST_FAILURE";

export const EDIT_QUOTE_LIST = "EDIT_QUOTE_LIST";
export const EDIT_QUOTE_LIST_SUCCESS = "EDIT_QUOTE_LIST_SUCCESS";
export const EDIT_QUOTE_LIST_FAILURE = "EDIT_QUOTE_LIST_FAILURE";
