import { joiResolver } from "@hookform/resolvers/joi";
import { Button, Card, Col, FormGroup, Row } from "react-bootstrap";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import PreLoaderWidget from "../../components/Loader";

import { useSelector } from "react-redux";
import { quoteListSelector } from "../../redux/quoteList/selectors";
import Joi from "../../services/joi";
import { formField } from "../../components/FormBuilder/formField";
import { StyledCard, StyledCardHeader } from "./Cards";
import {
  fetchServiceProviderBusiness,
  fetchSupplierBusiness,
} from "../../services/api";

const fieldsObject = {
  serviceProviderBusiness: {
    type: "asyncSelect",
    name: "serviceProviderBusiness",
    validationRules: Joi.object()
      .required()
      .messages({ "object.base": `"Service provider" is required` }),
    getOptionLabel: (option) => <span>{`${option.businessName}`}</span>,
    getOptionValue: (option) => option.id,
    loadOptions: fetchServiceProviderBusiness,
    id: "serviceProviderBusiness",
    label: "Garage",
    columnProps: {
      sm: 12,
      md: 6,
      lg: 4,
    },
  },
  item_name: {
    type: "text",
    name: "item_name",
    id: "item_name",
    label: "Item name",
    validationRules: Joi.string().required(),
    columnProps: {
      sm: 12,
      md: 6,
      lg: 4,
    },
  },
  supplierBusiness: {
    type: "asyncCreatableSelect",
    name: "supplierBusiness",
    validationRules: Joi.object()
      .required()
      .messages({ "object.base": `"Supplier" is required` }),
    getOptionLabel: (option) => option.businessName || option?.label,
    getOptionValue: (option) => option.id || "",
    loadOptions: fetchSupplierBusiness,
    id: "supplierBusiness",
    label: "Supplier business",
    columnProps: {
      sm: 12,
      md: 6,
      lg: 4,
    },
  },
  supplier_contact_number: {
    type: "phoneNumber",
    name: "supplier_contact_number",
    id: "supplier_contact_number",
    label: "supplier contact number",
    validationRules: Joi.number().required(),
    columnProps: {
      sm: 12,
      md: 6,
      lg: 4,
    },
  },
  quantity: {
    type: "number",
    name: "quantity",
    id: "quantity",
    label: "Quantity",
    validationRules: Joi.number().optional(),
    columnProps: {
      sm: 12,
      md: 3,
      lg: 2,
    },
  },
  qoute_price: {
    type: "number",
    name: "qoute_price",
    id: "qoute_price",
    label: "Quote price",
    validationRules: Joi.number().required(),
    columnProps: {
      sm: 12,
      md: 3,
      lg: 2,
    },
  },
  item_cost_price: {
    type: "number",
    name: "item_cost_price",
    id: "item_cost_price",
    label: "item cost price",
    validationRules: Joi.number().required(),
    columnProps: {
      sm: 12,
      md: 3,
      lg: 2,
    },
  },

  warranty: {
    type: "number",
    name: "warranty",
    id: "warranty",
    label: "warranty",
    validationRules: Joi.number().required(),
    columnProps: {
      sm: 12,
      md: 2,
      lg: 2,
    },
  },
  qoute_submited: {
    type: "switch",
    name: "qoute_submited",
    id: "qoute_submited",
    label: "Qoute submited",
    activeLabel: "Yes",
    validationRules: Joi.any().required(),
    columnProps: {
      sm: 12,
      md: 2,
      lg: 3,
    },
  },
  order_received: {
    type: "switch",
    name: "order_received",
    id: "order_received",
    label: "Order received",
    activeLabel: "Yes",
    validationRules: Joi.any().required(),
    columnProps: {
      sm: 12,
      md: 2,
      lg: 3,
    },
  },
};

const schema = Joi.object({
  allfields: Joi.array().items({
    serviceProviderBusiness: Joi.object()
      .required()
      .messages({ "object.base": `Service provider is required` }),
    item_name: Joi.string().required().label("Item name"),
    supplierBusiness: Joi.object()
      .required()
      .messages({ "object.base": `Supplier is required` }),
    supplier_contact_number_code: Joi.number().optional().allow(null, "", 0),
    supplier_contact_number: Joi.number()
      .optional()
      .label("Supplier contact number")
      .allow(null, "", 0),
    qoute_price: Joi.number()
      .optional()
      .label("Quote price")
      .allow(null, "", 0),
    quantity: Joi.number().optional().label("Quantity").allow(null, "", 0),
    item_cost_price: Joi.number()
      .optional()
      .label("Cost price")
      .allow(null, "", 0),
    warranty: Joi.string().optional().label("Warranty").allow(null, "", 0),
    qoute_submited: Joi.boolean()
      .optional()
      .label("Qoute Submitted")
      .allow(null, "", 0),
    order_received: Joi.boolean()
      .optional()
      .label("Order received")
      .allow(null, "", 0),
  }),
});
const RfqResponseForm = ({ onSubmit }) => {
  const { saveLoading } = useSelector(quoteListSelector);
  const {
    handleSubmit,
    control,
    register,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    resolver: joiResolver(schema),
    defaultValues: {
      allfields: [
        {
          serviceProviderBusiness: null,
          item_name: "",
          supplierBusiness: null,
          supplier_contact_number_code: 971,
          supplier_contact_number: "",
          qoute_price: "",
          quantity: "",
          item_cost_price: "",
          warranty: "",
          qoute_submited: false,
          order_received: false,
        },
      ],
    },
  });
  const { append, remove, fields } = useFieldArray({
    control,
    name: "allfields",
  });

  const handleAddNewRow = () => {
    append({
      serviceProviderBusiness: null,
      item_name: "",
      supplierBusiness: null,
      supplier_contact_number_code: 971,
      supplier_contact_number: "",
      qoute_price: "",
      quantity: "",
      item_cost_price: "",
      warranty: "",
      qoute_submited: false,
      order_received: false,
    });
  };
  const handleRemoveRow = (index) => (e) => {
    remove(index);
  };
  const handlePhoneCodeChange = (e) => {
    setValue(e.target.name, e.target.value);
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {saveLoading && <PreLoaderWidget />}
      <div className="d-flex justify-content-end my-4 ">
        <Button onClick={handleAddNewRow}>Add new</Button>
      </div>
      {fields?.map((field, i) => (
        <StyledCard key={field.id}>
          <StyledCardHeader className="d-flex justify-content-between align-items-center bg-white">
            <h5>Qoute no. {i + 1}</h5>
            <Button onClick={handleRemoveRow(i)} variant="outline-secondary">
              remove
            </Button>
          </StyledCardHeader>
          <Card.Body>
            <Row>
              {Object.keys(field).map((fieldName, j) => {
                if (fieldName !== "id" && fieldsObject[fieldName]) {
                  const { columnProps, validationRules, ...restFieldProps } =
                    fieldsObject[fieldName];

                  return (
                    <Col key={`${field.id}-${j}`} {...columnProps}>
                      <Controller
                        name={`allfields.${i}.${restFieldProps?.name}`}
                        control={control}
                        render={({ field, fieldState: { error } }) => {
                          if (restFieldProps.type === "phoneNumber") {
                            return formField({
                              field: {
                                ...restFieldProps,
                                ...field,
                                phoneCodeProps: {
                                  name: `allfields.${i}.${fieldName}_code`,
                                  onChange: handlePhoneCodeChange,
                                  defaultValue: 971,
                                },
                                id: `allfields.${i}.${restFieldProps?.name}`,
                              },
                              error,
                            });
                          }
                          return formField({
                            field: {
                              ...restFieldProps,
                              ...field,
                              id: `allfields.${i}.${restFieldProps?.name}`,
                            },
                            error,
                          });
                        }}
                      />
                    </Col>
                  );
                }
              })}
            </Row>
          </Card.Body>
        </StyledCard>
      ))}
      <div className=" d-flex justify-content-end">
        <Button type="submit" variant="secondary">
          Submit
        </Button>
      </div>
    </form>
  );
};

export default RfqResponseForm;
