import React from "react";

const Status = ({ status }) => {
  return (
    <span className={`badge badge-soft-${status ? "success" : "danger"}`}>
      {status ? "Active" : "Inactive"}
    </span>
  );
};

export default Status;
