import { useState } from "react";
import { useDispatch } from "react-redux";
import PropType from "prop-types";
const useTableActions = (deleteAction = () => {}) => {
  const [open, setOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const dispatch = useDispatch();
  const handleDelete = (e) => {
    console.log(deleteId);
    dispatch(deleteAction({ id: deleteId }));
    setOpen(false);
  };
  const modalOpen = (id) => (e) => {
    setDeleteId(id);
    toggleModal();
  };
  const toggleModal = (e) => setOpen(!open);
  return {
    open,
    setOpen,
    handleDelete,
    toggleModal,
    modalOpen,
  };
};
export default useTableActions;
