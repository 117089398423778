import { joiResolver } from "@hookform/resolvers/joi";
import React, { useCallback, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  Col,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  Label,
  Row,
} from "reactstrap";
import FieldErrorText from "../../components/FormBuilder/FieldErrorText";
import { formField } from "../../components/FormBuilder/formField";
import PreLoaderWidget from "../../components/Loader";
import {
  getAllSupplierBusinessApi,
  getCarModel,
  getCity,
  getPartsCategoryOptionBySupplierType,
  getProvinceOption,
  getSingleCountry,
  getSpartPartsSubCategory,
  getVehicleMakeOption,
} from "../../services/api";
import { formatArrayField, formatDropdownData } from "../../utils/helpers";
import FileUpload from "../../components/FileUpload/FileUpload";
import { fields, schema } from "./formFieldsAdd";
import { appNotification, fetchCountryCode } from "../../redux/app/actions";
import MutliCheckboxSelect from "../../components/MultiCheckboxSelect/MultiCheckboxSelect";
import {
  clearSupplierBusinessUpdate,
  getSupplierBusinessUpdate,
  saveSupplierBusinessUpdate,
} from "../../redux/supplierBusinessUpdate/actions";

const AddSupplierBusiness = () => {
  const {
    supplierBusinessData: supplierBusiness,
    error: supplierError,
    loading,
    saveSuppplierBusinessUpdate: {
      loading: submitLoading,
      data: savedResponse,
      error,
    },
  } = useSelector((state) => state.SupplierBusinessUpdate);

  const dispatch = useDispatch();

  const reducedFields = {
    ...fields,
  };
  const [defaultData, setDefaultData] = useState({
    businessName: "",
    businessContactPerson: "",
    businessContactNumberCode: 971,
    businessContactNumber: null,
    dealershipTypeId: { id: 1, type: "Used spare parts supplier" },
    businessCity: "",
    vehicleMake: null,
    vehicleModel: null,
    category: null,
    subCategory: null,
    photos: null,
    gMapLink: null,
    userId: null,
    provider: "agent",
  });
  const [selectedCategory, setSelectedCategory] = useState([]);

  let newFields = reducedFields;

  const {
    register,
    handleSubmit,
    control,
    setFocus,
    setError,
    setValue,
    getValues,
    reset,
    formState: { errors },
  } = useForm({
    reValidateMode: "onChange",
    mode: "onChange",
    defaultValues: defaultData,
    resolver: joiResolver(schema(newFields)),
  });
  const history = useHistory();
  const handleCancel = useCallback((e) => history.goBack(), []);
  const [clearVehicleMake, setClearVehicleMake] = useState(false);
  const [clearCategory, setClearCategory] = useState(false);
  const { data: countryCode } = useSelector((state) => state.App.countryCode);
  const [categoryOption, setCategoryOption] = useState({
    isLoading: false,
    data: [],
  });
  const [vehicleMakeOption, setVehicleMakeOption] = useState({
    isLoading: false,
    data: [],
  });

  const onSubmit = (data) => {
    // const dealershipTypeId = formatArrayField(
    //   data?.dealershipTypeId,
    //   "dealershipTypeId",
    //   "id"
    // );

    const vehicleMake = formatArrayField(
      data?.vehicleMake,
      "vehicleMake",
      "id_car_make"
    );
    console.log("vehicleMake", vehicleMake);
    const category = formatArrayField(data?.category, "category", "value");
    let subCategory = null;
    if (data?.subCategory) {
      const subCategoryArr = Object.values(data?.subCategory)
        .flat()
        .filter((v) => v);
      subCategory = formatArrayField(subCategoryArr, "subCategory");
    }
    let vehicleModel = null;
    if (data?.vehicleModel && Object.keys(data?.vehicleModel)?.length) {
      vehicleModel = Object.keys(data?.vehicleModel)
        .map((v) =>
          data?.vehicleModel[v]
            .filter((vv) => vv)
            .map((v1) => ({
              makeId: v.split("_")[1],
              modelId: v1.toString(),
            }))
        )
        .flat();
    }

    const photos = formatArrayField(data?.photos, "photos");
    delete data.vehicleMake;
    delete data.vehicleModel;
    delete data.category;
    delete data.supplierBusiness;
    delete data.subCategory;
    delete data.photos;
    const formData = {
      ...data,
      provider: "agent",
      businessCity: data?.businessCity?.id,
      businessProvinceId: data?.businessCity?.provinceId,
      businessCountryId: data?.businessCity?.countryId,
      dealershipTypeId: data?.dealershipTypeId?.id,
      supplierType: data?.supplierType?.map((v) => v?.id),
      vehicleModel: JSON.stringify(vehicleModel),
      ...subCategory,
      ...vehicleMake,
      ...photos,
      ...category,
    };
    console.log("formData", formData);
    dispatch(
      saveSupplierBusinessUpdate({
        data: formData,
        onSuccess: (response) => {
          reset();
          setClearVehicleMake(true);
          setClearCategory(true);
          setClearVehicleMake(false);
          setClearCategory(false);
        },
        onFailed: (error) => {
          console.log(error);
          appNotification({
            type: "danger",
            message: "Oops something went wrong",
          });
        },
      })
    );
  };

  const [fieldWithExtraAttribute, setfieldWithExtraAttribute] =
    useState(reducedFields);

  const fetchCarModelOption = async (vehicleMakeId) => {
    const result = await getCarModel({
      search: "",
      vehicleMake: vehicleMakeId,
    });
    return result;
  };
  const fetchSubCategoryOption = async (categoryId) => {
    const result = await getSpartPartsSubCategory({
      search: "",
      categoryId: categoryId,
      level: 3,
    });
    if (result?.status === "Success") return result?.data;
    return [];
  };

  const fetchVehicleMakeOptions = async () => {
    setVehicleMakeOption((s) => ({ data: [], isLoading: true }));
    const vehicleMake = await getVehicleMakeOption("");
    setVehicleMakeOption((s) => ({
      data: vehicleMake,
      isLoading: false,
    }));
  };

  const fetchCategoryOption = async (
    selectedSupplierType,
    selectAll = false
  ) => {
    setCategoryOption((s) => ({ ...s, isLoading: true }));
    const result = await getSpartPartsSubCategory({
      search: "",
      level: 2,
      categoryId: selectedSupplierType?.id,
    });
    if (result?.data) {
      setCategoryOption((s) => ({
        data: {
          ...s?.data,
          [selectedSupplierType?.id]: {
            label: selectedSupplierType?.categoryName,
            value: selectedSupplierType?.id,
            options: [
              {
                key: -selectedSupplierType?.id,
                label: `${selectedSupplierType?.categoryName} (All)`,
                value: -selectedSupplierType?.id,
              },
              ...formatDropdownData(
                result?.data,
                "categoryName",
                "id",
                "parentsparePartCategoryId"
              ),
            ],
          },
        },
        isLoading: false,
      }));
      if (selectAll)
        setSelectedCategory((s) => [
          {
            key: -selectedSupplierType?.id,
            label: `${selectedSupplierType?.categoryName} (All)`,
            value: -selectedSupplierType?.id,
            ["data-parentsparePartCategoryId"]: selectedSupplierType?.id,
          },
          ...s,
        ]);
    }
  };

  const handleSupplierTypChange = (handler) => async (val, actionMeta) => {
    handler(val);
    if (actionMeta?.action === "select-option")
      await fetchCategoryOption(actionMeta?.option, true);
    if (actionMeta.action === "clear") setClearCategory(true);
    if (actionMeta.action === "remove-value") {
      const newCategoryOption = categoryOption?.data;
      delete newCategoryOption?.[actionMeta?.removedValue?.id];
      setCategoryOption((s) => ({ ...s, data: newCategoryOption }));
      setSelectedCategory((s) =>
        s?.filter(
          (v) =>
            v?.["data-parentsparePartCategoryId"] !==
            actionMeta?.removedValue?.id
        )
      );
    }
  };

  const handleImageUpload = (handler) => async (file) => {
    if (file?.target?.name && file?.target?.files[0]) {
      // let preview = [];
      // [...file.target.files].forEach(async (v) =>
      //   preview.push(await toBase64(v))
      // );
      setfieldWithExtraAttribute((state) => ({
        ...state,
        [file.target.name]: {
          ...state[file.target.name],
          binary: [...file.target.files],
          // preview,
        },
      }));
    }
    handler(file);
  };

  useEffect(() => {
    if (!countryCode) dispatch(fetchCountryCode());
    fetchVehicleMakeOptions();
    dispatch(clearSupplierBusinessUpdate());
  }, []);

  useEffect(() => {
    if (errors) {
      Object.keys(errors)?.forEach((k) => {
        console.log("error", errors[k]);

        appNotification({ type: "danger", message: errors[k]?.message });
      });
    }
  }, [errors]);
  useEffect(() => {
    if (error) {
      console.log("er", typeof error === "string", error);

      if (typeof error === "object") {
        Object.keys(error)?.forEach((k) => {
          setError(k, { type: "custom", message: error[k]?.[0] });
          if (k === "subCategory" || k === "vehicleModel") {
            appNotification({ type: "danger", message: error[k]?.[0] });
          }
        });
      }
      if (typeof error === "string") {
        dispatch(
          appNotification({
            type: "danger",
            message: error,
          })
        );
      }
    }
  }, [error]);
  return (
    <>
      <Card>
        <CardBody>
          {(loading || submitLoading) && <PreLoaderWidget />}
          <form onSubmit={handleSubmit(onSubmit)}>
            {
              <Row>
                {Object.keys(fieldWithExtraAttribute).map((key) => {
                  const fieldVal = fieldWithExtraAttribute[key];
                  const { columnProps, validationRules, ...fieldProps } =
                    fieldVal;
                  if (fieldVal?.type === "section") {
                    return (
                      <React.Fragment key={`${fieldVal?.id}-${key}`}>
                        <Col xs={12}>
                          <h5
                            style={{
                              borderBottom: "1px solid",
                              paddingBottom: 10,
                            }}
                          >
                            {fieldVal?.title}
                          </h5>
                        </Col>
                      </React.Fragment>
                    );
                  }

                  if (fieldVal?.type === "file" || fieldVal?.type === "image") {
                    const { ref, onChange } = register(fieldVal?.name);
                    return (
                      <Col {...columnProps} key={`${fieldVal?.id}-${key}`}>
                        <FormGroup>
                          <Label>{fieldProps?.label}</Label>
                          <FileUpload
                            fieldProps={fieldProps}
                            onChange={handleImageUpload(onChange)}
                            ref={ref}
                            type={fieldVal?.type}
                            preview={defaultData?.[fieldVal?.name] || null}
                          />
                          {errors?.[fieldProps?.name]?.message && (
                            <FieldErrorText
                              error={errors?.[fieldProps?.name]?.message}
                            />
                          )}
                        </FormGroup>
                      </Col>
                    );
                  }

                  if (
                    fieldVal?.type === "multiSelectCheckbox" &&
                    fieldVal.name === "vehicleMake"
                  ) {
                    return (
                      <Col {...columnProps} key={`${fieldVal?.id}-${key}`}>
                        <FormGroup>
                          <MutliCheckboxSelect
                            checkboxFieldLabel={"Vehicle model"}
                            checkboxFieldName={"vehicleModel"}
                            checkboxFieldLabelKey={"name"}
                            selectedDropDown={null}
                            selectedCheckbox={null}
                            checkboxFieldIdKey={"id_car_model"}
                            control={control}
                            getOptionLabel={(option) => option.name}
                            getOptionValue={(option) => option.id_car_make}
                            optionLabelKey="name"
                            optionValueKey="id_car_make"
                            dropdownFieldLabel="Vehicle make"
                            dropdownFieldName={"vehicleMake"}
                            fetchCheckboxOptionHandler={fetchCarModelOption}
                            getValues={getValues}
                            options={vehicleMakeOption?.data || []}
                            setValue={setValue}
                            isLoading={vehicleMakeOption?.isLoading}
                            clear={clearVehicleMake}
                          />
                        </FormGroup>
                      </Col>
                    );
                  }
                  if (
                    fieldVal?.type === "multiSelectCheckbox" &&
                    fieldVal?.name === "category"
                  ) {
                    return (
                      <Col {...columnProps} key={`${fieldVal?.id}-${key}`}>
                        <FormGroup>
                          <MutliCheckboxSelect
                            dropdownFieldLabel="Spare parts category"
                            dropdownFieldName={"category"}
                            isGroupOption
                            checkboxFieldLabel={"Sub category"}
                            checkboxFieldName={"subCategory"}
                            checkboxFieldLabelKey={"categoryName"}
                            selectedDropDown={selectedCategory}
                            selectedCheckbox={null}
                            // optionLabelKey="categoryName"
                            // optionValueKey="id"
                            checkboxFieldIdKey={"id"}
                            control={control}
                            fetchCheckboxOptionHandler={fetchSubCategoryOption}
                            getValues={getValues}
                            options={Object.values(categoryOption?.data) || []}
                            setValue={setValue}
                            isLoading={categoryOption?.isLoading}
                            clear={clearCategory}
                          />
                        </FormGroup>
                      </Col>
                    );
                  }
                  if (fieldVal.type === "hidden")
                    return (
                      <Controller
                        name={fieldProps?.name}
                        control={control}
                        render={({ field: { name, ref } }) =>
                          formField({ field: { type: "hidden", name, ref } })
                        }
                      />
                    );
                  if (fieldVal?.type === "phoneNumber") {
                    const { phoneCodeProps, ...phoneNumberProps } = fieldProps;
                    return (
                      <Col {...columnProps} key={`${fieldVal?.id}-${key}`}>
                        <FormGroup>
                          <Label for={phoneNumberProps?.id || "phoneNumber"}>
                            {phoneNumberProps?.label}
                          </Label>

                          <InputGroup>
                            <InputGroupAddon addonType="prepend">
                              <Controller
                                name={phoneCodeProps?.name}
                                control={control}
                                render={({
                                  field: { name, ref, onChange, value },
                                  fieldState: { error },
                                }) => (
                                  <Input
                                    type="select"
                                    ref={ref}
                                    onChange={onChange}
                                    value={value}
                                    name={name}
                                    className="input-group-text"
                                    required
                                  >
                                    {countryCode?.length &&
                                      countryCode.map(
                                        ({ phone, countryCode }) => (
                                          <option
                                            value={phone}
                                            key={countryCode}
                                          >
                                            {phone}
                                          </option>
                                        )
                                      )}
                                  </Input>
                                )}
                              />
                            </InputGroupAddon>
                            <Controller
                              name={phoneNumberProps?.name}
                              control={control}
                              render={({
                                field: { ref, onChange, value, name },
                                fieldState: { error },
                              }) => (
                                <Input
                                  ref={ref}
                                  onChange={onChange}
                                  name={name}
                                  value={value || ""}
                                  type="text"
                                  placeholder="Enter phone number"
                                  id="phoneNumber"
                                  required
                                  className={`${
                                    error?.phoneNumber?.[0]
                                      ? "is-invalid"
                                      : null
                                  } ${phoneNumberProps?.className}`}
                                />
                              )}
                            />
                          </InputGroup>
                          {errors?.[fieldProps?.name]?.message && (
                            <FieldErrorText
                              error={errors?.[fieldProps?.name]?.message}
                            />
                          )}
                        </FormGroup>
                      </Col>
                    );
                  }
                  return (
                    <Col {...columnProps} key={`${fieldVal?.id}-${key}`}>
                      <FormGroup>
                        <Controller
                          control={control}
                          name={fieldProps?.name}
                          render={({
                            field: { onChange, onBlur, value, ref, ...rest },
                            fieldState: { error },
                          }) => {
                            let formFieldState = {
                              field: {
                                ...fieldProps,
                                ...rest,
                                innerRef: ref,
                                onChange: fieldProps?.onChange
                                  ? fieldProps?.onChange(onChange)
                                  : onChange,
                                onBlur: fieldProps?.onBlur
                                  ? (e) => onBlur(fieldProps?.onBlur)
                                  : onBlur,
                                value: value || "",
                              },
                              error:
                                error || errors?.[fieldProps?.name]?.message,
                            };
                            if (fieldProps?.name === "supplierType") {
                              formFieldState = {
                                ...formFieldState,
                                field: {
                                  ...formFieldState.field,
                                  onChange: handleSupplierTypChange(onChange),
                                },
                              };
                            }
                            if (fieldProps.type === "switch")
                              formFieldState = {
                                ...formFieldState,
                                field: {
                                  ...formFieldState.field,
                                  checked: value,
                                },
                              };
                            if (fieldProps.type === "richText")
                              formFieldState = {
                                ...formFieldState,
                                field: {
                                  ...formFieldState.field,
                                  initialContent: defaultData?.description,
                                  onEditorContentChange: onChange,
                                },
                              };
                            return formField(formFieldState);
                          }}
                        ></Controller>
                      </FormGroup>
                    </Col>
                  );
                })}
              </Row>
            }
            <Row>
              <Col className="d-flex justify-content-end">
                <FormGroup>
                  <Button
                    type="button"
                    className="btn btn-light mr-1"
                    onClick={handleCancel}
                  >
                    Cancel
                  </Button>
                  <Button color="primary">Save</Button>
                </FormGroup>
              </Col>
            </Row>
          </form>

          {/* <FormBuilder
            schema={schema}
            fields={fields}
            onSubmit={handleSubmit}
          /> */}
        </CardBody>
      </Card>
    </>
  );
};

export default AddSupplierBusiness;
