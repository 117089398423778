import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import Select from "react-select";
import Loader from "../../../components/Loader";

import {
  Button,
  Card,
  CardBody,
  Col,
  CustomInput,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import PageTitle from "../../../components/PageTitle";
import { AvForm, AvInput, AvField } from "availity-reactstrap-validation";

import { saveWhatsAppGroup } from "../../../redux/whatsappGroup/actions";

const AddEditWhatsAppGroup = () => {
  const [defaultValue, setDefaultValue] = useState({
    groupName: "",
    status: false,
    groupUrl: "",
  });
  const params = useParams();
  const { data, loading } = useSelector((state) => state.WhatsAppGroup);
  const history = useHistory();
  const dispatch = useDispatch();
  const toggleSwitch = (e) =>
    setDefaultValue((s) => ({ ...s, status: !defaultValue.status }));

  const handleSubmit = (e, values) => {
    e.preventDefault();
    let data = { ...values };
    if (params?.id) data["id"] = params?.id;
    dispatch(
      saveWhatsAppGroup({
        data,
        history,
      })
    );
  };

  return (
    <>
      <Row className="page-title">
        <Col md={12}>
          <PageTitle
            breadCrumbItems={[
              {
                label: "WhatsApp group",
                path: "/whatsaap-group",
              },
              {
                label: "Add new",
                path: "/whatsapp-group/add",
                active: true,
              },
            ]}
            title={`Add new whatsApp group`}
          />
        </Col>
      </Row>
      <Card>
        <CardBody>
          {loading ? (
            <Loader />
          ) : (
            <AvForm onValidSubmit={handleSubmit}>
              <Row>
                <Col xs={12} md={8} lg={8}>
                  <FormGroup>
                    <Label for="groupName">Group name</Label>
                    <AvInput
                      type="text"
                      name="groupName"
                      id="groupName"
                      placeholder="Enter group name"
                      value={defaultValue?.groupName || ""}
                      required
                    />
                  </FormGroup>

                  <FormGroup>
                    <Label for="groupUrl">Group url</Label>
                    <AvInput
                      type="text"
                      name="groupUrl"
                      id="groupUrl"
                      placeholder="Enter group url"
                      value={defaultValue?.groupUrl || ""}
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="status">Status</Label>
                    <div>
                      <AvField
                        tag={CustomInput}
                        type="switch"
                        id="status"
                        name="status"
                        label="Active"
                        checked={defaultValue.status}
                        value={defaultValue.status}
                        onChange={toggleSwitch}
                      />
                    </div>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col className="d-flex justify-content-end">
                  <FormGroup>
                    <Button
                      type="button"
                      className="btn btn-light mr-1"
                      onClick={(e) => history.goBack()}
                    >
                      Cancel
                    </Button>
                    <Button color="primary">Save</Button>
                  </FormGroup>
                </Col>
              </Row>
            </AvForm>
          )}
        </CardBody>
      </Card>
    </>
  );
};

export default AddEditWhatsAppGroup;
