import { joiResolver } from "@hookform/resolvers/joi";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  Col,
  CustomInput,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  Label,
  Row,
} from "reactstrap";
import FileUploader from "../../components/FileUploader";
import FieldErrorText from "../../components/FormBuilder/FieldErrorText";
import { formField } from "../../components/FormBuilder/formField";
import GoogleMapInput from "../../components/GoogleMapInput";
import PreLoaderWidget from "../../components/Loader";
import PageTitle from "../../components/PageTitle";
import {
  getSupplierBusiness,
  saveSupplierBusiness,
} from "../../redux/supplierBusiness/actions";
import {
  getCarModel,
  getProvinceOption,
  getSingleCountry,
  getSpartPartsCategory,
  getSpartPartsSubCategory,
  getVehicleMakeOption,
} from "../../services/api";
import { formatArrayField, formatDropdownData } from "../../utils/helpers";
import FileUpload from "../../components/FileUpload/FileUpload";
import { fields, schema, verificationStatusOptions } from "./formFields";
import { toBase64 } from "../../helpers";
import { appNotification, fetchCountryCode } from "../../redux/app/actions";
import sanitizeHtml from "sanitize-html";
import Joi from "joi";
import PhoneNumber from "../../components/PhoneNumber/PhoneNumber";
import MutliCheckboxSelect from "../../components/MultiCheckboxSelect/MultiCheckboxSelect";

const workingDaysOptions = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

const AddEditShop = () => {
  const baseUrl = `/supplier/business`;
  const isEdit = false;
  const { error, data, supplierBusiness, loading } = useSelector(
    (state) => state.SupplierBusiness
  );
  const { user, loading: userLoading } = useSelector((state) => state.Auth);
  const params = useParams();

  const [defaultData, setDefaultData] = useState({
    userId: null,
    businessName: "",
    businessEmail: "",
    dealershipTypeId: "",
    businessContactPerson: "",
    businessContactNumber: "",
    businessContactNumberCode: "",
    businessCountryId: "",
    businessProvinceId: "",
    businessCity: "",
    businessAddress1: "",
    businessAddress2: "",
    // specialization: "",
    vehicleMake: null,
    vehicleModel: null,
    category: null,
    subCategory: null,
    description: "",
    licenceNumber: "",
    businessWebsite: "",
    logo: null,
    latitude: "",
    longitude: "",
    status: false,
    licenceDocument: null,
    // licenceNumberDocName: null,
    photos: null,
    workingDays: null,
    workingStartHours: null,
    workingEndHours: null,
    gMapLink: null,
    gMapPlusCode: null,
    provider: null,
  });
  let newFields = fields;
  if (user?.roles?.[0]?.role_slug === "supplier")
    newFields = {
      ...newFields,
      userId: {
        ...newFields.userId,
        validationRules: Joi.allow("", 0),
        value: user?.id,
      },
    };
  const {
    register,
    handleSubmit,
    control,
    setFocus,
    setError,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    reValidateMode: "onChange",
    mode: "onChange",
    defaultValues: defaultData,
    resolver: joiResolver(schema(newFields)),
  });
  const history = useHistory();
  const handleCancel = useCallback((e) => history.goBack(), []);
  const dispatch = useDispatch();

  const { data: countryCode } = useSelector((state) => state.App.countryCode);
  const [categoryOption, setCategoryOption] = useState({
    isLoading: false,
    data: [],
  });
  const [vehicleMakeOption, setVehicleMakeOption] = useState({
    isLoading: false,
    data: [],
  });

  const onSubmit = (data) => {
    // const dealershipTypeId = formatArrayField(
    //   data?.dealershipTypeId,
    //   "dealershipTypeId",
    //   "id"
    // );
    const vehicleMake = formatArrayField(
      data?.vehicleMake,
      "vehicleMake",
      "value"
    );
    const category = formatArrayField(data?.category, "category", "value");

    const vehicleModelArr = Object.values(data?.vehicleModel)
      .flat()
      .filter((v) => v);
    const subCategoryArr = Object.values(data?.subCategory)
      .flat()
      .filter((v) => v);
    let vehicleModel = null;
    if (data?.vehicleModel) {
      vehicleModel = Object.keys(data?.vehicleModel)
        .map((v) =>
          data?.vehicleModel[v]
            .filter((vv) => vv)
            .map((v1) => ({
              makeId: v.split("_")[1],
              modelId: v1.toString(),
            }))
        )
        .flat();
    }

    const subCategory = formatArrayField(subCategoryArr, "subCategory");
    let workingDays = [];
    let isWorkingDaysValid = true;
    console.log("data", data);
    if (data?.workingDays) {
      workingDays = data?.workingDays.map((v, i) => {
        if (v) {
          let startTime, endTime;
          if (data?.workingStartHours[i])
            startTime =
              typeof data?.workingStartHours[i] === "object"
                ? moment(new Date(data?.workingStartHours[i])).format(
                    "HH:mm:ss"
                  )
                : data?.workingStartHours[i];
          if (data?.workingEndHours[i])
            endTime =
              typeof data?.workingEndHours[i] === "object"
                ? moment(new Date(data?.workingEndHours[i])).format("HH:mm:ss")
                : data?.workingEndHours[i];
          if (!startTime) {
            setError(`workingStartHours[${i}]`, {
              message: "Start time is required",
            });
            isWorkingDaysValid = false;
          }
          if (!endTime) {
            setError(`workingEndHours[${i}]`, {
              message: "End time is required",
            });

            isWorkingDaysValid = false;
          }
          return {
            day: typeof v === "boolean" && v ? workingDaysOptions[i] : v,
            startTime,
            endTime,
          };
        }
      });
    }
    const photos = formatArrayField(data?.photos, "photos");
    delete data.vehicleMake;
    delete data.vehicleModel;
    delete data.category;
    delete data.subCategory;
    delete data.photos;
    delete data.logo;
    delete data.licenceDocument;
    const formData = {
      ...data,
      provider: data?.provider || "admin",
      userId: data?.userId?.id || data?.userId,
      businessCity: data?.businessCity?.id,
      businessProvinceId: data?.businessProvinceId?.id,
      businessCountryId: data?.businessCountryId?.id,
      dealershipTypeId: data?.dealershipTypeId?.id,
      supplierType: data?.supplierType?.[0]?.id,
      verification_status: data?.verification_status?.value,
      workingDays: JSON.stringify(workingDays.filter((v) => v)),
      description: encodeURIComponent(sanitizeHtml(data?.description)),
      vehicleModel: JSON.stringify(vehicleModel),
      ...subCategory,
      ...vehicleMake,
      ...photos,
      ...category,
    };
    if (fieldWithExtraAttribute?.logo?.binary?.[0])
      formData["logo"] = fieldWithExtraAttribute?.logo?.binary?.[0];
    if (fieldWithExtraAttribute?.licenceDocument?.binary?.[0])
      formData["licenceDocument"] =
        fieldWithExtraAttribute?.licenceDocument?.binary?.[0];
    console.log("formData", formData);
    if (isWorkingDaysValid)
      dispatch(saveSupplierBusiness({ data: formData, history }));
    else
      dispatch(
        appNotification({
          type: "danger",
          message: "Start or end time is required",
        })
      );
  };

  const onCityChange = (handler) => async (city) => {
    handler(city);
    const provinceOption = await getProvinceOption({
      countryId: city?.countryId,
      provinceId: city?.provinceId,
    });
    const countryOption = await getSingleCountry({ id: city?.countryId });
    setChildOptions([provinceOption], "businessProvinceId");
    setChildOptions([countryOption], "businessCountryId");
    setValue("businessProvinceId", provinceOption);
    setValue("businessCountryId", countryOption);
  };
  const setChildOptions = async (options, childOptionFieldName) => {
    setfieldWithExtraAttribute((state) => ({
      ...state,
      [`${childOptionFieldName}`]: {
        ...state?.[`${childOptionFieldName}`],
        options,
      },
    }));
  };

  const handleSupplierTypChange = (handler) => (val, actionMeta) => {
    handler(val);
    if (actionMeta?.action === "select-option")
      fetchCategoryOption(actionMeta?.option);
  };
  const [fieldWithExtraAttribute, setfieldWithExtraAttribute] = useState({
    ...fields,
    businessCity: {
      ...fields.businessCity,
      onChange: onCityChange,
      value: {},
    },
    supplierType: {
      ...fields?.supplierType,
      onChange: handleSupplierTypChange,
      value: {},
    },
  });

  const fetchCarModelOption = async (vehicleMakeId) => {
    const result = await getCarModel({
      search: "",
      vehicleMake: vehicleMakeId,
    });
    return result;
  };
  const fetchSubCategoryOption = async (categoryId) => {
    const result = await getSpartPartsSubCategory({
      search: "",
      categoryId: categoryId,
      level: 3,
    });
    if (result?.status === "Success") return result?.data;
    return [];
  };

  const fetchVehicleMakeOptions = async () => {
    setVehicleMakeOption((s) => ({ data: [], isLoading: true }));
    const vehicleMake = await getVehicleMakeOption("");
    console.log("vehicleMake", vehicleMake);
    setVehicleMakeOption((s) => ({
      data: formatDropdownData(vehicleMake, "name", "id_car_make"),
      isLoading: false,
    }));
  };

  const fetchCategoryOption = async (selectedSupplierType) => {
    setCategoryOption((s) => ({ ...s, loading: true }));
    const result = await getSpartPartsSubCategory({
      search: "",
      level: 2,
      categoryId: selectedSupplierType?.id,
    });
    if (result?.data)
      setCategoryOption((s) => ({
        data: [
          ...s?.data,
          {
            label: selectedSupplierType?.categoryName,
            value: selectedSupplierType?.id,
            options: formatDropdownData(result?.data, "categoryName", "id"),
          },
        ],
        loading: false,
      }));
  };

  const handleImageUpload = (handler) => async (file) => {
    if (file?.target?.name && file?.target?.files[0]) {
      // let preview = [];
      // [...file.target.files].forEach(async (v) =>
      //   preview.push(await toBase64(v))
      // );
      setfieldWithExtraAttribute((state) => ({
        ...state,
        [file.target.name]: {
          ...state[file.target.name],
          binary: [...file.target.files],
          // preview,
        },
      }));
    }
    handler(file);
  };

  useEffect(() => {
    if (!countryCode) dispatch(fetchCountryCode());
    fetchVehicleMakeOptions();
  }, []);

  useEffect(() => {
    if (error) {
      Object.keys(error).map((field) =>
        setError(field, { message: error?.[field]?.[0] })
      );
    }
  }, [error]);
  useEffect(() => {
    //edit actions
    if (params?.id) {
      dispatch(getSupplierBusiness(params?.id));
    }
  }, []);
  useEffect(() => {
    if (supplierBusiness && !loading) {
      const newSupplierBusiness = {
        ...supplierBusiness,
        businessCountryId: supplierBusiness?.country,
        businessProvinceId: supplierBusiness?.province,
        dealershipTypeId: supplierBusiness?.dealership_type,
        userId: supplierBusiness?.user,
        vehicleMake: supplierBusiness?.vehicle_makes,
        category: supplierBusiness?.spare_parts_categories,
        description: decodeURIComponent(supplierBusiness?.description),
      };

      delete newSupplierBusiness?.country;
      delete newSupplierBusiness?.dealership_type;
      delete newSupplierBusiness?.province;
      delete newSupplierBusiness?.user;
      delete newSupplierBusiness?.vehicle_makes;
      delete newSupplierBusiness?.spare_parts_categories;
      delete newSupplierBusiness?.created_at;
      delete newSupplierBusiness?.updated_at;
      delete newSupplierBusiness?.deleted_at;
      delete newSupplierBusiness?.registerNumber;
      delete newSupplierBusiness?.googleRating;
      delete newSupplierBusiness?.googleReview;
      delete newSupplierBusiness?.supplier_type;
      delete newSupplierBusiness?.businessCode;
      delete newSupplierBusiness?.motonexaRating;

      if (newSupplierBusiness?.working_days) {
        newSupplierBusiness?.working_days.forEach((v) => {
          const i = workingDaysOptions.indexOf(v?.working_day);
          setValue(`workingDays[${i}]`, v?.working_day);
          setValue(`workingStartHours[${i}]`, v?.working_start_time);
          setValue(`workingEndHours[${i}]`, v?.working_end_time);
        });
      }
      delete newSupplierBusiness?.working_days;

      Object.keys(newSupplierBusiness).map((key) => {
        setDefaultData((state) => {
          if (key === "licence_document") {
            return {
              ...state,
              licenceDocument: newSupplierBusiness["licence_document"],
            };
          }
          if (key === "photos") {
            return {
              ...state,
              photos: newSupplierBusiness?.photos,
            };
          }
          return {
            ...state,
            [key]: newSupplierBusiness?.[key],
          };
        });

        if (key === "status") setValue(key, newSupplierBusiness?.[key]);
        if (key !== "licence_document" && key !== "logo" && key !== "photos")
          setValue(key, newSupplierBusiness?.[key]);
        if (key === "verification_status")
          setValue(key, verificationStatusOptions[newSupplierBusiness?.[key]]);
      });
    }
  }, [supplierBusiness]);
  useEffect(() => {
    if (errors) {
      Object.keys(errors)?.forEach((k) => {
        console.log("error", errors[k]);

        appNotification({ type: "danger", message: errors[k]?.message });
      });
    }
  }, [errors]);

  return (
    <>
      <Row className="page-title">
        <Col md={12}>
          <PageTitle
            breadCrumbItems={[
              { label: "Supplier business", path: baseUrl },
              {
                label: "add",
                path: `${baseUrl}/add`,
                active: true,
              },
            ]}
            title={`${isEdit ? "Edit" : "Add new"} Supplier business`}
          />
        </Col>
      </Row>
      <Card>
        <CardBody>
          {loading && <PreLoaderWidget />}
          <form onSubmit={handleSubmit(onSubmit)}>
            {!userLoading && (
              <Row>
                {Object.keys(fieldWithExtraAttribute).map((key) => {
                  const fieldVal = fieldWithExtraAttribute[key];
                  const { columnProps, validationRules, ...fieldProps } =
                    fieldVal;
                  if (
                    fieldVal?.name === "userId" &&
                    user?.roles?.[0]?.role_slug === "supplier"
                  ) {
                    return (
                      <input
                        key={`${fieldVal?.id}-${key}`}
                        type="hidden"
                        value={user?.id}
                        {...register("userId", {
                          // valueAsNumber: true,
                          value: user?.id,
                        })}
                      />
                    );
                  }
                  if (fieldVal?.type === "section") {
                    return (
                      <React.Fragment key={`${fieldVal?.id}-${key}`}>
                        <Col xs={12}>
                          <h5
                            style={{
                              borderBottom: "1px solid",
                              paddingBottom: 10,
                            }}
                          >
                            {fieldVal?.title}
                          </h5>
                        </Col>
                      </React.Fragment>
                    );
                  }
                  if (fieldVal?.id === "workingDays")
                    return (
                      <Col xs={12} key={`${fieldVal?.id}-${key}`}>
                        <div>
                          {workingDaysOptions.map((v, i) => (
                            <div
                              className="d-flex"
                              key={`workingDays-${i}-${key}`}
                            >
                              <Col xs={6} md={4} lg={4}>
                                <FormGroup>
                                  {i === 0 && <Label>{fieldVal?.label}</Label>}
                                  <Controller
                                    control={control}
                                    name={`workingDays[${i}]`}
                                    render={({
                                      field: { ref, onChange, value, ...rest },
                                      fieldState: { error },
                                    }) => {
                                      return (
                                        <CustomInput
                                          {...rest}
                                          id={`${fieldVal.id}-${i}`}
                                          type="checkbox"
                                          checked={value || false}
                                          innerRef={ref}
                                          label={v}
                                          onChange={onChange}
                                          value={v || ""}
                                        />
                                      );
                                    }}
                                  />
                                </FormGroup>
                              </Col>
                              <Col xs={4} md={3} lg={2}>
                                <FormGroup>
                                  {i === 0 && <Label>Start time</Label>}
                                  <Controller
                                    name={`workingStartHours[${i}]`}
                                    control={control}
                                    render={({
                                      field,
                                      fieldState: { error },
                                    }) =>
                                      formField({
                                        field: {
                                          type: "timePicker",
                                          id: `${fieldVal.id}-${i}`,
                                          ...field,
                                        },
                                        error,
                                      })
                                    }
                                  />
                                </FormGroup>
                              </Col>
                              <Col xs={6} md={3} lg={2}>
                                <FormGroup>
                                  {i === 0 && <Label>End time</Label>}
                                  <Controller
                                    name={`workingEndHours[${i}]`}
                                    control={control}
                                    render={({
                                      field,
                                      fieldState: { error },
                                    }) =>
                                      formField({
                                        field: {
                                          type: "timePicker",
                                          id: `${fieldVal.id}-${i}`,
                                          ...field,
                                        },
                                        error,
                                      })
                                    }
                                  />
                                </FormGroup>
                              </Col>
                            </div>
                          ))}
                        </div>
                      </Col>
                    );
                  if (fieldVal?.type === "file" || fieldVal?.type === "image") {
                    const { ref, onChange } = register(fieldVal?.name);
                    return (
                      <Col {...columnProps} key={`${fieldVal?.id}-${key}`}>
                        <FormGroup>
                          <Label>{fieldProps?.label}</Label>
                          <FileUpload
                            fieldProps={fieldProps}
                            onChange={handleImageUpload(onChange)}
                            ref={ref}
                            type={fieldVal?.type}
                            preview={defaultData?.[fieldVal?.name] || null}
                          />
                          {errors?.[fieldProps?.name] && (
                            <FieldErrorText
                              error={errors?.[fieldProps?.name]}
                            />
                          )}
                        </FormGroup>
                      </Col>
                    );
                  }
                  if (fieldVal?.type === "gmaps") {
                    const { ref, onChange } = register(fieldVal?.name);
                    return (
                      <Col {...columnProps} key={`${fieldVal?.id}-${key}`}>
                        <FormGroup>
                          <Label>{fieldProps?.label}</Label>
                          <GoogleMapInput
                            inputProps={{
                              ...fieldProps,
                              id: fieldProps.id,
                              type: "text",
                              name: fieldProps.name,
                              onChange,
                            }}
                            ref={ref}
                            onPlaceSelected={(s) => console.log("s", s)}
                          />
                        </FormGroup>
                      </Col>
                    );
                  }
                  if (fieldVal?.type === "phoneNumber") {
                    // const {
                    //   ref: phoneNumberRef,
                    //   onChange: phoneNumberChangeHanlder,
                    // } = register(fieldVal?.name);
                    // const {
                    //   ref: phoneCodeRef,
                    //   onChange: phoneCodeChangeHanlder,
                    // } = register(fieldVal?.phoneCodeProps?.name);
                    const { phoneCodeProps, ...phoneNumberProps } = fieldProps;
                    return (
                      <Col {...columnProps} key={`${fieldVal?.id}-${key}`}>
                        <FormGroup>
                          <Label for={phoneNumberProps?.id || "phoneNumber"}>
                            {phoneNumberProps?.label}
                          </Label>

                          <InputGroup>
                            <InputGroupAddon addonType="prepend">
                              <Controller
                                name={phoneCodeProps?.name}
                                control={control}
                                render={({
                                  field: { name, ref, onChange, value },
                                  fieldState: { error },
                                }) => (
                                  <Input
                                    type="select"
                                    ref={ref}
                                    onChange={onChange}
                                    value={value}
                                    name={name}
                                    className="input-group-text"
                                    required
                                  >
                                    {countryCode?.length &&
                                      countryCode.map(
                                        ({ phone, countryCode }) => (
                                          <option
                                            value={phone}
                                            key={countryCode}
                                          >
                                            {phone}
                                          </option>
                                        )
                                      )}
                                  </Input>
                                )}
                              />
                            </InputGroupAddon>
                            <Controller
                              name={phoneNumberProps?.name}
                              control={control}
                              render={({
                                field: { ref, onChange, value, name },
                                fieldState: { error },
                              }) => (
                                <Input
                                  ref={ref}
                                  onChange={onChange}
                                  name={name}
                                  value={value}
                                  type="text"
                                  placeholder="Enter phone number"
                                  id="phoneNumber"
                                  required
                                  className={`${
                                    error?.phoneNumber?.[0]
                                      ? "is-invalid"
                                      : null
                                  } ${phoneNumberProps?.className}`}
                                />
                              )}
                            />
                          </InputGroup>

                          {/* <PhoneNumber
                            {...phoneNumberProps}
                            type="text"
                            ref={phoneNumberRef}
                            onChange={phoneNumberChangeHanlder}
                            phoneCodeProps={{
                              ...phoneCodeProps,
                              ref: phoneCodeRef,
                              onChange: phoneCodeChangeHanlder,
                            }}
                          /> */}
                        </FormGroup>
                      </Col>
                    );
                  }
                  if (
                    fieldVal?.type === "multiSelectCheckbox" &&
                    fieldVal.name === "vehicleMake"
                  ) {
                    return (
                      <Col {...columnProps} key={`${fieldVal?.id}-${key}`}>
                        <FormGroup>
                          <MutliCheckboxSelect
                            checkboxFieldLabel={"Vehicle model"}
                            checkboxFieldName={"vehicleModel"}
                            checkboxFieldLabelKey={"name"}
                            selectedDropDown={null}
                            selectedCheckbox={null}
                            checkboxFieldIdKey={"id_car_model"}
                            control={control}
                            dropdownFieldLabel="Vehicle make"
                            dropdownFieldName={"vehicleMake"}
                            fetchCheckboxOptionHandler={fetchCarModelOption}
                            getValues={getValues}
                            options={vehicleMakeOption?.data || []}
                            setValue={setValue}
                            isLoading={vehicleMakeOption?.isLoading}
                          />
                        </FormGroup>
                      </Col>
                    );
                  }
                  if (
                    fieldVal?.type === "multiSelectCheckbox" &&
                    fieldVal?.name === "category"
                  ) {
                    return (
                      <Col {...columnProps} key={`${fieldVal?.id}-${key}`}>
                        <FormGroup>
                          <MutliCheckboxSelect
                            dropdownFieldLabel="Spare parts category"
                            dropdownFieldName={"category"}
                            checkboxFieldLabel={"Sub category"}
                            checkboxFieldName={"subCategory"}
                            checkboxFieldLabelKey={"categoryName"}
                            selectedDropDown={null}
                            selectedCheckbox={null}
                            checkboxFieldIdKey={"id"}
                            control={control}
                            fetchCheckboxOptionHandler={fetchSubCategoryOption}
                            getValues={getValues}
                            options={categoryOption?.data}
                            setValue={setValue}
                            isLoading={categoryOption?.isLoading}
                          />
                        </FormGroup>
                      </Col>
                    );
                  }
                  if (fieldVal.type === "hidden")
                    return (
                      <Controller
                        name={fieldProps?.name}
                        control={control}
                        render={({ field: { name, ref } }) =>
                          formField({ field: { type: "hidden", name, ref } })
                        }
                      />
                    );
                  return (
                    <Col {...columnProps} key={`${fieldVal?.id}-${key}`}>
                      <FormGroup>
                        <Controller
                          control={control}
                          name={fieldProps?.name}
                          render={({
                            field: { onChange, onBlur, value, ref, ...rest },
                            fieldState: { error },
                          }) => {
                            let formFieldState = {
                              field: {
                                ...fieldProps,
                                ...rest,
                                innerRef: ref,
                                onChange: fieldProps?.onChange
                                  ? fieldProps?.onChange(onChange)
                                  : onChange,
                                onBlur: fieldProps?.onBlur
                                  ? (e) => onBlur(fieldProps?.onBlur)
                                  : onBlur,
                                value: value || "",
                              },
                              error,
                            };
                            if (fieldProps.type === "switch")
                              formFieldState = {
                                ...formFieldState,
                                field: {
                                  ...formFieldState.field,
                                  checked: value,
                                },
                              };
                            if (fieldProps.type === "richText")
                              formFieldState = {
                                ...formFieldState,
                                field: {
                                  ...formFieldState.field,
                                  initialContent: defaultData?.description,
                                  onEditorContentChange: onChange,
                                },
                              };
                            return formField(formFieldState);
                          }}
                        ></Controller>
                      </FormGroup>
                    </Col>
                  );
                })}
              </Row>
            )}
            <Row>
              <Col className="d-flex justify-content-end">
                <FormGroup>
                  <Button
                    type="button"
                    className="btn btn-light mr-1"
                    onClick={handleCancel}
                  >
                    Cancel
                  </Button>
                  <Button color="primary">Save</Button>
                </FormGroup>
              </Col>
            </Row>
          </form>

          {/* <FormBuilder
            schema={schema}
            fields={fields}
            onSubmit={handleSubmit}
          /> */}
        </CardBody>
      </Card>
    </>
  );
};

export default AddEditShop;
