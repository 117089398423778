import { joiResolver } from "@hookform/resolvers/joi";
import AudioRecorder from "../../components/AudioRecorder/AudioRecorder";
import Joi from "joi";
import { useEffect, useState } from "react";
import { Button, Card } from "react-bootstrap";
import { useFieldArray, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { appNotification } from "../../redux/app/actions";
import { saveRfqResponse } from "../../redux/rfqResponse/actions";
import FileUpload from "../../components/FileUpload/FileUpload";
import { Delete, Trash2 } from "react-feather";
import { getAllSupplierBusiness } from "../../redux/supplierBusiness/actions";
const manufacturerOptions = [
  { label: "Used spare parts", value: "Used spare parts" },
  // {
  //   label: 'New spare part (first copy)',
  //   value: 'New spare part (first copy)',
  // },
  // { label: 'Genuine spare parts', value: 'Genuine spare parts' },
  // { label: 'After market spare parts', value: 'After market spare parts' },
];
const RfqResponseDetailForm = ({ rfqRequestId, rfqRequest, afterSubmit }) => {
  const schema = Joi.object({
    rfqId: Joi.required(),
    rfqRequestId: Joi.required(),
    rfqRequestNo: Joi.required(),
    businessCode: Joi.any().required().label("Business code"),
    businessId: Joi.any().required().label("Business"),
    spareParts: Joi.array().items({
      sparePartName: Joi.optional().label("spare part name or number"),
      quantity: Joi.number()
        .required()
        .label("Quantity")
        .max(rfqRequest?.rfq_request?.quantity),
      return: Joi.number()
        .optional()
        .allow("", 0, null)
        .label("return")
        .max(30),
      warranty: Joi.number().required().label("warranty").max(365),
      manufacturer: Joi.optional(),
      photo: Joi.optional(),
      voiceNote: Joi.optional(),
      price: Joi.number()
        .label("Price")
        .messages({ "number.base": "Price field is required" }),
      description: Joi.optional().label("Description"),
    }),
  });
  const {
    rfqResponse: { error: formError, loading },
  } = useSelector((s) => s.RfqResponse);
  const [products, setProducts] = useState(1);
  const [previewFile, setPreviewFile] = useState();
  const [previewVoice, setPreviewVoice] = useState();
  const [selectedBusiness, setSelectedBusiness] = useState(null);
  const {
    businesses: {
      data: businessData,
      loading: businessLoading,
      error: businessError,
    },
  } = useSelector((s) => s.SupplierBusiness);

  const dispatch = useDispatch();
  const {
    handleSubmit,
    register,
    setValue,
    reset,
    control,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    resolver: joiResolver(schema),
    defaultValues: {
      rfqId: rfqRequest?.rfq_request?.rfq?.id,
      rfqRequestId,
      rfqRequestNo: rfqRequest?.rfq_request?.rfqRequestNo,
      businessCode: null,
      businessId: null,
      spareParts: [
        {
          sparePartName: null,
          description: null,
          manufacturer: manufacturerOptions[0]?.value,
          photo: [],
          voiceNote: [],
          quantity: 1,
          return: null,
          warranty: null,
          price: null,
        },
      ],
    },
  });
  const { append, remove, fields } = useFieldArray({
    control,
    name: "spareParts",
  });
  const { ref: businessCodeRef, ...restBusinessCode } =
    register("businessCode");

  const addProduct = () => {
    if (products < 6) {
      setProducts((s) => s + 1);
      append({
        sparePartName: null,
        description: null,
        photo: [],
        voiceNote: [],
        quantity: 1,
        price: null,
      });
    }
    if (products === 6)
      dispatch(
        appNotification({
          type: "danger",
          message: "You can add upto 6 spare parts in an RFQ",
        })
      );
  };
  const removeProduct = (i) => {
    setProducts((s) => s - 1);
    remove(i);
  };

  const handleVoiceNote =
    (name) =>
    ({ file, blobUrl }) => {
      setPreviewVoice((s) => ({ ...s, [name]: file }));
    };

  const removeFile = (fieldName, index, ref) => (e) => {
    setPreviewFile((s) => ({
      ...s,
      [fieldName]: s?.[fieldName]?.filter((_, i) => i !== index),
    }));
    ref.current.value = "";
  };
  const handleAfterSelectBusiness = (business) => {
    setValue("businessCode", business?.businessCode);
    setSelectedBusiness(business);
  };
  const onSubmit = (data) => {
    let spareParts = [];
    if (data?.spareParts) {
      spareParts = data?.spareParts?.map((v, i) => ({
        ...v,
        photo: v?.photo?.length ? [...v?.photo] : null,
        manufacturer: manufacturerOptions[0].value,
        voiceNote: previewVoice?.[`spareParts.${i}.voiceNote`]
          ? [previewVoice?.[`spareParts.${i}.voiceNote`]]
          : null,
      }));
    }

    console.log("data", {
      ...data,
      rfqId: rfqRequest?.rfq_request?.rfq?.id,
      rfqType: rfqRequest?.rfq_request?.rfq?.rfqType,
      businessId: selectedBusiness?.id,
      userId: selectedBusiness?.userId,
      spareParts,
    });
    dispatch(
      saveRfqResponse({
        payload: {
          ...data,
          rfqId: rfqRequest?.rfq_request?.rfq?.id,
          rfqType: rfqRequest?.rfq_request?.rfq?.rfqType,
          businessId: selectedBusiness?.id,
          userId: selectedBusiness?.userId,
          spareParts,
        },
        onSuccess: (successData) => {
          afterSubmit(successData);
          reset();
          setPreviewFile([]);
          setPreviewVoice([]);
        },
        onError: (error) => {
          dispatch(
            appNotification({
              type: "danger",
              message: "Oops something went wrong",
            })
          );
        },
      })
    );
  };

  const handlePhotoUpload = (files, event) => {
    setValue(event.target.name, files);
  };

  useEffect(() => {
    setValue("rfqRequestId", rfqRequestId);
    setValue("rfqRequestNo", rfqRequest?.rfq_request?.rfqRequestNo);
  }, [rfqRequest?.rfq_request]);

  useEffect(() => {
    dispatch(getAllSupplierBusiness("supplier"));
  }, []);
  console.log("errors", errors);
  useEffect(() => {
    if (!businessLoading && businessData?.data?.[0]) {
      setValue("businessCode", businessData?.data?.[0]?.businessCode);
      setSelectedBusiness(businessData?.data?.[0]);
    }
  }, [businessData?.data]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <input
        type="hidden"
        {...register("rfqId")}
        value={rfqRequest?.rfq_request?.rfq?.id}
      />
      <input type="hidden" {...register("rfqRequestId")} value={rfqRequestId} />
      <input
        type="hidden"
        {...register("rfqRequestNo")}
        value={rfqRequest?.rfq_request?.rfqRequestNo}
      />
      {!businessLoading && (
        <div
          className={`row ${
            businessData?.data?.length > 1 ? "d-block" : "d-none"
          }`}
        >
          <div className="col-sm-12 col-lg-4 mb-3">
            <div className="form-group">
              <label htmlFor="business-code">Suppliers</label>
              <Select
                {...restBusinessCode}
                id="business-code"
                ref={businessCodeRef}
                containerClass="form-control"
                getOptionLabel={(option) => option.businessName}
                getOptionValue={(option) => option.businessCode}
                options={businessData?.data ? businessData?.data : []}
                placeholder="Select business"
                onChange={handleAfterSelectBusiness}
                defaultValue={businessData?.data?.[0]}
              />
              {errors?.businessCode && (
                <span className="error-text">
                  {errors?.businessCode?.message}
                </span>
              )}
            </div>
          </div>
        </div>
      )}
      <div>
        <span>
          <small>
            <i>All (*) fields are required</i>
          </small>
        </span>

        {fields.map((v, i) => {
          const { onChange: fileChange, ...restFile } = register(
            `spareParts.${i}.photo`
          );
          const {
            ref: voiceRef,
            onChange: voiceChange,
            ...restVoice
          } = register(`spareParts.${i}.voiceNote`);
          const {
            ref: manufacturerRef,
            name: manufacturerName,
            ...restManufacturer
          } = register(`spareParts.${i}.manufacturer`);
          return (
            <Card key={`rfq-quote-${v?.id}`} className="mb-4">
              <Card.Header>
                <legend className="m-0 border-bottom-1 d-flex justify-content-between">
                  <span>Quotation No. {i + 1}</span>

                  {fields?.length > 1 ? (
                    <Button
                      variant="outline-danger"
                      onClick={(e) => removeProduct(i)}
                    >
                      <Trash2 size={16} />
                    </Button>
                  ) : null}
                </legend>
              </Card.Header>
              <Card.Body>
                <fieldset className="mb-2">
                  <div className="row">
                    <div className="col-sm-12 col-lg-4 mb-2 d-none">
                      <div className="form-group">
                        <label htmlFor="manufacturer">Type</label>
                        <Select
                          name={manufacturerName}
                          ref={manufacturerRef}
                          placeholder="Spare part manufacturer"
                          defaultValue={manufacturerOptions[0]}
                          options={manufacturerOptions}
                        />
                        {errors?.spareParts?.[i]?.manufacturer && (
                          <span className="error-text">
                            {errors?.spareParts?.[i]?.manufacturer?.message}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="col-sm-12 col-lg-2 mb-2">
                      <div className="form-group">
                        <label htmlFor="price">Price*</label>

                        <input
                          id="price"
                          {...register(`spareParts.${i}.price`)}
                          className="form-control"
                          placeholder="Price in AED"
                        />

                        {errors?.spareParts?.[i]?.price && (
                          <span className="error-text">
                            {errors?.spareParts?.[i]?.price?.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-sm-12 col-lg-4 mb-2 d-none">
                      <div className="form-group">
                        <label htmlFor="quantity">Quantity*</label>
                        <input
                          id="quantity"
                          {...register(`spareParts.${i}.quantity`)}
                          type="number"
                          defaultValue={1}
                          className="form-control"
                          placeholder="Quantity"
                        />
                        {errors?.spareParts?.[i]?.quantity && (
                          <span className="error-text">
                            {errors?.spareParts?.[i]?.quantity?.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-sm-12 col-lg-4 mb-2">
                      <div className="form-group">
                        <label htmlFor="warranty">Warranty* (in days)</label>
                        <input
                          id="warranty"
                          {...register(`spareParts.${i}.warranty`)}
                          type="number"
                          className="form-control"
                          placeholder="Warranty"
                        />
                        {errors?.spareParts?.[i]?.warranty && (
                          <span className="error-text">
                            {errors?.spareParts?.[i]?.warranty?.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-sm-12 col-lg-6">
                      <div className="form-group">
                        <label htmlFor="description">Description</label>
                        <textarea
                          {...register(`spareParts.${i}.description`)}
                          className="form-control"
                          placeholder="Description"
                          id="description"
                        ></textarea>
                      </div>
                    </div>
                    <div className="col-sm-12 col-lg-6 mb-2">
                      <FileUpload
                        {...restFile}
                        accept=".jpg,.png,.jpeg"
                        label="Photos (Optional)"
                        labelProps={{ style: { fontSize: 16 } }}
                        // reset={resetFiles.photos}
                        multiple
                        onChange={fileChange}
                        updateFilesCb={handlePhotoUpload}
                      />
                      {errors?.spareParts?.[i]?.photo && (
                        <span className="error-text">
                          {errors?.spareParts?.[i]?.photo?.[0]?.message}
                        </span>
                      )}

                      {/* <div className="form-group">
                        <label htmlFor="photos">Photos</label>
                        <label htmlFor={`photo-${i}`} style={{ width: '100%' }}>
                          <div className="photouplodbox">
                            <img
                              style={{ width: 24, marginRight: 5 }}
                              src={require('../../assets/images/uploadicon.png')}
                              className="img-fluid"
                            />
                            <span className="text-secondary">Upload photo</span>
                            <input
                              {...restFile}
                              id={`photo-${i}`}
                              ref={photoRef}
                              type="file"
                              accept="image/*"
                              multiple
                              className="d-none"
                              onChange={handlefileChange(fileChange)}
                            />
                            {errors?.spareParts?.[i]?.photo && (
                              <span className="error-text">
                                {errors?.spareParts?.[i]?.photo?.[0]?.message}
                              </span>
                            )}
                          </div>
                        </label>
                        {previewFile?.[`spareParts.${i}.photo`]?.length
                          ? previewFile?.[`spareParts.${i}.photo`]?.map((photo, j) => (
                              <div
                                className="file-upload-preview-wrapper"
                                key={`logo-spareParts.${photo?.name}${photo?.name?.length}.photo`}>
                                <div className="file-upload-preview-content">
                                  <img
                                    className="file-upload-image"
                                    src={photo?.base64}
                                    alt="your image"
                                  />
                                  <div className="file-upload-preview-text">
                                    <span>{photo?.name}</span>
                                    <span>{photo?.size / 1000}KB</span>
                                  </div>
                                </div>
                                <button
                                  type="button"
                                  onClick={removeFile(`spareParts.${i}.photo`, j, photoRef)}
                                  className="btn btn-outline-secondary btn-sm">
                                  <i className="fa fa-close" />
                                </button>
                              </div>
                            ))
                          : null}
                      </div> */}
                    </div>

                    <div className="col-sm-12 col-lg-6 mb-2">
                      <div className="form-group">
                        <label htmlFor="quantity">Voice note</label>
                      </div>
                      <AudioRecorder
                        onStop={handleVoiceNote(restVoice.name)}
                        maxRecordingTime={60}
                      />
                    </div>
                  </div>
                </fieldset>
              </Card.Body>
            </Card>
          );
        })}

        <div className="col-lg-12 mb-3">
          <Button
            variant="outline-primary"
            className="btn-block"
            onClick={addProduct}
          >
            <i className="fa fa-plus se-2"></i> Add another quote
          </Button>
        </div>
        <div className="row">
          <div className="col-12 d-flex justify-content-center">
            <div className="subMitBTN">
              <button
                type="submit"
                disabled={loading}
                className="btn btn-secondary"
              >
                {loading ? "Saving..." : "Submit quote"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default RfqResponseDetailForm;
