import axios from "axios";
import { getCsrfToken, getUserSession } from "../helpers/authUtils";
const source = axios.CancelToken.source();

axios.interceptors.request.use((instance) => {
  const user = getUserSession();
  const { csrfToken } = getCsrfToken();
  if (user) instance.headers.Authorization = `Bearer ${user?.token}`;
  instance.headers["X-Requested-With"] = "XMLHttpRequest";
  if (csrfToken) instance.headers["X-XSRF-TOKEN"] = csrfToken;
  instance.withCredentials = true;
  instance.cancelToken = source.token;
  return instance;
});

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    // @todo implement refresh token
    const user = getUserSession();

    if (error.response?.status === 419 && user?.token) {
      document.location.pathname = "account/logout";
    }

    return Promise.reject(error);
  }
);

export default axios;
