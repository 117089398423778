import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import Select from "react-select";
import Loader from "../../../components/Loader";

import {
  Button,
  Card,
  CardBody,
  Col,
  CustomInput,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import PageTitle from "../../../components/PageTitle";
import {
  AvForm,
  AvGroup,
  AvInput,
  AvFeedback,
  AvField,
} from "availity-reactstrap-validation";
import {
  getSparePartsCategory,
  saveSparePartsCategory,
} from "../../../redux/sparePartsCategory/actions";
import { toBase64 } from "../../../helpers";

const AddEditVehicleTrim = () => {
  const [defaultValue, setDefaultValue] = useState({
    categoryName: "",
    status: false,
    parentsparePartCategoryId: "",
    image: null,
  });
  const params = useParams();
  const { sparePartsCategory, data, loading } = useSelector(
    (state) => state.SparePartsCategory
  );
  const [parentCategoryList, setParentCategoryList] = useState([]);
  const isEdit = !!params?.id;
  const history = useHistory();
  const [preview, setPreview] = useState(null);
  const [image, setImage] = useState(null);
  const dispatch = useDispatch();
  const toggleSwitch = (e) =>
    setDefaultValue((s) => ({ ...s, status: !defaultValue.status }));

  const handleSubmit = (e, values) => {
    e.preventDefault();
    let data = { ...values };
    delete data.image;
    if (image) data = { ...data, image };
    if (params?.id) data["id"] = params?.id;
    if (data?.parentsparePartCategoryId)
      data["parentsparePartCategoryId"] =
        data?.parentsparePartCategoryId?.value;
    dispatch(
      saveSparePartsCategory({
        data,
        history,
      })
    );
  };
  const handlefileChange = async (e) => {
    const dataUrl = await toBase64(e.target.files[0]);
    setImage(e.target.files[0]);
    setPreview(dataUrl);
  };
  useEffect(() => {
    //edit actions
    if (params?.id) {
      dispatch(getSparePartsCategory(params?.id));
    }
  }, []);
  useEffect(() => {
    let options = [];
    if (data?.data) {
      options = data?.data
        ?.map((v) => {
          if (isEdit) {
            if (v?.status === 1 && v?.id !== parseInt(params?.id))
              return { value: v?.id, label: v?.categoryName };
          } else if (v?.status === 1)
            return { value: v?.id, label: v?.categoryName };
        })
        .filter((v) => v);
      setParentCategoryList(options);
    }
  }, [data?.data]);
  useEffect(() => {
    if (sparePartsCategory && !loading) {
      //   setStatus(sparePartsCategory?.status === 1);
      const { categoryName, parentsparePartCategoryId, status, image } =
        sparePartsCategory;
      const selectedParentCategory = parentCategoryList?.find(
        (v) => v?.value === parentsparePartCategoryId
      );
      console.log(
        "selectedParentCategory",
        parentsparePartCategoryId,
        selectedParentCategory,
        parentCategoryList
      );
      setDefaultValue((s) => ({
        categoryName,
        parentsparePartCategoryId: selectedParentCategory,
        status: status === 1,
        image,
      }));
    }
  }, [sparePartsCategory, loading, parentCategoryList]);
  return (
    <>
      <Row className="page-title">
        <Col md={12}>
          <PageTitle
            breadCrumbItems={[
              {
                label: "SpareParts categories",
                path: "/spare-parts-categories",
              },
              {
                label: `${isEdit ? "Edit" : "Add new"}`,
                path: "/spare-parts-categories/add",
                active: true,
              },
            ]}
            title={`${isEdit ? "Edit" : "Add new"} spare parts category`}
          />
        </Col>
      </Row>
      <Card>
        <CardBody>
          {loading ? (
            <Loader />
          ) : (
            <AvForm onValidSubmit={handleSubmit}>
              <Row>
                <Col xs={12} md={4} lg={4}>
                  <Label
                    for="image"
                    className="d-flex flex-column"
                    style={{ cursor: "pointer" }}
                  >
                    <span className="mb-4">Choose image</span>
                    <img
                      src={
                        preview ||
                        (defaultValue?.image
                          ? defaultValue?.image
                          : require("../../../assets/images/no-image.png"))
                      }
                      style={{ width: "50%" }}
                    />
                    <AvInput
                      type="file"
                      name="image"
                      id="image"
                      className="d-none"
                      onChange={handlefileChange}
                    />
                  </Label>
                </Col>
                <Col xs={12} md={8} lg={8}>
                  <FormGroup>
                    <Label for="parentsparePartCategoryId">
                      Parent category name
                    </Label>
                    <AvField
                      tag={Select}
                      name="parentsparePartCategoryId"
                      id="parentsparePartCategoryId"
                      placeholder="Select parent category name"
                      value={defaultValue.parentsparePartCategoryId || ""}
                      options={parentCategoryList}
                      style={{ zIndex: 2 }}
                      onChange={(v) =>
                        setDefaultValue((s) => ({
                          ...s,
                          parentsparePartCategoryId: v,
                        }))
                      }
                      blurInputOnSelect
                    />
                  </FormGroup>

                  <FormGroup>
                    <Label for="text">Category name</Label>
                    <AvInput
                      type="text"
                      name="categoryName"
                      id="text"
                      placeholder="Enter category name"
                      value={defaultValue?.categoryName || ""}
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="status">Status</Label>
                    <div>
                      <AvField
                        tag={CustomInput}
                        type="switch"
                        id="status"
                        name="status"
                        label="Active"
                        checked={defaultValue.status}
                        value={defaultValue.status}
                        onChange={toggleSwitch}
                      />
                    </div>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col className="d-flex justify-content-end">
                  <FormGroup>
                    <Button
                      type="button"
                      className="btn btn-light mr-1"
                      onClick={(e) => history.goBack()}
                    >
                      Cancel
                    </Button>
                    <Button color="primary">Save</Button>
                  </FormGroup>
                </Col>
              </Row>
            </AvForm>
          )}
        </CardBody>
      </Card>
    </>
  );
};

export default AddEditVehicleTrim;
